import React, { useState, useEffect } from 'react';
import BackofficeTable from '../../../../components/backoffice/table/index';
import { getSchedulesList as get, deleteSchedule as del } from '../../../../api/libelista';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import { InputCustom } from '../../../../components/input';
import SectionLoader from '../../../../components/loader/SectionLoader';
import { useLocation, useHistory } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import Select from 'react-select';
import './schedulesTable.scss';

const headers = {
  id: 'I.D.',
  title: 'Título',
  'display_type_schedule.0.name': 'Tipo de evento',
  status: 'Estado',
  start_date: 'Fecha de inicio',
  place: 'Lugar',
};
const SchedulesTable = (props) => {
  const { ActionsMenu, loadData } = props;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [type_schedule__slug, setType_Schedule__Slug] = useState(() => query.get('type_schedule__slug') || '');
  const [title, setTitle] = useState(() => query.get('title') || '');
  const [status, setStatus] = useState(() => query.get('status') || '');
  const [place, setPlace] = useState(() => query.get('place' || ''));
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showInputTypeScheduleSlug, setShowInputTypeScheduleSlug] = useState(false);
  const [showInputTitle, setShowInputTitle] = useState(false);
  const [showInputStatus, setShowInputStatus] = useState(false);
  const [showInputPlace, setShowInputPlace] = useState(false);
  const history = useHistory();
  loadData.colSpan = Object.keys(headers).length;

  const optionsTypeSchedule = [
    { value: 'firma-de-libros', label: 'Firma de libros' },
    { value: 'presentacion', label: 'Presentación' },
    { value: 'actividades-infantiles', label: 'Actividades Infantiles' },
  ];

  const optionsStatus = [
    { value: 'draft', label: 'Borrador' },
    { value: 'published', label: 'Publicado' },
    { value: 'hidden', label: 'Oculto' },
  ];

  const utilitiesWidth = {
    width: '200px',
  };

  const marginBottomContainer = {
    marginBottom: '55px',
  };

  const deleteQueryParams = () => {
    history.push('/administration/schedules');
  };

  const myInputs = () => {
    const showSearchButton = showInputTypeScheduleSlug || showInputTitle || showInputStatus || showInputPlace;
    const url = window.location.href;
    const includesQueryParams = url.includes('?');

    return (
      <div className="d-flex " style={marginBottomContainer}>
        {includesQueryParams && (
          <button className="btn  buttonDeleteQuery" onClick={() => deleteQueryParams()}>
            Volver
          </button>
        )}
        <Accordion
          defaultActiveKey="1"
          className={`${includesQueryParams ? 'modifyPosition' : 'searchButton'} buttonSearch`}
          style={{ marginTop: '15px' }}
        >
          <Accordion.Toggle className="btn btn-info w-100 ml-3">Búsqueda</Accordion.Toggle>
          <Accordion.Collapse className="ml-3 searchAccordion">
            <div className="d-flex flex-column ml-3">
              <button onClick={() => setShowInputTypeScheduleSlug(!showInputTypeScheduleSlug)}>Tipo de evento</button>
              <button onClick={() => setShowInputTitle(!showInputTitle)}>Título</button>
              <button onClick={() => setShowInputStatus(!showInputStatus)}>Estado</button>
              <button onClick={() => setShowInputPlace(!showInputPlace)}>Lugar</button>
            </div>
          </Accordion.Collapse>
        </Accordion>
        <form className={`${includesQueryParams ? 'modifyMargin' : 'form'}  d-flex `} onSubmit={searchData}>
          {showInputTypeScheduleSlug && (
            <div className="mr-3 " style={utilitiesWidth}>
              <Select
                options={optionsTypeSchedule}
                onChange={({ value }) => {
                  setType_Schedule__Slug(value);
                }}
                placeholder="Tipo de evento"
                className="inputFontSize "
              />
            </div>
          )}
          {showInputTitle && (
            <div className="mr-3 ">
              <InputCustom
                key={`hello`}
                type="text"
                value={title}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
                placeholder="Título"
                className="inputFontSize "
                style={{ zIndex: '1' }}
              />
            </div>
          )}
          {showInputStatus && (
            <div className="mr-3 " style={utilitiesWidth}>
              <Select
                options={optionsStatus}
                onChange={({ value }) => {
                  setStatus(value);
                }}
                placeholder="Estado"
                className="inputFontSize"
                style={{ zIndex: '1' }}
              />
            </div>
          )}
          {showInputPlace && (
            <div>
              <InputCustom
                key={`hello`}
                type="text"
                value={place}
                onChange={(event) => {
                  setPlace(event.target.value);
                }}
                placeholder="Lugar"
                className=" inputFontSize "
                style={{ zIndex: '1' }}
              />
            </div>
          )}
          {showSearchButton && (
            <div className="my-3 styleButton">
              <input className="btn btn-info" type="submit" value="Buscar" />
            </div>
          )}
        </form>
      </div>
    );
  };

  const getLoadData = async () => {
    setIsLoading(true);
    const { status: status1, data } = await get(1, 15, type_schedule__slug, title, status, place);
    if (status1 === 200) {
      setData(data.results);
    }
    setIsLoading(false);
    setType_Schedule__Slug('');
    setTitle('');
    setStatus('');
    setPlace('');
  };

  useEffect(() => {
    getLoadData();
  });

  const searchData = () => {
    history.push(
      `?&type_schedule__slug=${type_schedule__slug}&title=${title}&status=${status}&place=${place}&page=${1}&page_size=${15}/`
    );
    getLoadData();
  };

  const hasData = () => {
    if (data !== null && data !== undefined) {
      return Object.keys(data).length;
    } else {
      return false;
    }
  };

  const schedulesTableProps = {
    title: 'Actividades',
    data,
    headers,
    ActionsMenu,
    extraFormat: {
      start_date: 'date',
    },
    loadData,
    customParams: myInputs(),
  };
  if (isLoading) {
    return <SectionLoader />;
  } else if (hasData()) {
    return <BackofficeTable {...schedulesTableProps} />;
  } else {
    return (
      <>
        <div>No se encontraron datos</div>
        <button className="btn btn-info" onClick={() => deleteQueryParams()}>
          Volver
        </button>
      </>
    );
  }
};

SchedulesTable.request = {
  get,
  del,
};
SchedulesTable.itemClass = 'schedule';
SchedulesTable.requestParameter = 'id';

const SchedulesTablePage = () => <BackofficePagesManager PageViewer={SchedulesTable} />;
export default SchedulesTablePage;
