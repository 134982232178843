import React from "react";
import { useParams } from "react-router-dom";
import BackofficePagesManager from "../../../../components/backoffice/pagesManager";
import {
  getAuthor as get,
  createAuthor as create,
  editAuthor as edit,
} from "../../../../api/products";
import ActionsForm from "../../../../components/backoffice/actionsForm/index";
const AuthorsItem = ({ data = { url: "Placeholder" } }) => {
  const { action } = useParams();
  const inputFields = [
    {
      label: "Nombre del Autor",
      name: "nombre_autor",
      type: "text",
      required: true,
    },
  ];
  action === "detail" &&
    inputFields.push({
      label: "U.R.L",
      name: "url",
      type: "text",
      disabled: true,
    });
  const actionsFormProps = {
    inputFields,
    title: "Autor",
    subsectionPath: "Autor",
    data,
    requests: { edit, create },
    action,
  };
  return <ActionsForm {...actionsFormProps} />;
};
AuthorsItem.request = {
  get,
};
const AuthorsItemPage = () => (
  <BackofficePagesManager PageViewer={AuthorsItem} />
);
export default AuthorsItemPage;
