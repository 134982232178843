import React, { useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

import { recoverPassword } from "../../api/users";
import { InputCustom } from "../../components/input";

// import "./infoPages.scss";

const SendMailRecover = () => {
  const [feedback, setFeedback] = useState('')
  const [successSendEmail, setSuccessSendEmail] = useState(false)
  const [email, setEmail] = useState('')

  const onRecover = async (event) => {
    event.preventDefault()
    event.stopPropagation()

    setSuccessSendEmail(false)
    setFeedback('')

    if (email.length > 0) {
      const payload = { email }
      const { status, data } = await recoverPassword(payload)
      if (status === 200) {
        setSuccessSendEmail(true)
      } else if (status === 404) {
        setFeedback('El correo no existe o es incorrecto')
      } else {
        setFeedback('Problemas de conexión, inténtelo más tarde')
      }
    } else {
      setFeedback('Debes de ingresar un correo')
    }
  }

  return (
    <div className="container-fluid">
      <Row className="justify-content-center mainRow infoRow">
        <Col lg={8} className="mt-4">
          <div className="row">
            <div className="col-md-12">
              <h1 className="infoTitle">Reestablecer Contraseña</h1>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>Introduce tu correo electrónico:</p>
            </div>
            <div className="col-md-12 py-4" style={{ background: "#F8F8F8" }}>
              <p className="mb-0 inputTitle lato">Correo electrónico*</p>
              <Form onSubmit={onRecover}>
                <InputCustom
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Ingrese su correo electrónico"
                  type="email"
                  placeholder={'Ingrese su correo'}
                />
                <span className="feedback">{feedback}</span>
                {successSendEmail ? (
                  <Row className="alert-success">
                    <span>Se ha enviado un enlace a su correo</span>
                  </Row>
                ) : null}
                <Button
                  type="submit"
                  className="blue-btn px-4 py-1 lato"
                  style={{ fontSize: "15px", fontWeight: "500" }}
                >
                  ENVIAR
                </Button>
              </Form>
            </div>
          </div>
        </Col>
        <Col lg={3} className="mt-4">
          {/* <img src={Condiciones} alt="paper.png" className="infoImg" /> */}
        </Col>
      </Row>
    </div>
  );
};

export default SendMailRecover;
