import React, { useState, useEffect } from 'react';
import { getLibelistaProducts as get } from '../../../../api/libelista';
import BackofficeTable from '../../../../components/backoffice/table/index';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import { InputCustom } from '../../../../components/input';
import SectionLoader from '../../../../components/loader/SectionLoader';
import { useLocation, useHistory } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { deleteBook as del } from 'api/libros';
import './styleTableProducts.scss';
const headers = {
  get_url: 'Imagen',
  titulo: 'Nombre',
  is_ebook: 'Tipo de producto',
  'autor.0.nombre_autor': 'Autor',
  editorial: 'Sello/Proveedor',
  ean: 'ISBN',
  publicada: 'Estado',
};
const ProductsTable = (props) => {
  //loadData.colSpan = Object.keys(headers).length;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [titulo, setTitulo] = useState(() => query.get('titulo') || '');
  const [ean, setEan] = useState(() => query.get('ean') || '');
  const [autor, setAutor] = useState(() => query.get('autor') || '');
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showInputTitulo, setShowInputTitulo] = useState(false);
  const [showInputEan, setShowInputEan] = useState(false);
  const [showInputAutor, setShowInputAutor] = useState(false);
  const { managerData, ActionsMenu } = props;
  const history = useHistory();

  const showButtonSearch = showInputTitulo || showInputEan || showInputAutor;

  const deleteQueryParams = () => {
    history.push('/administration/products');
  };

  const myInputs = () => {
    const url = window.location.href;
    const includesQueryParams = url.includes('?');
    return (
      <div className="d-flex  ">
        {includesQueryParams && (
          <button className="btn  deleteQuery" onClick={() => deleteQueryParams()}>
            Volver
          </button>
        )}
        <Accordion defaultActiveKey="1" className={`${includesQueryParams ? 'modifySearch' : 'styleSearch'} `}>
          <Accordion.Toggle className="btn btn-info w-100">Búsqueda</Accordion.Toggle>
          <Accordion.Collapse className="buttonCollapse">
            <div className="d-flex flex-column">
              <button onClick={() => setShowInputTitulo(!showInputTitulo)}>Nombre</button>
              <button onClick={() => setShowInputEan(!showInputEan)}>ISBN</button>
              <button onClick={() => setShowInputAutor(!showInputAutor)}>Autor</button>
            </div>
          </Accordion.Collapse>
        </Accordion>
        <form className={`${includesQueryParams ? 'modifyMarginForm' : 'styleForm'} `} onSubmit={searchData}>
          {showInputTitulo && (
            <div className="mr-3 utilitiesWidth">
              <InputCustom
                key={`hello`}
                type="text"
                value={titulo}
                onChange={(event) => {
                  setTitulo(event.target.value);
                }}
                placeholder="Buscar por nombre"
              />
            </div>
          )}
          {showInputEan && (
            <div className="mr-3 utilitiesWidth">
              <InputCustom
                key={`hello`}
                type="text"
                value={ean}
                onChange={(event) => {
                  setEan(event.target.value);
                }}
                placeholder="Buscar por ISBN"
              />
            </div>
          )}
          {showInputAutor && (
            <div className="utilitiesWidth">
              <InputCustom
                key={`hello`}
                type="text"
                value={autor}
                onChange={(event) => {
                  setAutor(event.target.value);
                }}
                placeholder="Buscar por autor"
              />
            </div>
          )}
          {showButtonSearch && (
            <div className="my-3">
              <input role="button" className="btn btn-info buttonSearchStyle" type="submit" value="Buscar" />
            </div>
          )}
        </form>
      </div>
    );
  };

  useEffect(() => {
    getLoadData();
  }, []);

  const searchData = () => {
    history.push(`?&titulo=${titulo}&ean=${ean}&autor=${autor}&page=${1}&page_size=${15}/`);
    getLoadData();
  };

  const getLoadData = async () => {
    setIsLoading(true);
    const { status, data } = await get(1, 15, titulo, ean, autor);
    if (status === 200) {
      setData(data.results);
      console.log(data.results, 'holi');
    }
    setIsLoading(false);
    setTitulo('');
    setEan('');
    setAutor('');
  };

  const hasData = () => {
    if (data !== null && data !== undefined) {
      return Object.keys(data).length;
    } else {
      return false;
    }
  };

  const productsTableProps = {
    title: 'Productos',
    data,
    headers,
    ActionsMenu,
    extraFormat: {
      titulo: 'elipsis',
      get_url: 'image',
      is_ebook: 'bookFormat',
      stock: 'string',
      publicada: 'published',
    },
    customParams: myInputs(),
  };
  if (isLoading) {
    return <SectionLoader />;
  } else if (hasData()) {
    return <BackofficeTable {...productsTableProps} />;
  } else {
    return (
      <>
        <div>No se encontraron datos</div>
        <button className="btn  buttonDeleteQuery mt-5" onClick={() => deleteQueryParams()}>
          Volver
        </button>
      </>
    );
  }
};
ProductsTable.request = {
  get,
  del,
};
ProductsTable.itemClass = 'producto';
ProductsTable.requestParameter = 'id';
const ProductsTablePage = () => <BackofficePagesManager PageViewer={ProductsTable} />;
export default ProductsTablePage;
