import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Col } from 'react-bootstrap';
import { RiShoppingBagFill, RiCloseLine } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import delay from 'delay';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

//redux
import { cartSelector, removeItem } from '../../slices/cart';
import { useSelector } from 'react-redux';

// context
import { UserContext } from '../../context/userContext';

// own components
import DropMenuLogged from './DropMenuLogged';
import MiniCart from './MiniCart';

//helpers
import { unSetLocal } from '../../helpers';

//api
import { logoutApi } from '../../api/login';
import { logoutSuccess } from '../../slices/user';

const ProductBarDesktop = ({ brandLogo, menus, isLogged }) => {
  const { isSignUpModalOpen, showSignUpModal } = useContext(UserContext);
  const [showToast, setShowToast] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const location = useLocation();

  const handleCloseModal = useCallback(() => showSignUpModal(false), [showSignUpModal]);
  const handleShowModal = useCallback(() => showSignUpModal(true), [showSignUpModal]);

  const { items, countItems, countGiftCard, total, lastAddedItem, lastRemovedItem, isIncrease } =
    useSelector(cartSelector);
  const previousCounterValue = useRef(countItems);
  const dispatch = useDispatch();

  const [counterAnimProps, set] = useSpring(() => ({
    backgroundColor: 'white',
    transform: 'translateY(0px)',
    config: {
      tension: 160,
      friction: 20,
    },
  }));

  useEffect(() => {
    setModalShow(false);
  }, [location]);

  useEffect(() => {
    (async () => {
      if (countItems !== previousCounterValue.current) {
        // set({
        //   backgroundColor: "rgb(145, 214, 255)",
        //   transform: "translateY(-2px)",
        // });
        await delay(180);
        setShowToast(true);
        // set({ backgroundColor: "white", transform: "translateY(0px)" });
      }
    })();
  }, [countItems]);

  useEffect(() => {
    (async () => {
      if (showToast) {
        await delay(3000);
        setShowToast(false);
      }
    })();
  }, [showToast]);

  const closeSession = async () => {
    const { status } = await logoutApi({
      refresh_token: localStorage.getItem('refresh'),
    });
    if (status === 200) {
      unSetLocal('access');
      unSetLocal('refresh');
      unSetLocal('permissions');
      unSetLocal('type_user');
      dispatch(logoutSuccess());
    }
  };

  return (
    <Col lg={3} className="header__products d-none d-lg-block">
      {/* PRODUCTOS ADAPTACION A LIBELISTA */}

      <button id="dropTrolley" className="" onClick={() => setModalShow(true)}>
        <p className="info-cart-products">
          <span className="total">{total} €</span>
          <span className="divider">|</span>
          <span className="number-products">
            <span className="number">{`${countItems} ${countItems !== 1 ? 'productos' : 'producto'}`}</span>
          </span>

          <RiShoppingBagFill className="icon-md fas-shopping-bag" />
        </p>
      </button>
      {/* {countItems > 0 && (
                        <animated.span
                          style={counterAnimProps}
                          className="cart-counter"
                        >
                          {countItems}
                        </animated.span>
                      )} */}

      {/* {showToast && (
        <div className="cart-toast">
          <span>
            Has {isIncrease ? 'añadido ' : 'quitado '}
            {isIncrease ? (
              <strong>"{lastAddedItem}"</strong>
            ) : (
              <strong>"{lastRemovedItem}"</strong>
            )}{' '}
            {isIncrease ? 'al' : 'del'} carrito
          </span>
        </div>
      )} */}

      {/* MODAL CARRO COMPRA */}
      <div id="trolleyArea" style={{ display: modalShow ? 'block' : 'none' }}>
        <div className="modal-header">
          <h3 className="section cart">ÚLTIMOS PRODUCTOS AÑADIDOS</h3>
          <button className="close" onClick={() => setModalShow(false)}>
            <RiCloseLine className="icon-md fas-closing" />
          </button>
        </div>
        <div className="modal-body">
          {countItems === 0 ? (
            <h5>No hay productos</h5>
          ) : (
            // <div className="cart">{itemsShow}</div>
            <MiniCart isLogged={isLogged} />
          )}
        </div>
        {countItems !== 0 ? (
          <div className="modal-footer">
            <Link className="modal-footer__link bg-info" to="/carrito" onClick={() => setModalShow(false)}>
              COMPRAR
            </Link>
          </div>
        ) : null}
      </div>

      {/* BORRAR icono carrito y usuario */}

      <div
        // to="/carrito"
        // aria-label={`Carrito de compras (${countItems} items)`}
        className={`header__button header__button--desktopCart`}
      >
        {/* ICONO ANTERIOR CARRO*/}
        {/* <AiOutlineShoppingCart className="icon-md" /> */}

        {/* ICONO ACTUAL BOLSA BORRAR! */}
        {/* <RiShoppingBagFill className="icon-md" /> */}
        {/* {countItems > 0 && (
                <animated.span
                  style={counterAnimProps}
                  className="cart-counter"
                >
                  {countItems}
                </animated.span>
              )} */}

        {showToast && (
          <div className="cart-text" style={{ backgroundColor: isIncrease ? 'green' : 'red' }}>
            <span className="cart-text__detail d-lg-block">
              Has {isIncrease ? 'añadido ' : 'quitado '}
              {isIncrease ? <strong>"{lastAddedItem}"</strong> : <strong>"{lastRemovedItem}"</strong>}{' '}
              {isIncrease ? 'al' : 'del'} carrito
            </span>
          </div>
        )}
      </div>

      {/* ICONO DE USUARIO CUANDO NO CONECTADO - BORRAR!!! - */}
      {/* {isLogged ? (
        <DropMenuLogged dropDirection="down" closeSession={closeSession} />
      ) : (
        <button
          className="header__button header__button--user"
          aria-label="usuario"
          onClick={handleShowModal}
        >
          <AiOutlineUser role="none" className="icon-md" />
        </button>
      )} */}
    </Col>
  );
};

export default ProductBarDesktop;
