import React from "react";
import { Row, Col } from "react-bootstrap";

import Condiciones from "../../assets/img/img_condiciones.png";
import "./infoPages.scss";

const TermsOfUse = () => {
  return (
    <div className="container-fluid mainDiv">
      <Row className="justify-content-center mainRow infoRow">
        <Col lg={8} className="mt-4">
          <div className="row">
            <div className="col-md-12">
              <h1 className="infoTitle">Condiciones de uso</h1>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" id="static-content">
              <h2>Identidad del titular de la web</h2>

              <p>&nbsp;</p>

              <p>
                En cumplimiento de la Ley 34/2002 de Servicios de la Sociedad de
                la Información y del Comercio Electrónico (*LSSICE), os
                informamos que la titularidad del dominio de la tienda virtual
                de libros digitales y físicos&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>,
                designado a continuación como Libelista, corresponde a Libelista
                SL, sociedad legalmente constituida en España con CIF B-65815706
                e inscrita en el Registro Mercantil de Barcelona el 26 de junio
                de 2012, Tomo 43.248, Hoja B 421443, Folio 83, Inscripción 1.
              </p>

              <p>
                Para cualquier consulta, puede contactar con Libelista a través
                del e-mail
                <a href="mailto:info@libelista.com">info@libelista.com</a>.
                Nuestro horario de atención es de 10:00 a 18:00 h. Las
                condiciones generales de venta detalladas en este documento
                regulan las relaciones contractuales entre cualquier usuario del
                sitio web&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>&nbsp;y
                de Libelista (a continuación, designado como el “Usuario”) y
                Libelista SL.
              </p>

              <p>
                Estas condiciones generales de venta son las únicas aplicables y
                sustituyen a cualquier otra condición, excepto derogación
                previa, expresa y escrita. Libelista SL se puede ver obligado
                puntualmente a modificar algunas de las disposiciones de sus
                condiciones generales, por lo cual es necesario volver a leer
                estas antes de cada visita a la web de&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>. Estas
                modificaciones son oponibles a partir de su puesta en línea y no
                pueden aplicarse a los contratos celebrados anteriormente. Cada
                compra en el portal está regulada por las condiciones generales
                aplicables en la fecha del pedido, que tiene que aceptar el
                Usuario después de haberlas leído.
              </p>

              <p>&nbsp;</p>

              <p>&nbsp;</p>

              <h2>Uso correcto del sitio web</h2>

              <p>&nbsp;</p>

              <p>
                El acceso a&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>
                &nbsp;por parte de los usuarios es libre y gratuito y no exige
                la previa suscripción o registro.&nbsp; Aun así, para acceder a
                algunos contenidos ofrecidos por esta página, el Usuario tendrá
                que estar&nbsp;incluido en nuestra base de datos.
              </p>

              <p>
                El Usuario se obliga a utilizar en la web&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>
                &nbsp;los contenidos en conformidad con lo que prevé la Ley, en
                este Aviso Legal, las buenas costumbres y el orden público. Así
                mismo, el Usuario se obliga a no utilizar los servicios que se
                presten a través de la web&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>
                &nbsp;con fines o efectos ilícitos o contrarios a la Ley o al
                contenido de este Aviso Legal, lesivos de los intereses o
                derechos de terceros, o que pueda dañar, inutilizar o deteriorar
                el portal&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>&nbsp;o
                impedir una normal utilización
                de&nbsp;www.libelista.com&nbsp;por los otros usuarios.
              </p>

              <p>
                Asimismo, el Usuario se compromete expresamente a no destruir,
                alterar, inutilizar o, de cualquier otra forma, dañar los datos,
                programas o documentos electrónicos y otros que se encuentren
                en&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>.
              </p>

              <p>
                El Usuario se compromete a no obstaculizar el acceso de otros
                usuarios al servicio mediante el consumo masivo de los recursos
                informáticos a través de los cuales Libelista SL presta el
                servicio, así como realizar acciones que dañen, interrumpan o
                generen errores a estos sistemas. El Usuario se compromete a no
                introducir programas, virus, macros, applets, controles ActiveX
                o cualquier otro dispositivo lógico o secuencia de caracteres
                que causen o sean susceptibles de causar cualquier tipo de
                alteración en los sistemas informáticos de Libelista S.L. o de
                terceros.
              </p>

              <p>&nbsp;</p>

              <p>&nbsp;</p>

              <h2>Aceptación de las condiciones de uso</h2>

              <p>&nbsp;</p>

              <p>
                Este Aviso Legal tiene como objeto regular el acceso, navegación
                y uso del portal de la web&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>, que
                Libelista SL pone a disposición del público, así como todas las
                responsabilidades derivadas de la utilización de su contenido.
                El acceso o la utilización de&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>
                &nbsp;por un tercero le atribuyen la condición de Usuario y
                comporta la aceptación por este Usuario de todas y cada una de
                las condiciones que se incorporan en el presente Aviso Legal.
                Libelista SL puede ofrecer, a través de&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>,
                productos que se pueden encontrar sometidos a unas condiciones
                particulares propias que, según los casos, sustituyen, completan
                y / o modifican el presente Aviso Legal. En este caso, el
                Usuario tendrá que ser informado de las mismas y, en caso de ser
                necesario, contar con su consentimiento expreso.
              </p>

              <p>
                Antes de utilizar y / o contratar estos servicios específicos
                prestados a&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>, el
                Usuario tendrá que leer atentamente las correspondientes
                condiciones particulares creadas a tal efecto. La utilización y
                / o la contratación de estos servicios específicos implican la
                aceptación de las condiciones particulares que los regulan en la
                versión publicada por Libelista SL en el momento en que se
                produzca esta utilización y/o contratación. OBSERVACIONES DEL
                USUARIO El Usuario garantiza que la información, material,
                contenidos u observaciones que no sean sus propios datos
                personales y que sean facilitados a Libelista SL a través de
                la&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>&nbsp;y
                de Libelista no infringen los derechos de propiedad intelectual
                o industrial de terceros, ni ninguna otra disposición legal. La
                información, materiales, contenidos u observaciones que el
                Usuario facilite a Libelista SL se considerarán no
                confidenciales, reservando Libelista&nbsp;SL el derecho a
                usarlos de la manera que considere más adecuada.
              </p>

              <p>&nbsp;</p>

              <p>&nbsp;</p>

              <h2>Régimen de responsabilidad</h2>

              <p>&nbsp;</p>

              <p>
                <strong>
                  Responsabilidad por el uso de&nbsp;
                  <a href="https://www.libelista.com">www.libelista.com</a>:
                </strong>{" "}
                El Usuario es el único responsable de las infracciones en que
                pueda incurrir o de los perjuicios que pueda causar por la
                utilización del portal de la web&nbsp;www.libelista.com,
                quedando completamente exonerados Libelista SL, sus socios,
                empresas del grupo, colaboradores, empleados y representantes,
                de cualquier clase de responsabilidad que se pudiera derivar por
                las acciones realizadas por el Usuario. El Usuario se hace único
                responsable ante cualquier reclamación o acción legal, judicial
                o extrajudicial, iniciada por terceras personas contra Libelista
                SL basadas en la utilización realizada por el Usuario de la
                web&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>. En
                este caso, el Usuario se hará responsable de todos los gastos,
                costes e indemnizaciones que sean imputados a La Compañía con
                motivo de las reclamaciones o acciones legales anteriormente
                especificadas.
              </p>

              <p>
                Responsabilidad por el funcionamiento de la web. Libelista SL no
                se hace responsable de las interferencias, omisiones,
                interrupciones, virus informáticos, averías telefónicas o
                desconexiones que pudiera sufrir el sistema electrónico,
                motivado por causas ajenas a la voluntad de Libelista SL.
                Asimismo, Libelista SL excluye cualquier responsabilidad
                derivada por retrasos o bloqueos en el funcionamiento de&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>
                &nbsp;causados por deficiencias o sobrecarga en las líneas
                telefónicas o Internet, así como de daños causados por terceras
                personas mediante intromisiones ilegítimas.
              </p>

              <p>
                La Compañía podrá suspender temporalmente, y sin previo aviso,
                la accesibilidad a&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>, con
                motivo de operaciones de mantenimiento, reparación,
                actualización o mejora. Aun así, siempre que nos sea posible se
                procurará advertir de estas operaciones con tiempo de
                antelación.
              </p>

              <p>
                <strong>Responsabilidad por links:</strong> Los enlaces o links
                contenidos en&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>&nbsp;y
                en las tiendas asociadas pueden conducir al Usuario a otros
                sitios web gestionados por terceros. En este caso, Libelista SL
                no se hace responsable de la información que se encuentre fuera
                de&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>
                &nbsp;(y de sus otros dominios afines), puesto que la función de
                los enlaces que aparecen es únicamente la de informar al Usuario
                sobre la existencia otras fuentes de información sobre un tema
                en concreto. Libelista SL&nbsp; no se hará responsable del
                funcionamiento de estos enlaces, ni del contenido de los mismos
                ni de los perjuicios que el Usuario pudiera sufrir por la visita
                a estos enlaces.
              </p>

              <p>
                <strong>Responsabilidad por foros:</strong> Libelista SL ofrece
                a los usuarios la posibilidad de introducir comentarios para
                incorporarlos en las secciones correspondientes. La publicación
                de los comentarios está sujeta al presente Aviso Legal. El
                Usuario que introduzca comentarios tiene que estar registrado en
                esta página web y será el único responsable de los mismos. Los
                comentarios, en ningún caso, reflejan la opinión de Libelista SL
                ni hace declaraciones sobre esto. La Compañía no será
                responsable de cualquier error, inexactitud o irregularidad que
                puedan contener los comentarios contenidos en el foro. Libelista
                SL se reserva el derecho a suprimir los comentarios que sean
                contrarios a las leyes, o atenten contra la dignidad o intimidad
                de las personas. La utilización del foro implica la aceptación
                de todas y cada una de las cláusulas establecidas en este aviso
                legal.
              </p>

              <p>&nbsp;</p>

              <p>&nbsp;</p>

              <h2>Propiedad intelectual e industrial</h2>

              <p>&nbsp;</p>

              <p>
                Todos los contenidos de&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>&nbsp;
                y de las tiendas asociadas, salvo que se indique lo contrario,
                son titularidad exclusiva de su correspondiente editorial o
                proveedor y Libelista SL garantiza tener autorización expresa
                para comercializar estos contenidos en su nombre, incluyendo,
                con carácter enunciativo, y en ningún caso limitativo, el diseño
                gráfico, código fuente, logotipos, textos, gráficos,
                ilustraciones o fotografías.&nbsp;Libelista SL dispone de
                autorización por parte del proveedor de explotación de todos
                aquellos nombres comerciales, marcas o signos distintivos de
                cualquier clase. La visita a la web&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>
                &nbsp;no implica en ningún caso la concesión de ningún tipo de
                licencia o autorización de uso personal a favor del Usuario
                sobre sus derechos de propiedad intelectual e industrial o sobre
                cualquier otro derecho relacionado con las 'tiendas' y los
                servicios que se ofrecen. Por eso, el Usuario reconoce que la
                reproducción, distribución, comercialización, transformación, y
                en general, cualquier otra forma de explotación, por cualquier
                procedimiento, de todo o parte de los contenidos de&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>
                &nbsp;constituye una infracción de los derechos de propiedad
                intelectual y / o industrial del titular de la web o del titular
                de los mismos.
              </p>

              <p>&nbsp;</p>

              <p>&nbsp;</p>

              <h2>Modificaciones de las condiciones de uso</h2>

              <p>&nbsp;</p>

              <p>
                Libelista SL se reserva el derecho a modificar, desarrollar o
                actualizar en cualquier momento y sin aviso previo este Aviso
                Legal. El Usuario quedará obligado automáticamente por el Aviso
                Legal que esté vigente en el momento en que acceda a la&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>&nbsp;y
                a Libelista, por lo cual tendrá que leer periódicamente este
                Aviso Legal.
              </p>

              <p>&nbsp;</p>

              <p>&nbsp;</p>

              <h2>Legislación aplicable y competencia jurisdiccional</h2>

              <p>&nbsp;</p>

              <p>
                En el supuesto de que surja cualquier conflicto o discrepancia
                en la interpretación o aplicación de este aviso legal, los
                Juzgados y Tribunales que, si procede, conocerán el asunto,
                serán los que dispongan la normativa legal aplicable en materia
                de jurisdicción competente, en la que se atiene, tratándose de
                consumidores finales, al lugar del cumplimiento de la obligación
                o al del domicilio de la parte compradora. En el supuesto de que
                la parte compradora tenga su domicilio fuera de España o que se
                trate de una compraventa realizada por una empresa, ambas partes
                se someten, con renuncia expresa a cualquier otro fuero, a los
                Juzgados y Tribunales de Barcelona (España).
              </p>

              <p>&nbsp;</p>

              <p>&nbsp;</p>

              <h2>Publicidad</h2>

              <p>&nbsp;</p>

              <p>
                Partes de&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>&nbsp;
                pueden albergar contenidos publicitarios o pueden estar
                patrocinados. Los anunciantes y patrocinadores son los únicos
                responsables del material remitido para su inclusión, obligando
                al cumplimiento de las leyes que puedan ser aplicables. En
                ningún caso, Libelista SL será responsable de errores,
                inexactitudes o irregularidades que puedan contener los
                contenidos publicitarios o de los patrocinadores. Los usuarios
                pueden dirigir cualquier reclamación relacionada con la
                información publicitaria incluida en este sitio web a la
                siguiente dirección de correo electrónico{" "}
                <a href="mailto:info@libelista.com">info@libelista.com</a>.
              </p>

              <p>&nbsp;</p>

              <p>&nbsp;</p>

              <h2>
                Condiciones especiales de los contratos celebrados por vía
                electrónica por los usuarios con Libelista S.L
              </h2>

              <p>&nbsp;</p>

              <p>
                <strong>Condiciones Generales:</strong> Las presentes
                condiciones regulan los contratos que el Usuario celebre con
                Libelista SL y son las únicas aplicables, reemplazando a
                cualquier o tra condición, excepto en el supuesto de que sean
                anuladas previamente, de manera expresa y por escrito. Al
                celebrar cualquier contrato el Usuario tiene que leer y aceptar
                sin reservas, marcando la opción 'Acepto', las condiciones que
                se encuentran en vigor, puesto que las mismas regulan el
                contrato subscrito por el Usuario. La celebración del contrato
                por parte del Usuario supone la aceptación de las condiciones
                generales reguladoras del mismo, que han estado a disposición
                del Usuario previamente.
              </p>

              <p>
                <strong>Pedidos:</strong> El cliente puede formular pedidos de
                productos o de servicios únicamente a través de las páginas de
                compra de la&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>,
                siempre que previamente se haya registrado como cliente,
                facilitando la totalidad de los datos que se le solicitan, entre
                los cuales tiene que figurar obligatoriamente una dirección de
                correo electrónico en la cual realizar las comunicaciones
                relativas al contrato. Hace falta, en todo caso, facilitar una
                dirección electrónica en la que el cliente pueda recibir las
                comunicaciones necesarias para la ejecución del contrato,
                contenidas en estas condiciones generales o en las condiciones
                particulares.
              </p>

              <p>
                <strong>Información sobre los productos:</strong> La información
                relativa a los bienes y servicios que se ofrecen a&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>&nbsp;
                se hará mediante la descripción de sus características, así como
                de sus prestaciones.
              </p>

              <p>
                <strong>Información sobre los precios:</strong> En cumplimiento
                del que prevé la Ley 34/2002, de 11 de julio, de servicios de la
                sociedad de la información y de comercio electrónico, los
                productos ofrecidos al Portal&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>&nbsp;
                contienen el precio completo, incluidos los impuestos, o
                presupuesto, y se desglosa, si procede, el importe de los
                incrementos o descuentos que se aplican, de los gastos que
                repercutan al consumidor y usuario, y de los gastos adicionales
                por servicios accesorios, especialmente los gastos de envío o
                transporte, los gastos de financiación y las otras condiciones
                de pago. El precio de los productos ofrecidos en los catálogos y
                listas de precios del Portal son variables a lo largo del
                tiempo. Por eso, a cada contrato, es aplicable el precio del
                bien que figure en el Portal cuando el Usuario haga el pedido
                del producto contratado. El Usuario no tendrá derecho a rebajas
                en el precio una vez haya formulado el pedido, ni Libelista SL
                puede aumentar el precio de los productos que haya adquirido el
                cliente, teniendo que respetar en todo caso el precio vigente
                aplicable al celebrar el contrato.
              </p>

              <p>
                <strong>Información y condiciones sobre el pago:</strong> El
                Usuario puede pagar a Libelista SL el precio de los productos
                contratados por cualquier de los mecanismos que consten en las
                condiciones particulares que se apliquen al contrato celebrado
                por el Usuario. A todos los efectos, el pago podrá hacerse
                únicamente mediante el uso de tarjetas de crédito o de débito.
                El Usuario tiene que pagar el precio del producto contratado en
                el plazo fijado en las condiciones particulares. Durante el
                proceso de compra, el cliente tendrá que facilitar el número de
                la tarjeta, la fecha de caducidad y el número identificativo o
                CVC. Aun así, con el fin de evitar un fraude, y en beneficio de
                los intereses del consumidor y usuario, Libelista podrá
                solicitar otros documentos que autentifiquen la identidad de la
                titularidad de la tarjeta empleada para el pago.
              </p>

              <p>
                <strong>Tecnología segura de encriptación:</strong> Los
                servicios ofrecidos por Libelista SL se ofrecen en modalidad de
                encriptación SSL garantizando la seguridad y privacidad de las
                operaciones de compra en línea.
              </p>

              <p>
                <strong>Reserva de propiedad de productos:</strong> Libelista SL
                se reserva expresamente la propiedad de los productos entregados
                hasta el pago íntegro del precio de venta, intereses y otros
                gastos que figuren en la oferta o en el contrato. De acuerdo con
                esto, el pago efectuado mediante las letras de cambio, pagarés,
                cheques o cualquier otro título que cree obligación de pagar, no
                se considera hecho hasta que se llegue a buen fin con el cobro
                de estos documentos. No obstante, lo que establece el párrafo
                anterior, los riesgos se transfieren al cliente desde la entrega
                de los productos.
              </p>

              <p>
                El cliente se compromete a la custodia y conservación de los
                productos y a subscribir los seguros pertinentes para cubrir los
                desperfectos y siniestros susceptibles de ser causados a
                los&nbsp;productos. En caso de que el cliente suspenda los
                pagos, Libelista SL puede optar entre reclamar el pago o bien
                reclamar los productos, quedando en su poder las cantidades por
                anticipado anteriormente pagadas en concepto de cláusula de
                penalización. En el caso de contratos combinados de compra de
                dispositivo de lectura electrónica y contrato de suscripción de
                libros electrónicos, los pagos efectuados con anterioridad por
                el cliente se tienen que imputar como pago de uno u otros, de
                acuerdo con el cuadro de amortización que aparezca en la oferta.
              </p>

              <p>
                <strong>Garantías del producto:</strong> Libelista SL garantiza
                que puede vender productos desde&nbsp;
                <a href="https://www.libelista.com">www.libelista.com</a>,
                garantizando igualmente que sus plataformas de Internet están
                técnicamente preparadas para la venta de productos. Libelista SL
                se obliga a entregar al consumidor y usuario productos que sean
                conformes con el contrato, respondiendo ante él de cualquier
                falta de conformidad. De acuerdo con la legislación vigente, los
                productos comercializados tienen una garantía de 2 años, sin
                perjuicio de establecer, en cada caso, las garantías de calidad
                o funcionamiento en las condiciones particulares relativas a
                cada producto. Esta garantía no se aplica a los resultados que
                pueda obtener el consumidor del uso de sus productos y tampoco
                se aplica a los productos de terceros y dejará de ser efectiva,
                si se comprueba fehacientemente la manipulación por parte del
                cliente, para lo cual los equipos vienen provistos de precintos
                de seguridad. El comprador es responsable, salvo que se indique
                lo contrario, de los gastos de transporte, telefónicos, de
                correos y otros gastos ocurridos durante el periodo de garantía.
              </p>

              <p>
                <strong>Legislación aplicable y jurisdicción:</strong> Las
                presentes condiciones generales, las condiciones particulares
                relativas, si procede, a cada producto, y los contratos que en
                su virtud celebren los consumidores y usuarios con Libelista SL,
                se rigen por las disposiciones legales españolas. Cualquier
                controversia que pueda surgir sobre ellas, se dirimirá ante la
                jurisdicción de los tribunales españoles.
              </p>

              <p>Mediante la aceptación del contrato declara:</p>

              <ul>
                <li>
                  Que tenéis capacidad de contratar, de acuerdo con la normativa
                  española aplicable.
                </li>
                <li>
                  Que habéis leído estas condiciones generales y las aceptáis
                  expresamente.
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col lg={3} className="mt-4">
          <img src={Condiciones} alt="paper.png" className="infoImg" />
        </Col>
      </Row>
    </div>
  );
};

export default TermsOfUse;
