import React, { useState } from 'react';
import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import letterIcon from '../../assets/img/ico_newsletter.png';
import facebookIcon from '../../assets/img/ico_facebook.png';
import twitterIcon from '../../assets/img/ico_twitter.png';
import instaIcon from '../../assets/img/ico_instagram.png';
import appsIcon from '../../assets/img/ico_apps.png';
import appleIcon from '../../assets/img/ico_download_apple_store.png';
import googleIcon from '../../assets/img/ico_download_google_play.png';
import { postSubscriptionEmail } from '../../api/company';
import { emailRegex } from '../../constants';
import './subscriptionForm.scss';
import LegalModal from '../../components/legalModals/legalModal';
import ConditionsModal from '../../components/legalModals/conditionsModal';

const SubscriptionForm = () => {
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [showModalConditions, setShowModalConditions] = useState(false);
  const [showModalLegal, setShowModalLegal] = useState(false);
  const [legalCheck, setLegalCheck] = useState(false);

  const registerEmail = async (event) => {
    if (legalCheck && (event.keyCode === 13 || event.type === 'click')) {
      event.preventDefault();
      const emailValid = emailRegex.test(email);
      if (emailValid) {
        setFeedback('');
        const payload = { email };
        const { status } = await postSubscriptionEmail(payload);
        if (status === 201) {
          setRegisterSuccess(true);
        } else if (status === 400) {
          setFeedback('Su email ya se encuentra registrado');
          setRegisterSuccess(false);
        } else {
          if (status !== 201) {
            setFeedback('Error interno intentar mas tarde');
          }
        }
      } else if (!emailValid) {
        setFeedback('Ingrese una dirección de correo válida.');
      }
    }
  };

  return (
    <Container fluid className="justify-content-center px-0">
      <Col xs={12} lg={11} className="px-0">
        <Row className="subscription-row px-0">
          <Col xs={12} sm={10} md={10} lg={5} className="px-0">
            <Row className="justify-content-center overflow-hidden">
              <Col lg={10} className="mb-4 px-0 overflow-hidden">
                <Col xs={3} sm={3} md={3} lg={3} className="d-flex justify-content-end">
                  <img onClick={() => setShowModalConditions(true)} src={letterIcon} alt="A letter icon" />
                </Col>
                <Col xs={9} sm={9} md={9} lg={9} className="px-0">
                  {/* <p onClick={() => setShowModalLegal(true)}> */}
                  <p>
                    <strong>Subscríbete a nuestro newsletter </strong>para estar al día de todas las novedades,
                    recomendaciones y promociones
                  </p>
                  {/* </p> */}
                </Col>
              </Col>
              <Col lg={10}>
                <Col md={11} lg={11} xs={11} className="d-flex justify-content-center">
                  <Form.Control
                    id="inlineFormInput"
                    placeholder="Tu correo electrónico "
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
                <Col sm={3} md={3} lg={3}>
                  <Button disabled={!legalCheck || registerSuccess} onClick={registerEmail}>
                    Suscribirse
                  </Button>
                </Col>
              </Col>
              <Col lg={10} className="mt-2 d-flex">
                <Form.Check type={'checkbox'} onChange={() => setLegalCheck(!legalCheck)} />
                <p>
                  He leído, comprendo y acepto el
                  <span onClick={() => setShowModalLegal(true)}> Aviso legal y la Política de privacidad </span>y las{' '}
                  <span onClick={() => setShowModalConditions(true)}>Condiciones de uso</span>.
                </p>
              </Col>
              {registerSuccess ? (
                <Col className="alert-success" lg={12}>
                  <span>Su email ha sido registrado con éxito.</span>
                </Col>
              ) : null}
              <span className="feedback">{feedback}</span>
            </Row>
          </Col>
          <Col xs={9} sm={4} md={4} lg={3} className="social-col mt-sm-3 mt-md-3 mt-lg-0">
            <Row>
              <Col lg={10} className="mb-4">
                {/* <Row>
                  <a
                    className="d-flex ml-4"
                    href="https://twitter.com/LibelistaEs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Col className="ml-1" xs="3" lg="3">
                      <img src={twitterIcon} alt="twitter.png" />
                    </Col>
                    <Col className="ml-3" xs="8" lg="8">
                      <p>Twitter</p>
                    </Col>
                  </a>
                </Row> */}
              </Col>
              <Col lg={10} className="mb-4">
                <Row>
                  <a
                    className="d-flex ml-4"
                    href="https://www.instagram.com/libelistabooks/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Col xs="3" lg="3">
                      <img src={instaIcon} alt="insta.png" />
                    </Col>
                    <Col className="ml-3" xs="8" lg="8">
                      <p>Instagram</p>
                    </Col>
                  </a>
                </Row>
              </Col>
              <Col lg={10}>
                <Row>
                  <a
                    className="d-flex ml-4"
                    href="https://www.facebook.com/Libelista/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Col xs="3" lg="3">
                      <img src={facebookIcon} alt="faceb.png" />
                    </Col>
                    <Col className="ml-3" xs="8" lg="8">
                      <p>Facebook</p>
                    </Col>
                  </a>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col xs={9} sm={4} md={4} lg={4} className="appCol mt-4 mt-sm-3 mt-md-3 mt-lg-0">
            <Row>
              <Col lg={12} className="mb-4">
                <Row className="justify-content-start">
                  <Col xs={2} sm={2} md={2} lg={2} className="pl-4">
                    <img src={appsIcon} alt="apps.png" className="phone-img" />
                  </Col>
                  <Col xs={9} sm={9} md={9} lg={9} className="p-0">
                    <p>
                      <strong> Descárgate la app de Libelista </strong>y explora los mejores libros en tu móvil
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col lg={10}>
                <Row className="justify-content-start appImg">
                  <Col xs={6} sm={6} md={6} lg={6} className="">
                    <img src={googleIcon} alt="apple.png" />
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} className="p-0">
                    <img src={appleIcon} alt="google.png" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <LegalModal showModal={showModalLegal} onHide={() => setShowModalLegal(false)} />
          <ConditionsModal showModal={showModalConditions} onHide={() => setShowModalConditions(false)} />
        </Row>
      </Col>
    </Container>
  );
};

export default SubscriptionForm;
