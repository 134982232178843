import { methodGet } from './methods';
import { API_INFO } from '../constants';

export const getPosts = async (query) => {
  return methodGet(API_INFO + 'blog/posts/', query);
};

export const getPost = async ({ year, month, day, slug }) => {
  return methodGet(API_INFO + `blog/posts/${year}/${month}/${day}/${slug}`);
};

export const getBlogPosts = async (type_post = '', author_username = '', title = '') => {
  return methodGet(
    API_INFO +
      `libelista/posts/all-posts/${type_post ? '&type_post=' + type_post : ''}${
        author_username ? '&author_username=' + author_username : ''
      }${title ? '&title=' + title : ''}`
  );
};
