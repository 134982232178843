import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Col, Row, Pagination, Spinner } from 'react-bootstrap';
import './blog.scss';
import shopWhite from '../../assets/img/ico_shop_white.png';
import personWhite from '../../assets/img/ico_person_white.png';
import bookWhite from '../../assets/img/ico_book_white.png';
import { useSelector } from 'react-redux';
import { BooksOfTheMonthSection } from '../../components/blog/BooksOfTheMonthSection';
import { companySelector } from '../../slices/company';
import { getPosts } from '../../api/libelista';
import Head from '../../components/metatags_helmet/metatags';
import BlogCategories from '../../components/blog/BlogCategories';

const Categoryblog = ({ match }) => {
  const [loaded, setLoaded] = useState(false);
  const [allPostsLoaded, setAllPostsLoaded] = useState(false);
  const [listPostCategory, setListPostCategory] = useState([]);
  const [bigPost, setBigPost] = useState([{}]);
  const [smallPosts, setSmallPosts] = useState([]);
  const [totalNumberPost, setTotalNumberPost] = useState();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentImage, setCurrentImage] = useState(1);
  const maxPostsXPage = 4;
  let url = window.location;

  const selectedCategory = match.params.category;
  const isLibrosDelMesSection = selectedCategory === 'libros-del-mes';
  const title = 'Mundo libelista';
  const { descripcion_pub } = useSelector(companySelector);

  useEffect(() => {
    async function setPostCategory() {
      const rs = await getPosts({ type_post__slug: match.params.category });
      if (rs?.status === 200) {
        setListPostCategory(rs.data.results);
        setTotalNumberPost(rs.data.count);
        setLoaded(true);
      }
    }
    setPostCategory();
    setCurrentPage(1);
    goUp();
  }, [selectedCategory]);

  useEffect(() => {
    async function handlePagination() {
      if (currentPage === 1) {
        const response = await getPosts({
          type_post__slug: match.params.category,
        });
        if (response?.status === 200) {
          setListPostCategory(response.data.results);
          setTotalNumberPost(response.data.count);
          setLoaded(true);
        }
      } else {
        const response = await getPosts({
          page: currentPage,
          type_post__slug: match.params.category,
        });
        if (response?.status === 200) {
          setListPostCategory(response.data.results);
          setTotalNumberPost(response.data.count);
          setLoaded(true);
        }
      }
    }
    handlePagination();
    goUp();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1) {
      setBigPost(listPostCategory.slice(0, 1));
      setSmallPosts(listPostCategory.slice(1));
      setAllPostsLoaded(true);
    } else {
      setSmallPosts(listPostCategory.slice(0));
      setAllPostsLoaded(true);
    }
  }, [listPostCategory]);

  useEffect(() => {
    let path = url.pathname.split('/').slice(-2)[0];
    switch (path) {
      case 'librerias-protagonistas':
        setCurrentImage(shopWhite);
        break;
      case 'libelista-propone':
        setCurrentImage(bookWhite);
        break;
      case 'entrevistas-libelista':
        setCurrentImage(personWhite);
        break;
      default:
    }
  }, []);

  const goUp = () => {
    window.scrollTo(0, 0);
  };

  const PaginationBlog = (props) => {
    const { totalPosts, currentPageNumber, postPage } = props;
    const setSiteNumber = () => {
      const currentPageURL = parseInt(location.search.split('=')[1], 10);
      if (isNaN(currentPageURL)) {
        setCurrentPage(1);
      } else {
        setCurrentPage(currentPageURL);
      }
    };

    let totalPages = Math.ceil(totalPosts / postPage);

    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      if (currentPageNumber === 1) {
        if (number !== totalPages) {
          items.push(
            <Pagination.Item key={number} disabled={number === currentPageNumber} onClick={setSiteNumber()}>
              <Link to={`?page=${number}`}>{number}</Link>
            </Pagination.Item>
          );
        } else {
          items.push(
            <Pagination.Item key={number} disabled={number === currentPageNumber} onClick={setSiteNumber()}>
              <Link to={`?page=${number}`}>Última Página</Link>
            </Pagination.Item>
          );
        }
      } else if (currentPageNumber === totalPages) {
        if (number === 1) {
          items.push(
            <Pagination.Item key={number} disabled={number === currentPageNumber} onClick={setSiteNumber()}>
              <Link to={`?page=${number}`}>Primera Página</Link>
            </Pagination.Item>
          );
        } else {
          items.push(
            <Pagination.Item key={number} disabled={number === currentPageNumber} onClick={setSiteNumber()}>
              <Link to={`?page=${number}`}>{number}</Link>
            </Pagination.Item>
          );
        }
      } else {
        items.push(
          <Pagination.Item key={number} disabled={number === currentPageNumber} onClick={setSiteNumber()}>
            <Link to={`?page=${number}`}>{number}</Link>
          </Pagination.Item>
        );
      }
    }
    return (
      <Pagination className="my-3" style={{ float: 'right' }}>
        {items}
      </Pagination>
    );
  };

  if ((loaded && allPostsLoaded && listPostCategory[0]) || isLibrosDelMesSection) {
    const categoryTitle = listPostCategory[0]?.selected_type_post.name;

    return (
      <div className="content mainDiv mx-auto">
        <div className="container-fluid mx-auto blog">
          <Head description={descripcion_pub} title={title} noIndex={true} />
          <Row>
            <Col md={12}>
              <h1 className="page-title static-title">
                Mundo libelista - {isLibrosDelMesSection ? 'Libros del mes' : categoryTitle}
              </h1>
            </Col>
          </Row>
          <Row>
            <div className="blogContainer">
              {isLibrosDelMesSection ? (
                <BooksOfTheMonthSection />
              ) : (
                <Col sm={12} md={8}>
                  <Row>
                    <Col md={12}>
                      {currentPage === 1 ? (
                        <>
                          <div id={`highlighted-post-${bigPost[0]?.slug}`} className="post-item">
                            <div className="show-desktop">
                              <Link to={`/libelista/posts/${bigPost[0]?.id}`}>
                                <img
                                  style={{
                                    width: '100%',
                                    maxHeight: '330px',
                                    objectFit: 'cover',
                                  }}
                                  src={bigPost[0]?.featured_image}
                                  alt={`${bigPost[0]?.title} img`}
                                />
                              </Link>
                            </div>
                            <div
                              className="highlighted-post-header-title"
                              style={{ position: 'relative', height: '0px' }}
                            >
                              <div style={{ position: 'relative', bottom: '80px' }}>
                                <h3 className="degradeHue mb-1 py-4">
                                  <Link className="mx-2 ml-3" to={`/libelista/posts/${bigPost[0]?.id}`}>
                                    {bigPost[0]?.title ? (
                                      <img src={currentImage} alt={`${bigPost[0]?.title} imagen`} />
                                    ) : null}
                                  </Link>
                                  <Link
                                    className="pb-1 ml-2"
                                    to={`/libelista/posts/${bigPost[0]?.id}`}
                                    style={{
                                      position: 'relative',
                                      top: '5px',
                                      color: 'white',
                                    }}
                                  >
                                    {bigPost[0]?.title}
                                  </Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                          <Row style={{ position: 'relative', top: '25px' }}>
                            <Col md={3}>
                              <p>{bigPost[0]?.publish_date}</p>
                              <p>
                                en{' '}
                                <Link to={`/blog/${bigPost[0]?.selected_type_post.slug}`} onClick={goUp}>
                                  {bigPost[0]?.selected_type_post.name}
                                </Link>
                              </p>
                            </Col>
                            <Col md={9}>
                              <p className="post-info abstract">{bigPost[0]?.featured_paragraph}</p>
                              <Link to={`/libelista/posts/${bigPost[0]?.id}`}>Ver más...</Link>
                            </Col>
                          </Row>
                        </>
                      ) : null}

                      {smallPosts.length !== 0 ? (
                        <>
                          <h2
                            className="section"
                            style={{
                              textAlign: 'left',
                              position: 'relative',
                              top: '44px',
                            }}
                          >
                            Todas las publicaciones
                          </h2>
                          <Row
                            style={{
                              position: 'relative',
                              top: '44px',
                            }}
                          >
                            {smallPosts.map((post) => {
                              return (
                                <Col xs={12} sm={12} md={6} lg={4} key={post.id}>
                                  <div
                                    id={post.id}
                                    key={post.id}
                                    className="mb-4 post-card"
                                    style={{ minHeight: '370px' }}
                                  >
                                    <Link to={`/libelista/posts/${post.id}/`}>
                                      <img
                                        src={post.featured_image}
                                        alt={`${post.title} imagen`}
                                        style={{ maxWidth: '100%' }}
                                      />
                                    </Link>
                                    <div className="category-image">
                                      <div
                                        className="pt-2 pl-4 ico-category-md"
                                        style={{
                                          position: 'absolute',
                                          top: '0',
                                          left: '0',
                                          right: '75%',
                                          zIndex: '1',
                                        }}
                                      >
                                        <img src={currentImage} alt={`${post.title} imagen`} />
                                      </div>
                                    </div>
                                    <div className="post-card__detail">
                                      <Link to={`/libelista/posts/${post.id}/`}>{post.title}</Link>
                                      <p>
                                        {post.publish_date} en{' '}
                                        <Link to={`/blog/${post.type_post[0]?.slug}`} onClick={goUp}>
                                          {post.type_post[0]?.name}
                                        </Link>
                                      </p>
                                      <p>{post.featured_paragraph}</p>
                                      <Link to={`/libelista/posts/${post.id}/`}>Ver más...</Link>
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="pag">
                    <Col md={12}>
                      <PaginationBlog
                        totalPosts={totalNumberPost}
                        currentPageNumber={currentPage}
                        postPage={maxPostsXPage}
                      />
                    </Col>
                  </Row>
                </Col>
              )}

              <Col md={4} className={`blog-aside ${isLibrosDelMesSection && window.innerWidth <= 576 ? 'mt-5' : ''}`}>
                <BlogCategories />
                <div className="blog-aside-mostvisited">
                  <h2 className="section-col mt-3">Agenda</h2>
                </div>
              </Col>
            </div>
          </Row>
        </div>
      </div>
    );
  } else {
    return (
      <Container fluid={true} style={{ height: '50vh' }}>
        <Spinner animation={'grow'} style={{ marginTop: '20vh', marginLeft: '40vw' }} />
      </Container>
    );
  }
};

export default Categoryblog;
