import * as React from "react";
import { getBooksOfTheMonth } from "./../../api/libelista";
import { useParams } from "react-router-dom";
import { BookPost } from "./BookPost";
const booksOfTheMonthRequestHandler = async (
  requestParams,
  stateSetterCallback
) => {
  const response = await getBooksOfTheMonth(requestParams);
  try {
    stateSetterCallback(response.data);
  } catch (error) {
    console.log(error);
  }
};

const Books = () => {
  const [booksOfTheMonth, setBooksOfTheMonth] = React.useState(undefined);
  const { booksOfTheMonthId } = useParams();
  React.useEffect(() => {
    booksOfTheMonthRequestHandler(booksOfTheMonthId, setBooksOfTheMonth);
  }, []);
  const books = booksOfTheMonth?.detail_featured_books;
  return (
    <div className="container-fluid books-of-the-month">
      <div className="row">
        <div className="col-md-12">
          <h2 className="page-title static-title">
            <span>
              Mundo libelista - {booksOfTheMonth?.title || "Próximamente"}
            </span>
          </h2>
        </div>
        {books?.map((data, index) => (
          <BookPost key={data.title + "#" + index} {...data} />
        ))}
      </div>
    </div>
  );
};
export default Books;
