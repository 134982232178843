import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Compras from '../../assets/img/img_como_comprar.png';
import './infoPages.scss';

//redux
import { useSelector } from 'react-redux';
import { companySelector } from '../../slices/company';

//own components
import Head from '../../components/metatags_helmet/metatags';

const HowToBuy = () => {
  const title = 'Cómo comprar';
  const { descripcion_pub } = useSelector(companySelector);

  return (
    <div className="container-fluid px-0 mainDiv">
      <Head description={descripcion_pub} title={title} noIndex={true} />
      <Row className="justify-content-center mainRow infoRow px-0">
        <Col xs={11} lg={8} className="mt-4 pl-0">
          <div className="row">
            <div className="col-md-12">
              <h1 className="infoTitle">Cómo comprar</h1>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h3>Libros en papel</h3>

              <p>&nbsp;</p>

              <p>
                En Libelista puedes escoger entre recoger tus libros en la librería que has elegido o que te los
                entreguemos en tu domicilio. Sea cual sea tu elección, la librería que hayas elegido se beneficiará de
                tu compra.
              </p>

              <h4>&nbsp;</h4>

              <h4>
                <br />
                <u>
                  <strong>Recogida en una librería</strong>
                </u>
              </h4>

              <p>&nbsp;</p>

              <ul>
                <li>
                  <p>Elige el libro o libros que quieras adquirir.</p>
                </li>
                <li>
                  <p>
                    Si no lo estás ya, regístrate como cliente. Debes rellenar los campos obligatorios y elegir una
                    librería a la que vincularte (esta librería recibirá el importe de la compra realizada).{' '}
                    <span style={{ color: 'rgb(165,165,28)' }}>
                      En el caso que no quieras seleccionar ninguna de las librerías de la red, podrás elegir la opción
                      de que Libelista seleccione una al azar.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    Elige la librería por la que quieras pasar a recoger tu pedido (no es necesario que esta tenga
                    disponibilidad del libro o libros solicitados ni que sea la librería a la que te has vinculado).
                    ¿Esta librería no es la misma a la que me he vinculado en el paso anterior? No tiene por qué ser la
                    librería con la que te has vinculado. Puedes estar vinculado a Parcir y pasarlo a recoger por
                    Cervantes, por ejemplo.
                  </p>
                </li>
                <li>
                  <p>
                    Al realizar la compra recibirás un correo confirmando que tu pedido se ha realizado correctamente.
                  </p>
                </li>
                <li>
                  <p>
                    Para pasar a recoger tu pedido por la librería elegida, debes esperar a recibir un nuevo correo en
                    el que se especifica que tu pedido ya está preparado para ser recogido.
                  </p>
                </li>
                <li>
                  <p>Esta opción de recogida no tiene gastos de envío adicionales.</p>
                </li>
                <li>
                  <p>
                    Si tienes alguna duda sobre cómo realizar un pedido o sobre el estado de este puedes escribirnos
                    a&nbsp;
                    <a href="mailto:pedidos@libelista.com">pedidos@libelista.com</a>
                  </p>
                </li>
              </ul>

              <h4>&nbsp;</h4>

              <h4>
                <br />
                <u>
                  <strong>Entrega a domicilio</strong>
                </u>
              </h4>

              <p>&nbsp;</p>

              <ul>
                <li>
                  <p>Elige el libro o libros que quieras adquirir.</p>
                </li>
                <li>
                  <p>
                    Regístrate como cliente. Debes rellenar los campos obligatorios y elegir una librería a la que
                    vincularte (esta librería recibirá el importe de la compra realizada).
                    <span style={{ color: 'rgb(165,165,28)' }}>
                      En el caso que no quieras seleccionar ninguna de las librerías de la red, podrás elegir la opción
                      de que Libelista seleccione una al azar.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    En la ficha de registro indícanos los datos de facturación, y especifícanos la dirección de entrega
                    (no olvides adjuntar tu teléfono).
                  </p>
                </li>
                <li>
                  <p>
                    Al realizar la compra recibirás un correo confirmando que tu pedido se ha realizado correctamente.
                  </p>
                </li>
                <li>
                  <p>
                    En el momento que el pedido esté preparado y en proceso de envío recibirás un correo informativo. A
                    partir de este momento el plazo habitual de entrega es de 48 horas.
                  </p>
                </li>
                <li>
                  <p>
                    Los gastos de envío para pedidos inferiores a 60 € son de 3,40 €. Para pedidos superiores a 60 € el
                    envío es gratuito.
                  </p>
                </li>
                <li>
                  <p>
                    Si tienes alguna duda sobre cómo realizar un pedido o sobre el estado de este puedes escribirnos
                    a&nbsp;
                    <a href="mailto:pedidos@libelista.com">pedidos@libelista.com</a>
                  </p>
                </li>
              </ul>

              <p>&nbsp;</p>

              <h3>
                <br />
                Un libro digital
              </h3>

              <p>&nbsp;</p>

              <p>
                Puedes consultarlo <a href="https://www.libelista.com/ebook/">aquí</a>.
              </p>

              <h3>&nbsp;</h3>

              <h3>Tarifas de envío y plazos de entrega</h3>

              <p>&nbsp;</p>

              <p>
                Puedes consultarlas <a href="/tarifas-y-plazos-de-entrega/">aquí</a>.<br />
                &nbsp;
              </p>

              <h3>Devoluciones</h3>

              <p>&nbsp;</p>

              <p>
                Puedes consultar nuestra política de devoluciones y anulaciones{' '}
                <a href="/devoluciones-y-anulaciones/">aquí</a>.
              </p>

              <p>&nbsp;</p>

              <h3>Limitaciones</h3>

              <p>
                La página permite un máximo de 3 compras a la hora por usuario. En el caso que se realicen más de tres
                solicitudes de pago distintas en ese período de tiempo, la pasarela bancária activará un bloqueo que
                afectará a la dirección IP del usuario, imposibilitando cualquier otro intento de pago que realice en la
                plataforma Libelista hasta transcurridas 24 horas.
              </p>

              <p>&nbsp;</p>
            </div>
          </div>
        </Col>
        <Col xs={3} lg={3} className="mt-4 pr-0">
          <img src={Compras} alt="buy.png" className="infoImg" />
        </Col>
      </Row>
    </div>
  );
};

export default HowToBuy;
