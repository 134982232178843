import React, { useState, useEffect } from 'react';
// import { getPost, getPosts } from "../../api/blog";
import { Container, Col, Row, Spinner } from 'react-bootstrap';

//styles
import './blog.scss';

// api
import { getPost } from '../../api/libelista';

//own components
import CardPost from '../../components/blog/cardPost';

const Post = ({ match }) => {
  const [post, setPost] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const { id } = match.params;

  useEffect(() => {
    const getPostData = async () => {
      const rs = await getPost(id);
      if (rs?.status === 200) {
        setPost(rs?.data);
        setLoaded(true);
      }
    };
    getPostData();
  }, [match]);

  if (loaded) {
    const categoryTitle = post?.selected_type_post?.name;

    return (
      <div className="content mainDiv mx-auto">
        <div className="container-fluid blog mx-auto">
          {/* <Head description={descripcion_pub} title={title} noIndex={true} /> */}
          <Row>
            <Col md={12}>
              <h1 className="page-title static-title">Mundo libelista - {categoryTitle}</h1>
            </Col>
          </Row>
          <div className="blogContainer">
            <CardPost event={post} isContainer={true} isOnlyPost={true} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Container fluid={true} style={{ height: '50vh' }}>
        <Spinner animation={'grow'} style={{ marginTop: '20vh', marginLeft: '40vw' }} />
      </Container>
    );
  }
};

export default Post;
