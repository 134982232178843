import React from 'react';
import { useParams } from 'react-router-dom';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import { getDiscountCoupon, editDiscountCoupon, createDiscountCoupon } from '../../../../api/products';
import ActionsForm from '../../../../components/backoffice/actionsForm/index';

let typeDiscountOptions = [
  { label: 'Porcentaje', value: 0 },
  { label: 'Monto fijo', value: 1 },
];

const CouponsItem = ({ data = {} }) => {
  const optionsTypeDiscount = [
    { label: 'Descuento por porcentaje', value: 0 },
    { label: 'Descuento por monto fijo', value: 1 },
  ];

  const statusOptions = [
    { label: 'Activo', value: true },
    { label: 'Inactivo', value: false },
  ];
  const { action } = useParams();
  let isDisabled = action === 'detail';
  const inputFields = [
    {
      label: 'Nombre del Cupón',
      name: 'coupon',
      type: 'text',
      required: true,
    },
    {
      label: 'Descuento',
      name: 'discount',
      type: 'number',
      required: true,
      min: 0,
      max: 100,
    },
    {
      label: 'Valido desde',
      name: 'valid_from',
      type: 'datetime-local',
      required: true,
    },
    {
      label: 'Valido hasta',
      name: 'valid_until',
      type: 'datetime-local',
    },
    {
      label: 'Tipo de descuento',
      name: 'type_discount',
      type: 'select',
      options: optionsTypeDiscount,
      defaultValue:
        optionsTypeDiscount?.find((option) => option.value == data?.type_discount) || optionsTypeDiscount[2],
    },
    {
      label: 'Estado',
      name: 'is_active',
      type: 'select',
      options: statusOptions,
      defaultValue: statusOptions.find((option) => option.value == data?.is_active) || statusOptions[2],
    },
    {
      label: 'Tipo de descuento',
      name: 'type_discount',
      type: 'select',
      defaultValue: typeDiscountOptions[!data?.type_discount ? 0 : 1],
      options: typeDiscountOptions,
    },
  ];
  const actionsFormProps = {
    inputFields,
    title: 'Cupon',
    subsectionPath: 'coupons',
    data,
    requests: { edit: editDiscountCoupon, create: createDiscountCoupon },
    action,
  };
  return <ActionsForm {...actionsFormProps} />;
};
CouponsItem.request = {
  get: getDiscountCoupon,
};
const CouponsItemPage = () => <BackofficePagesManager PageViewer={CouponsItem} />;
export default CouponsItemPage;
