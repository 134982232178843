import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import ActionsForm from '../../../../components/backoffice/actionsForm/index';
import { getSelectDataHandlers } from '../../../../utils/backoffice/getSelectDataHandlers';
import {
  getScheduleDetail as get,
  editSchedule as edit,
  postSchedule as create,
  getTypeSchedule,
} from '../../../../api/libelista';
import { getAllBooks, getBookDetail } from '../../../../api/libros';
import SectionLoader from 'components/loader/SectionLoader';

const statusOptions = [
  { label: 'Draft', value: 'draft' },
  { label: 'Published', value: 'published' },
  { label: 'Hidden', value: 'hidden' },
];

const ScheduleItem = ({ data, loadData }) => {
  const { action } = useParams();

  const { image, id, title, slug, status, publish_date, start_date, end_date, type_schedule, body, libro, place } =
    data || {};

  const [
    { defaultOptions: defaultCategoriesOptions, defaultOption: defaultCategoriesOption },
    setCategoriesDefaultOptionValues,
  ] = useState({});
  const { getSelectData: getCategoriesOptions, setSelectDefaultData: setCategoriesOptions } = getSelectDataHandlers(
    undefined,
    getTypeSchedule,
    setCategoriesDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'name',
      fieldNameWithRequestValue: 'id',
    }
  );

  const [{ defaultOptions: defaultBookOptions, defaultOption: defaultBookOption }, setBooksDefaultOptionValues] =
    useState({});
  const { getSelectData: getBookOptions, setSelectDefaultData: setBookOptions } = getSelectDataHandlers(
    getBookDetail,
    getAllBooks,
    setBooksDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'titulo',
      fieldNameWithRequestValue: 'id',
    }
  );

  useEffect(() => {
    setCategoriesOptions();
  }, []);

  useEffect(() => {
    if (!mustWaitForData || data) {
      setBookOptions(data?.libro);
    }
  }, [data]);

  const mustWaitForData = ['detail', 'edit'].includes(action);
  const disabled = ['edit', 'detail', 'create'].includes(action);
  const renderConditions = [
    !mustWaitForData || data,
    defaultCategoriesOptions?.length || defaultCategoriesOption || data,
    defaultBookOptions?.length || defaultBookOption,
  ];

  const inputFields = [
    {
      label: 'Título',
      name: 'title',
      type: 'text',
    },
    {
      label: 'Slug',
      name: 'slug',
      type: 'text',
    },
    {
      label: 'Estado',
      name: 'status',
      type: 'select',
      defaultValue: statusOptions.find(({ value }) => value === data?.status) || statusOptions[0],
      options: statusOptions,
    },
    {
      label: 'Fecha de creación',
      name: 'publish_date',
      type: 'text',
      disabled,
    },
    {
      label: 'Inicio del evento',
      name: 'start_date',
      type: 'datetime-local',
    },
    {
      label: 'Finalización del evento',
      name: 'end_date',
      type: 'datetime-local',
    },
    {
      label: 'Tipo de evento',
      name: 'type_schedule',
      type: 'select',
      //defaultValue:defaultCategoriesOptions?.|1|,
      defaultValue: defaultCategoriesOptions?.filter((option) => option.value == parseInt(data?.type_schedule)),
      options: defaultCategoriesOptions,
      required: true,
    },
    {
      label: 'Descripción',
      name: 'body',
      type: 'textarea',
    },
    {
      label: 'Libro relacionado',
      name: 'libro',
      type: 'select',
      isAsync: true,
      loadOptions: getBookOptions,
      defaultOptions: defaultBookOptions,
      defaultValue: defaultBookOption,
      isDisabled: action === 'detail',
    },
    {
      label: 'Lugar del evento',
      name: 'place',
      type: 'text',
    },
    {
      label: 'Fotografía Anexa',
      name: 'image',
      type: 'file',
      imageUrl: image,
    },
  ];
  const actionsFormProps = {
    inputFields,
    title: 'Entrada de Libro del Mes',
    subsectionPath: 'schedules',
    data: {
      id,
      title,
      slug,
      status,
      publish_date,
      start_date,
      end_date,
      type_schedule,
      body,
      libro,
      place,
    },
    requests: { edit, create },
    extraFormat: { publish_date: 'date' },
    formDataConfiguration: {},
    action,
    loadData,
  };
  // return action === 'create' || data ? <ActionsForm {...actionsFormProps} /> : <SectionLoader />;
  return renderConditions.every((condition) => condition) ? <ActionsForm {...actionsFormProps} /> : <SectionLoader />;
};
ScheduleItem.request = {
  get,
};
const ScheduleItemPage = () => <BackofficePagesManager PageViewer={ScheduleItem} />;
export default ScheduleItemPage;
