import React, { useEffect } from "react";
import "./assets/css/styles.scss";

import MainRoute from "./routes";

//context
import UserContextProvider from "./context/userContext";
import { companySelector, loadMetaCompany } from "./slices/company";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Favicon from "react-favicon";
import { getMetadataCompany } from "./api/company";
import setCSSVariables from "./utils/setTheme";
import { themeBase } from "./constants";
import faviconLibelista from "./assets/img/libe.ico";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const { data, status } = await getMetadataCompany();
        if (status === 200) {
          dispatch(loadMetaCompany(data));
          setCSSVariables(data.theme);
        } else {
          setCSSVariables(themeBase);
        }
      } catch (e) {}
    })();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <Favicon url={faviconLibelista} />
      <UserContextProvider>
        <MainRoute />
      </UserContextProvider>
    </div>
  );
}

export default App;
