import React, { useState, useRef } from 'react';
import { Field } from 'formik';
import { Col, Button, DropdownButton, Dropdown, InputGroup, Form } from 'react-bootstrap';
import { AiOutlineSearch } from 'react-icons/ai';
import SearchBar from './SearchBar';
const ENGLISH_CODE = '6';
const SPANISH_CODE = '1';
const CATALAN_CODE = '2';
const GALLEGO_CODE = '4';
const PORTUGUESE_CODE = '10';
const EUSKERA_CODE = '136';
const SPANISH_OPTION_DATA = { label: 'Español', value: SPANISH_CODE, name: 'idioma' };
const ENGLISH_OPTION_DATA = { label: 'Inglés', value: ENGLISH_CODE, name: 'idioma' };
const CATALAN_OPTION_DATA = { label: 'Catalán', value: CATALAN_CODE, name: 'idioma' };
const GALLEGO_OPTION_DATA = { label: 'Gallego', value: GALLEGO_CODE, name: 'idioma' };
const PORTUGUESE_OPTION_DATA = { label: 'Portuguese', value: PORTUGUESE_CODE, name: 'idioma' };
const EUSKERA_OPTION_DATA = { label: 'Euskera', value: EUSKERA_CODE, name: 'idioma' };
const EBOOK_OPTION_DATA = { label: 'Libro Digital', value: 'true', name: 'is_ebook' };
const BOOK_OPTION_DATA = { label: 'Libro Tradicional', value: 'false', name: 'is_ebook' };
const TITLE_OPTION_DATA = { label: 'Titulo', value: 'titulo', name: 'searchType' };
const AUTHOR_OPTION_DATA = { label: 'Autor', value: 'autor', name: 'searchType' };
const EDITORIAL_OPTION_DATA = { label: 'Editorial', value: 'editorial', name: 'searchType' };
const EAN_OPTION_DATA = { label: 'I.S.B.N.', value: 'ean', name: 'searchType' };
const SEARCH_OPTIONS_DATA = [TITLE_OPTION_DATA, AUTHOR_OPTION_DATA, EDITORIAL_OPTION_DATA, EAN_OPTION_DATA];
const FILTER_BY_PRODUCT_TYPE_OPTIONS_DATA = [EBOOK_OPTION_DATA, BOOK_OPTION_DATA];
const FILTER_BY_LANGUAGE_OPTIONS_DATA = [
  SPANISH_OPTION_DATA,
  ENGLISH_OPTION_DATA,
  CATALAN_OPTION_DATA,
  GALLEGO_OPTION_DATA,
  PORTUGUESE_OPTION_DATA,
  EUSKERA_OPTION_DATA,
];
const SEARCH_TYPE_DIVISION_DATA = { name: 'Seleccionar área de búsqueda', optionsData: SEARCH_OPTIONS_DATA };
const FILTER_BY_LANGUAGE_DIVISION_DATA = {
  name: 'Filtrar resultados por idioma',
  optionsData: FILTER_BY_LANGUAGE_OPTIONS_DATA,
};
const FILTER_BY_PRODUCT_TYPE_DIVISION_DATA = {
  name: 'Filtrar resultados por tipo de producto',
  optionsData: FILTER_BY_PRODUCT_TYPE_OPTIONS_DATA,
};
const FORM_RADIO_OPTIONS_DIVISIONS = [
  SEARCH_TYPE_DIVISION_DATA,
  FILTER_BY_PRODUCT_TYPE_DIVISION_DATA,
  FILTER_BY_LANGUAGE_DIVISION_DATA,
];
const SearchBarDesktop = () => {
  return (
    <Col className="header__search desktop ">
      <div className="search-desktop ">
        <SearchBar>
          <DropdownButton
            as={InputGroup.Append}
            variant="outline-dark"
            title="Opciones de búsqueda"
            id="input-group-dropdown-2"
          >
            <Form.Group>
              <Dropdown.Divider />
              {FORM_RADIO_OPTIONS_DIVISIONS.map(({ name, optionsData }, index) => {
                return (
                  <React.Fragment key={`divition#${index}`}>
                    <Form.Label as="legend" column lg={12}>
                      {name}
                    </Form.Label>
                    <Col lg={12}>
                      {optionsData.map((optionData, index) => (
                        <Field
                          as={Form.Check}
                          key={`radioOption#${index}`}
                          type="radio"
                          id={optionData.label}
                          {...optionData}
                        />
                      ))}
                    </Col>
                    <Dropdown.Divider />
                  </React.Fragment>
                );
              })}
            </Form.Group>
          </DropdownButton>
          <InputGroup.Append>
            <Button type="submit" className="deskt-search" variant="info">
              <AiOutlineSearch className="fas icon-search" />
            </Button>
          </InputGroup.Append>
        </SearchBar>
      </div>
    </Col>
  );
};
export default SearchBarDesktop;
