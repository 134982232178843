import React, { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import preview from '../../assets/img/preview.jpg';
import Image from '../../components/image';
import { cartSelector, removeItem, increaseQuantity, decreaseQuantity, removeGiftCard } from '../../slices/cart';
import { useSelector, useDispatch } from 'react-redux';
import { AiFillDelete, AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import { BiCheck, BiMinus } from 'react-icons/bi';
import { BsPlus } from 'react-icons/bs';
import { COMPANY } from '../../constants';
import { userSelector } from '../../slices/user';
import { addItemCart, decreaseQuantityItemCart, removeItemCart, deleteGiftCardCart } from '../../api/ecommerce';
import giftImageCard from '../../assets/img/giftCard.jpg';

import './cart.scss';

const Items = (props) => {
  const dispatch = useDispatch();
  const { isLogged } = useSelector(userSelector);
  const { items, shippingCosts, giftCard, hasGiftCard } = useSelector(cartSelector);

  useEffect(() => {
    window.document.title = 'Carrito de compras | ' + COMPANY;
  }, []);

  const increaseQuantityItem = async (id) => {
    if (isLogged) {
      const payload = { item: id, quantity: 1 };
      const { status, dataResponse } = await addItemCart(payload);
      if (status === 201) {
        dispatch(increaseQuantity(id));
      } else {
        console.log('error');
      }
    } else {
      dispatch(increaseQuantity(id));
    }
  };

  const decreaseQuantityItem = async (id) => {
    if (isLogged) {
      const payload = { item: id, quantity: 1 };
      const { status, dataResponse } = await decreaseQuantityItemCart(payload);
      if (status === 200) {
        dispatch(decreaseQuantity(id));
      } else {
        console.log('error');
      }
    } else {
      dispatch(decreaseQuantity(id));
    }
  };

  const removeProductCart = async (item) => {
    if (isLogged) {
      const payload = { item: item.id };
      const { status, dataResponse } = await removeItemCart(payload);
      if (status === 200) {
        dispatch(removeItem(item));
      } else {
        console.log('error');
      }
    } else {
      dispatch(removeItem(item));
    }
  };

  const removeGiftCardCart = async () => {
    if (isLogged) {
      const payload = { item: giftCard };
      const { status, data } = await deleteGiftCardCart(payload);
      if (status === 200) {
        dispatch(removeGiftCard());
      } else {
        console.log('error');
      }
    } else {
      dispatch(removeGiftCard());
    }
  };

  const urlItem = (item) => `/productos/${item.type}/${item.url}/`;

  const styleGiftCard = {
    width: '100%',
    height: 'auto',
    maxHeight: '242px',
  };

  const styleCardPrice = {
    color: '#f2a20d',
    fontSize: '25px',
    fontFamily: 'Lato',
    paddingTop: '2rem',
  };

  const styleButtonDelete = {
    width: '105px',
    fontSize: '14px',
    position: 'relative',
    top: '37px',
    right: '20px',
  };

  return (
    <Row>
      <Col md={12}>
        {items?.map((item, i) => (
          <div key={i} className="cart-item">
            <div className="cart-item__details">
              <Link style={{ cursor: 'pointer' }} to={urlItem(item)}>
                <Image key={item.id} src={item.get_url} fallbackSrc={preview} height="100" />
              </Link>
              <div>
                <Link
                  style={{
                    cursor: 'pointer',
                    color: 'black',
                    textDecoration: 'none',
                  }}
                  to={urlItem(item)}
                >
                  <h2> {item.titulo} </h2>
                </Link>
                <Col md={12} className="position-relative p-0 d-flex" style={{ top: '-15px' }}>
                  {item.autor ? (
                    <p className="mr-5" style={{ color: 'black', opacity: 0.4, fontSize: 15 }}>
                      {item.autor.length > 0 ? item.autor[0].nombre_autor : ''}
                    </p>
                  ) : (
                    ''
                  )}
                  <p style={{ color: 'black', opacity: 0.4, fontSize: 15 }}>{item.type}</p>
                </Col>
                <Col md={12} className="position-relative p-0 d-flex check" style={{ top: '-15px' }}>
                  <BiCheck /> Disponible
                </Col>
              </div>
            </div>
            {props?.isResume ? (
              <div className="cart-item__resume">
                <div className="infoQuantyItem">
                  <div className="dataItem">
                    <div className="fieldData">Cantidad:</div>
                    <div className="valueData">{item.quantity}</div>
                  </div>
                </div>
                <div className="infoItem">
                  <div className="dataItem">
                    <div className="fieldData">Precio:</div>
                    <div className="valueData">{item.precio_venta_publico} €</div>
                  </div>
                  <div className="dataItem">
                    <div className="fieldData">Total:</div>
                    <div className="valueData">{(item.quantity * item.precio_venta_publico).toFixed(2)} €</div>
                  </div>
                </div>
              </div>
            ) : null}
            {!props.isResume ? (
              <div className="cart-item__actions">
                <Col lg={12}>
                  <h6>
                    <strong>{item.precio_venta_publico} €</strong>
                  </h6>
                  <Col lg={12} className="add-remove pr-3">
                    <p className="m-1">
                      <Link
                        to="/carrito"
                        onClick={() => increaseQuantityItem(item.id)}
                        aria-label="quitar una unidad de este producto del carrito"
                        className="pr-2"
                      >
                        <BsPlus className="icon-md" color="#3a86ff" />
                      </Link>
                    </p>
                    <Button className="p-1 mb-1">{item.quantity}</Button>
                    <p className="m-0 pr-1">
                      <Link
                        to="/carrito"
                        onClick={() => decreaseQuantityItem(item.id)}
                        aria-label="añadir una unidad de este producto del carrito"
                        className="pr-2"
                      >
                        <BiMinus className="icon-md" color="#3a86ff" />
                      </Link>
                    </p>
                  </Col>{' '}
                </Col>

                <Col lg={12}>
                  <Link
                    to="/carrito"
                    className="cart-item__delete"
                    aria-label="eliminar este item del carrito"
                    onClick={() => removeProductCart(item)}
                  >
                    <Button className="white-btn p-1">ELIMINAR</Button>
                  </Link>
                </Col>
              </div>
            ) : null}
          </div>
        ))}
      </Col>
      <Col md={12}>
        {hasGiftCard && (
          <div className="cart-item">
            <div className="cart-item__details" style={{ paddingTop: '2rem' }}>
              <Image src={giftImageCard} style={styleGiftCard} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h2>Tarjeta de regalo</h2>
                <p className="mr-5 mt-5" style={{ color: 'black', opacity: 0.4, fontSize: 15 }}>
                  Nombre del cupón
                </p>
              </div>
            </div>
            <div style={{ position: 'relative', left: '83px' }}>
              <h6 style={styleCardPrice}>
                <strong>{giftCard?.discount} €</strong>
              </h6>
              <p
                className="mr-5 mt-5"
                style={{ color: 'black', opacity: 0.4, fontSize: 15, position: 'relative', right: '48px' }}
              >
                Precio de descuento
              </p>
              <Button className="white-btn p-1" style={styleButtonDelete} onClick={() => removeGiftCardCart()}>
                ELIMINAR
              </Button>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default Items;
