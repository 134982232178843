import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
const defaultMessage = 'Sección bajo construcción, estará disponible a la brevedad.';
const defaultTitle = '¡Próximamente!';
const FeedbackPage = ({ title = defaultTitle, message = defaultMessage, maxHeight = false }) => {
  const history = useHistory();
  return (
    <div
      className={`d-flex flex-column justify-content-between align-items-mt-5 p-5 w-100 alert alert-info ${
        maxHeight ? 'h-100' : ''
      }`}
      role="alert"
    >
      <span>
        <h4 className="alert-heading">{title}</h4>
        <p>{message}</p>
      </span>
      <span>
        <hr className="w-100" />
        <strong role="button" onClick={() => history.goBack()} className="mb-0">
          <BiArrowBack /> Volver
        </strong>
      </span>
    </div>
  );
};

export default FeedbackPage;
