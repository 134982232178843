import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Form } from 'react-bootstrap';
import { useIntersectionObserver } from 'react-intersection-observer-hook';
import Select from 'react-select';

// own components
import ItemList from '../../components/itemList/item';
import { convertMetatagName, recoverMetatagName } from '../../utils';
import { getSmallReviews } from '../../api/libros';

import Head from '../../components/metatags_helmet/metatags';
import Paperplane from '../../assets/img/background_paperplane.png';
import './smallReviews.scss';

const SmallReviews = (props) => {
  const [items, setItems] = useState([]);
  const [itemsShow, setItemsShow] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [metaTitle, setMetaTitle] = useState('');

  const getLoadData = async () => {
    const { status, data } = await getSmallReviews();
    if (status === 200) {
      setItems(data);
    }
  };

  const addField = () => {
    items.map((el) => {
      el.libro[0].editorial = 'Editorial Española';
      setItemsShow((prevState) => {
        return [...prevState, el];
      });
    });
  };

  useEffect(() => {
    getLoadData();
  }, []);

  useEffect(() => {
    addField();
  }, [items]);

  const setTitle = () => {
    if (name.toLowerCase() !== 'merchandising') {
      setMetaTitle(`El mejor contenido sobre *${recoverMetatagName(name)}*`);
      setDescription(
        `Si buscas libros, películas o música sobre ${convertMetatagName(
          name
        )}, ¡estás de suerte en encontrarnos! Compra en Name a domicilio.`
      );
    }
  };

  return (
    <div className="container-fluid px-0 mainDiv">
      <Head description={description} title={metaTitle} />
      <Row className="px-2 mx-0">
        <Col md={12} className="pl-1">
          <h1 className="page-title static-title mt-4 spectral">Mundo Libelista - Pequeñas reseñas</h1>
        </Col>
      </Row>
      <Container fluid className="px-0 mx-0">
        <Row className="justify-content-center pl-3 mx-0">
          <Col lg={12}>
            <Row className="itemsRow">
              {itemsShow.map((item, i) => (
                <Col md={4} lg={3} sm={6} key={i} style={{ marginTop: 5 }} className="">
                  <ItemList key={i} data={item.libro[0]} isReview={true} reviewId={item.id} />
                </Col>
              ))}
            </Row>
            <Row className="">
              <Col className="d-flex justify-content-center">
                <img src={Paperplane} alt="paper.png" className="planeImg" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SmallReviews;
