import React, { useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";

import Modal from "../modal";

const LegalModal = (props) => {
  const modalSections = {
    title() {
      return <h3>Aviso legal y Política de privacidad</h3>;
    },
    body() {
      return (
        <div>
          <p>
            Para dar cumplimiento con lo establecido en la Ley 34/2002, de 11 de
            julio, de servicios de la sociedad de la información y de comercio
            electrónico, a continuación se indican los datos de la información
            general de este sitio Web:
          </p>
          <p>
            <strong>OBJETO</strong> Estas condiciones generales (en adelante,
            las "Condiciones Generales") regulan el uso de todos los servicios
            de este sitio Web (en adelante "el Portal") que
            <strong>LIBELISTA S.L</strong>, pone a disposición de los usuarios
            de Internet. Por la mera utilización del Portal, los usuarios
            expresan la aceptación y sin reservas de las mismas, las cuales
            podrán ser modificadas por <strong>LIBELISTA S.L</strong> en
            cualquier momento. Los usuarios se ven asimismo sometidos a todas
            aquellas condiciones particulares, avisos o reglamentos de
            instrucciones que se pongan en su conocimiento, en relación a los
            contenidos o servicios concretos, que contemplan con lo previsto en
            estas Condiciones Generales en cuanto no se opongan.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h3>Información al usuario</h3>
          <p>&nbsp;</p>
          <p>
            <strong>LIBELISTA S.L,</strong> es el Responsable del tratamiento de
            los datos personales del Usuario y le informa que estos datos serán
            tratados de conformidad con lo dispuesto en la normativa vigente en
            protección de datos personales, el Reglamento (UE) 2016/679 de 27 de
            abril de 2016 (GDPR) relativo a la protección de las personas
            físicas en lo que respecta al tratamiento de datos personales y a la
            libre circulación de estos datos, por lo que se le facilita la
            siguiente información del tratamiento:
          </p>
          <p>
            <strong>
              ¿Quién es el Responsable del tratamiento de sus datos?
            </strong>{" "}
            El presente documento constituye la Política de PROTECCION DE DATOS
            que aplica a todos los datos de carácter personal que
            <strong>LIBELISTA S.L</strong> trata sobre Usted. A continuación le
            informamos sobre los datos identificativos del RESPONSABLE del
            tratamiento:
          </p>
          <p>Responsable: Libelista S.L.</p>
          <p>CIF: B-65815706</p>
          <p>Dirección Postal: C/ Mallorca, 272-276 1ª 08037. Barcelona.</p>
          <p>Teléfono de contacto: 935152971</p>
          <p>
            Correo electrónico:{" "}
            <a href="mailto:info@libelista.com">info@libelista.com</a>
          </p>
          <p>
            Datos de contacto del Delegado de Protección de Datos (DPD-DPO):{" "}
            <a href="mailto:lopd@msanchez.cat">lopd@msanchez.cat</a>
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h3>Finalidad. ¿Con qué finalidad tratamos sus Datos?</h3>
          <p>&nbsp;</p>
          <p>
            <strong>Clientes Web:</strong> Cuando compras un producto en nuestra
            tienda online, los datos que nos facilites serán utilizados para
            tramitar, enviar y facturar los productos adquiridos. Para poder
            realizar pedidos en la tienda on line, cada cliente proporciona de
            forma voluntaria y bajo su responsabilidad, sus datos de carácter
            personal (nombre, apellidos, dirección, teléfono, datos de
            facturación y forma de pago) que deberán ser veraces y exactos.
          </p>
          <p>
            <strong>Boletín / Comunicaciones comerciales:</strong> En los casos
            en que nos otorgues tu consentimiento según lo previsto en el
            artículo 21 de la Ley de Servicios de la Sociedad de la Información,
            utilizaremos tus datos identificativos y de contacto para enviarte
            información sobre las novedades de la página web, noticias de
            interés sobre el sector, así como promociones y ofertas de nuestros
            productos y servicios.
          </p>
          <p>
            La vigencia de las promociones que te enviamos pueden estar sujetos
            a la disponibilidad de los productos y especificaciones más
            concretas sobre las mismas. Ante cualquier duda, te informaremos
            sobre las condiciones de las promociones a través de nuestro
            Departamento de Atención al Cliente.
          </p>
          <p>
            <strong>Formulario de contacto:</strong> Los datos personales que
            nos envías mediante el formulario de contacto se tratarán únicamente
            para atender tu consulta. La información recogida se limita a datos
            identificativos y de contacto, así como datos relacionados con la
            naturaleza de la petición que nos dirijas. En cuanto a la
            información automática de navegación que recaudamos, te recomendamos
            leer nuestra Política de cookies.
          </p>
          <p>
            <strong>Redes sociales:</strong> A través de nuestra página web
            puede acceder a las redes sociales Facebook, Twitter, Instagram de
            acceso abierto a todos los usuarios. Se trata de sitios web donde el
            usuario puede registrar y seguirnos gratuitamente. En estas redes
            sociales los usuarios podrán conocer de nuestras actividades,
            opiniones, acceder a las fotos y vídeos. Los usuarios de estas redes
            sociales deben ser conscientes de que este sitio es independiente de
            nuestra web y está abierto, es decir, es visible para todos sus
            usuarios, y las políticas de privacidad a aplicar a estos contenidos
            son las fijadas por Facebook, Twitter, Instagram.{" "}
            <strong>" LIBELISTA S.L "</strong> no es titular de las redes
            sociales.
          </p>
          <p>
            <strong>Duración del tratamiento de Datos:</strong> Criterios de
            conservación de los datos: Se conservarán mientras exista un interés
            mutuo para mantener el fin del tratamiento y cuando ya no sea
            necesario para tal fin, se suprimirán con medidas de seguridad
            adecuadas para garantizar la seudonimización de los datos o la
            destrucción total de los mismos.
          </p>
          <p>
            <strong>
              Legitimación. ¿Cuál es la legitimación para el tratamiento de
              datos?:
            </strong>{" "}
            La base legal para el tratamiento de sus datos es mediante el
            consentimiento del afectado, la ejecución de contrato de servicios y
            ofrecer respuestas a sus consultas recibidas mediante el formulario
            de contacto o correo electrónico (según los términos y condiciones
            que constan en nuestra política de privacidad). La oferta
            prospectiva de productos y servicios está basada en el
            consentimiento que se solicita, sin que en ningún caso la retirada
            de este consentimiento condicione la ejecución del contrato de
            servicios.
          </p>
          <p>
            <strong>
              Destinatarios. ¿A qué destinatarios se comunicarán sus datos?:
            </strong>{" "}
            No se cederán datos a terceros, salvo obligación legal.
          </p>
          <p>
            <strong>
              Derechos. ¿Cuáles son sus derechos cuando nos facilita sus datos?
            </strong>
          </p>
          <ul>
            <li>
              <strong>Derecho de acceso:</strong> Cualquier persona tiene
              derecho a obtener confirmación sobre si en LIBELISTA S.L estamos
              tratando datos personales que les conciernan, o no.
            </li>
            <li>
              <strong>Derecho de rectificación:</strong> Las personas
              interesadas tienen derecho a solicitar la rectificación de los
              datos inexactos.
            </li>
            <li>
              <strong>Derecho de supresión:</strong> Las personas interesadas
              podrán solicitar la eliminación de sus datos personales y ya no
              sean necesarios para los fines que fueron recogidos.
            </li>
            <li>
              <strong>Derecho de oposición:</strong> Los interesados ​​pueden
              oponerse al tratamiento de sus datos con fines de servicios que
              nos soliciten de su interés. LIBELISTA S.L dejará de tratar los
              datos, salvo motivos legítimos imperiosos o para ejercer o
              defender posibles reclamaciones.
            </li>
            <li>
              <strong>Derecho de Limitación del Tratamiento:</strong> En
              determinadas circunstancias previstas en el artículo 18 RGPD, los
              interesados pueden solicitar la limitación del tratamiento de sus
              datos en los siguientes casos:
              <ul>
                <li>
                  Mientras se comprueba la impugnación de la exactitud de tus
                  datos.
                </li>
                <li>
                  Cuando el tratamiento es ilícito. Pero se opone a ejercer el
                  derecho a la supresión de sus datos.
                </li>
                <li>
                  Cuando en LIBELISTA S.L no necesita tratar sus datos, pero
                  usted los necesita para el ejercicio o la defensa de
                  reclamaciones.
                </li>
                <li>
                  Cuando usted se oponga al tratamiento de datos para el
                  cumplimiento de una misión en interés público o para la
                  satisfacción de un interés legítimo. Mientras se verifica si
                  los motivos legítimos para el tratamiento prevalecen sobre los
                  de usted.
                </li>
              </ul>
              <strong> </strong>
            </li>
            <li>
              <strong>Derecho de Portabilidad:</strong> En virtud del derecho a
              la portabilidad, los interesados ​​tienen derecho a obtener sus
              datos personales en un formato Electrónico estructurado de uso
              común y lectura mecánica y transmitirlos a otro responsable
            </li>
          </ul>

          <p>
            <strong>¿Cómo puedo ejercer los derechos?</strong> Si usted
            considera que en LIBELISTA S.L no hemos tratado sus datos de acuerdo
            con la normativa, puede contactar con nuestro delegado de protección
            de datos en la siguiente dirección de correo electrónico:{" "}
            <strong>
              <a href="mailto:lopd@msanchez.cat">lopd@msanchez.cat</a>.
            </strong>
          </p>

          <p>
            Además, puede acudir al{" "}
            <strong>Departamento Protección de Datos</strong> (Dirección postal:
            C/ Mallorca, 272-276 1ª. 08037. Barcelona. Teléfono: 935.152.971).
          </p>
          <p>
            Para ejercitar sus derechos, se deberá de acompañar a su solicitud,
            su DNI o documento equivalente que acredite su identidad.
          </p>
          <p>
            <u>
              <strong>El ejercicio de estos derechos es gratuito.</strong>
            </u>
          </p>
          <p>
            <strong>¿Qué vías de reclamación hay?</strong> Los interesados
            tienen derecho a presentar una reclamación ante la Autoridad de
            Control en materia de protección de datos competente, especialmente
            cuando no haya obtenido satisfacción en el ejercicio de sus
            derechos, y la forma de ponerse en contacto con ella es mediante la
            página Web.
            <a href="http://www.agpd.es">http://www.agpd.es</a>.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h3>
            Política de privacidad. Carácter obligatorio o facultativo de la
            información facilitada por el usuario.
          </h3>
          <p>&nbsp;</p>
          <p>
            Los Usuarios, mediante la entrada de datos en los campos en el
            formulario de contacto o presentados en formularios de descarga,
            aceptan expresamente y de forma libre e inequívoca, que sus datos
            son necesarias para atender su petición, por parte del prestador,
            siendo voluntaria la inclusión de datos en los campos restantes. El
            Usuario garantiza que los datos personales facilitados a{" "}
            <strong>LIBELISTA S.L</strong> son veraces y se hace responsable de
            comunicar cualquier modificación de los mismos.
          </p>
          <p>
            <strong>LIBELISTA S.L</strong> informa y garantiza expresamente a
            los usuarios que sus datos personales no serán cedidos en ningún
            caso a terceros, y que siempre que realizara algún tipo de cesión de
            datos personales, se pedirá previamente el consentimiento expreso,
            informado e inequívoco por parte los Usuarios. Todos los datos
            solicitados a través del sitio Web son obligatorios, ya que son
            necesarios para la prestación de un servicio óptimo al Usuario. En
            caso de que no sean facilitados todos los datos, no se garantiza que
            la información y servicios facilitados sean completamente ajustados
            a sus necesidades.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h3>Medidas de seguridad</h3>
          <p>&nbsp;</p>
          <p>
            Que de conformidad con lo dispuesto en las normativa vigente en
            protección de datos personales,
            <strong>LIBELISTA S.L</strong> está cumpliendo con todas las
            disposiciones de la normativas del RGPD, para el tratamiento de los
            datos personales de su responsabilidad, y manifiestamente con los
            principios descritos en el artículo 5 del RGPD, por los cuales son
            tratados de manera lícita, leal y transparente en relación con el
            interesado y adecuados, pertinentes y limitados a lo necesario en
            relación con los fines para los que son tratados.
          </p>
          <p>
            <strong>LIBELISTA S.L</strong> garantiza que ha implementado
            políticas técnicas y organizativas apropiadas para aplicar las
            medidas de seguridad que establece el RGPD, para proteger los
            derechos y libertades de los Usuarios y les ha comunicado la
            información adecuada para que puedan ejercerlos.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h3>Utilización del portal y de los servicios para los usuarios</h3>
          <p>&nbsp;</p>
          <p>
            Excepto en el caso que hayan obtenido previa y expresa autorización
            específicamente otorgada a tal efecto por parte de{" "}
            <strong>LIBELISTA S.L</strong>, los usuarios se abstendrán de
            obtener o intentar obtener cualquier tipo de contenido, ya sean
            textos, gráficos, dibujos, archivos de sonido, imágenes o
            fotografías, vídeos, software y en general cualquier clase de
            material accesible a través del Portal o de los servicios utilizados
            por ellos, por medios distintos de los que se hayan puesto a su
            disposición a este efecto o de los que se empleen habitualmente a
            este efecto en Internet.
          </p>
          <p>
            La reproducción, modificación, copia, uso, distribución,
            comercialización, comunicación pública o cualquier otra utilización
            de la información contenida en el sitio Web de{" "}
            <strong>LIBELISTA S.L</strong> (incluidos su propio diseño,
            configuración y forma de exposición del sitio Web) que se realicen
            sin autorización de <strong>LIBELISTA S.L</strong>
            constituye una infracción de la legislación vigente en materia de
            propiedad intelectual.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h3>Política de cookies</h3>
          <p>&nbsp;</p>
          <p>
            A través de este sitio web no se recaban datos de carácter personal
            de los usuarios sin su conocimiento, ni se ceden a terceros. Con la
            finalidad de ofrecerle el mejor servicio y con el objeto de
            facilitar el uso, se analizan el número de páginas visitadas, el
            número de visitas, así como la actividad de los visitantes y su
            frecuencia de utilización. A estos efectos,
            <strong>LIBELISTA S.L</strong> utiliza la información estadística
            elaborada por el Proveedor de Servicios de Internet.{" "}
            <strong>LIBELISTA S.L</strong> no utiliza cookies para recoger
            información de los usuarios, ni registra las direcciones IP de
            acceso. Únicamente se utilizan cookies propias, de sesión, con
            finalidad técnica (aquellas que permiten al usuario la navegación a
            través del sitio web y la utilización de las diferentes opciones y
            servicios que en ella existen).
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h3>Exclusión de responsabilidad de los contenidos</h3>
          <p>&nbsp;</p>
          <p>
            La empresa no es en ningún caso responsable, ni de forma directa ni
            subsidiaria, de ningún contenido, información, comunicación, opinión
            o manifestación de cualquier tipo que tenga su origen en el usuario
            o cualquier persona o entidad y que se comunique, difunda, transmita
            o exhiba a través de este portal.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h3>Propiedad intelectual e industrial</h3>
          <p>&nbsp;</p>
          <p>
            Todos los contenidos así como nombres, logos, marcas y bases de
            datos accesibles en las páginas Web del Portal están sujetos a
            derechos de propiedad industrial e intelectual de{" "}
            <strong>LIBELISTA S.L</strong>o de terceros titulares de los mismos.
            En ningún caso el acceso a las páginas Web del Portal implica ningún
            tipo de renuncia, transmisión o cesión total o parcial de los
            mencionados derechos, ni confiere ningún derecho de utilización,
            alteración, explotación, reproducción, distribución o comunicación
            pública sobre los contenidos o bienes de propiedad industrial sin la
            previa y expresa autorización específicamente otorgada a tal efecto
            por parte de la empresa o del tercero titular de los derechos,
            excepto los derechos de visualizar y de realizar copias para uso
            personal y exclusivo del usuario, los cuales deberán ejercer siempre
            conforme a los principios de la buena fe y la legislación
            aplicable.&nbsp;<strong>LIBELISTA S.L</strong> y otras
            denominaciones de productos, servicios, gráficos y logotipos son
            marcas protegidas por <strong>LIBELISTA S.L.</strong> Las
            denominaciones de otros productos, servicios y compañías mencionadas
            en este documento pueden ser marcas registradas de sus respectivos
            propietarios.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h3>Ley aplicable</h3>
          <p>&nbsp;</p>
          <p>
            La ley aplicable en caso de disputa o conflicto de interpretación de
            los términos que conforman este aviso legal, así como cualquier
            cuestión relacionada con los servicios del presente portal, será la
            ley española.
          </p>
        </div>
      );
    },
    footer() {
      return (
        <div>
          <Button onClick={props.onHide}>Cerrar</Button>
        </div>
      );
    },
  };

  return (
    <Container fluid className="justify-content-center">
      <Col lg={11}>
        <Modal
          className={"legalModal"}
          show={props.showModal}
          onHide={props.onHide}
          title={modalSections.title()}
          children={modalSections.body()}
          footer={modalSections.footer()}
        ></Modal>
      </Col>
    </Container>
  );
};

export default LegalModal;
