import axios from "axios";
import { API_INFO } from "../constants";

const headers = {
  "Content-Type": "application/json",
};

const axiosInstance = axios.create({
  baseURL: API_INFO,
  headers: headers,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access");
    if (token) {
      config.headers.Authorization = `JWT ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      const refresh_token = localStorage.getItem("refresh");
      if (refresh_token) {
        const payload = { refresh: refresh_token };
        try {
          const { status, data } = await axios.post(
            API_INFO + "token/refresh/",
            payload
          );
          localStorage.setItem("access", data.access);
          localStorage.setItem("refresh", data.refresh);
          axiosInstance.defaults.headers["Authorization"] =
            "JWT " + data.access;
          originalRequest.headers["Authorization"] = "JWT " + data.access;
          return axiosInstance(originalRequest);
        } catch (error) {
          localStorage.clear();
          window.location = "/";
        }
      } else {
        localStorage.clear();
        window.location = "/";
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
