import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Select from 'react-select';
import { FaArrowCircleDown } from 'react-icons/fa';
import { companySelector } from '../../slices/company';

const ListItemsFilter = (props) => {
  const [path, setPath] = useState(window.location);
  const [childLists, setChidLists] = useState(null);
  const [isChild, setIsChild] = useState(null);
  const { mainLists } = useSelector(companySelector);
  const [listName, setListName] = useState(props.listName);
  const partsPathname = window.location.pathname.split('/');
  const pathname = window.location;
  const slug = partsPathname[2];
  const { selectFilter } = props;
  const options = [
    { value: 'titulo', label: 'Título' },
    { value: 'precio', label: 'Precio' },
    { value: 'fecha_publicacion', label: 'Fecha de publicación' },
  ];
  const productTypes = [
    { value: 'ebook', type: 'Libro digital' },
    { value: 'paper', type: 'Libro papel' },
  ];
  const languajes = [
    { value: 1, type: 'Español' },
    { value: 2, type: 'Catalán' },
    { value: 4, type: 'Gallego' },
    { value: 6, type: 'Inglés' },
    { value: 10, type: 'Portugués' },
    { value: 136, type: 'Euskera' },
  ];

  const currentList = () => {
    let current = mainLists.filter((el) => {
      return listName == el.title;
    });
    if (current.length !== 0) {
      setChidLists(current[0].children);
    }
  };

  const isChildList = () => {
    setIsChild(false);
    let iter = childLists?.map((el) => {
      if (listName == el.title) {
        setIsChild(true);
      }
      return '';
    });
  };

  const setBookFormat = (value) => {
    let parameters = '';
    if (slug === 'materias') {
      if (value === 'ebook') {
        parameters = { ebook: true };
      } else {
        parameters = { ebook: false };
      }
    } else {
      if (value === 'ebook') {
        parameters = { is_ebook: true };
      } else {
        parameters = { is_ebook: false };
      }
    }
    selectFilter(parameters);
  };

  const setLanguageFilter = (value) => {
    let parameters = '';
    if (slug === 'materias') {
      parameters = { language_id: value };
    } else {
      parameters = { idioma: value };
    }
    selectFilter(parameters);
  };

  useEffect(() => {
    currentList();
    isChildList();
  }, [listName]);

  useEffect(() => {
    setListName(props.listName);
  }, [props.listName]);

  return (
    <div className="filterCol">
      <Container fluid className="">
        <Row className="px-0 mx-0">
          <Col lg={12} className="px-0 mx-0">
            <p className="filterTitle">Ordenar por</p>
          </Col>
          <Col lg={12} className="px-0 d-flex">
            <Select
              onChange={(event) => selectFilter({ ordering: event.value })}
              options={options}
              className="mb-3 select"
            />
          </Col>
        </Row>
        {slug !== 'ebooks' && (
          <Row className="px-0 mx-0">
            <Col className="px-0 mx-0">
              <p className="filterTitle mt-3">Tipo de producto</p>
              {productTypes.map((el, index) => (
                <p onClick={() => setBookFormat(el.value)} className="ml-2" key={`productTypes#${index}}`}>
                  {el.type}
                </p>
              ))}
            </Col>
          </Row>
        )}
        {slug === 'materias' && (
          <Row className="px-0 mx-0">
            <Col className="px-0 mx-0">
              <p className="filterTitle mt-3">Subcategorías</p>
              {childLists && !isChild ? (
                childLists.map((el, index) => (
                  <p key={index}>
                    <Link
                      to={`/lista/materias/${el.id}`}
                      className="ml-2"
                      key={`lenguajes#${index}}`}
                      onClick={() => setListName(el.title)}
                    >
                      {el.title}
                    </Link>
                  </p>
                ))
              ) : (
                <p style={{ cursor: 'not-allowed' }}>No posee subcategorías</p>
              )}
            </Col>
          </Row>
        )}
        <Row className="px-0 mx-0">
          <Col className="px-0 mx-0">
            <p className="filterTitle mt-3">Idioma</p>
            {languajes.map((el, index) => (
              <p className="ml-2" key={`lenguajes#${index}}`}>
                {el.type}
              </p>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ListItemsFilter;
