import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//styles

// api
import { getPostsCategories } from "../../api/libelista";

function BlogCategories() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function setBlogCategories() {
      const rs = await getPostsCategories();
      if (rs.status === 200) {
        setCategories(rs.data);
      }
    }
    setBlogCategories();
  }, []);

  return (
    <div className="blog-aside-categories">
      <h2 className="section-col mt-3">Categorías</h2>
      {categories !== "" &&
        categories.map((cat, indx) => {
          return (
            <Link to={`/blog/${cat.slug}/`} className="media" key={cat.id}>
              {cat.featured_image !== "" ? (
                <img
                  className="media-img"
                  src={cat.featured_image}
                  alt={`${cat.model_name} image`}
                />
              ) : (
                <img className="media-img" src="" alt="" />
              )}
              <p className="media-text">{cat.name}</p>
            </Link>
          );
        })}
    </div>
  );
}

export default BlogCategories;
