import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Container, Button, Form, Collapse, Jumbotron, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { FiGift } from 'react-icons/fi';
import Select from 'react-select';
import TPV from './tpv';

import { COMPANY } from '../../constants';
import { InputCustom } from '../../components/input';
import { getCountries } from '../../api/company';
import { createOrder } from '../../api/ecommerce';
import { cartSelector, clear } from '../../slices/cart';
import { companySelector } from '../../slices/company';
import { userSelector, setBillingAddress } from '../../slices/user';
import './cart.scss';

const Pay = (props) => {
  const [addDirection, setAddDirection] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [payment, setPayment] = useState('');
  const [billingForm, setBillingForm] = useState({});
  const [paySuccess, setPaySuccess] = useState(false);
  const [successPurchase, setSuccessPurchase] = useState(false);
  const [detailOrder, setDetailOrder] = useState({});
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const { state } = props.location;
  const { data } = useSelector(companySelector);
  const { billingAddress, shippingAddress, benefitedBookstore } = useSelector(userSelector);
  const [validData, setValidData] = useState([false, 'Ingrese datos faltantes']);
  const dispatch = useDispatch();
  let dir = window.location.search;
  const location = useLocation();
  const {
    isNewShippingAddress,
    storePickup,
    items,
    shippingCosts,
    isGiftOrder,
    giftData,
    countItems,
    subtotal,
    iva,
    total,
    appliedCoupon,
  } = useSelector(cartSelector);
  const [showDiscountDetail, setShowDiscountDetail] = useState(false);
  const [finalPrice, setFinalPrice] = useState((parseFloat(total) + parseFloat(shippingCosts)).toFixed(2));

  useEffect(() => {
    setBillingForm(billingAddress);
  }, [billingAddress]);

  useEffect(() => {
    async function handleCountries() {
      const rs = await getCountries();
      if (rs.status === 200) {
        const c = rs.data.map((item) => ({
          value: item.codigo,
          label: item.nombre,
        }));
        setCountries(c);
      }
    }
    handleCountries();
    window.document.title = 'Carrito de compras | ' + COMPANY;
  }, []);

  useEffect(() => {
    if (location.state?.discount) {
      applyDiscount(location.state?.percentage, location.state?.typeDiscount);
    }
  }, [total]);

  const applyDiscount = (discount, typeDiscount) => {
    if (typeDiscount === 0) {
      setFinalPrice((finalPrice - finalPrice * (discount / 100)).toFixed(2));
      setAppliedDiscount(discount);
      setShowDiscountDetail(true);
    } else {
      setFinalPrice((finalPrice - discount).toFixed(2));
      setAppliedDiscount(discount);
      setShowDiscountDetail(true);
    }
  };

  const checkData = () => {
    if (billingAddress !== undefined) {
      let properties = ['address', 'postal_code', 'locality'];
      for (let i of properties) {
        if (billingAddress[`${i}`] === undefined || billingAddress[`${i}`] == '') {
          setValidData([false, 'Ingrese datos faltantes']);
          return '';
        }
      }
      setValidData([true, billingAddress.address + ', ' + billingAddress.postal_code + ', ' + billingAddress.locality]);
    }
  };

  useEffect(() => {
    checkData();
  }, [billingAddress]);

  const saveBillingAddress = () => {
    dispatch(setBillingAddress(billingForm));
    alert('Dirección guardada correctamente!');
  };

  const confirmOrder = async (finishProcess) => {
    const payload = {
      forma_pago: payment,
      billing_address: billingAddress,
      shipping_address: shippingAddress,
      is_new_shipping_address: false,
      store_pickup: storePickup,
      items,
      shipping_cost: shippingCosts,
      finish_process: finishProcess,
      is_gift: isGiftOrder,
      benefited_company: benefitedBookstore?.id,
      applied_coupon: appliedCoupon,
    };
    try {
      const { status, data } = await createOrder(payload);
      if (status === 201) {
        if (payment === 4) {
          setPaySuccess(true);
        } else {
          setDetailOrder(data);
          setSuccessPurchase(true);
          dispatch(clear());
        }
      } else {
        console.log('error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const totalWithoutDiscount = (parseFloat(total) + parseFloat(shippingCosts)).toFixed(2);

  return (
    <Container fluid className="sm-fluid mainDiv">
      {!successPurchase ? (
        <Row className="justify-content-center">
          <Col md={9}>
            <div className="row">
              <div className="col-md-12">
                <ul className="nav nav-tabs wizard">
                  <li className="grayArrow">1. Cesta</li>
                  <li className="grayArrow">2. Entrega</li>
                  <li style={{ background: '#4B95A6' }}>
                    <span style={{ color: '#fff' }}>3. Pago</span>
                  </li>
                </ul>
              </div>
            </div>

            <Row className="tabs-row">
              <Col md={12} lg={12} xs={12} className="ml-1">
                <div className="">
                  <h2 onClick={() => console.log(giftData)} className="mt-3">
                    Dirección de facturación...
                  </h2>
                  <div className="giftDiv">
                    <Row className="">
                      {!addDirection ? (
                        <Col lg={12} className="mt-2 d-flex">
                          <Form.Control value={validData[1]} className="mr-2 pl-0" disabled />
                          <Button
                            className="white-btn py-2 px-0"
                            style={{ width: '25%', fontSize: '14px' }}
                            onClick={() => setAddDirection(!addDirection)}
                          >
                            EDITAR DIRECCIÓN
                          </Button>
                        </Col>
                      ) : (
                        <Col lg={12} className="mt-2">
                          <InputCustom
                            type="text"
                            label="Dirección"
                            placeholder="Dirección"
                            title="Dirección"
                            value={billingForm.address}
                            onChange={(e) => {
                              setBillingForm({
                                ...billingForm,
                                address: e.target.value,
                              });
                            }}
                          />
                          <InputCustom
                            type="text"
                            name="postalcode"
                            label="Código postal"
                            placeholder="Código postal"
                            title="Código postal"
                            value={billingForm.postal_code}
                            onChange={(e) => {
                              setBillingForm({
                                ...billingForm,
                                postal_code: e.target.value,
                              });
                            }}
                          />
                          <InputCustom
                            type="text"
                            label="Localidad"
                            placeholder="Localidad"
                            title="Localidad"
                            value={billingForm.locality}
                            onChange={(e) => {
                              setBillingForm({
                                ...billingForm,
                                locality: e.target.value,
                              });
                            }}
                          />
                          <InputCustom
                            type="text"
                            label="Provincia"
                            placeholder="Provincia"
                            title="Provincia"
                            value={billingForm.province}
                            onChange={(e) => {
                              setBillingForm({
                                ...billingForm,
                                province: e.target.value,
                              });
                            }}
                          />
                          <label>País</label>
                          <Select
                            placeholder="País"
                            title="País"
                            className="mb-3"
                            options={countries}
                            defaultValue={billingForm.country}
                            value={billingForm.country}
                            onChange={(e) => {
                              setBillingForm({
                                ...billingForm,
                                country: { value: e.value, label: e.label },
                              });
                            }}
                          />
                          <InputCustom
                            type="text"
                            label="Teléfono"
                            placeholder="Teléfono"
                            title="Teléfono"
                            value={billingForm.phone}
                            onChange={(e) => {
                              setBillingForm({
                                ...billingForm,
                                phone: e.target.value,
                              });
                            }}
                          />
                          <InputCustom
                            type="text"
                            label="Email"
                            placeholder="Email"
                            title="Email"
                            value={billingForm.email}
                            onChange={(e) => {
                              setBillingForm({
                                ...billingForm,
                                email: e.target.value,
                              });
                            }}
                          />
                          <div className="w-100 d-flex justify-content-end">
                            <Button
                              className="white-btn py-2 px-0 mr-2"
                              style={{ width: '25%' }}
                              onClick={() => setAddDirection(!addDirection)}
                            >
                              CANCELAR
                            </Button>
                            <Button
                              className="blue-btn py-2 px-0"
                              style={{ width: '35%', fontSize: '14px' }}
                              onClick={() => {
                                setAddDirection(!addDirection);
                                saveBillingAddress();
                              }}
                            >
                              GUARDAR DIRECCIÓN
                            </Button>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                </div>

                <div className="">
                  <h2 className="mt-3">Forma de pago</h2>
                  <div className="giftDiv">
                    <Row className="position-relative" style={{ left: '2.2%' }}>
                      <Form.Check
                        type="radio"
                        onChange={() => {
                          setPayment(4);
                        }}
                        checked={payment === 4}
                      />
                      <p>Pago con tarjeta</p>
                    </Row>
                    <Row className="position-relative" style={{ left: '2.2%' }}>
                      <Form.Check
                        type="radio"
                        onChange={() => {
                          setPayment(1);
                        }}
                        checked={payment === 1}
                      />
                      <p style={{ marginBottom: '2px' }}>Transferencia bancaria</p>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="discount mt-5">
              <Col md={12} lg={12} xs={12} className="d-flex justify-content-between">
                <span>
                  <Button
                    className="orange-btn ml-1 text-white"
                    as={Link}
                    to={{
                      pathname: '/shipping',
                      state: {
                        discount: state?.discount,
                        percentage: state?.percentage,
                        typeDiscount: state?.typeDiscount,
                      },
                    }}
                    variant="link"
                    size="sm"
                  >
                    VOLVER
                  </Button>
                </span>
                <span>
                  <p className="pt-2 mr-3 d-inline">¡Todo listo!</p>
                  <div className="d-inline">
                    <Collapse in={!paySuccess} timeout={180}>
                      <Button
                        onClick={() => {
                          if (payment == 1) {
                            confirmOrder(true);
                          } else {
                            confirmOrder(false);
                          }
                        }}
                        className="orange-btn p-2"
                        disabled={addDirection || typeof payment !== 'number'}
                      >
                        COMPRAR AHORA
                      </Button>
                    </Collapse>
                    <Collapse in={paySuccess} timeout={170}>
                      <div>
                        <TPV />
                      </div>
                    </Collapse>
                  </div>
                </span>
              </Col>
            </Row>
          </Col>

          <Col md={3} className="mb-2 mt-3 text-muted priceCol">
            <Card>
              <Card.Body>
                <div className="aside-title mb-3 px-2">
                  <span>Tu pedido</span>
                </div>
                <Card.Subtitle className="mb-3 pl-3 text-muted">{countItems} items</Card.Subtitle>
                <Col className="mt-4 d-flex justify-content-between">
                  <p>Subtotal:</p>
                  <p> {subtotal} €</p>
                </Col>
                <Col className="d-flex justify-content-between">
                  <p>IVA:</p>
                  <p> {iva} €</p>
                </Col>
                <Col className="d-flex justify-content-between">
                  <p>Envío para España: </p>
                  <p>{shippingCosts > 0 ? shippingCosts + ' €' : <b>Gratis</b>} </p>
                </Col>
                <Col className="borderDiscount  d-flex justify-content-between">
                  <p>{showDiscountDetail ? 'Total sin descuento:' : 'Total:'} </p>
                  <p>{totalWithoutDiscount} €</p>
                </Col>
                {showDiscountDetail && (
                  <>
                    <Col className=" d-flex justify-content-between textDiscount">
                      <p>Descuento: </p>
                      <p>{`${appliedDiscount}${state?.typeDiscount === 0 ? '%' : '€'} `} </p>
                    </Col>
                    <Col className=" d-flex justify-content-between textDiscount">
                      <p>Monto a descontar</p>
                      <p>{(totalWithoutDiscount - finalPrice).toFixed(2)} €</p>
                    </Col>
                    <Col className=" d-flex justify-content-between textDiscount borderDiscount">
                      <p>Total con descuento: </p>
                      <p>{finalPrice} €</p>
                    </Col>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Jumbotron style={{ textAlign: 'center' }}>
          <p>Pedido procesado con éxito.</p>
          <p>Recibirás un email con los datos del pedido.</p>
          {parseInt(detailOrder.forma_pago) === 1 && (
            <Row style={{ justifyContent: 'center' }}>
              <Col md={10}>
                <Table bordered hover variant="dark">
                  <tbody>
                    <tr>
                      <td colSpan="2">
                        <h3>Realizar transferencia a:</h3>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td>Cuenta bancaria :</td>
                      <td>{data.cif}</td>
                    </tr>
                    <tr>
                      <td>Concepto:</td>
                      <td>{detailOrder.id} </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col md={8} style={{ marginTop: 30 }}>
                <p>
                  Por favor para completar su compra realize una transferencia al número de cuenta <b>{data.cif}</b> con
                  concepto el numero de pedido : <b>{detailOrder.id}</b>.
                </p>
                <p>
                  Las trasferencias pueden tardar de 2 a 3 días en recibirse. Una vez hayamos recibido el pago
                  prepararemos el envío de su pedido.
                </p>
                <p>Gracias</p>
                <Link to="/" className="">
                  <Button className="mt-3 p-3">VOLVER AL HOME</Button>
                </Link>
              </Col>
            </Row>
          )}
        </Jumbotron>
      )}
    </Container>
  );
};

export default Pay;
