import * as React from "react";
import BackofficeTable from "../../../../components/backoffice/table/index";
import { getKeywords, deleteKeyword } from "../../../../api/products";
import BackofficePagesManager from "../../../../components/backoffice/pagesManager";
const KeywordsTable = ({ data, ActionsMenu, loadData }) => {
  const headers = {
    id: "I.D.",
    palabra: "Nombre del Autor",
    url: "U.R.L.",
  };

  loadData.colSpan = Object.keys(headers).length;
  const keywordsTableProps = {
    title: "Palabras Clave",
    data,
    headers,
    ActionsMenu,
    loadData,
  };
  return <BackofficeTable {...keywordsTableProps} />;
};
KeywordsTable.request = {
  get: getKeywords,
  del: deleteKeyword,
};
KeywordsTable.itemClass = "cupon";
KeywordsTable.requestParameter = "id";
const KeywordsTablePage = () => (
  <BackofficePagesManager PageViewer={KeywordsTable} />
);
export default KeywordsTablePage;
