import { methodGet, methodPost, methodDelete, methodPut } from './methods';
import { API_INFO } from '../constants';

export const getCategories = async () => {
  return methodGet(API_INFO + 'company/gallery/categories');
};

export const getGallery = async () => {
  return methodGet(API_INFO + 'company/gallery');
};

export const getEvents = async (query) => {
  return methodGet(API_INFO + 'company/events/', query);
};

export const getEvent = async (id) => {
  return methodGet(API_INFO + 'company/events/' + id);
};

export const getCountries = async () => {
  return methodGet(API_INFO + 'company/countries/');
};
export const getCountry = async (id) => {
  return methodGet(API_INFO + `company/countries/${id}/`);
};

export const getCompanies = async (id, name = '', code = '', email = '') => {
  return methodGet(
    API_INFO + `${id}/${name ? '&nombre=' + name : ''}${code ? '&code=' + code : ''}${email ? '&email=' + email : ''}`
  );
};

export const getCompany = async () => {
  return methodGet(API_INFO + 'company/my-info/');
};

export const getListCompany = async () => {
  return methodGet(API_INFO + `company/empresas/`);
};

export const getLocalities = async (searchName) => {
  return methodGet(API_INFO + `company/localities/${searchName ? '?nombre=' + searchName : ''}`);
};
export const getLocality = async (id) => {
  return methodGet(API_INFO + `company/localities/${id}/`);
};

export const getMetadataCompany = async () => {
  return methodGet(API_INFO + 'company/metadata-company/');
};

export const getProvinces = async (params) => {
  return methodGet(API_INFO + 'company/provinces/', params);
};

export const getLocalitiesByProvince = async (params) => {
  return methodGet(API_INFO + 'company/localitiesByProvince/', params);
};

export const getCSData = async () => {
  return methodGet(API_INFO + 'company/coffee-shop');
};

export const getBookingForm = async (params) => {
  return methodGet(`${API_INFO}user/reservation/send/`, params);
};

export async function postSubscriptionEmail(payload) {
  const url = API_INFO + 'company/subscription/';
  return methodPost(url, payload);
}

export const getLanguages = async () => {
  return methodGet(API_INFO + 'company/languages/');
};
export const deleteBookstore = async (id) => {
  return methodDelete(API_INFO + `company/empresas/${id}/`);
};
export const getBookstore = async (id) => {
  return methodGet(API_INFO + `company/empresas/${id}/`);
};
export const editBookstore = async (payload) => {
  return methodPut(API_INFO + `company/empresas/${payload.id || payload.get('id')}/`, payload);
};
export const createBookstore = async (payload) => {
  return methodPost(API_INFO + `company/empresas/`, payload);
};
export const getAllBookstores = async (pageNumber, page_size, name, code, email) => {
  const firstVal =
    API_INFO + `company/empresas/?&name=${name}&code=${code}&email=${email}&page=${pageNumber}&page_size=${page_size}/`;
  const secondVal = API_INFO + `company/empresas/?page=${pageNumber}&page_size=${page_size}/`;
  const url = name || code || email ? firstVal : secondVal;
  const testing = await methodGet(url);
  return testing;
};
export const getBookstoresBackoffice = async () => {
  const url = API_INFO + `company/empresas/`;
  const request = await methodGet(url);
  return request;
};
export const getLibelistaData = async () => {
  return methodGet(API_INFO + `company/empresas/?name=libelista`);
};
