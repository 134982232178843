import { getAllBooksOfTheMonth as get, deleteBooksOfTheMonth as del } from '../../../../api/libelista';
import * as React from 'react';
import BackofficeTable from '../../../../components/backoffice/table/index';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
const BooksOfTheMonthTable = ({ data, ActionsMenu, loadData }) => {
  const headers = {
    title: 'Título',
  };

  loadData.colSpan = Object.keys(headers).length;
  const booksOfTheMonthTableProps = {
    title: 'Libros del Mes',
    data,
    headers,
    ActionsMenu,
    loadData,
    filterParameterName: 'search',
    filterRelatedFields: ['title'],
  };
  return <BackofficeTable {...booksOfTheMonthTableProps} />;
};
BooksOfTheMonthTable.request = {
  get,
  del,
};
BooksOfTheMonthTable.itemClass = 'Libros del mes';
BooksOfTheMonthTable.requestParameter = 'id';
const BooksOfTheMonthTablePage = () => {
  const backOfficesPageManagerProps = { PageViewer: BooksOfTheMonthTable, filterParameterName: 'search' };
  return <BackofficePagesManager {...backOfficesPageManagerProps} />;
};
export default BooksOfTheMonthTablePage;
