import React from 'react';
import BackofficeTable from '../../../../components/backoffice/table/index';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import { deleteDetailOrder, getAllOrders } from '../../../../api/orders';

const headers = {
  code: 'Número de pedido',
  get_usuario_biblioteca: 'Cliente',
  // 'empresa.name': 'Librería',
  'bookstore_to_pickup_selected.name': 'Librería de retiro',
  fecha: 'Fecha',
  subtotal_no_iva: 'Precio sin impuestos',
  iva: 'Impuestos',
  shipping_cost: 'Gastos de envío',
  total: 'Costo final',
  status: 'Estado',
  comentario: 'Comentarios',
};

const OrdersTable = ({ data, ActionsMenu, loadData }) => {
  loadData.colSpan = Object.keys(headers).length;
  const ordersTableProps = {
    title: 'Pedidos',
    data,
    headers,
    ActionsMenu,
    extraFormat: {
      fecha: 'date',
      comentario: 'elipsis',
      get_usuario_biblioteca: 'elipsis',
      createButton: { disabled: true },
    },
    loadData,
    filterParameterName: 'search',
    filterRelatedFields: ['email', 'Nombre de usuario'],
  };
  return <BackofficeTable {...ordersTableProps} />;
};

OrdersTable.request = {
  get: getAllOrders,
  del: deleteDetailOrder,
};
OrdersTable.itemClass = 'pedidos';
OrdersTable.requestParameter = 'id';

const OrdersTablePage = () => {
  const backOfficesPageManagerProps = {
    PageViewer: OrdersTable,
    filterParameterName: 'search',
  };
  return <BackofficePagesManager {...backOfficesPageManagerProps} />;
};

export default OrdersTablePage;
