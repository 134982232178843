import React from "react";
import { Link } from "react-router-dom";

import Errorlink from "../../assets/img/static/ico_error404_xl.png";

const NotFound = () => (
  <div className="px-4 d-flex flex-column justify-content-center align-items-center static-empty mainDiv">
    <img src={Errorlink} alt="link not found" />
    <h2>Error 404</h2>
    <p className="text-center gray">
      Lo sentimos, la página solicitada no está disponible.
    </p>
    <p className="text-center">
      Por favor, comprueba que has tecleado correctamente la dirección.
    </p>
    <p className="text-center">
      Si lo deseas puedes acceder a la página principal de{" "}
      <Link to="/">Libelista</Link>
    </p>
    <Link to="/" className="btn btn-info fit">
      Volver a la home
    </Link>
  </div>
);

export default NotFound;
