import React from 'react';
import { Link } from 'react-router-dom';

import Capture1 from '../../assets/img/captura1.png';
import Capture2 from '../../assets/img/captura2.jpg';
import Capture3 from '../../assets/img/captura3.jpg';
import CardEvent from '../../components/event/itemEvent';
import './home.scss';

const EventHome = ({ blogs, events, lists }) => {
  return (
    <>
      <div className="homeContainer mb-3">
        {events.length > 0 && (
          <div className="containerPosts">
            <div className="aside-title">
              <a href="/agenda/">Agenda</a>
            </div>
            {events.map((item, i) => (
              <CardEvent event={item} key={i} />
            ))}
          </div>
        )}
      </div>

      <div className="homeContainer mb-3">
        <div className="aside-title mb-2">
          <a href="/#">Mundo Libelista</a>
        </div>
        <div className="blogCategories">
          <Link to={'/blog/librerias-protagonistas/'}>
            <img className="mt-3" src={Capture1} alt="pic.png" />
            <h6 className="mt-2">LIBRERÍAS PROTAGONISTAS</h6>
          </Link>
          <Link to={'/blog/libelista-propone/'}>
            <img className="mt-3" src={Capture2} alt="pic.png" />
            <h6 className="mt-2">LIBELISTA PROPONE</h6>
          </Link>
          <Link to={'/blog/entrevistas-libelista/'}>
            <img className="mt-3" src={Capture3} alt="pic.png" />
            <h6 className="mt-2">ENTREVISTAS</h6>
          </Link>
        </div>
      </div>

      <div className="homeContainer">
        <div className="aside-title mb-2">
          <a href="/#">Listas</a>
        </div>
        {lists && (
          <div className="containerPosts">
            {lists.map((item, index) => (
              <Link key={`eventLink#${index}`} className="blue d-block pl-2 mt-2" to={`/lista/materias/${item.id}`}>
                {item.title}
              </Link>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default EventHome;
