import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { BiCalendarCheck } from "react-icons/bi";
import { MdPlace } from "react-icons/md";
import { AiOutlineIdcard } from "react-icons/ai";
import "../blog/blog.scss";
import { truncate } from "../../helpers";

const ItemEvent = (props) => {
  const {
    start_date,
    end_date,
    image,
    body,
    title,
    place,
    id,
  } = props.event;
  return (
    <div className="cardBlog mt-md-4" style={{background: "#F8F8F8"}}>
      <div
        className="imageCardBlog"
        style={{
          backgroundImage: `url(${image})`,
          height: 150,
          width: "none",
        }}
      ></div>
      <Row>
        <Col md={12} className="text-left text-bold">
          <h6 className="titleCardBlog">{title} </h6>
          <BiCalendarCheck style={{color: '#f2a20d'}} />{" "}
          <span className="text-bold text-small">
            {start_date} - {end_date}{" "}
          </span>
          <br />
          <MdPlace style={{color: '#f2a20d'}} />{" "}
          <span className="text-bold text-small">{place} </span>
          <br />
          {body && (
            <span className="text-small">{truncate(body, 120)} </span>
          )}
        </Col>
        <Col className="text-secondary text-right">
          <AiOutlineIdcard className="eventLink blue" />
          {"   "}
          <a
            className="eventLink blue"
            href={`/agenda/${id}`}
          >
            Ver más
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default ItemEvent;
