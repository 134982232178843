import React, { useState, useEffect } from 'react';
import { getAllClientMessages as get, deleteClientMessage as del } from '../../../../api/libelista';
import BackofficeTable from '../../../../components/backoffice/table/index';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import { InputCustom } from '../../../../components/input';
import SectionLoader from '../../../../components/loader/SectionLoader';
import { useLocation, useHistory } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import './tableClientMesagges.scss';

const headers = {
  full_name: 'Nombre completo',
  email: 'Correo Electrónico',
  department: 'Departamento',
  message: 'Mensaje',
  answered: 'Respondido',
  created_at: 'Fecha de creación',
};

const ClientMessagesTable = (props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [answered, setAnswered] = useState(() => query.get('answered') || '');
  const [full_name, setFull_Name] = useState(() => query.get('full_name') || '');
  const [email, setEmail] = useState(() => query.get('email') || '');
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showInputAnswered, setShowInputAnswered] = useState(false);
  const [showInputFullName, setShowInputFullName] = useState(false);
  const [showInputEmail, setShowInputEmail] = useState(false);
  const { managerData, ActionsMenu } = props;
  const history = useHistory();

  const showButtonSearch = showInputAnswered || showInputFullName || showInputEmail;

  const deleteQueryParams = () => {
    history.push('/administration/client-messages');
  };

  const myInputs = () => {
    const url = window.location.href;
    const includesQueryParams = url.includes('?');
    return (
      <div className="d-flex my-3 ">
        {includesQueryParams && (
          <button className="btn  deleteQueryParams" onClick={() => deleteQueryParams()}>
            Volver
          </button>
        )}
        <Accordion defaultActiveKey="1" className={`${includesQueryParams ? 'modifyPositionSearch' : 'buttonSearch'} `}>
          <Accordion.Toggle className="btn btn-info w-100">Búsqueda</Accordion.Toggle>
          <Accordion.Collapse className="buttonCollapse">
            <div className="d-flex flex-column">
              <button onClick={() => setShowInputAnswered(!showInputAnswered)}>Respondido</button>
              <button onClick={() => setShowInputFullName(!showInputFullName)}>Nombre</button>
              <button onClick={() => setShowInputEmail(!showInputEmail)}>Correo electrónico</button>
            </div>
          </Accordion.Collapse>
        </Accordion>
        <form className={`${includesQueryParams ? 'modifyMarginForm' : 'form-position'} `} onSubmit={searchData}>
          {showInputAnswered && (
            <div className="d-flex styleInputCheckbox ">
              <p className="mt-3">Respondido</p>
              <InputCustom
                key={`hello`}
                type="checkbox"
                value={answered}
                onChange={(event) => {
                  setAnswered(event.target.checked);
                }}
                className="widthCheckbox mr-3"
              />
            </div>
          )}
          {showInputFullName && (
            <div className="mr-3 utilitiesWidth">
              <InputCustom
                key={`hello`}
                type="text"
                value={full_name}
                onChange={(event) => {
                  setFull_Name(event.target.value);
                }}
                placeholder="Nombre"
                className="stylePlaceholder"
              />
            </div>
          )}
          {showInputEmail && (
            <div className="utilitiesWidth">
              <InputCustom
                key={`hello`}
                type="text"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                placeholder="Correo electrónico"
                className="stylePlaceholder"
              />
            </div>
          )}
          {showButtonSearch && (
            <div className="my-3">
              <input role="button" className="btn btn-info styleButtonSearch" type="submit" value="Buscar" />
            </div>
          )}
        </form>
      </div>
    );
  };

  useEffect(() => {
    getLoadData();
  }, []);

  const searchData = () => {
    history.push(`?&answered=${answered}&full_name=${full_name}&email=${email}&page=${1}&page_size=${15}/`);
    getLoadData();
  };

  const getLoadData = async () => {
    setIsLoading(true);
    const { status, data } = await get(1, 15, answered, full_name, email);
    if (status === 200) {
      setData(data.results);
    }
    setIsLoading(false);
    setAnswered('');
    setFull_Name('');
    setEmail('');
  };

  const hasData = () => {
    if (data !== null && data !== undefined) {
      return Object.keys(data).length;
    } else {
      return false;
    }
  };

  const clientMessagesTableProps = {
    title: 'Mensajes de clientes',
    data,
    headers,
    ActionsMenu,
    extraFormat: {
      message: 'elipsis',
      created_at: 'date',
      answered: 'yesOrNo',
      createButton: { disabled: true },
    },
    customParams: myInputs(),
  };
  if (isLoading) {
    return <SectionLoader />;
  } else if (hasData()) {
    return <BackofficeTable {...clientMessagesTableProps} />;
  } else {
    return (
      <>
        <div>No se encontraron datos</div>
        <button className="btn  buttonDeleteQuery mt-5" onClick={() => deleteQueryParams()}>
          Volver
        </button>
      </>
    );
  }
};
ClientMessagesTable.request = {
  get,
  del,
};
ClientMessagesTable.itemClass = 'mensaje de cliente';
ClientMessagesTable.requestParameter = 'id';
const ClientMessagesTablePage = () => <BackofficePagesManager PageViewer={ClientMessagesTable} />;
export default ClientMessagesTablePage;
