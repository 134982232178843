import { createSlice, current } from '@reduxjs/toolkit';

export const initialState = {
  items: [],
  countItems: 0,
  subtotal: 0.0,
  iva: 0.0,
  total: 0.0,
  shippingCosts: 0.0,
  lastAddedItem: null,
  lastRemovedItem: null,
  storePickup: false,
  isGiftOrder: false,
  giftData: {},
  giftCard: {},
  appliedCoupon: null,
  hasGiftCard: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, action) => {
      const product = action.payload;
      const itemExists = state.items?.find((item) => product?.id === item?.id);
      if (itemExists) {
        itemExists.quantity += 1;
      } else {
        const newProduct = { ...product, ...{ quantity: 1 } };
        state.items.push(newProduct);
      }
      const iva_porc = 4;
      const pvp = parseFloat(product?.precio_venta_publico);
      const pc = ((pvp * 100) / (100 + iva_porc)).toFixed(2);
      const subtotal = parseFloat(state.subtotal) + parseFloat(pc);
      const iva = parseFloat(state.iva) + parseFloat(pvp) - parseFloat(pc);
      const total = parseFloat(state.total) + pvp;
      const removeShippingCostGiftCard = Math.abs(total - parseFloat(state.giftCard?.discount));
      state.countItems += 1;
      state.shippingCosts =
        removeShippingCostGiftCard.toFixed(2) >= 50 && product.quantity == 1 && Object.keys(state.giftCard).length
          ? 0.0
          : 3.2;

      state.iva = iva.toFixed(2);
      state.subtotal = subtotal.toFixed(2);
      state.total = total.toFixed(2);
      state.lastAddedItem = action.payload?.titulo;
      state.isIncrease = true;
    },
    removeItem: (state, action) => {
      const product = action.payload;
      state.items = state.items.filter((item) => product?.id !== item?.id);
      const iva_porc = 4;
      const pvp = parseFloat(product.precio_venta_publico);
      const pc = ((pvp * 100) / (100 + iva_porc)).toFixed(2);
      const subtotal = parseFloat(state.subtotal) - parseFloat(pc) * product.quantity;
      const iva = parseFloat(state.iva) - (parseFloat(pvp) - parseFloat(pc)) * product.quantity;
      const total = parseFloat(state.total) - pvp * parseFloat(product.quantity);
      const removeShippingCostGiftCard = Math.abs(total - parseFloat(state.giftCard?.discount));
      state.shippingCosts =
        (removeShippingCostGiftCard.toFixed(2) >= 50 && product.quantity >= 1) ||
        Object.keys(state.giftCard).length >= 1
          ? 0.0
          : 3.2;
      state.isIncrease = false;
      state.countItems -= product.quantity;
      state.iva = iva.toFixed(2);
      state.subtotal = subtotal.toFixed(2);
      state.total = total.toFixed(2);
      state.lastRemovedItem = action.payload.titulo;
    },
    increaseQuantity: (state, action) => {
      const id = action.payload;
      const itemExists = state.items.find((item) => id === item.id);
      const iva_porc = itemExists.type === 'libro' ? 4 : 21;
      const pvp = parseFloat(itemExists.precio_venta_publico);
      const pc = ((pvp * 100) / (100 + iva_porc)).toFixed(2);
      const subtotal = parseFloat(state.subtotal) + parseFloat(pc);
      const iva = parseFloat(state.iva) + parseFloat(pvp) - parseFloat(pc);
      const total = parseFloat(state.total) + pvp;
      const removeShippingCostGiftCard = state.giftCard?.discount ? total - parseFloat(state.giftCard?.discount) : total;
      itemExists.quantity += 1;
      state.shippingCosts = removeShippingCostGiftCard.toFixed(2) >= 50 && itemExists.quantity > 1 ? 0.0 : 3.2;
      state.countItems += 1;
      state.iva = iva.toFixed(2);
      state.subtotal = subtotal.toFixed(2);
      state.total = total.toFixed(2);
      state.isIncrease = true;
      state.lastAddedItem = itemExists.titulo;
    },

    decreaseQuantity: (state, action) => {
      const id = action.payload;
      const itemExists = state.items.find((item) => id === item.id);
      if (itemExists.quantity === 1) {
        state.items = state.items.filter((item) => id !== item.id);
      } else {
        itemExists.quantity -= 1;
      }
      const iva_porc = itemExists.type === 'libro' ? 4 : 21;
      const pvp = parseFloat(itemExists.precio_venta_publico);
      const pc = ((pvp * 100) / (100 + iva_porc)).toFixed(2);
      const subtotal = parseFloat(state.subtotal) - parseFloat(pc);
      const iva = parseFloat(state.iva) - (parseFloat(pvp) - parseFloat(pc));
      const total = parseFloat(state.total) - pvp;
      const removeShippingCostGiftCard = state.giftCard?.discount ? total - parseFloat(state.giftCard?.discount) : total;

      state.countItems -= 1;

      state.shippingCosts =
        removeShippingCostGiftCard.toFixed(2) >= 50 || state.items.length === 0
          ? 0.0
          : 3.2;
      state.iva = iva.toFixed(2);
      state.subtotal = subtotal.toFixed(2);
      state.total = total.toFixed(2);
      state.isIncrease = false;
      state.lastRemovedItem = itemExists.titulo;
    },
    clear: (state, action) => {
      state.items = initialState.items;
      state.countItems = initialState.countItems;
      state.subtotal = initialState.subtotal;
      state.iva = initialState.iva;
      state.total = initialState.total;
      state.shippingCosts = initialState.shippingCosts;
      state.shippingAddress = null;
      state.isNewShippingAddress = false;
      state.storePickup = false;
      state.isGiftOrder = false;
      state.giftData = {};
      state.giftCard = {};
      state.appliedCoupon = null;
      state.hasGiftCard = initialState.hasGiftCard;
    },
    shippingFree: (state, action) => {
      state.shippingCosts = state.total >= 50 || action.payload ? 0.0 : 3.2;
    },
    setStorePickup: (state, action) => {
      state.storePickup = action.payload;
    },
    setIsGiftOrder: (state, action) => {
      state.isGiftOrder = action.payload;
    },
    setGiftData: (state, action) => {
      state.giftData = action.payload;
    },
    setAppliedCoupon: (state, action) => {
      state.appliedCoupon = action.payload;
    },
    setGiftCard: (state, action) => {
      state.giftCard = action.payload;

      const ivaPorc = 0.04;
      const giftCardDiscount = parseFloat(state.giftCard?.discount);
      const giftCardIva = giftCardDiscount * ivaPorc;
      const giftCardSubtotal = giftCardDiscount - giftCardIva;
      const giftCardTotal = giftCardSubtotal + giftCardIva;

      state.countItems += 1;
      state.iva = (parseFloat(state.iva) + giftCardIva).toFixed(2);
      state.subtotal = (parseFloat(state.subtotal) + giftCardSubtotal).toFixed(2);
      state.total = (parseFloat(state.total) + giftCardTotal).toFixed(2);
      const removeShippingCostGiftCard = Math.abs(parseFloat(state.total) - giftCardTotal).toFixed(2);
      state.lastAddedItem = 'Tarjeta de regalo';
      state.isIncrease = true;
      state.hasGiftCard = true;
      state.shippingCosts =
        (removeShippingCostGiftCard >= 50 && state.items.length === 0) ||
        (Object.keys(state.giftCard).length >= 1 && state.items.length === 0)
          ? 0.0
          : 3.2;
    },
    removeGiftCard: (state) => {
      state.lastRemovedItem = 'Tarjeta de regalo';
      const ivaPorc = 0.04;

      const giftCardDiscount = parseFloat(state.giftCard.discount);
      const giftCardIva = giftCardDiscount * ivaPorc;
      const giftCardSubtotal = giftCardDiscount - giftCardIva;
      const giftCardTotal = giftCardSubtotal + giftCardIva;

      state.iva = (parseFloat(state.iva) - giftCardIva).toFixed(2);
      state.subtotal = (parseFloat(state.subtotal) - giftCardSubtotal).toFixed(2);
      state.total = (parseFloat(state.total) - giftCardTotal).toFixed(2);
      state.countItems -= 1;
      state.isIncrease = true;
      state.hasGiftCard = false;
      state.isIncrease = false;
      state.giftCard = initialState.giftCard;
    },
  },
});

export const {
  addItem,
  removeItem,
  increaseQuantity,
  decreaseQuantity,
  clear,
  shippingFree,
  setStorePickup,
  setIsGiftOrder,
  setGiftData,
  setAppliedCoupon,
  setGiftCard,
  removeGiftCard,
  clearGiftCard,
} = cartSlice.actions;
export const cartSelector = (state) => state.cart;
export default cartSlice.reducer;
