import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import delay from 'delay';

//redux
import { useSelector } from 'react-redux';
import { companySelector } from '../../slices/company';

//styles
import '../../assets/css/staticPages.scss';

//own components
import Head from '../../components/metatags_helmet/metatags';
import { postMessage } from '../../api/libelista';

const Contact = () => {
  const [names, setNames] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [department, setDepartment] = useState('');
  const [message, setMessage] = useState('');
  const [recieveNews, setRecieveNews] = useState(false);
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [requestStatus, setRequestStatus] = useState(undefined);
  const title = 'Contacto';

  const { descripcion_pub } = useSelector(companySelector);

  const goUp = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    goUp();
  }, []);

  const sendMessage = async () => {
    setRequestStatus('loading');
    const payload = {
      full_name: names,
      email: email,
      phone_number: phone,
      message: message,
      company: company,
      department: department,
      rcv_news_offers_acceptance: recieveNews,
      policies_conditions_acceptance: acceptPolicy,
    };
    try {
      const rs = await postMessage(payload);
      if (rs.status === 201) {
        setRequestStatus('success');
      } else {
        setRequestStatus('error');
      }
    } catch (error) {
      setRequestStatus('error');
    }
    await delay(4000);
    setRequestStatus(undefined);
  };
  let buttonText = 'ENVIAR';
  let buttonVariant = 'dark';
  switch (requestStatus) {
    case 'error':
      buttonText = 'Error';
      buttonVariant = 'danger';
      break;
    case 'success':
      buttonText = '¡Enviado!';
      buttonVariant = 'success';
      break;
    case 'loading':
      buttonText = 'Enviando...';
      buttonVariant = 'warning';
      break;
    default:
  }
  return (
    <div className="content">
      <div className="container-fluid contact">
        <Head description={descripcion_pub} title={title} noIndex={true} />
        <Row>
          <Col lg={8} xl={9}>
            <h1 className="page-title static-title">Contacto</h1>
            <p>
              Puedes rellenar este formulario con tu consulta y nuestro equipo de atención al cliente te responderá en
              breve.
            </p>
            <Form>
              <Form.Group controlId="formEmail">
                <Form.Label>Nombre y Apellido *</Form.Label>
                <Form.Control onChange={(e) => setNames(e.target.value)} type="text" required />
              </Form.Group>

              <Form.Group controlId="formEnterprise">
                <Form.Label>Empresa</Form.Label>
                <Form.Control onChange={(e) => setCompany(e.target.value)} type="text" />
              </Form.Group>

              <Form.Group controlId="formMail">
                <Form.Label>Correo electrónico *</Form.Label>
                <Form.Control onChange={(e) => setEmail(e.target.value)} type="email" required />
              </Form.Group>

              <Form.Group controlId="formPhone">
                <Form.Label>Teléfono *</Form.Label>
                <Form.Control onChange={(e) => setPhone(e.target.value)} type="phone" required />
              </Form.Group>

              <Form.Group controlId="FormDepartment">
                <Form.Label>Departamento *</Form.Label>
                <Form.Control onChange={(e) => setDepartment(e.target.value)} as="select" required>
                  <option value="info">Información, ayuda y atención al cliente</option>
                  <option value="orders">Pedidos y ebooks</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="FormMessage">
                <Form.Label>Mensaje *</Form.Label>
                <Form.Control onChange={(e) => setMessage(e.target.value)} as="textarea" rows={5} required />
              </Form.Group>

              <Form.Group controlId="formMandatoryText">
                <Form.Text className="text-muted">* Campos obligatorios</Form.Text>
              </Form.Group>

              <Form.Group controlId="formOffersText">
                <Form.Check
                  type="checkbox"
                  label="Quiero recibir novedades y ofertas exclusivas."
                  onChange={() => setRecieveNews(!recieveNews)}
                />
              </Form.Group>

              <Form.Group controlId="formLegalText">
                <Form.Check
                  type="checkbox"
                  label="He leído, comprendo y acepto el Aviso legal y la Política de privacidad y las Condiciones de uso. "
                  required
                  onChange={() => setAcceptPolicy(!acceptPolicy)}
                />
              </Form.Group>

              <Button
                disabled={requestStatus}
                variant={buttonVariant}
                onClick={sendMessage}
                className="mx-auto d-block w-25"
              >
                {buttonText}
              </Button>
            </Form>
            <br></br>
            <p className="legal-info">
              Le informamos que los datos personales que contiene este formulario, están incorporados en un fichero bajo
              nuestra responsabilidad, con la finalidad de prestarle el servicio solicitado. Los datos se conservarán
              mientras se mantenga la relación comercial o durante los años necesarios para cumplir con las obligaciones
              legales. Los datos no se cederán a terceros salvo en los casos en que exista una obligación legal. Usted
              tiene derecho a acceder a sus datos personales, rectificar los datos inexactos o solicitar su supresión
              cuando los datos ya no sean necesarios (Reglamento (UE) 2016/679).
            </p>
          </Col>

          <Col lg={4} xl={3} className="side">
            <Row>
              <Col md={12} className="side-square1">
                <h2 className="page-title static-title mt-3">Datos Útiles</h2>
                <p>Si lo prefieres, puedes ponerte en contacto con nuestros departamentos específicos.</p>
                <p className="contact-info">
                  Información, ayuda y atención al cliente: <a href="mailto: info@libelista.com"> info@libelista.com</a>
                </p>
                <p className="contact-info">
                  Teléfonos de atención al cliente: <br></br>
                  <Link to="#">0034 663293839</Link> /<Link to="#">0034 935152971</Link> <br></br>
                  <span style={{ color: 'red' }}>¿(de lunes a viernes de 10 a 18 horas)? ¿Horario?</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="side-square2">
                <p className="contact-info">
                  Si necesitas contactar con alguna de nuestras librerías puedes consultar sus teléfonos{' '}
                  <Link to="/librerias">aquí.</Link>
                </p>
              </Col>
            </Row>
            <Row>
              <h3>Formulario de librería más cercana</h3>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Contact;
