import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BiCalendarCheck } from 'react-icons/bi';
import { MdPlace } from 'react-icons/md';
import { FcCancel } from 'react-icons/fc';

import './schedule.scss';
import { getActivities } from '../../api/libelista';
import Head from '../../components/metatags_helmet/metatags';
import { InputCustom } from '../../components/input';
import Presentation from '../../assets/img/ico_presentation.png';
import Signature from '../../assets/img/ico_signature.png';
import Activities from '../../assets/img/ico_activities.png';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { differenceInCalendarDays } from 'date-fns';

const generateIsDateInRangeFilterFunction = (fixedDateValue) => {
  return (event) => {
    const rangeBeginning = new Date(event.start_date);
    const rangeEnd = new Date(event.end_date);
    const date = new Date(fixedDateValue);
    const maxRange = differenceInCalendarDays(rangeEnd, rangeBeginning);
    const isWithinRange =
      0 <= differenceInCalendarDays(rangeEnd, date) &&
      differenceInCalendarDays(rangeEnd, date) <= maxRange &&
      0 <= differenceInCalendarDays(date, rangeBeginning) &&
      differenceInCalendarDays(date, rangeBeginning) <= maxRange;
    return isWithinRange;
  };
};
const Schedule = () => {
  const [activities, setActivities] = useState([]);
  const [address, setAddress] = useState('');
  const [bigPost, setBigPost] = useState([]);
  const [smallPosts, setSmallPosts] = useState([]);
  const [slug, setSlug] = useState('');
  const [noItems, setNoItems] = useState(false);
  const [value, onChange] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(undefined);

  const title = 'Actividades';
  const description = '¡Encuentra todas las actividades cerca de ti!';

  async function getScheduleItems() {
    try {
      const { status, data } = slug !== '' ? await getActivities(slug) : await getActivities();
      if (status == 200) {
        data.results.map(e => {
          e.start_date = e.start_date.split('T')[0];
          e.end_date = e.end_date.split('T')[0];
        })
        setActivities(data);
      }
    } catch (exception) {
      console.log(exception);
    }
  }

  useEffect(() => {
    if (activities.results) {
      let events = activities.results;
      if (selectedDate) {
        events = events.filter(generateIsDateInRangeFilterFunction(selectedDate));
      }
      setBigPost(events.slice(0, 1));
      setSmallPosts(events.slice(1));
      if (activities.count !== 0) {
        setNoItems(false);
      } else {
        setNoItems(true);
      }
    }
  }, [activities, selectedDate]);

  const goUp = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getScheduleItems();
    goUp();
  }, []);

  useEffect(() => {
    getScheduleItems();
  }, [slug]);

  return (
    <Container fluid className="justify-content-center mainDiv">
      <Head description={description} title={title} noIndex={true} />
      <Row className="schedule-row justify-content-center">
        <Col md={0} lg={3} className="filter-col">
          <h1 className="spectral mt-4">Agenda</h1>
          <hr />
          <div>
            <InputCustom
              type="text"
              placeholder="Evento, localidad..."
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <div
              className="d-flex mt-4"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSlug((prevState) => {
                  prevState = 'firma-de-libros';
                  return prevState;
                });
              }}
            >
              <img className="mr-2" src={Signature} alt="" />
              <p className="mb-1">Firma de libros</p>
            </div>
            <div
              className="d-flex mt-4"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSlug((prevState) => {
                  prevState = 'presentacion';
                  return prevState;
                });
              }}
            >
              <img className="mr-2" src={Presentation} alt="" />
              <p className="mb-1">Presentación</p>
            </div>
            <div
              className="d-flex mt-4"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSlug((prevState) => {
                  prevState = 'actividades-infantiles';
                  return prevState;
                });
              }}
            >
              <img className="mr-3" src={Activities} alt="" />
              <p className="mb-1">Actividades infantiles</p>
            </div>
            <div className="d-flex w-100 mt-4">
              <Calendar
                className="border-0"
                tileClassName={({ date, value }) => {
                  const tileDate = new Date(date);
                  const currentDate = new Date();
                  const isToday = !differenceInCalendarDays(tileDate, currentDate);
                  const isSelectedDate = selectedDate && !differenceInCalendarDays(tileDate, selectedDate);
                  const disabled = !activities?.results?.find(generateIsDateInRangeFilterFunction(date));
                  if (isToday) {
                    return 'bg-info text-white';
                  } else if (isSelectedDate) {
                    return 'bg-warning text-white';
                  } else if (disabled) {
                    return 'bg-white text-muted';
                  } else {
                    return 'text-dark border border-warning rounded';
                  }
                }}
                showNeighboringMonth={false}
                tileDisabled={({ date }) => {
                  return !activities?.results?.find(generateIsDateInRangeFilterFunction(date));
                }}
                onChange={(value, event) => {
                  setSelectedDate(new Date(value));
                }}
                value={value}
              />
            </div>
          </div>
        </Col>
        <Col md={12} lg={9} className="activitiesCol mt-4 pl-lg-0">
          {!noItems ? (
            <>
              {bigPost[0] && (
                <Row style={{ background: '#F6F6F6' }} className="mx-md-2 mx-lg-0 px-0">
                  <Col md={6} lg={6}>
                    <h6 className="orange text-uppercase mt-4">{bigPost[0]?.display_type_schedule[0]?.name}</h6>
                    <Link to={`/agenda/${bigPost[0].id}`} className="text-decoration-none" style={{ color: '#231f20' }}>
                      <h3 className="mt-4 mb-3 spectralReg text-uppercase">{bigPost[0]?.title}</h3>
                    </Link>
                    <div className="d-flex mt-5">
                      <BiCalendarCheck style={{ color: '#f2a20d' }} />
                      <span className="ml-2 font-weight-bold">{bigPost[0]?.start_date} &nbsp;</span> AL
                      <span className="font-weight-bold">&nbsp;{bigPost[0]?.end_date}</span>
                    </div>
                    <div className="d-flex">
                      <MdPlace style={{ color: '#f2a20d' }} />
                      <p className="ml-2 text-uppercase font-weight-bold">{bigPost[0]?.place}</p>
                    </div>
                    <p className="mt-3 mb-4 text--long"> {bigPost[0].body}</p>
                    <Link style={{ color: '#4b95a6' }} to={`/agenda/${bigPost[0]?.id}`}>
                      Ver mas...
                    </Link>
                  </Col>
                  <Col md={6} lg={6}>
                    <Link to={`/agenda/${bigPost[0]?.id}`}>
                      <img src={`${bigPost[0]?.image}`} alt="placeholder" style={{ width: 'inherit' }} />
                    </Link>
                  </Col>
                </Row>
              )}
              <Row className="mt-4 mb-4">
                {smallPosts[0] &&
                  smallPosts.map((el, val) => {
                    return (
                      <Col lg={4} md={12} key={val} className="px-0 pr-sm-1 pr-md-5 mx-3 mt-4">
                        <div style={{ background: '#F6F6F6' }}>
                          <Row>
                            <Col className="p-0">
                              <Link to={`/agenda/${el.id}`}>
                                <img src={`${el.image}`} alt="placeholder" className="cardImg" />
                              </Link>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="descriptionCard pl-5 pr-2">
                              <p className="orange text-uppercase mt-3 mb-1">{el.display_type_schedule[0]?.name}</p>
                              <Link
                                to={`/agenda/${el.id}`}
                                className="text-decoration-none"
                                style={{ color: '#231f20' }}
                              >
                                <h5 className="">{el.title}</h5>
                              </Link>
                              <div className="d-flex mt-2">
                                <BiCalendarCheck style={{ color: '#f2a20d' }} />
                                <span className="ml-1 font-weight-bold">{el.start_date} AL</span>
                                <span className="font-weight-bold">&nbsp;{el.end_date}</span>
                              </div>
                              <div className="d-flex mt-1">
                                <MdPlace style={{ color: '#f2a20d' }} />
                                <span className="ml-1 text-uppercase font-weight-bold">{el.place}</span>
                              </div>
                              <p className="mt-2 mb-3 text"> {el.body}</p>
                              <Link style={{ color: '#4b95a6' }} to={`/agenda/${el.id}`}>
                                Ver mas...
                              </Link>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </>
          ) : (
            <Row className="justify-content-center noItems mt-5">
              <Col lg={8}>
                <h1>No hay eventos disponibles para esta categoría</h1>
                <br />
                <FcCancel />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Schedule;
