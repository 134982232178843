import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import {
  getPost as get,
  editPost as edit,
  createPost as create,
  getPostsCategories,
  getAssociatedList,
} from '../../../../api/libelista';
import ActionsForm from '../../../../components/backoffice/actionsForm/index';
import { getSelectDataHandlers } from '../../../../utils/backoffice/getSelectDataHandlers';
import SectionLoader from '../../../../components/loader/SectionLoader';
import * as yup from 'yup';
const statusOptions = [
  { label: 'Draft', value: 'draft' },
  { label: 'Published', value: 'published' },
  { label: 'Hidden', value: 'hidden' },
];
const BlogPostItem = ({ data, loadData }) => {
  const { action } = useParams();
  const [
    { defaultOptions: defaultCategoriesOptions, defaultOption: defaultCategoriesOption },
    setCategoriesDefaultOptionValues,
  ] = useState({});
  const { getSelectData: getCategoriesOptions, setSelectDefaultData: setCategoriesOptions } = getSelectDataHandlers(
    undefined,
    getPostsCategories,
    setCategoriesDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'name',
      fieldNameWithRequestValue: 'id',
    }
  );
  const [
    { defaultOptions: defaultAssociatedListOptions, defaultOption: defaultAssociatedListOption },
    setAssociatedListDefaultOptionValues,
  ] = useState({});
  const { getSelectData: getAssociatedListOptions, setSelectDefaultData: setAssociatedListOptions } =
    getSelectDataHandlers(undefined, getAssociatedList, setAssociatedListDefaultOptionValues, {
      fieldNameWithDisplayValue: 'title',
      fieldNameWithRequestValue: 'id',
    });

  useEffect(() => {
    setCategoriesOptions();
    setAssociatedListOptions();
  }, []);
  const mustWaitForData = ['detail', 'edit'].includes(action);
  const renderConditions = [
    !mustWaitForData || data,
    defaultCategoriesOptions?.length || defaultCategoriesOption || data,
    defaultAssociatedListOptions?.length || defaultAssociatedListOption,
  ];
  const inputFields = [
    {
      label: 'Título',
      name: 'title',
      type: 'text',
      required: true,
    },
    {
      label: 'Slug',
      name: 'slug',
      type: 'text',
      required: true,
    },
    {
      label: 'Párrafo destacado',
      name: 'featured_paragraph',
      type: 'text',
    },
    {
      label: 'Enlace a la Imagen de Portada',
      name: 'featured_image',
      type: 'text',
    },
    {
      label: 'Texto de Portada',
      name: 'body',
      type: 'textarea',
    },
    {
      label: 'Estado de la Publicación',
      name: 'status',
      type: 'select',
      defaultValue: statusOptions.find(({ value }) => value === data?.status) || statusOptions[0],
      options: statusOptions,
      isDisabled: action === 'detail',
      required: true,
    },
    {
      label: 'Tipo de publicación',
      name: 'type_post',
      type: 'select',
      defaultValue: defaultCategoriesOptions?.find((option) => option.value == data?.type_post),
      options: defaultCategoriesOptions,
      isDisabled: action === 'detail',
      required: true,
    },
    {
      label: 'Listas asociadas',
      name: 'display_related_book_list',
      type: 'select',
      defaultValue: defaultAssociatedListOptions?.find((option) => {
        return option.value == data?.display_related_book_list;
      }),
      options: defaultAssociatedListOptions,
      isDisabled: action === 'detail',
      required: true,
    },
  ];
  const schema = {
    type_post: yup.string().required('* '),
    title: yup.string().required('* '),
    slug: yup.string().required('* '),
  };
  const actionsFormProps = {
    inputFields,
    title: 'publicación de blog',
    subsectionPath: 'blog-posts',
    data,
    requests: { edit, create },
    formDataConfiguration: {},
    action,
    schema,
    loadData,
  };
  return renderConditions.every((condition) => condition) ? <ActionsForm {...actionsFormProps} /> : <SectionLoader />;
};
BlogPostItem.request = {
  get,
};
const BlogPostItemPage = () => <BackofficePagesManager PageViewer={BlogPostItem} />;
export default BlogPostItemPage;
