import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Tabs, Tab } from 'react-bootstrap';
import './register.scss';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { loginSuccess, setBenefitedBookstore, setAsociatedLibraryImage } from '../../slices/user';
import { cartSelector } from '../../slices/cart';
import { postRegisterUser } from '../../api/libelista';
import { addItemCart } from '../../api/ecommerce';
import registerIcon1 from '../../assets/img/ico_registration_01.png';
import registerIcon2 from '../../assets/img/ico_registration_02.png';
import registerIcon3 from '../../assets/img/ico_registration_03.png';
import SearchSelect from '../../components/searchSelect';

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [surname, setSurname] = useState('');
  const [library, setLibrary] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [receiveInvoices, setReceiveInvoices] = useState(false);
  const [acceptConditions, setAcceptConditions] = useState(false);
  const [receiveNewsOffers, setReceiveNewsOffers] = useState(false);
  const [feedback, setFeedback] = useState('')

  const history = useHistory();
  const dispatch = useDispatch();
  const { items } = useSelector(cartSelector);

  useEffect(() => {
    // initial load
    goUp();
  }, []);

  const goUp = () => {
    window.scrollTo(0, 0);
  };

  const selectedLibrary = (libraryId) => {
    setLibrary(libraryId.id);
  };

  const registerUser = async (e) => {
    e.preventDefault()
    setFeedback('')
    const payload = {
      name: name,
      email: email,
      surname: surname,
      password: password,
      password2: password2,
      receive_invoices: receiveInvoices,
      receive_news_offers: receiveNewsOffers,
      accept_conditions: acceptConditions,
    };
    if (library !== '') {
      payload.bookstore_id = library;
    }
    try {
      const { status, data } = await postRegisterUser(payload);
      if (status === 201) {
        localStorage.setItem('access', data.access);
        localStorage.setItem('refresh', data.refresh);
        dispatch(loginSuccess());
        dispatch(setAsociatedLibraryImage(data.logo_empresa));
        dispatch(setBenefitedBookstore(data.benefited_bookstore));
        for (const item of items) {
          await addItemCart({ item: item.id, quantity: item.quantity });
        }
        history.push('/');
      } else {
        const errfeedback = Object.keys(data)
          .map((val, i) => {
            return `${val}: ${data[val]} \n`
          })
          .join('')
        setFeedback(errfeedback)
      }
    } catch (error) {
      setFeedback("Error de conexión, intente mas tarde nuevamente.")
    }
  };

  return (
    <div className="container-fluid mainDiv">
      <Row className="justify-content-center mainRow register">
        <Col className="" lg={8}>
          <Form onSubmit={registerUser}>
          <div className="row">
            <div className="col-md-12">
              <h1 className="infoTitle">Regístrate gratis y conecta con tu librería</h1>
              <hr />
            </div>
          </div>
          <p>Los campos marcados con * son obligatorios.</p>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label>Nombre *</Form.Label>
              <Form.Control type="text" required onChange={(e) => setName(e.target.value)} value={name} />
            </Form.Group>
          </Form>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label>Apellidos *</Form.Label>
              <Form.Control type="text" required onChange={(e) => setSurname(e.target.value)} value={surname} />
            </Form.Group>
          </Form>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label>Correo electrónico *</Form.Label>
              <Form.Control type="text" required onChange={(e) => setEmail(e.target.value)} value={email} />
            </Form.Group>
          </Form>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label>Contraseña *</Form.Label>
              <Form.Control type="password" required onChange={(e) => setPassword(e.target.value)} value={password} />
            </Form.Group>
          </Form>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label>Repetir contraseña *</Form.Label>
              <Form.Control type="password" required onChange={(e) => setPassword2(e.target.value)} value={password2} />
            </Form.Group>
          </Form>
          <div className="mb-4 d-flex checkDiv">
            <Form.Check
              type={'checkbox'}
              className="ml-3"
              onChange={(e) => setReceiveInvoices(!receiveInvoices)}
              checked={receiveInvoices}
            />
            <p>Quiero recibir factura de mis pedidos</p>
          </div>
          <Tabs className="mt-3 mb-5" fill defaultActiveKey="first">
            <Tab eventKey="first" title="Seleccionar mi librería beneficiada">
              <SearchSelect className="mb-5" callback={selectedLibrary} />
            </Tab>
            <Tab eventKey="second" title="Prefiero elegir una al final de cada compra">
              <p className="mt-4 mb-5">
                Al finalizar el proceso de compra podrás elegir tu librería beneficiada (podrás elegir una distinta en
                cada pedido que hagas).
              </p>
            </Tab>
          </Tabs>
          <div className="mt-4 d-flex checkDiv">
            <Form.Check
              type={'checkbox'}
              className="ml-3"
              onChange={(e) => setReceiveNewsOffers(!receiveNewsOffers)}
              checked={receiveNewsOffers}
            />
            <p>Quiero recibir novedades y ofertas exclusivas</p>
          </div>
          <div className="mt-1 d-flex checkDiv">
            <Form.Check
              type={'checkbox'}
              className="ml-3"
              onChange={(e) => setAcceptConditions(!acceptConditions)}
              checked={acceptConditions}
            />
            <p>
              He leído, comprendo y acepto el
              <Link to={'/aviso-legal-y-politica-de-privacidad'}> Aviso legal y política de privacidad</Link> y las
              <Link to={'/condiciones-de-uso'}> Condiciones de uso.</Link>
            </p>
          </div>
          {feedback !== '' && (<span className="feedback" style={{whiteSpace: 'pre-line'}}>{feedback}</span>)}
          <Button className="mt-3 mb-5" type='submit' disabled={!acceptConditions}>
            REGISTRARME
          </Button>
          </Form>
        </Col>
        <Col className="border asideCol" lg={3}>
          <Row>
            <Col className="mt-4" lg={12}>
              <img src={registerIcon1} alt="icon.png" />
              <p className="mt-2">
                Las librerías cumplen en su entorno un papel insustituible: crean comunidad y mantienen vivo el tejido
                cultural
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="mt-4" lg={12}>
              <img src={registerIcon2} alt="icon.png" />
              <p className="mt-2">
                Libelista es la apuesta por el comercio local: una tienda online de libros e eBooks vendidos por
                librerías independientes y un portal para descubrirlas y conocerlas.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="mt-4 mb-5" lg={12}>
              <img src={registerIcon3} alt="icon.png" />
              <p className="mt-2">
                Las librerías se benefician de cada venta: te pediremos que elijas una librería de nuestra red y esta
                recibirá un porcentaje del dinero de tu compra.
              </p>
            </Col>
          </Row>
        </Col>
        <Col className="textInfo" lg={12}>
          <p>
            Le informamos que los datos personales que contiene este formulario, están incorporados en un fichero bajo
            nuestra responsabilidad, con la finalidad de prestarle el servicio solicitado. Los datos se conservarán
            mientras se mantenga la relación comercial o durante los años necesarios para cumplir con las obligaciones
            legales. Los datos no se cederán a terceros salvo en los casos en que exista una obligación legal. Usted
            tiene derecho a acceder a sus datos personales, rectificar los datos inexactos o solicitar su supresión
            cuando los datos ya no sean necesarios (Reglamento (UE) 2016/679).
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
