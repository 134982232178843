import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import ActionsForm from '../../../../components/backoffice/actionsForm/index';
import { getSelectDataHandlers } from '../../../../utils/backoffice/getSelectDataHandlers';
import { getUserById as get, editUser as edit } from '../../../../api/users';
import SectionLoader from 'components/loader/SectionLoader';
import { getListCompany } from 'api/company';

const UserItem = ({ data, loadData }) => {
  const { action } = useParams();

  const {
    id,
    email,
    username,
    first_name,
    last_name,
    bookstore,
    is_staff,
    is_active,
    shipping_address,
    billing_address,
    ...restData
  } = data || {};

  const [
    { defaultOptions: defaultCompanyOptions, defaultOption: defaultCompanyOption },

    setCompanyDefaultOptionValues,
  ] = useState({});
  const { getSelectData: getCompanyOptions, setSelectDefaultData: setCompanyOptions } = getSelectDataHandlers(
    undefined,
    getListCompany,
    setCompanyDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'name',
      fieldNameWithRequestValue: 'id',
    }
  );

  useEffect(() => {
    setCompanyOptions();
  }, []);

  useEffect(() => {
    if (!mustWaitForData) {
      !defaultCompanyOptions && setCompanyOptions(data?.name);
    }
  }, [data]);

  const mustWaitForData = ['detail', 'edit'].includes(action);
  const renderConditions = [!mustWaitForData || data, defaultCompanyOptions?.length];

  const inputFields = [
    {
      label: 'Correo Electrónico',
      name: 'email',
      type: 'email',
    },
    {
      label: 'Nombre',
      name: 'first_name',
      type: 'text',
    },
    {
      label: 'Apellido',
      name: 'last_name',
      type: 'text',
    },
    {
      label: 'Libreria beneficiada',
      name: 'bookstore',
      type: 'select',
      defaultValue: defaultCompanyOptions?.filter((option) => option.value == parseInt(data?.bookstore)),
      options: defaultCompanyOptions,
    },

    { label: 'Staff', name: 'is_staff', type: 'checkbox' },
    { label: 'Activo', name: 'is_active', type: 'checkbox' },
    {
      label: 'Dirección de envío',
      name: 'shipping_address',
      type: 'textarea',
    },
    {
      label: 'Dirección de facturación',
      name: 'billing_address',
      type: 'textarea',
    },
  ];

  const shipping_address1 = JSON.parse(JSON.stringify(shipping_address));
  const billing_address1 = JSON.parse(JSON.stringify(billing_address));

  const actionsFormProps = {
    inputFields,
    title: 'Usuario',
    subsectionPath: 'users',
    data: {
      id,
      email,
      username: email,
      first_name,
      last_name,
      bookstore,
      is_staff,
      is_active,
      shipping_address: JSON.stringify(shipping_address),
      billing_address: JSON.stringify(billing_address),
      billing_address1,
      shipping_address1,
    },
    requests: { edit },
    extraFormat: { publish_date: 'date' },
    formDataConfiguration: {},
    action,
    loadData,
  };

  return renderConditions.every((condition) => condition) ? (
    <>
      <ActionsForm {...actionsFormProps} />{' '}
    </>
  ) : (
    <SectionLoader />
  );
};

UserItem.request = {
  get,
};
const UserItemPage = () => <BackofficePagesManager PageViewer={UserItem} />;
export default UserItemPage;
