import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import {
  getAwardedBook as get,
  getBookDetail,
  editAwardedBook as edit,
  createAwardedBook as create,
  getBooksByTitle,
} from '../../../../api/libros';
import ActionsForm from '../../../../components/backoffice/actionsForm/index';
import { getSelectDataHandlers } from '../../../../utils/backoffice/getSelectDataHandlers';
import SectionLoader from '../../../../components/loader/SectionLoader';
import * as yup from 'yup';
const AwardWinningBooksItem = ({ data, loadData }) => {
  const { action } = useParams();
  const [
    { defaultOptions: defaultAwardedBooksOptions, defaultOption: defaultAwardedBooksOption },
    setAwardedBooksDefaultOptionValues,
  ] = useState({});
  const { getSelectData: getAwardedBooksOptions, setSelectDefaultData: setAwardedBooksOptions } = getSelectDataHandlers(
    getBookDetail,
    getBooksByTitle,
    setAwardedBooksDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'titulo',
      fieldNameWithRequestValue: 'id',
    }
  );
  const mustWaitForData = ['detail', 'edit'].includes(action);
  const renderConditions = [
    !mustWaitForData || (defaultAwardedBooksOptions?.length && defaultAwardedBooksOption?.value),
  ];
  useEffect(() => {
    const awardWinningBookId = data?.libro;
    setAwardedBooksOptions(awardWinningBookId);
  }, [data]);
  const inputFields = [
    {
      label: 'Premio',
      name: 'prize',
      type: 'text',
      required: true,
    },
    {
      label: 'Año de premiación',
      name: 'year',
      type: 'text',
      required: true,
    },
    {
      label: 'Libro',
      name: 'libro',
      type: 'select',
      isAsync: true,
      defaultValue: defaultAwardedBooksOption,
      loadOptions: getAwardedBooksOptions,
      defaultOptions: true,
      isDisabled: action === 'detail',
      required: true,
    },
  ];
  const schema = {
    year: yup.string().required('* '),
    prize: yup.string().required('* '),
    libro: yup.string().required('* '),
  };
  const actionsFormProps = {
    inputFields,
    title: 'Libros Premiados',
    subsectionPath: 'award-winning-books',
    data,
    requests: { edit, create },
    formDataConfiguration: {},
    action,
    schema,
    loadData,
  };
  return renderConditions.every((condition) => condition) ? <ActionsForm {...actionsFormProps} /> : <SectionLoader />;
};
AwardWinningBooksItem.request = {
  get,
};
const AwardWinningBooksItemPage = () => <BackofficePagesManager PageViewer={AwardWinningBooksItem} />;
export default AwardWinningBooksItemPage;
