import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
// import { AiOutlineUser } from "react-icons/ai";
import {
  FaUserAlt,
  FaBookReader,
  FaHeart,
  FaUserEdit,
  FaSignOutAlt,
} from "react-icons/fa";

const DropMenuLogged = (props) => {
  return (
    <DropdownButton
      drop={props.dropDirection}
      className="btn-logged header__button--logged position-relative"
      size="small"
      id="dropdown-basic-button"
      style={{ bottom: "6px" }}
      title={
        <>
          <FaUserAlt className="icon-sm" title="Mi Cuenta" />
          <span className={` ${props.sticky ? "d-none" : "d-block"}`}>
            Mi Cuenta
          </span>
        </>
      }
    >
      <Dropdown.Item as={Link} to="/perfil">
        <FaUserEdit className="icon-sm" />
        Mi Perfil
      </Dropdown.Item>
      <Dropdown.Item as={Link} className="mr-4 d-flex" to="/mis-libros">
        <FaBookReader className="icon-sm" color="info" title="Mis Libros" />
        Mis libros
      </Dropdown.Item>
      <Dropdown.Item as={Link} to="/favoritos">
        <FaHeart className="icon-sm" />
        Mis Favoritos
      </Dropdown.Item>
      <Dropdown.Item onClick={() => props.closeSession()}>
        <FaSignOutAlt className="icon-sm" />
        Cerrar sesión
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default DropMenuLogged;
