import React, { useState, useEffect } from 'react';
import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from 'react-google-maps';

import { AiFillPhone } from 'react-icons/ai';
import { MdWatchLater } from 'react-icons/md';
import { getLibraries } from '../../api/libelista';

const Map = (props) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [zoom, setZoom] = useState(6);
  const [libraries, setLibraries] = useState([]);

  async function getLibrariesAsoc() {
    const rs = await getLibraries('', '');
    if (rs.status === 200) {
      setLibraries((prevState) => {
        prevState = rs.data;
        return prevState;
      });
    }
  }

  useEffect(() => {
    getLibrariesAsoc();
  }, []);

  useEffect(() => {
    if (props.lat !== 39.28) {
      setZoom(14);
    }
  }, [props.lat, props.long]);

  useEffect(() => {
    const filterLibraries = () => {
      let acc = [];
      libraries.map((el) => {
        let addr = el.address.toLowerCase();
        let locality = el.locality.toLowerCase();
        if (addr.includes(props.address.toLowerCase()) || locality.includes(props.address.toLowerCase())) {
          acc.push(el);
        }
      });
      setLibraries(acc);
    };
    filterLibraries();
  }, [props.callByState]);

  return (
    <GoogleMap defaultZoom={zoom} defaultCenter={{ lat: props.lat, lng: props.long }}>
      {libraries.map((bookstore, val) => (
        <Marker
          position={{
            lat: parseFloat(bookstore.coordinates.lat),
            lng: parseFloat(bookstore.coordinates.lon),
          }}
          icon={{
            url: `http://maps.google.com/mapfiles/kml/paddle/orange-circle.png`,
            scaledSize: new window.google.maps.Size(35, 35),
          }}
          onClick={() => {
            setSelectedItem(bookstore);
          }}
          key={val}
        />
      ))}
      {selectedItem && (
        <InfoWindow
          onCloseClick={() => {
            setSelectedItem(null);
          }}
          position={{
            lat: parseFloat(selectedItem.coordinates.lat),
            lng: parseFloat(selectedItem.coordinates.lon),
          }}
        >
          <div className="infoPlace">
            <h5 style={{ color: '#4b95a6' }}>{selectedItem.name}</h5>
            <p>
              {selectedItem.address} {selectedItem.locality}
            </p>
            <div className="d-flex">
              <AiFillPhone className="mr-2" style={{ color: '#f2a20d' }} />
              <p className="mr-2">{selectedItem.phone_number}</p>
              <MdWatchLater className="mr-2 ml-2" style={{ color: '#f2a20d' }} />
              <p>{selectedItem.working_schedule}</p>
            </div>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(Map));
