import React from 'react';
import { useParams } from 'react-router-dom';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import ActionsForm from '../../../../components/backoffice/actionsForm/index';
import SectionLoader from '../../../../components/loader/SectionLoader';
import {
  getBookstore as get,
  editBookstore as edit,
  createBookstore as create,
  getLibelistaData,
} from '../../../../api/company';
import * as Yup from 'yup';
const BookstoresItem = ({ data }) => {
  const { action } = useParams();
  const mustWaitForData = ['detail', 'edit'].includes(action);
  const renderConditions = [!mustWaitForData || data];
  // Separate used data fields only
  if (data) {
    data.parent_affiliate = data?.parent_affiliate ? true : null;
  }
  const {
    id,
    parent_affiliate,
    code,
    name,
    store_photo,
    redes_sociales,
    direccion,
    telefono,
    latitude,
    longitude,
    web_url,
    business_hours,
    email,
    ...restData
  } = data || {};
  const { facebook, twitter, instagram } =
    redes_sociales === 'string' ? JSON.parse(redes_sociales) : redes_sociales || {};
  const inputFields = [
    { label: 'Nombre', name: 'name', type: 'text' },
    { label: 'Código', name: 'code', type: 'text', placeholder: 'LIBE' },
    { label: 'Dirección', name: 'direccion', type: 'text' },
    { label: 'Correo electrónico', name: 'email' },
    { label: 'Teléfono', name: 'telefono' },
    { label: 'Latitud', name: 'latitude', type: 'text' },
    { label: 'Longitud', name: 'longitude', type: 'text' },
    { label: 'Horario(s) de atención', name: 'business_hours', type: 'text' },
    { label: '¿Pertenece a red Libelista?', name: 'parent_affiliate', type: 'checkbox' },
    { label: 'Sitio web', name: 'web_url', type: 'url' },
    { label: 'Facebook', name: 'facebook', type: 'url' },
    { label: 'Twitter', name: 'twitter', type: 'url' },
    { label: 'Instagram', name: 'instagram', type: 'url' },
    { label: 'Fotografía Anexa', name: 'store_photo', type: 'file', imageUrl: store_photo },
  ];
  const schema = {
    name: Yup.string().required('* '),
    code: Yup.string().max(4, 'Max. cuatro letras '),
  };
  const makeSocialNetworkFormDataValueObject = async (values) => {
    const { facebook, instagram, twitter, parent_affiliate } = values;
    if (parent_affiliate) {
      const response = await getLibelistaData();
      values.parent_affiliate = response.data?.results?.[0].id;
    } else {
      values.parent_affiliate = '';
    }
    values.redes_sociales = JSON.stringify({ facebook, instagram, twitter });
  };
  const actionsFormProps = {
    inputFields,
    title: 'Librería',
    subsectionPath: 'bookstores',
    data: {
      id,
      code,
      name,
      email,
      parent_affiliate,
      direccion,
      telefono,
      latitude,
      longitude,
      web_url,
      business_hours,
      facebook,
      twitter,
      instagram,
    },
    requests: { edit, create },
    schema,
    formDataConfiguration: {},
    extraSubmitLogic: makeSocialNetworkFormDataValueObject,
    action,
  };
  return renderConditions.every((condition) => condition) ? <ActionsForm {...actionsFormProps} /> : <SectionLoader />;
};
BookstoresItem.request = {
  get,
};
const BookstoresItemPage = () => <BackofficePagesManager PageViewer={BookstoresItem} />;
export default BookstoresItemPage;
