import { methodGet, methodDelete, methodPut, methodPost, methodPatch } from './methods';
import { API_INFO } from '../constants';

export async function getBooksByTitle(title = '', page = 1, pageSize = 50) {
  const url = API_INFO + `libros/libros/?titulo=${title}&page=${page}&page_size=${pageSize}`;
  return methodGet(url);
}
export async function getBookBindings() {
  const url = API_INFO + 'libros/libros/list-encuadernacion/';
  return methodGet(url);
}
export async function getAllEbooks(slug, page = 1) {
  const url = API_INFO + `libros/libros/ebooks/?page=${page}`;
  return methodGet(url);
}

export async function listIdiomas(id, query) {
  const url = API_INFO + 'libros/libros/list-idiomas/';
  return methodGet(url, query);
}

export async function listPaises(query) {
  const url = API_INFO + 'libros/libros/list-paises/';
  return methodGet(url, query);
}

export const getMateriasByCompany = () => {
  const url = API_INFO + 'libros/materias/main-materies/';
  return methodGet(url);
};

export const detailMateryGeslibApi = (code) => {
  const url = API_INFO + 'libros/materias-geslib/' + code;
  return methodGet(url);
};

export const getDetailMateryGeslib = (slug) => {
  const url = API_INFO + 'libros/materias-geslib/' + slug;
  return methodGet(url);
};

export const getDetailMateryIbic = (slug) => {
  const url = API_INFO + 'libros/materias-ibic/' + slug;
  return methodGet(url);
};

export const getElementsMateryGeslib = (slug, page) => {
  const url = API_INFO + 'libros/materias-geslib/' + slug + '/get-products/?page=' + page;
  return methodGet(url);
};

export const getElementsMateryIbic = (slug, page) => {
  const url = API_INFO + 'libros/materias-ibic/' + slug + '/get-products/?page=' + page;
  return methodGet(url);
};

export const featuredMattersGeslibApi = () => {
  const url = API_INFO + 'libros/materias-geslib/featured-matters';
  return methodGet(url);
};

export async function getAwardedBooks(page = 1, page_size = 10, search = ' ') {
  const url =
    API_INFO + `libros/literary-awards/?page=${page}&page_size=${page_size}${search ? '&search=' + search : ''}`;
  return methodGet(url);
}
export async function getAwardedBook(id) {
  const url = API_INFO + `libros/literary-awards/${id}/`;
  return methodGet(url);
}
export async function deleteAwardedBook(id) {
  const url = API_INFO + `libros/literary-awards/${id}/`;
  return methodDelete(url);
}
export async function editAwardedBook(payload) {
  const url = API_INFO + `libros/literary-awards/${payload.id || payload.get('id')}/`;
  return methodPut(url, payload);
}
export async function createAwardedBook(payload) {
  const url = API_INFO + `libros/literary-awards/`;
  return methodPost(url, payload);
}

export async function getSmallReviews() {
  const url = API_INFO + 'libros/libros/small-reviews/';
  return methodGet(url);
}

export async function getSmallReviewsDetail(id) {
  const url = API_INFO + `libros/libros/small-reviews-detail/?small_reviews_id=${id}`;
  return methodGet(url);
}

export async function getBookDetail(id) {
  const url = API_INFO + `libros/libros/${id}/`;
  return methodGet(url, {});
}
export async function deleteBook(id) {
  const url = API_INFO + `libros/libros/${id}/`;
  return methodDelete(url);
}
export async function editBookDetail(payload) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const url = API_INFO + `libros/libros/${payload.id || payload.get('id')}/`;
  return methodPatch(url, payload, config);
}
export async function createBook(payload) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const url = API_INFO + `libros/libros/`;
  return methodPost(url, payload, config);
}
export async function getAllBooks(title) {
  const url = API_INFO + `libros/libros${title ? '?page_size=10&titulo=' + title : ''}`;
  return methodGet(url);
}

export async function getMateriasIbic() {
  const url = API_INFO + `libros/materias-ibic/all-materies/`;
  return methodGet(url, {});
}
