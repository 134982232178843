import React from "react";
import { Row, Col } from "react-bootstrap";

import Condiciones from "../../assets/img/img_condiciones.png";
import "./infoPages.scss";

const Cookies = () => {
  return (
    <div className="container-fluid mainDiv">
      <Row className="justify-content-center mainRow infoRow">
        <Col lg={8} className="mt-4">
          <div className="row">
            <div className="col-md-12">
              <h1 className="infoTitle">Cookies</h1>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" id="static-content">
              <h2>¿Qué son las cookies?</h2>

              <p>&nbsp;</p>

              <p>
                Una cookie es un archivo que se descarga en su ordenador al
                acceder a determinadas páginas web. Las cookies permiten a una
                página web, entre otras cosas, almacenar y recuperar información
                sobre los hábitos de navegación de un usuario o de su equipo y,
                dependiendo de la información que contengan y de la forma en que
                utilice su equipo, se pueden utilizar para reconocer al usuario.
              </p>

              <p>&nbsp;</p>

              <p>&nbsp;</p>

              <h2>¿Qué tipo de cookies utiliza esta página web?</h2>

              <p>&nbsp;</p>

              <p>
                <strong>Cookies técnicas:</strong> Son aquellas creadas y
                gestionadas por nosotros, y permiten al usuario la navegación a
                través de nuestra página web y la utilización de las diferentes
                opciones o servicios tales como, identificar la sesión, filtrar
                o personalizar información, acceder a partes de acceso
                restringido.
              </p>

              <p>
                <strong>Cookies de análisis:</strong> Son aquellas nos permiten
                realizar la medición y análisis estadístico de la utilización
                que hacen los usuarios del servicio ofrecido. Para ello se
                analiza su navegación en nuestra página web con el fin de
                mejorar la oferta de productos o servicios que le ofrecemos.
              </p>

              <p>&nbsp;</p>

              <p>&nbsp;</p>

              <h2>Revocación y eliminación de cookies</h2>

              <p>&nbsp;</p>

              <p>
                Puedes permitir, bloquear o eliminar las cookies instaladas en
                tu equipo mediante la configuración de las opciones del
                navegador. En caso de que no permita la instalación de cookies
                en su navegador es posible que no pueda acceder a alguna de las
                secciones de nuestra web.
              </p>

              <p>&nbsp;</p>

              <p>&nbsp;</p>

              <h2>Lista de cookies recogidas</h2>

              <p>&nbsp;</p>

              <p>La lista de cookies recogidas es la siguiente:</p>

              <p>
                ga, __utma, __utmb, __utmc, __utmz, __utmv: Cookies asociadas a
                Google Analytics. Permite distinguir los diferentes usuarios de
                la web. Caducan a los dos años.
              </p>

              <p>ASP.NET_SessionId: Cookie de sesión.</p>
            </div>
          </div>
        </Col>
        <Col lg={3} className="mt-4">
          <img src={Condiciones} alt="paper.png" className="infoImg" />
        </Col>
      </Row>
    </div>
  );
};

export default Cookies;
