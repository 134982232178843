import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import BackofficePagesManager from 'components/backoffice/pagesManager';
import ActionsForm from 'components/backoffice/actionsForm/index';
import SectionLoader from 'components/loader/SectionLoader';
import { getBookDetail as get, editBookDetail as edit, createBook as create, getBookBindings } from 'api/libros';
import { getAuthors } from 'api/products';
import { GoPencil } from 'react-icons/go';
import { FaCalendarAlt } from 'react-icons/fa';
import { getCountries, getCountry, getLanguages } from 'api/company';
import { getSelectDataHandlers } from 'utils/backoffice/getSelectDataHandlers';
import { getLibelistaLists } from 'api/libelista';
import * as Yup from 'yup';

const bookStatusOptions = [
  { label: 'Disponible', value: 0 },
  { label: 'No disponible', value: 1 },
  { label: 'Descatalogado', value: 2 },
];
const genreOptions = [
  { label: 'Adulto', value: 'adulto' },
  { label: 'Infantil', value: 'infantil' },
];
const clasificationOptions = [
  { label: 'Mayores de 12 años', value: '2' },
  { label: 'Mayores de 18 años', value: '3' },
  { label: 'Mayores de 16 años', value: '4' },
  { label: 'Mayores de 13 años', value: '5' },
  { label: 'Mayores de 7 años', value: '6' },
  { label: 'Todos los públicos', value: '7' },
  { label: 'Especial infancia', value: '8' },
  { label: 'Varios', value: '9' },
];

const ProductsItem = ({ data }) => {
  const { action } = useParams();
  const usedData = {};
  if (data) {
    usedData.published_status = data.published_status.LIBE === 2;
    usedData.availabled_status = data.availabled_status.LIBE === 2;
    usedData.cover = undefined;
    [
      'id',
      'precio_venta_publico',
      'titulo',
      'get_url',
      'url',
      'texto_resumen',
      'ean',
      'estado',
      'autor',
      'active',
      'genero',
      'clasificacion',
      'encuadernacion',
      'libelista_list',
      'pais_public',
      'idioma_orig',
      'idioma',
      'fecha_public',
      'num_paginas',
      'is_novelty',
      'recommended',
    ].forEach((field) => (usedData[field] = data[field]));
  }

  const [
    { defaultOptions: defaultAuthorsOptions, defaultOption: defaultAuthorsOption },
    setAuthorsDefaultOptionValues,
  ] = useState({});

  const { getSelectData: getAuthorsOptions, setSelectDefaultData: setAuthorsOptions } = getSelectDataHandlers(
    undefined,
    getAuthors,
    setAuthorsDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'nombre_autor',
      fieldNameWithRequestValue: 'id',
    }
  );

  const [
    { defaultOptions: defaultCountryOptions, defaultOption: defaultCountryOption },
    setCountryDefaultOptionValues,
  ] = useState({});

  const { setSelectDefaultData: setCountryOptions } = getSelectDataHandlers(
    getCountry,
    getCountries,
    setCountryDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'nombre',
      fieldNameWithRequestValue: 'id',
      fieldNameWithExtraLabelInformation: 'codigo',
    }
  );

  const [
    { defaultOptions: defaultLanguageOptions, defaultOption: defaultLanguageOption },
    setLanguageDefaultOptionValues,
  ] = useState({});

  const { setSelectDefaultData: setLanguageOptions } = getSelectDataHandlers(
    undefined,
    getLanguages,
    setLanguageDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'nombre',
      fieldNameWithRequestValue: 'id',
      fieldNameWithExtraLabelInformation: '',
    }
  );

  const [
    { defaultOptions: defaultBindingOptions, defaultOption: defaultBindingOption },
    setBindingDefaultOptionValues,
  ] = useState({});

  const { setSelectDefaultData: setBindingOptions } = getSelectDataHandlers(
    undefined,
    getBookBindings,
    setBindingDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'descripcion',
      fieldNameWithRequestValue: 'id',
      fieldNameWithExtraLabelInformation: '',
    }
  );

  const [{ defaultOptions: defaultListOptions, defaultOption: defaultListOption }, setListDefaultOptionValues] =
    useState({});

  const { setSelectDefaultData: setListOptions } = getSelectDataHandlers(
    undefined,
    getLibelistaLists,
    setListDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'title',
      fieldNameWithRequestValue: 'id',
    }
  );

  const mustWaitForData = ['detail', 'edit'].includes(action);

  useEffect(() => {
    setBindingOptions();
    setListOptions();
  }, []);

  useEffect(() => {
    if (mustWaitForData && data) {
      setCountryOptions(data?.pais_public);
      setAuthorsOptions(data?.display_autor?.[0]?.nombre_autor);
      setLanguageOptions(data?.idioma_orig);
    } else if (!mustWaitForData) {
      setAuthorsOptions();
      setCountryOptions();
      setLanguageOptions();
    }
  }, [data]);

  const renderConditions = [
    !mustWaitForData || data,
    defaultAuthorsOptions,
    defaultCountryOptions,
    defaultBindingOptions,
    defaultListOptions,
  ];

  const inputFields = [
    { label: 'Datos principales', type: 'formSubsectionHeader', icon: GoPencil },
    { label: 'Título', name: 'titulo', type: 'text' },
    { label: 'Precio de venta público', name: 'precio_venta_publico', type: 'text' },
    { label: 'Slug', name: 'url', type: 'text' },
    {
      label: 'Imagen',
      name: 'cover',
      type: 'file',
      imageUrl: /vacia/.test(data?.get_url) ? undefined : data?.get_url,
    },
    { label: 'Nombre único (slug)', name: 'url', type: 'text', hidden: ['edit', 'create'].includes(action) },
    { label: 'Texto resumen', name: 'texto_resumen', type: 'textarea' },
    { label: 'E.A.N.', name: 'ean', type: 'text' },
    {
      label: 'Estado',
      name: 'estado',
      type: 'select',
      options: bookStatusOptions,
      defaultValue: bookStatusOptions.find((option) => option.value == data?.estado) || bookStatusOptions[2],
    },
    {
      label: 'Autor(es)',
      name: 'autor',
      type: 'select',
      isAsync: true,
      isMulti: true,
      loadOptions: getAuthorsOptions,
      defaultOptions: defaultAuthorsOptions,
      cacheOptions: true,
      defaultValue: defaultAuthorsOptions
        ? defaultAuthorsOptions.find((option) => option.value == data?.display_autor?.[0]?.id)
        : defaultAuthorsOptions?.[0],
    },
    { label: '¿Publicado en Libelista?', name: 'published_status', type: 'checkbox' },
    { label: '¿Stock en Libelista?', name: 'availabled_status', type: 'checkbox' },

    { label: '¿Activo?', name: 'active', type: 'checkbox' },
    { label: 'Características', type: 'formSubsectionHeader', icon: FaCalendarAlt, doubleLine: true },
    {
      label: 'Género',
      name: 'genero',
      type: 'select',
      options: genreOptions,
      defaultValue: genreOptions.find((option) => option.value == data?.genero) || genreOptions[2],
    },
    {
      label: 'Clasificación',
      name: 'clasificacion',
      type: 'select',
      options: clasificationOptions,
      defaultValue:
        clasificationOptions.find((option) => option.value == data?.clasificacion) || clasificationOptions[2],
    },
    {
      label: 'Encuadernación',
      name: 'encuadernacion',
      type: 'select',
      isMulti: true,
      defaultValue: defaultBindingOptions?.filter((option) => data?.encuadernacion?.includes(parseInt(option.value))),
      options: defaultBindingOptions,
    },
    {
      label: 'Listas pertenecientes',
      name: 'libelista_list',
      type: 'select',
      isAsync: true,
      isMulti: true,
      loadOptions: setListOptions,
      defaultOptions: defaultListOptions,
      cacheOptions: true,
      defaultValue: defaultListOptions?.filter((option) => data?.libelista_list?.includes(parseInt(option.value))),
    },
    {
      label: 'Pais de publicación',
      name: 'pais_public',
      type: 'select',
      options: defaultCountryOptions,
      defaultValue: defaultCountryOption,
    },
    {
      label: 'Idioma original',
      name: 'idioma_orig',
      type: 'select',
      options: defaultLanguageOptions,
      defaultValue:
        defaultLanguageOptions?.find((option) => option.value == data?.idioma_orig) || defaultLanguageOptions?.[0],
    },
    {
      label: 'Idioma(s) del ejemplar',
      name: 'idioma',
      type: 'select',
      isMulti: true,
      options: defaultLanguageOptions,
      defaultValue: defaultLanguageOptions?.filter((option) => data?.idioma.includes(parseInt(option.value))),
    },
    { label: 'Fecha de publicación', name: 'fecha_public', type: 'date' },
    { label: 'Número de páginas', name: 'num_paginas', type: 'text' },
    { label: '¿Novedad?', name: 'is_novelty', type: 'checkbox' },
    { label: '¿Recomendado?', name: 'recommended', type: 'checkbox' },
  ];

  const schema = {
    titulo: Yup.string().required('* '),
    precio_venta_publico: Yup.string()
      .required('* ')
      .test('Positive', 'Inválido', (value) => parseFloat(value) > 0),
    autor: Yup.mixed().required('* '),
    encuadernacion: Yup.mixed().required('* '),
    idioma: Yup.mixed().required('* '),
  };

  const handleDataShapeshifting = (values = {}) => {
    values.published_status = JSON.stringify(
      action === 'create'
        ? { LIBE: values.published_status ? 2 : 1 }
        : { ...data?.published_status, LIBE: values.published_status ? 2 : 1 }
    );
    values.availabled_status = JSON.stringify(
      action === 'create'
        ? { LIBE: values.availabled_status ? 2 : 1 }
        : { ...data?.availabled_status, LIBE: values.availabled_status ? 2 : 1 }
    );
    if (['create'].includes(action)) {
      values.url = 'placeholder';
    }
  };

  const actionsFormProps = {
    inputFields,
    title: 'Producto',
    subsectionPath: 'products',
    data: usedData,
    requests: { edit, create },
    schema,
    formDataConfiguration: { autor: true, idioma: true, libelista_list: true, encuadernacion: true },
    extraSubmitLogic: handleDataShapeshifting,
    action,
  };
  return renderConditions.every((condition) => condition) ? <ActionsForm {...actionsFormProps} /> : <SectionLoader />;
};

ProductsItem.request = {
  get,
};
const ProductsItemPage = () => <BackofficePagesManager PageViewer={ProductsItem} />;
export default ProductsItemPage;
