import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ImBook } from 'react-icons/im';
import { TiShoppingCart } from 'react-icons/ti';
import { RiHistoryLine } from 'react-icons/ri';
import './homePanel.scss';
import { getRecentSales, getRecentSalesNumber } from '../../../api/orders';
const getRecentSalesData = async (recentSalesDataSetter) => {
  try {
    const recentSalesItemsData = await getRecentSales();
    const recentSalesNumberData = await getRecentSalesNumber();
    if ([recentSalesItemsData?.status, recentSalesNumberData?.status].every((status) => status === '200')) {
      const {
        data: { total_orders: number },
      } = recentSalesNumberData;
      const { data: sales } = recentSalesItemsData;
      recentSalesDataSetter({ sales, number });
    }
  } catch (exception) {
    console.log(exception);
  }
};
const HomePanel = () => {
  const [recentSalesData, setRecentSalesData] = React.useState({ sales: [], number: 0 });

  React.useEffect(() => {
    getRecentSalesData(setRecentSalesData);
  }, []);
  // const enterColumns = () => {
  //   let columns = [];
  //   for (let i = 0; i <= 6; i++) {
  //     columns.push(
  //       <Row className="py-1 w-100 px-0" key={i}>
  //         <Col lg={12} className="d-flex sellItemCol">
  //           <p className="mb-2 mt-2">01/08/21 20:30</p>
  //           <div className="">
  //             <TiShoppingCart className="p-1 mx-2 position-relative" />
  //           </div>
  //           <Link className="mb-2 mt-2">Some Book Title</Link>
  //         </Col>
  //       </Row>
  //     );
  //   }
  //   return columns;
  // };

  return (
    <Row className="homeRow">
      <Col lg={12} className="px-4">
        <h3 className="w-100 text-start my-2 titlePanel" style={{ color: 'inherit' }}>
          Visión de conjunto
        </h3>
      </Col>
      <Col className="d-flex mt-3 px-4" lg={12}>
        {[
          { icon: TiShoppingCart, endpoint: '/#', name: 'Ventas' },
          { icon: ImBook, endpoint: 'administration/products/list', name: 'Productos' },
        ].map(({ icon: Icon, endpoint, name }, index) => (
          <Link
            key={`directLink#${index}`}
            to={endpoint}
            className="d-flex flex-column justify-content-around align-items-center iconLink mx-1 p-4"
          >
            <Icon style={{ width: '50px', height: '50px' }} />
            {name}
          </Link>
        ))}
      </Col>
      <Col lg={12} className="mt-3 px-4">
        <Row>
          <Col lg={12} className="d-flex titleCol">
            <RiHistoryLine className="position-relative mr-2" />
            <p className="mb-1">Resumen</p>
          </Col>
          <Col lg={12} className="d-flex">
            <p className="mb-2 mt-2">Unidades vendidas en los últimos 7 días</p>
            <h3 className="mt-2">{recentSalesData.number}</h3>
          </Col>
        </Row>
      </Col>
      <Col lg={12} className="mt-3 px-4">
        <Row>
          <Col lg={12} className="d-flex titleCol">
            <TiShoppingCart className="position-relative mr-2" />
            <p className="mb-1">Ventas recientes</p>
          </Col>
          <Col lg={12} className="justify-content-center mb-3">
            {recentSalesData.sales.map(({ items, fecha }, index) => (
              <Row className="py-1 w-100 px-0" key={`recentSalesInterface#${index}`}>
                <Col lg={12} className="sellItemCol">
                  <div className="my-1">
                    <TiShoppingCart className="p-1 mx-2 position-relative" />
                    {fecha}
                  </div>
                  <Col className="d-flex flex-column pl-5">
                    {items?.map((item, index) => (
                      <Link
                        key={`saleIndexNumber#${index}`}
                        className=""
                        to={`/productos/libro/${item.url}/`}
                        target="_blank"
                      >
                        {item.nombre}
                      </Link>
                    ))}
                  </Col>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HomePanel;
