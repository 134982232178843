import React from "react";
import { Row, Col } from "react-bootstrap";

import Tarifas from "../../assets/img/img_tarifas.png";
import "./infoPages.scss";

//redux
import { useSelector } from "react-redux";
import { companySelector } from "../../slices/company";

//own components
import Head from "../../components/metatags_helmet/metatags";

const PricesAndTerms = () => {
  const title = "Tarifas y plazos de entrega";
  const { descripcion_pub } = useSelector(companySelector);

  return (
    <div className="container-fluid mainDiv">
      <Head description={descripcion_pub} title={title} noIndex={true} />
      <Row className="justify-content-center mainRow infoRow">
        <Col lg={8} className="mt-4">
          <div className="row">
            <div className="col-md-12">
              <h1 className="infoTitle">Tarifas y plazos de entrega</h1>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>Tarifas de envío</p>
              <ul>
                <li>
                  <p>
                    Puedes recoger tu pedido en una librería asociada a
                    Libelista sin ningún coste adicional. Debes seleccionar el
                    punto de recogida durante el proceso de compra.
                  </p>
                </li>
                <li>
                  <p>
                    Si prefieres recibir tu pedido en una dirección de entrega,
                    los gastos de envío son de 3,80 € para la Península.
                  </p>
                </li>
                <li>
                  <p>Los gastos de envío para las Islas Baleares es de 12 €.</p>
                </li>
                <li>
                  <p>
                    Tramitamos comandas a Ceuta y Melilla en casos
                    excepcionales. Consúltalo a{" "}
                    <strong>pedidos@libelista.com</strong>
                  </p>
                </li>
                <li>
                  <p>
                    Por el momento, Libelista no tramita pedidos a las Islas
                    Canarias ni fuera de la península.
                  </p>
                </li>
                <li>
                  <p>Gastos de envío gratuitos en pedidos superiores a 60 €.</p>
                </li>
              </ul>

              <p>&nbsp;</p>

              <h3>Plazos de entrega</h3>

              <p>&nbsp;</p>

              <p>
                Los plazos de entrega de los pedidos son entre 4-6 días
                laborables.
              </p>
            </div>
          </div>
        </Col>
        <Col lg={3} className="mt-4">
          <img src={Tarifas} alt="cards.png" className="infoImg" />
        </Col>
      </Row>
    </div>
  );
};

export default PricesAndTerms;
