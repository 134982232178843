import React, { useState, useEffect } from 'react';
import { useQuery } from '../../utils/customHooks/useQuery';
import { Link, useParams, useLocation } from 'react-router-dom';
import BackofficePaginator from './paginator';
import { RiDeleteBinLine } from 'react-icons/ri';
import { GoPencil } from 'react-icons/go';
import { FaEye } from 'react-icons/fa';
import ActionConfirmationModal from './actionConfirmationModal';
import SectionLoader from 'components/loader/SectionLoader';

const ACTIONS = ['edit', 'list', 'create', 'detail'];

const BackofficePagesManager = ({ PageViewer = () => null, filterParameterName }) => {
  const DEFAULT_REQUEST_PARAMETER = 'id';
  const {
    request: { get, del } = { get: () => ({}), del: () => ({}) },
    requestParameter = DEFAULT_REQUEST_PARAMETER,
    itemClass,
  } = PageViewer;
  const DEFAULT_PAGE = 1;
  const PAGE_SIZE = 15;
  const DEFAULT_ACTION = 'list';
  const DEFAULT_FILTER_PARAMETER_VALUE = '';
  const query = useQuery();
  const page = query.get('page') || DEFAULT_PAGE;
  const filterParameterValue = query.get(filterParameterName) || DEFAULT_FILTER_PARAMETER_VALUE;
  const location = useLocation();
  const { id, action = DEFAULT_ACTION } = useParams();
  const [pageData, setPageData] = useState(undefined);
  const [maxPages, setMaxPages] = useState(undefined);
  const [pageState, setPageState] = useState('loading');
  const [actionConfirmationModalData, setActionConfirmationModalData] = useState(undefined);

  const getDataHandler = async () => {
    try {
      /**
       * Although a section's requestParameter might not be "id", its value will be assigned
       * to the /:id url param unless they're page and page_size
       */
      const requestParametersValues = action === 'list' ? [page, PAGE_SIZE, filterParameterValue] : [id];
      const { status, data } = await get(...requestParametersValues);
      if ([200, 201].includes(status)) {
        const { results, count = 0 } = data;
        setPageData((action === 'list') & results !== undefined ? results : data);
        action === 'list' && setMaxPages(Math.ceil(count / PAGE_SIZE));
        setPageState('loaded');
      } else {
        setPageState(status === undefined ? 'loaded' : 'error');
      }
    } catch (exception) {
      setPageState('error');
      console.log(exception);
    }
  };

  let pageViewerProps = {
    data: pageData,
    action,
    loadData: { pageSize: PAGE_SIZE, status: pageState },
  };

  const updatActionState = (actionState = 'pending') =>
    setActionConfirmationModalData((prevActionConfirmationModalData) => ({
      ...prevActionConfirmationModalData,
      actionState,
    }));

  if (action === 'list') {
    var deleteActionHandler = async (payload) => {
      updatActionState();
      const SUCCESS_CODES = [204];
      try {
        const itemRef = payload?.[requestParameter];
        const response = await del(itemRef);
        const { status } = response;
        if (SUCCESS_CODES.includes(status)) {
          updatActionState('success');
          await getDataHandler();
        } else {
          updatActionState('error');
        }
      } catch (exception) {
        updatActionState('error');
        console.log(exception);
      }
    };
    var ActionsMenu = ({ payload }) => {
      const loadActionConfirmationModalData = () =>
        setActionConfirmationModalData({
          hidden: false,
          payload,
          actionHandler: deleteActionHandler,
          action: 'Eliminar',
          actionState: undefined,
          itemClass: undefined,
        });
      const generateActionPathname = (action = 'list') =>
        `${location.pathname.replace(/(\w)$/, '$1/')}${action}/${payload[PageViewer.requestParameter]}`;
      return (
        <>
          <Link to={generateActionPathname('detail')}>
            <span className="btn" data-toggle="tooltip" title="Detalle">
              <FaEye color="black" />
            </span>
          </Link>
          <Link to={generateActionPathname('edit')}>
            <span className="btn" data-toggle="tooltip" title="Editar">
              <GoPencil color="black" />
            </span>
          </Link>
          <span className="btn" data-toggle="tooltip" title="Borrar" onClick={loadActionConfirmationModalData}>
            <RiDeleteBinLine />
          </span>
        </>
      );
    };
    pageViewerProps.ActionsMenu = ActionsMenu;
    var actionConfirmationModalProps = {
      ...actionConfirmationModalData,
      resetModalDataHandler: () => setActionConfirmationModalData(undefined),
      itemClass: itemClass,
    };
  }

  useEffect(() => {
    if (ACTIONS.includes(action)) {
      const isCreateAction = action === 'create';
      setPageState(isCreateAction ? 'loaded' : 'loading');
      !isCreateAction && getDataHandler();
    } else {
      setPageState('error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, page, maxPages, filterParameterValue]);

  switch (pageState) {
    case 'error':
      return (
        <div className="alert alert-danger text-center" role="alert">
          Error al cargar el listado
        </div>
      );
    case 'loaded':
      const listViewComponents = [
        <ActionConfirmationModal key="ACM" {...actionConfirmationModalProps} />,
        <PageViewer key="PM" {...pageViewerProps} />,
        <BackofficePaginator
          key="BP"
          filterParameterName={filterParameterName}
          pageState={pageState}
          page={page}
          pages={maxPages}
        />,
      ];
      return action === 'list' ? listViewComponents : listViewComponents[1];

    default:
      return <SectionLoader />;
  }
};
export default BackofficePagesManager;
