import React, { useRef, useState } from 'react';
import { Row, Col, Accordion, Card, Button, InputGroup, FormControl } from 'react-bootstrap';
import { BiSearch } from 'react-icons/bi';

import FaqsImg from '../../assets/img/img_faqs.png';
import './infoPages.scss';

//redux
import { useSelector } from 'react-redux';
import { companySelector } from '../../slices/company';

//own components
import Head from '../../components/metatags_helmet/metatags';

const Faqs = () => {
  const title = 'Preguntas frecuentes';
  const { descripcion_pub } = useSelector(companySelector);
  const questionRef = [];
  const answerRef = [];
  const inputRef = useRef();
  const [items, setItems] = useState({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
    8: true,
    9: true,
    10: true,
    11: true,
    12: true,
    13: true,
  });

  const searchText = (e) => {
    e.preventDefault();
    let newObj = {};
    for (let key in items) {
      items[key] = true;
      newObj = Object.assign({}, items);
      setItems(newObj);
    }
    let value = e.currentTarget.value.toLowerCase();
    if (value !== '') {
      questionRef.forEach((el, val) => {
        let question = el.innerText.toLowerCase();
        if (!question.includes(value)) {
          let answer = answerRef[val].innerText.toLowerCase();
          if (!answer.includes(value)) {
            newObj[val] = !newObj[val];
            setItems(newObj);
          }
        }
      });
    }
  };

  return (
    <div className="container-fluid px-0 mainDiv">
      <Head description={descripcion_pub} title={title} noIndex={true} />
      <Row className="justify-content-center mainRow infoRow px-0">
        <Col xs={11} lg={8} className="mt-4 pl-0">
          <div className="row">
            <div className="col-md-12">
              <h1 className="infoTitle">Preguntas Frecuentes</h1>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <br />
              <form action="" method="get">
                <div className="form-group has-feedback has-search">
                  <i className="fas fa-search form-control-feedback"></i>
                  <InputGroup className="mb-3">
                    <FormControl
                      ref={inputRef}
                      type="text"
                      placeholder="Palabra clave, tópico, consulta..."
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          searchText(e);
                        }
                      }}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text
                      // onClick={searchText(()=>{
                      //   if(inputRef !== undefined) {
                      //     return inputRef.current.value
                      //   } else {
                      //     return inputRef
                      //   }
                      // })}
                      >
                        <BiSearch />{' '}
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </div>
              </form>

              <Accordion className={`mt-5 ${items[0] === true ? '' : 'd-none'}`}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" ref={(el) => questionRef.push(el)}>
                      1. He recibido la notificación de “Libro enviado” pero mi pedido no ha llegado.
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p ref={(el) => answerRef.push(el)}>
                        Una vez se realiza un pedido llegan dos correos: uno de confirmación y un segundo cuando el
                        producto ya está en camino o ya se puede recoger en la librería (depende del modo de recogida
                        que hayas elegido). En este caso, si ya has recibido el mensaje “libro enviado” y han pasado 48
                        horas desde el correo, contacta con{' '}
                        <span className="MsoHyperlink">
                          <a href="mailto:pedidos@libelista.com">pedidos@libelista.com</a>
                        </span>{' '}
                        y estudiaremos el caso por si ha podido haber algún incidente.{' '}
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Accordion className={`mt-3 ${items[1] === true ? '' : 'd-none'}`}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" ref={(el) => questionRef.push(el)}>
                      2. Quiero consultar el estado de mi pedido.
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p ref={(el) => answerRef.push(el)}>
                        Podrás consultar el estado de tu pedido accediendo a “Mi cuenta” desde nuestra página web o
                        desde la App Libelista. Una vez dentro, podrás ver el estado de todos tus pedidos en la tabla
                        que aparece abajo.{' '}
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Accordion className={`mt-3 ${items[2] === true ? '' : 'd-none'}`}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" ref={(el) => questionRef.push(el)}>
                      3. Quiero devolver un pedido.
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div ref={(el) => answerRef.push(el)}>
                        <p>
                          Podrás devolver el pedido dentro del plazo de 14 días desde el envío del paquete.
                          <br />
                        </p>
                        <p>
                          Si has realizado un pedido con recogida en tienda, solo tienes que acudir a la librería con el
                          producto y se te reembolsará el dinero en la misma cuenta que se hizo la compra.{' '}
                        </p>
                        <p>
                          Si has realizado un pedido con recogida a domicilio, puedes hacer la devolución de dos formas
                          distintas:{' '}
                        </p>
                        <p>
                          Por cuenta propia: puedes enviarnos el/los productos a la dirección facilitada a continuación
                          y te haremos el reembolso del total del libro una vez lo recibamos y hayamos comprobado que
                          todo esté correcto (los gastos de envíos correrán a cargo del cliente). Recuerda escribir bien
                          la dirección y de poner tu nombre, apellidos y número de pedido en el remitente. Este proceso
                          puede tardar entre 5 y 8 días.
                        </p>
                        <p align="center">Libelista</p>
                        <p align="center">Gran Via de les Corts Catalanes, 594 bajos local</p>
                        <p align="center">08007, Barcelona</p>
                        <p>
                          {' '}
                          Mediante mensajero: si quieres que gestionemos nosotros la devolución, contacta con{' '}
                          <span className="MsoHyperlink">
                            <a href="mailto:pedidos@libelista.com">pedidos@libelista.com</a>
                          </span>{' '}
                          y te explicaremos cómo hacerlo.{' '}
                        </p>
                        <p>
                          Si has recibido el paquete en mal estado, se te reembolsará el importe íntegro de tu compra
                          (libro/s y gastos de envío) y no se cobrará cargos adicionales por transporte. <br />
                        </p>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Accordion className={`mt-3 ${items[3] === true ? '' : 'd-none'}`}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" ref={(el) => questionRef.push(el)}>
                      4. Quiero cancelar un pedido.
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div ref={(el) => answerRef.push(el)}>
                        <p>
                          Podrás cancelar el pedido siempre y cuando no se haya hecho ya el envío del mismo. Podrás
                          saber si tu pedido ya se enviado o no si has recibido el mensaje “Libro enviado” en tu correo
                          electrónico.{' '}
                        </p>
                        <p> </p>
                        <p>
                          Puedes cancelar tu pedido a través de tu área de Usuario→ Mi cuenta → Pedidos → Cancelar. El
                          reembolsó íntegro se efectuará en unas horas.{' '}
                        </p>
                        <p> </p>
                        <p></p>
                        <p>
                          Para cualquier consulta, puedes contactar con{' '}
                          <span className="MsoHyperlink">
                            <a href="mailto:pedidos@libelista.com">pedidos@libelista.com</a>
                          </span>
                        </p>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Accordion className={`mt-3 ${items[4] === true ? '' : 'd-none'}`}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" ref={(el) => questionRef.push(el)}>
                      5. Quiero recoger un pedido en una librería.
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div ref={(el) => answerRef.push(el)}>
                        <p>
                          Tendrás que marcar la opción de compra "recoger en una librería", y seleccionar a continuación
                          la librería que mejor te venga dentro de nuestra red.
                        </p>
                        <p>
                          Podrás ir a recogerlo cuando hayas recibido el correo electrónico que lo notifique.
                          Presentando tu DNI y el número de pedido al librero será suficiente.{' '}
                        </p>
                        <p> </p>
                        <p>
                          Tampoco deberás realizar ningún pago en la librería, ya que todo el proceso de cobro se habrá
                          realizado en el momento en que se tramitó el pedido a través de la página Libelista.
                        </p>
                        <p> </p>
                        <p></p>
                        <p>
                          Recuerda que la librería será la que hayas asignado como punto de recogida y que esta no tiene
                          por qué ser la misma librería a la que estás vinculado/a. <br />
                        </p>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Accordion className={`mt-3 ${items[5] === true ? '' : 'd-none'}`}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" ref={(el) => questionRef.push(el)}>
                      6. Quiero hacer un regalo a una persona.
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div ref={(el) => answerRef.push(el)}>
                        <p>
                          Desde Libelista ofrecemos la posibilidad de hacer un regalo a otra persona.
                          <br />
                        </p>
                        <p>
                          En este caso, en el momento de hacer el pedido, tendrás que seleccionar la opción “comprar
                          como regalo” y rellenar los datos del destinatario (nombre, apellidos, correo electrónico y
                          dirección).{' '}
                        </p>
                        <p>
                          El beneficiario/a del regalo recibirá un correo donde se le informará que tiene un regalo, sin
                          especificar ni títulos ni precios, y que podrá obtenerlo o bien en la librería que se haya
                          elegido o bien en su domicilio (se le enviará un segundo correo cuando pueda ir a recogerlo o
                          esté a punto de llegar a su casa).{' '}
                        </p>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Accordion className={`mt-3 ${items[6] === true ? '' : 'd-none'}`}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" ref={(el) => questionRef.push(el)}>
                      7. Quiero modificar mis datos de cliente.
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p ref={(el) => answerRef.push(el)}>
                        Puedes cambiar tus datos de cliente a través de tu área de Usuario → Mi cuenta.{' '}
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Accordion className={`mt-3 ${items[7] === true ? '' : 'd-none'}`}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" ref={(el) => questionRef.push(el)}>
                      8. Quiero acceder a Libelista desde una aplicación.
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p ref={(el) => answerRef.push(el)}>
                        Puedes descargarte nuestra aplicación Libelista para Android e iOS.{' '}
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Accordion className={`mt-3 ${items[8] === true ? '' : 'd-none'}`}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" ref={(el) => questionRef.push(el)}>
                      9. No puedo descargar un ebook.
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div ref={(el) => answerRef.push(el)}>
                        <p>
                          Puedes descargar los libros electrónicos desde tu ordenador desde el “Rincón de lectura”
                          pulsando el botón “Descargar”.{' '}
                        </p>
                        <p>
                          Puede ser que no se descargue al no tener un ID de Adobe y tener instalado y autorizado el
                          programa Adobe Digital Editions. Averigua si tu ordenador tiene este programa. Si no lo
                          tienes, puedes descargártelo en el siguiente enlace:{' '}
                          <span className="MsoHyperlink">
                            <a href="https://www.adobe.com/es/solutions/ebook/digital-editions.html">
                              https://www.adobe.com/es/solutions/ebook/digital-editions.html
                            </a>
                          </span>
                        </p>
                        <p> </p>
                        <p>
                          Si ya tienes el programa Adobe Digital Editions y el ebook aun sigue sin descargarse, prueba
                          de hacerlo desde la App Libelsta.{' '}
                        </p>
                        <p> </p>
                        <p>
                          Si necesitas más información de cómo descargar un ebook, clica en este enlace:{' '}
                          <span className="MsoHyperlink">
                            <a href="https://www.libelista.com/ebook/">https://www.libelista.com/ebook/</a>
                          </span>
                        </p>
                        <p> </p>
                        <p>Si necesitas ayuda, contacta con info@libelista.com.</p>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Accordion className={`mt-3 ${items[9] === true ? '' : 'd-none'}`}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" ref={(el) => questionRef.push(el)}>
                      10. ¿Debo elegir una librería en el proceso de registro?.
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div ref={(el) => answerRef.push(el)}>
                        <p>
                          Libelista tiene como objetivo fomentar el comercio independiente y de proximidad. Vinculándote
                          a una librería conseguirás que ésta reciba una comisión por cada compra que realices.{' '}
                        </p>
                        <p>
                          En el caso que no quieras seleccionar ninguna de las librerías de la red, podrás elegir la
                          opción de que Libelista seleccione al azar a qué librería destinar los beneficios de tu
                          pedido.
                        </p>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Accordion className={`mt-3 ${items[10] === true ? '' : 'd-none'}`}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" ref={(el) => questionRef.push(el)}>
                      11. ¿Puedo cambiar mi librería beneficiada?
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p ref={(el) => answerRef.push(el)}>
                        Puedes cambiar tantas veces como quieras de librería desde Usuario →Mi cuenta, en el apartado
                        “Cambiar la librería beneficiada”.{' '}
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Accordion className={`mt-3 ${items[11] === true ? '' : 'd-none'}`}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" ref={(el) => questionRef.push(el)}>
                      12. He olvidado mi contraseña.
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p ref={(el) => answerRef.push(el)}>
                        Podrás recuperar tu contraseña marcando la opción “he olvidado mi contraseña” una vez hayas
                        intentado acceder a tu cuenta desde “Usuario”. Se te pedirá un correo electrónico para enviarte
                        un email y desde allí, poder cambiar la contraseña.{' '}
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Accordion className={`mt-3 ${items[12] === true ? '' : 'd-none'}`}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" ref={(el) => questionRef.push(el)}>
                      13. Soy una librería y quiero formar parte de Libelista.
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div ref={(el) => answerRef.push(el)}>
                        <p>
                          ¡En Libelista siempre estamos ansiosos por ampliar nuestra familia! Nos encanta descubrir
                          nuevas librerías y que formen parte de nuestro proyecto.{' '}
                        </p>
                        <p>
                          Contacta con{' '}
                          <span className="MsoHyperlink">
                            <a href="mailto:librerias@libelista.com">librerias@libelista.com</a>
                          </span>{' '}
                          y te explicaremos todos los pasos a seguir.{' '}
                        </p>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Accordion className={`mt-3 ${items[13] === true ? '' : 'd-none'}`}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" ref={(el) => questionRef.push(el)}>
                      14. Quiero afiliarme a Libelista.
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div ref={(el) => answerRef.push(el)}>
                        <p>
                          <strong></strong>Libelista ofrece la posibilidad de afiliarte. Esto significa que podrás
                          vender productos desde tu página web o cualquier otra plataforma y tener ingresos de forma
                          rápida y eficaz.{' '}
                        </p>
                        <p>
                          Si eres una editorial, booktuber, youtuber, instagramer, bloguero, y estás interesado en
                          afiliarte, contacta con <strong>librerias@libelista.com</strong> y te explicaremos cómo
                          hacerlo. <br />
                        </p>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </Col>
        <Col xs={3} lg={3} className="mt-4 pr-0">
          <img src={FaqsImg} alt="faqs.png" className="infoImg" />
        </Col>
      </Row>
    </div>
  );
};

export default Faqs;
