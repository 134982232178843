import * as React from 'react';
import {
  getAvailabilityNotificatonSubscriptions as get,
  deleteEmailAvailabilityNotificationSubscription as del,
} from '../../../../api/products';
import BackofficeTable from '../../../../components/backoffice/table/index';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
const EmailSubscriptionsTable = ({ data, ActionsMenu, loadData }) => {
  const headers = {
    producto_name: 'Nombre del Producto',
    email: 'Correo',
    producto: 'Numero del Producto',
    created: 'Fecha de creación',
    is_notify: 'Estado',
  };

  loadData.colSpan = Object.keys(headers).length;
  const emailSubscriptionsTableProps = {
    title: 'Lista de Suscripciiones a reabastecimiento de libros',
    data,
    headers,
    ActionsMenu,
    extraFormat: { is_child: 'yesOrNo', active: 'status', is_notify: 'notification' },
    loadData,
    filterParameterName: 'search',
    filterRelatedFields: ['title', 'email'],
  };
  return <BackofficeTable {...emailSubscriptionsTableProps} />;
};
EmailSubscriptionsTable.request = {
  get,
  del,
};
EmailSubscriptionsTable.itemClass = 'Listas';
EmailSubscriptionsTable.requestParameter = 'id';
const EmailSubscriptionsTablePage = () => {
  const backOfficesPageManagerProps = { PageViewer: EmailSubscriptionsTable, filterParameterName: 'search' };
  return <BackofficePagesManager {...backOfficesPageManagerProps} />;
};
export default EmailSubscriptionsTablePage;
