import { getLibelistaLists as get, deleteLibelistaList as del } from '../../../../api/libelista';
import React from 'react';
import BackofficeTable from '../../../../components/backoffice/table/index';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
const ListsTable = ({ data, ActionsMenu, loadData }) => {
  const headers = {
    title: 'Título',
    is_child: '¿Es sublista?',
    active: 'Estado',
  };

  loadData.colSpan = Object.keys(headers).length;
  const listsTableProps = {
    title: 'Listas',
    data,
    headers,
    ActionsMenu,
    extraFormat: { is_child: 'yesOrNo', active: 'status' },
    loadData,
    filterParameterName: 'title',
    filterRelatedFields: ['title'],
  };
  return <BackofficeTable {...listsTableProps} />;
};
ListsTable.request = {
  get,
  del,
};
ListsTable.itemClass = 'Listas';
ListsTable.requestParameter = 'id';
const ListsTablePage = () => {
  const backOfficesPageManagerProps = { PageViewer: ListsTable, filterParameterName: 'title' };
  return <BackofficePagesManager {...backOfficesPageManagerProps} />;
};
export default ListsTablePage;
