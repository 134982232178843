/**
 * @function getSelectDataHandlers
 * @param {Function} requestDatumCallback Call requesting a specific resource of type X
 * @param {Function} requestDataCallback Call requesting a list of resources of type X
 * @param {Function} defaultOptionsSetterCallback Local setter that should accept a {defaultOptions, defaultOption} object
 * @param {{ string, string }} { fieldNameWithDisplayValue, fieldNameWithRequestValue, fieldNameWithExtraLabelInformation }
 * fieldNameWithRequestValue html option's display value
 * fieldNameWithRequestValue html option's value attribute
 * fieldNameWithExtraLabelInformation html option's extra information shown on options' label between (),
 * Can be disabled by passing any falsy value Ex: ''
 */
const noOption = { label: 'Sin asignar', value: '' };
const FALLBACK_RESPONSE = {};
export const getSelectDataHandlers = (
  requestDatumCallback,
  requestDataCallback,
  defaultOptionsSetterCallback,
  {
    fieldNameWithDisplayValue,
    fieldNameWithRequestValue,
    fieldNameWithExtraLabelInformation = fieldNameWithRequestValue,
  }
) => {
  /**
   * Uses requestDataCallback both in multi and single select elements
   * to generate a list of options as promise resolution for react-select component
   * @function getSelectData
   * @param searchValue string used as a request query param value for filtering returning data if passed
   * @typedef { {label: string, value: string} } Option
   * @returns {Promise<Array<Option>>}
   */
  const getSelectData = async (searchValue) => {
    try {
      const reponseWithDataSet = (await requestDataCallback(searchValue)) || FALLBACK_RESPONSE;
      const { status, data } = reponseWithDataSet;
      const { results } = data;
      if ([200, 201].includes(status)) {
        /**
         * Order matters because if results exist then data exists but
         * it does not hold true the other way around
         */
        const requestData = results || data;
        const optionsData = requestData.map(
          ({
            [fieldNameWithDisplayValue]: label,
            [fieldNameWithRequestValue]: value,
            [fieldNameWithExtraLabelInformation]: extraLabelInformation,
          }) => {
            label = label ? (extraLabelInformation ? `${label} (${extraLabelInformation})` : label) : '...';
            return {
              label,
              value: String(value),
            };
          }
        );
        optionsData.unshift(noOption);
        return optionsData;
      }
    } catch (exception) {
      console.log(exception);
    }
  };
  /**
   * Uses requestDatumCallback and getSelectData internally to generate defaultData
   * which is then structured as a {defaultOption, defaultOptions} object passed to defaultOptionsSetterCallback
   * If searchValue is passed the data is taken from these values otherwise it'll default
   * to the values getSelectData returns without it
   * @function setSelectDefaultData
   * @param {string | Array<string>} searchValue
   * @returns \{Promise<void>}
   */
  const setSelectDefaultData = async (searchValue) => {
    try {
      const isMulti = Array.isArray(searchValue);
      let defaultOption = isMulti ? [noOption] : noOption;
      const defaultDataExists = isMulti ? searchValue.length : searchValue;
      if (defaultDataExists && requestDatumCallback) {
        let iteration = 0;
        let requestValue = undefined;
        let responseWithDatum = FALLBACK_RESPONSE;
        do {
          requestValue = isMulti ? searchValue[iteration] : searchValue;
          responseWithDatum = (await requestDatumCallback(requestValue)) || FALLBACK_RESPONSE;
          let {
            [fieldNameWithDisplayValue]: label,
            [fieldNameWithRequestValue]: value,
            [fieldNameWithExtraLabelInformation]: extraLabelInformation,
          } = responseWithDatum?.data;
          label += extraLabelInformation ? ` (${extraLabelInformation})` : '';
          defaultOption = isMulti ? [...defaultOption, { label, value }] : { label, value };
          iteration++;
        } while (isMulti && iteration < searchValue.length);
      }
      const defaultOptions = await getSelectData(
        requestDatumCallback && defaultOption?.label !== 'Sin asignar' ? defaultOption?.label : searchValue
      );
      // add all potentially missing default options in defaultOptions list
      if (defaultDataExists && isMulti) {
        for (let iteration = 0; isMulti && iteration < defaultOption.length; iteration++) {
          const optionThatShouldBeOnTheDefaultOptions = defaultOption[iteration];
          const id = optionThatShouldBeOnTheDefaultOptions.value;
          const optionFound = defaultOptions?.find(({ value }) => {
            // == is intended
            return value === id;
          });
          defaultOption.label += ` (${defaultOption.value})`;
          !optionFound && defaultOptions.push(optionThatShouldBeOnTheDefaultOptions);
        }
      }
      defaultOptionsSetterCallback({ defaultOptions, defaultOption });
    } catch (exception) {
      console.log(exception);
    }
  };
  return { getSelectData, setSelectDefaultData };
};
