import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Alert, Button } from 'react-bootstrap';
import { InputCustom } from '../../components/input';
import { createGiftCard } from 'api/ecommerce';
import { setGiftCard, cartSelector, removeGiftCard } from 'slices/cart';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './giftCard.scss';
import { userSelector } from 'slices/user';

const GiftCard = () => {
  const [coupon, setCoupon] = useState('');
  const [discount, setDiscount] = useState('');
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const { hasGiftCard } = useSelector(cartSelector);
  const { isLogged } = useSelector(userSelector);

  const handleCreateGiftCard = async (e) => {
    e.preventDefault();
    if (isLogged) {
      try {
        const payload = {
          coupon: coupon,
          discount: parseFloat(discount),
          type_discount: 1,
        };
        const { status, data } = await createGiftCard(payload);
        if (status === 201) {
          dispatch(setGiftCard(data.item.gift_card_coupon));
        }
        setCoupon('');
        setDiscount('');
      } catch (e) {
        console.log(e);
      }
    } else {
      dispatch(removeGiftCard());
    }
  };

  const string = 'aquí';

  const noticeLogout = {
    margin: '0',
    display: 'flex',
    marginRight: '35px',
  };

  return (
    <div className="containerGiftCard">
      <h1 className="page-title static-title styleTitle">Comprar tarjeta de regalo</h1>
      {isLogged ? (
        <>
          <p className="description">
            Hazle un detalle a tu ser querido, brindandole momentos de conocimiento y cultura.
          </p>
          <div className="giftDiv">
            <Form onSubmit={handleCreateGiftCard}>
              <div className="giftDiv d-flex">
                <Row className="d-flex flex-column inputForm">
                  <Col md={6} lg={6} xs={6} className="containerInput">
                    <p className="mb-0 w-100">Nombre tarjeta de regalo</p>
                    <InputCustom
                      type="text"
                      value={coupon}
                      onChange={(event) => {
                        setCoupon(event.target.value);
                      }}
                      placeholder="Escribe el nombre de la tarjeta de regalo"
                      className="styleInput"
                      disabled={hasGiftCard ? !disabled : disabled}
                    />
                  </Col>
                  <Col md={6} lg={6} xs={6} className="containerInput">
                    <p className="mb-0">Precio descuento</p>
                    <InputCustom
                      type="text"
                      value={discount}
                      onChange={(event) => {
                        setDiscount(event.target.value);
                      }}
                      placeholder="Escribe el precio de descuento"
                      className="styleInput"
                      disabled={hasGiftCard ? !disabled : disabled}
                    />
                  </Col>
                </Row>
                <div className="my-3 d-flex justify-content-center align-items-center">
                  {hasGiftCard ? (
                    <div className="styleButton">
                      <p>Ya tienes una tarjeta de regalo agregada al carro.</p>
                      <Link to="/carrito" className="btn btn-info">
                        Proceder compra
                      </Link>
                    </div>
                  ) : (
                    <div className="styleButton">
                      <input role="button" className="btn btn-info " type="submit" value="Crear tarjeta" />
                    </div>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </>
      ) : (
        <>
          <Row style={noticeLogout}>
            <Col>
              <Alert className="flex-column" show={true} variant="info">
                <Alert.Heading>Información incompleta</Alert.Heading>
                <p>Debes loguearte o crear una cuenta para poder comprar una tarjeta de regalo.</p>
                <hr className="w-100" />
                <div className="d-flex w-100">
                  <p>Inicia sesión o registrate haciendo click.</p>
                  <Link to="/login" className="ml-2">
                    {string.toUpperCase()}
                  </Link>
                </div>
              </Alert>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default GiftCard;
