import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Collapse } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";

import "./libraries.scss";
import { getLibraries } from "../../api/libelista";
import Head from "../../components/metatags_helmet/metatags";
import LibraryCard from "../../components/libraryCard/libraryCard";
import { InputCustom, SelectInputCustom } from "../../components/input";
import { BiSearchAlt2 } from "react-icons/bi";

const Libraries = () => {
  const [libraries, setLibraries] = useState([]);
  const [address, setAddress] = useState("");
  const [locality, setLocality] = useState("");
  const [open, setOpen] = useState(()=> {
    if (window.innerWidth > 992) {
      return true
    }
    return false
  });
  const [width, setWidth] = useState(window.innerWidth);
  const title = "Librerías";
  const description =
    "¡Encuentra todas las librerías que forman parte de la red Libelista!";

  async function getLibrariesAsoc() {
    const rs = await getLibraries(address, locality);
    if (rs.status === 200) {
      setLibraries(rs.data);
    }
  }

  useEffect(() => {  
    const handleResizeWindow = () => {
      setWidth(prevState => {
        prevState = window.innerWidth
        return prevState
      });
      checkOpen()
      return () => {
        window.removeEventListener("resize", handleResizeWindow);
      };
    }  
    window.addEventListener("resize", handleResizeWindow);
   }, []);

  const checkOpen = () => {
    if (width >= 990 && width <= 993) {
      setOpen(false)
    } 
    if (width > 992) {
      setOpen(true)
    }
  }  

  useEffect(() => {
    checkOpen()
  }, [width]);
  const goUp = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getLibrariesAsoc();
    goUp();
  }, []);

  return (
    <Container fluid className="justify-content-center d-flex mainDiv">
      <Head description={description} title={title} noIndex={true} />
      <Row className="libraries-row justify-content-center">
        <Col md={0} lg={3} className="filter-col">
          <h1 className="spectral mt-4">Librerías</h1>          
          <hr />
          {width < 992 && (
            <BiSearchAlt2 
              className="blue mb-3"
              onClick={()=> setOpen(!open)}
            />
          )}
          <Collapse in={open}>
            <div>
              <InputCustom
                type="text"
                placeholder="Dirección,ciudad, localidad..."
                value={address}
                onChange={(e)=>{setAddress(e.target.value)}}
              />
              <p>Filtrar por localidad</p>
              {/* <Select options={options} className="mb-3" /> */}
              <InputCustom
                type="text"
                placeholder="Localidad"
                value={locality}
                onChange={(e)=>{setLocality(e.target.value)}}
              />
              <Button onClick={getLibrariesAsoc}>Filtrar</Button>
              <Link to={"/bookshops/map"}>
                <Button>Ir al mapa de librerías</Button>
              </Link>
            </div>
          </Collapse>
        </Col>
        <Col md={12} lg={9} className="">
          <Row className="justify-content-center">
            {libraries.map((item, i) => (
              <Col sm={12} md={6} lg={4} key={i}>
                <LibraryCard library={item} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Libraries;
