import * as React from "react";
import { Link } from "react-router-dom";

const HeadingLine = ({ line }) => (
  <div className="row month-books-title">
    <div className="col-md-12">
      <div className="section">
        <h4>{line}</h4>
      </div>
    </div>
  </div>
);
const Body = ({ title, text }) => (
  <div className="media-body">
    <h3 className="media-heading">
      <a href="/#" className="">
        {title}
      </a>
    </h3>
    <span
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  </div>
);
const Media = ({ src, alt, path }) => (
  <div className="media-left pr-3">
    <Link to={`/productos/libro/${path}`}>
      <img className="big" width="200px" src={src} alt={alt} />
    </Link>
    <div className="text-center my-3">
      <Link
        className="btn btn-primary"
        role="button"
        to={`/productos/libro/${path}`}
      >
        Comprar
      </Link>
    </div>
  </div>
);
export const BookPost = ({ title, featured_paragraph, detail_featured_book, description }) => {
  const bookImageUrl = detail_featured_book?.get_url;
  const bookDetailUrl = detail_featured_book?.url;
  return (
    <div className="col-md-12">
      <HeadingLine line={featured_paragraph} />
      <div className="row month-books mb-3">
        <div className="col-md-12">
          <div className="d-flex flex-column align-items-center flex-md-row align-items-md-start ">
            <Media src={bookImageUrl} path={bookDetailUrl} alt={title} />
            <Body text={description} title={title} />
          </div>
        </div>
      </div>
    </div>
  );
};
