import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import "./adminFooter.scss"

const AdminFooter = () => {

  return (
    <footer style={{background: '#4B95a6'}}>
      <Container fluid>
        <Row className="adminFooterRow">
          <Col lg={12}>
            <p>© 2021 - Libelista </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default AdminFooter;