import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import ActionsForm from '../../../../components/backoffice/actionsForm/index';
import { getSelectDataHandlers } from '../../../../utils/backoffice/getSelectDataHandlers';
import {
  getRestockNotificationSubscription as get,
  updateEmailAvailabilityNotificationSubscription as edit,
  postNotifyAvailability as create,
} from '../../../../api/products';
import { formatDate } from '../../../../utils/formatDate';
import SectionLoader from 'components/loader/SectionLoader';
const EmailSuscroptionsItem = ({ data, loadData }) => {
  const { action } = useParams();
  const options = [
    { label: 'Pendiente', value: false },
    { label: 'Notificado', value: true },
  ];
  const inputFields = [
    {
      label: 'Nombre del producto',
      name: 'producto_name',
      type: 'text',
    },
    {
      label: 'Correo',
      name: 'email',
      type: 'email',
    },
    {
      label: 'Número del producto',
      name: 'producto',
      type: 'text',
    },
    {
      label: 'Fecha de creación',
      name: 'created',
      type: 'datetime-local',
    },
    {
      label: 'Notificacion',
      name: 'is_notify',
      type: 'select',
      defaultValue: options[data?.is_notify ? 1 : 0],
      options,
      isDisabled: action === 'detail',
    },
  ];
  const actionsFormProps = {
    inputFields,
    title: 'Entrada de Libro del Mes',
    subsectionPath: 'email-subscriptions',
    data: data || {},
    requests: { edit, create },
    extraFormat: { created: 'datetime-local-format' },
    action,
    loadData,
  };
  return action === 'create' || data ? <ActionsForm {...actionsFormProps} /> : <SectionLoader />;
};
EmailSuscroptionsItem.request = {
  get,
};
const EmailSuscroptionsItemPage = () => <BackofficePagesManager PageViewer={EmailSuscroptionsItem} />;
export default EmailSuscroptionsItemPage;
