import * as React from 'react';
import BackofficeTable from '../../../../components/backoffice/table/index';
import { getAllAuthors, deleteAuthor } from '../../../../api/products';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';

const AuthorsTable = ({ data, ActionsMenu, loadData }) => {
  const headers = {
    id: 'I.D.',
    nombre_autor: 'Nombre del Autor',
    url: 'U.R.L.',
  };
  loadData.colSpan = Object.keys(headers).length;
  const authorsTableProps = {
    title: 'Autores',
    data,
    headers,
    ActionsMenu,
    loadData,
    filterParameterName: 'nombre_autor',
    filterRelatedFields: ['nombre_autor'],
  };
  return <BackofficeTable {...authorsTableProps} />;
};

AuthorsTable.request = {
  get: getAllAuthors,
  del: deleteAuthor,
};
AuthorsTable.itemClass = 'autor';
AuthorsTable.requestParameter = 'url';

const AuthorsTablePage = () => {
  const backOfficesPageManagerProps = { PageViewer: AuthorsTable, filterParameterName: 'nombre_autor' };
  return <BackofficePagesManager {...backOfficesPageManagerProps} />;
};
export default AuthorsTablePage;
