import React from 'react';
import { getAllUsers as get, deleteUser as del } from '../../../../api/users';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import BackofficeTable from '../../../../components/backoffice/table/index';
const UsersTable = ({ data, ActionsMenu, loadData }) => {
  const headers = {
    email: 'Correo electrónico',
    name: 'Nombre',
    last_name: 'Apellido',
    'bookstore.name': 'Librería beneficiada',
    is_staff: 'Tipo de usuario',
    is_active: 'Estado',
  };

  loadData.colSpan = Object.keys(headers).length;
  const usersTableProps = {
    title: 'Usuarios',
    data,
    headers,
    ActionsMenu,
    extraFormat: { is_active: 'status', is_staff: 'staff' },
    loadData,
    filterParameterName: 'search',
    filterRelatedFields: ['Nombre de usuario', 'name', 'last_name', 'email'],
  };
  return <BackofficeTable {...usersTableProps} />;
};
UsersTable.request = {
  get,
  del,
};
UsersTable.itemClass = 'Usuarios';
UsersTable.requestParameter = 'id';
const UsersTablePage = () => {
  const backOfficesPageManagerProps = { PageViewer: UsersTable, filterParameterName: 'search' };
  return <BackofficePagesManager {...backOfficesPageManagerProps} />;
};
export default UsersTablePage;
