import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsive } from "../../constants";

// own components
import ItemList from "../../components/itemList/item";

const ListHome = (props) => {
  const { data } = props;

  function preloadImages(element) {
    let img = new Image();
    img.src = element;
  }

  useEffect(() => {
    if (data.productos) {
      const productImgs = data.productos.map(function (x) {
        return x.get_url;
      });
      productImgs.forEach((element) => preloadImages(element));
    }
  }, []);

  return (
    <section className="margin-bottom-md list-section">
      <Row className="p-0">
        <Col xs={12} sm={10}>
          <h3>{data.nombre}</h3>
        </Col>
        <Col xs={12} sm={2} className="v-center">
          <Link className="link-more" to={`/lista/materias/${data.id}`}>
            Ver todo
          </Link>
        </Col>
      </Row>
      <Col xs={12} sm={12} className="p-0">
        <Carousel
          responsive={responsive}
          slidesToSlide={1}
          minimumTouchDrag={40}
          draggable
          infinite={true}
          centerMode={true}
          swipeable
        >
          {data.productos.map((productDetails, index) => (
            <div key={`productItem#${index}`} className="slide-div">
              <ItemList data={productDetails} />
            </div>
          ))}
        </Carousel>
      </Col>
    </section>
  );
};

export default ListHome;
