import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Container, Button, Tabs, Tab, Form } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FiGift } from 'react-icons/fi';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';

import {
  loginSuccess,
  setShippingAddress,
  setBillingAddress,
  setPickUpLibrary,
  setFirstName,
  setLastName,
  userSelector,
} from '../../slices/user';
import { companySelector } from '../../slices/company';
import { cartSelector, addItem, setGiftData, setStorePickup, shippingFree, clear } from '../../slices/cart';
import { loginApi } from '../../api/login';
import {
  getCart,
  addItemCart,
  settingStorePickup,
  removeSettingStorePickup,
  removeItemCart,
} from '../../api/ecommerce';
import { getCountries } from '../../api/company';
import { postRegisterUser } from '../../api/libelista';
import { saveChanges } from '../../api/users';
import SearchSelect from '../../components/searchSelect';
import { InputCustom } from '../../components/input';
import { COMPANY } from '../../constants';

import heartIcon from '../../assets/img/ico_heart2.png';
import './cart.scss';

const Shipping = (props) => {
  const [addDirection, setAddDirection] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isGift, setIsGift] = useState(false);
  const [emailLogin, setEmailLogin] = useState('');
  const [password, setPassword] = useState('');
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [registerName, setRegisterName] = useState('');
  const [emailRegister, setEmailRegister] = useState('');
  const [registerSurname, setRegisterSurname] = useState('');
  const [passwordRegister, setPasswordRegister] = useState('');
  const [password2, setPassword2] = useState('');
  const [receiveInvoices, setReceiveInvoices] = useState(false);
  const [acceptConditions, setAcceptConditions] = useState(false);
  const [receiveNewsOffers, setReceiveNewsOffers] = useState(false);
  const [defaultBenefitedLibrary, setDefaultBenefitedLibrary] = useState(true);
  const [selectedBenefitedLibrary, setSelectedBenefitedLibrary] = useState(null);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [searchSelectLibrary, setSearchSelectLibrary] = useState(null);
  const [validData, setValidData] = useState([false, 'Ingrese datos faltantes']);
  const [shippingData, setShippingData] = useState({});
  const [giftInfo, setGiftInfo] = useState({
    email: '',
    name: '',
    surname: '',
    message: '',
  });
  const { isLogged, benefitedBookstore, shippingAddress, billingAddress, firstName, lastName, email, pickUpLibrary } =
    useSelector(userSelector);
  const history = useHistory();
  const {
    countItems,
    subtotal,
    iva,
    total,
    shippingCosts,
    items,
    storePickup,
    isGiftOrder,
    hasGiftCard,
    giftCard,
    setGiftCard,
  } = useSelector(cartSelector);
  const dispatch = useDispatch();
  const [finalPrice, setFinalPrice] = useState((parseFloat(total) + parseFloat(shippingCosts)).toFixed(2));
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const [showDiscountDetail, setShowDiscountDetail] = useState(false);
  const { state } = props.location;
  const { code, name: empresa } = useSelector(companySelector);
  let url = window.location.search;
  const location = useLocation();

  useEffect(() => {
    setShippingData(shippingAddress);
    setName(firstName);
    setSurname(lastName);
  }, []);

  useEffect(() => {
    async function handleCountries() {
      const response = await getCountries();
      if (response.status === 200) {
        const countries = response.data.map((item) => ({
          value: item.codigo,
          label: item.nombre,
        }));
        setCountries(countries);
      }
    }
    handleCountries();
    window.document.title = 'Carrito de compras | ' + COMPANY;
    if (url.substr(1) == 'gift') {
      setIsGift(true);
    }
    handleTabChange('first');
  }, []);

  const onLogin = async (event) => {
    if (event.keyCode === 13 || event.type === 'click') {
      setInvalidCredentials(false);
      const payload = { username: emailLogin, password };
      const { status, data } = await loginApi(payload);
      if (status === 200) {
        localStorage.setItem('access', data.access);
        localStorage.setItem('refresh', data.refresh);
        localStorage.setItem('type_user', data.type_user);
        localStorage.setItem('permissions', data.permissions);
        dispatch(loginSuccess());
        dispatch(setBillingAddress(data.direccion_facturacion));
        dispatch(setShippingAddress(data.direcciones_envio));
        const cart = await getCart();
        if (cart.status === 200) {
          if (cart.data.items) {
            for (const item of cart.data.items) {
              if (item.producto === null && item.created_gift_card !== null) {
                dispatch(setGiftCard(item.created_gift_card));
              } else if (item.producto !== null && item.created_gift_card !== null) {
                dispatch(addItem(item.producto));
                dispatch(setGiftCard(item.created_gift_card));
              } else {
                dispatch(addItem(item.producto));
              }
            }
          }
        } else {
          console.log('error');
        }
        for (const item of items) {
          await addItemCart({ item: item.id, quantity: item.quantity });
        }
        history.push('/carrito');
      } else if (status === 400) {
        setInvalidCredentials(true);
      } else {
        console.log('Error');
      }
    }
  };

  const registerUser = async () => {
    const payload = {
      name: name,
      email: emailRegister,
      surname: surname,
      password: password,
      password2: password2,
      receive_invoices: receiveInvoices,
      receive_news_offers: receiveNewsOffers,
      accept_conditions: acceptConditions,
    };
    try {
      const rs = await postRegisterUser(payload);
      if (rs.status === 201) {
        localStorage.setItem('access', rs.data.access);
        localStorage.setItem('refresh', rs.data.refresh);
        dispatch(loginSuccess());
        for (const item of items) {
          await addItemCart({ item: item.id, quantity: item.quantity });
        }
        history.push('/');
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (location.state?.discount) {
      applyDiscount(location.state?.percentage, location.state?.typeDiscount);
    }
  }, [total]);

  const applyDiscount = (discount, typeDiscount) => {
    if (typeDiscount === 0) {
      setFinalPrice((finalPrice - finalPrice * (discount / 100)).toFixed(2));
      setAppliedDiscount(discount);
      setShowDiscountDetail(true);
    } else {
      setFinalPrice((finalPrice - discount).toFixed(2));
      setShowDiscountDetail(true);
      setAppliedDiscount(discount);
    }
  };

  const checkData = () => {
    if (shippingData !== undefined) {
      let properties = ['address', 'postal_code', 'locality', 'phone'];
      for (let i of properties) {
        if (shippingData[`${i}`] === undefined || shippingData[`${i}`] == '') {
          setValidData([false, 'Ingrese datos faltantes']);
          return '';
        }
      }
      setValidData([true, shippingData.address + ', ' + shippingData.postal_code + ', ' + shippingData.locality]);
    }
  };

  useEffect(() => {
    checkData();
  }, [shippingData]);

  const saveShippingAddress = (value) => {
    if (!addDirection) {
      dispatch(setShippingAddress(selectedBenefitedLibrary));
    }
    handleChangeSave(value);
    dispatch(setShippingAddress(value));
    dispatch(setFirstName(name));
    dispatch(setLastName(surname));
    alert('Dirección guardada correctamente!');
  };

  const handleChangeSave = async (values) => {
    let payload = {
      name: name,
      last_name: surname,
      email: email,
      direccion_facturacion: {
        address: billingAddress?.address,
        country: billingAddress?.country,
        phone: billingAddress?.phone,
        province: billingAddress?.province,
        locality: billingAddress?.locality,
        postal_code: billingAddress?.postal_code,
        email: billingAddress?.email,
      },
      direccion_envio: {
        address: values.address,
        country: values.country,
        phone: values.phone,
        province: values.province,
        locality: values.locality,
        postal_code: values.postal_code,
      },
      benefited_bookstore: benefitedBookstore?.id,
    };
    const rs = await saveChanges(payload);
  };

  useEffect(() => {
    setSelectedBenefitedLibrary(benefitedBookstore);
  }, [total]);

  const selectedLibrary = (library) => {
    setSearchSelectLibrary(library);
    setSelectedBenefitedLibrary(library);
    setDefaultBenefitedLibrary(false)
  };

  useEffect(() => {
    dispatch(setPickUpLibrary(selectedBenefitedLibrary));
  }, [selectedBenefitedLibrary]);

  useEffect(() => {
    const storeData = {
      store_pickup: storePickup,
      items,
      code,
      empresa,
      user: firstName,
    };
    settingStorePickup(storeData);
  }, [storePickup]);

  const handleTabChange = (key) => {
    if (key === 'first') {
      dispatch(setStorePickup(false));
      dispatch(setPickUpLibrary([]));
      dispatch(shippingFree(false));
    } else {
      dispatch(setStorePickup(true));
      dispatch(setPickUpLibrary(selectedBenefitedLibrary));
      dispatch(shippingFree(true));
    }
  };

  const disableNext = () => {
    if (selectedBenefitedLibrary === null) {
      return true;
    } else if (selectedBenefitedLibrary) {
      if (storePickup && Object.keys(selectedBenefitedLibrary).length == 0) {
        return true;
      }
    } else if (addDirection || (!storePickup && !validData[0])) {
      return true;
    }
    return false;
  };

  const totalWithoutDiscount = (parseFloat(total) + parseFloat(shippingCosts)).toFixed(2);

  return (
    <Container fluid className="sm-fluid mainDiv">
      <Row className="justify-content-center">
        <Col md={9}>
          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-tabs wizard">
                <li className="grayArrow">1. Cesta</li>
                <li className="active">2. Entrega</li>
                <li>3. Pago</li>
              </ul>
            </div>
          </div>

          {isLogged ? (
            <>
              {isGift ? (
                <>
                  <div className="">
                    <h2 onClick={() => console.log(giftInfo)} className="mt-3">
                      Datos del destinatario
                    </h2>
                    <div className="giftDiv">
                      <Row className="">
                        <Col md={6} lg={6} xs={6} className="">
                          <p className="mb-0">Nombre del destinatario</p>
                          <InputCustom
                            onChange={(e) => {
                              return setGiftInfo({
                                ...giftInfo,
                                name: e.target.value,
                              });
                            }}
                            type="text"
                          />
                        </Col>
                        <Col md={6} lg={6} xs={6} className="">
                          <p className="mb-0">Apellido del destinatario</p>
                          <InputCustom
                            onChange={(e) => {
                              return setGiftInfo({
                                ...giftInfo,
                                surname: e.target.value,
                              });
                            }}
                            type="text"
                          />
                        </Col>
                      </Row>
                      <Row className="">
                        <Col md={12} lg={12} xs={12} className="">
                          <p className="mb-0">Email del destinatario</p>
                          <InputCustom
                            onChange={(e) => {
                              return setGiftInfo({
                                ...giftInfo,
                                email: e.target.value,
                              });
                            }}
                            type="text"
                          />
                          <p className="mb-0">Mensaje</p>
                          <InputCustom
                            onChange={(e) => {
                              return setGiftInfo({
                                ...giftInfo,
                                message: e.target.value,
                              });
                            }}
                            type="textarea"
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <p className="textInfo mt-4">
                    Los datos de contacto que nos facilites serán usados para crear una cuenta de usuario para el
                    destinatario. Este paso es necesario para poder tramitar correctamente el pedido y no serán
                    utilizados en ningún caso para el envío de publicidad ni con finalidades comerciales..
                  </p>
                  <p className="textInfo my-4">
                    El destinatario recibirá un correo electrónico en el que se le notificará que le has hecho un
                    obsequio, así como los títulos que le has regalado. Si quieres saber más información consulta
                    nuestras
                    <Link to={'/faqs'}> preguntas frecuentes</Link> nuestra
                    <Link to={'/aviso-legal-y-politica-de-privacidad'}> política de privacidad de datos</Link> o
                    <Link to={'/contact'}> ponte en contacto.</Link>
                  </p>
                </>
              ) : (
                ''
              )}

              <Row className="tabs-row">
                <Col md={12} lg={12} xs={12} className="ml-1">
                  <h2 className="mt-3">Forma de entrega</h2>
                  <Tabs className="mt-3" fill defaultActiveKey="first" onSelect={handleTabChange}>
                    <Tab eventKey="first" title="Con envío a domicilio">
                      <h4 className="my-4 pl-3">Dirección y datos de entrega</h4>
                      {!addDirection ? (
                        <Col lg={12} className="mt-2 d-flex">
                          <Form.Control value={validData[1]} className="mr-2 pl-0" disabled />
                          <Button className="white-btn py-2 px-0" onClick={() => setAddDirection(!addDirection)}>
                            EDITAR DIRECCIÓN
                          </Button>
                        </Col>
                      ) : (
                        <>
                          <InputCustom
                            type="text"
                            label="Nombre"
                            placeholder="Nombre"
                            title="Nombre"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          <InputCustom
                            type="text"
                            label="Apellidos"
                            placeholder="Apellidos"
                            title="Apellidos"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                          />
                          <InputCustom
                            type="text"
                            label="Dirección"
                            placeholder="Dirección"
                            title="Dirección"
                            value={shippingData.address}
                            onChange={(e) => {
                              setShippingData({
                                ...shippingData,
                                address: e.target.value,
                              });
                            }}
                          />
                          <InputCustom
                            type="text"
                            name="postalcode"
                            label="Código postal"
                            placeholder="Código postal"
                            title="Código postal"
                            value={shippingData.postal_code}
                            onChange={(e) => {
                              setShippingData({
                                ...shippingData,
                                postal_code: e.target.value,
                              });
                            }}
                          />
                          <InputCustom
                            type="text"
                            label="Teléfono"
                            placeholder="Teléfono"
                            title="Teléfono"
                            value={shippingData.phone}
                            onChange={(e) => {
                              setShippingData({
                                ...shippingData,
                                phone: e.target.value,
                              });
                            }}
                          />
                          <InputCustom
                            type="text"
                            label="Localidad"
                            placeholder="Localidad"
                            title="Localidad"
                            value={shippingData.locality}
                            onChange={(e) => {
                              setShippingData({
                                ...shippingData,
                                locality: e.target.value,
                              });
                            }}
                          />
                          <InputCustom
                            type="text"
                            label="Provincia"
                            placeholder="Provincia"
                            title="Provincia"
                            value={shippingData.province}
                            onChange={(e) => {
                              setShippingData({
                                ...shippingData,
                                province: e.target.value,
                              });
                            }}
                          />
                          <label>País</label>
                          <Select
                            placeholder="País"
                            title="País"
                            className="mb-3"
                            options={countries}
                            defaultValue={shippingData.country}
                            value={shippingData.country}
                            onChange={(e) => {
                              setShippingData({
                                ...shippingData,
                                country: { value: e.value, label: e.label },
                              });
                            }}
                          />
                          <div className="w-100 d-flex justify-content-end">
                            <Button
                              className="white-btn py-2 px-0 mr-2"
                              style={{ width: '25%' }}
                              onClick={() => setAddDirection(!addDirection)}
                            >
                              CANCELAR
                            </Button>
                            <Button
                              className="blue-btn py-2 px-0"
                              style={{ width: '35%' }}
                              onClick={() => {
                                setAddDirection(!addDirection);
                                saveShippingAddress(shippingData);
                                checkData();
                              }}
                            >
                              GUARDAR DATOS
                            </Button>
                          </div>
                        </>
                      )}
                    </Tab>
                    <Tab eventKey="second" title="Retirarlo en una tienda ¡Gratis!">
                      <h4 className="my-4 pl-3">Elige dónde retirar tu pedido</h4>
                      <Col lg={10} className="mt-2 d-flex libraryCheck">
                        {benefitedBookstore?.name && (
                          <>
                            <Form.Check
                              type={'checkbox'}
                              name="group1"
                              onChange={() => {
                                setDefaultBenefitedLibrary(!defaultBenefitedLibrary);
                                setSelectedBenefitedLibrary(benefitedBookstore);
                              }}
                              checked={defaultBenefitedLibrary}
                            />
                            <Col lg={7} className="p-2">
                              <p className="m-0">{benefitedBookstore !== undefined && `${benefitedBookstore.name}`}</p>
                              <p>{benefitedBookstore !== undefined && `${benefitedBookstore.address}`}</p>
                            </Col>
                            <img src={heartIcon} alt="icon.png" />
                            <p className="pt-3 pl-2">Tu librería beneficiada</p>
                          </>
                        )}
                      </Col>
                      <Col lg={10} className="mt-2 d-flex">
                        <Form.Check
                          type={'checkbox'}
                          name="group1"
                          onChange={() => {
                            setDefaultBenefitedLibrary(!defaultBenefitedLibrary);
                            setSelectedBenefitedLibrary(searchSelectLibrary);
                          }}
                          checked={!defaultBenefitedLibrary}
                        />
                        {searchSelectLibrary ? (
                          <p className="m-0 pl-2">{searchSelectLibrary.name}</p>
                        ) : (
                          <p className="m-0 pl-2">Seleccionar librería</p>
                        )}
                      </Col>
                      <SearchSelect callback={selectedLibrary} />
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </>
          ) : (
            <Row className="tabs-row mt-3">
              <Col md={12} lg={12} xs={12} className="">
                <h2 className="mt-3">¿Ya eres usuario de Libelista?</h2>
                <div className="giftDiv pl-2">
                  <Row className="">
                    <Col lg={8} className="mt-2">
                      <h3 className="blue">Inicia sesión</h3>
                      <p className="mb-0 mt-4">Correo electrónico*</p>
                      <InputCustom
                        type="text"
                        placeholder="Correo electrónico"
                        onChange={(e) => setEmailLogin(e.target.value)}
                        value={emailLogin}
                      />
                      <p className="mb-0">Contraseña*</p>
                      <InputCustom
                        type="password"
                        placeholder="Contraseña"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                      <Row>
                        {invalidCredentials && (
                          <Col lg={12}>
                            <p style={{ color: '#F28C0F' }}>El usuario o la contraseña son incorrectos</p>
                          </Col>
                        )}
                        <Col className="d-flex justify-content-between mt-3">
                          <Button
                            className="blue-btn px-4 py-0"
                            style={{
                              fontSize: '14px',
                              fontWeight: '500',
                              maxHeight: '40px',
                            }}
                            onClick={onLogin}
                          >
                            INICIAR SESIÓN
                          </Button>
                          <Link to={'/recuperar-contrasena'}>
                            <span className="mt-3 blue">¿Has olvidado tu contraseña?</span>
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>

                <h2 className="mt-3">¿Nuevo cliente?</h2>
                <div className="giftDiv pl-2">
                  <Row className="">
                    <Col lg={8} className="mt-2">
                      <h3 className="blue">Solo debes rellenar los siguientes campos</h3>
                      <p className="mt-2" style={{ fontSize: '14.5px', fontWeight: '500' }}>
                        Los campos marcados con * son obligatorios
                      </p>
                      <p className="mb-0 mt-4">Nombre*</p>
                      <InputCustom
                        type="text"
                        required
                        onChange={(e) => setRegisterName(e.target.value)}
                        value={registerName}
                      />
                      <p className="mb-0">Apellidos*</p>
                      <InputCustom
                        type="text"
                        required
                        onChange={(e) => setRegisterSurname(e.target.value)}
                        value={registerSurname}
                      />
                      <p className="mb-0">Correo electrónico*</p>
                      <InputCustom
                        type="text"
                        required
                        onChange={(e) => setEmailRegister(e.target.value)}
                        value={emailRegister}
                      />
                      <p className="mb-0">Contraseña*</p>
                      <InputCustom
                        type="password"
                        required
                        onChange={(e) => setPasswordRegister(e.target.value)}
                        value={passwordRegister}
                      />
                      <p className="mb-0">Repetir contraseña*</p>
                      <InputCustom
                        type="password"
                        required
                        onChange={(e) => setPassword2(e.target.value)}
                        value={password2}
                      />
                      <div className="mb-4 mt-4 d-flex checkDiv">
                        <Form.Check
                          type={'checkbox'}
                          className="ml-3"
                          onChange={(e) => setReceiveInvoices(!receiveInvoices)}
                          checked={receiveInvoices}
                        />
                        <p>Quiero recibir factura de mis pedidos</p>
                      </div>
                      <Tabs className="mt-3" fill defaultActiveKey="first">
                        <Tab eventKey="first" title="Seleccionar mi librería beneficiada">
                          <SearchSelect className="mb-5" />
                        </Tab>
                        <Tab eventKey="second" title="Prefiero elegir una al final de cada compra">
                          <p className="mt-4 mb-5">
                            Al finalizar el proceso de compra podrás elegir tu librería beneficiada (podrás elegir una
                            distinta en cada pedido que hagas).
                          </p>
                        </Tab>
                      </Tabs>
                      <div className="mt-4 d-flex checkDiv">
                        <Form.Check
                          type={'checkbox'}
                          className="ml-3"
                          onChange={(e) => setReceiveNewsOffers(!receiveNewsOffers)}
                          checked={receiveNewsOffers}
                        />
                        <p>Quiero recibir novedades y ofertas exclusivas</p>
                      </div>
                      <div className="mt-1 d-flex checkDiv">
                        <Form.Check
                          type={'checkbox'}
                          className="ml-3"
                          onChange={(e) => setAcceptConditions(!acceptConditions)}
                          checked={acceptConditions}
                        />
                        <p>
                          He leído, comprendo y acepto el
                          <Link to={'/aviso-legal-y-politica-de-privacidad'}>
                            {' '}
                            Aviso legal y política de privacidad
                          </Link>{' '}
                          y las
                          <Link to={'/condiciones-de-uso'}> Condiciones de uso.</Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>

                {isGift ? (
                  <>
                    <div className="">
                      <h2 className="mt-3">Datos del destinatario</h2>
                      <div className="giftDiv">
                        <Row className="">
                          <Col md={6} lg={6} xs={6} className="">
                            <p className="mb-0">Nombre del destinatario</p>
                            <InputCustom type="text" />
                          </Col>
                          <Col md={6} lg={6} xs={6} className="">
                            <p className="mb-0">Apellido del destinatario</p>
                            <InputCustom type="text" />
                          </Col>
                        </Row>
                        <Row className="">
                          <Col md={12} lg={12} xs={12} className="">
                            <p className="mb-0">Email del destinatario</p>
                            <InputCustom type="text" />
                            <p className="mb-0">Mensaje</p>
                            <InputCustom as="textarea" />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <p className="textInfo mt-4">
                      Los datos de contacto que nos facilites serán usados para crear una cuenta de usuario para el
                      destinatario. Este paso es necesario para poder tramitar correctamente el pedido y no serán
                      utilizados en ningún caso para el envío de publicidad ni con finalidades comerciales..
                    </p>
                    <p className="textInfo my-4">
                      El destinatario recibirá un correo electrónico en el que se le notificará que le has hecho un
                      obsequio, así como los títulos que le has regalado. Si quieres saber más información consulta
                      nuestras
                      <Link to={'/faqs'}> preguntas frecuentes</Link> nuestra
                      <Link to={'/aviso-legal-y-politica-de-privacidad'}> política de privacidad de datos</Link> o
                      <Link to={'/contact'}> ponte en contacto.</Link>
                    </p>
                  </>
                ) : (
                  ''
                )}
                <h2 className="mt-3">Forma de entrega</h2>
                <Tabs className="mt-3" fill defaultActiveKey="first">
                  <Tab eventKey="first" title="Con envío a domicilio">
                    <h4 className="my-4 pl-3">Dirección y datos de entrega</h4>
                    <p className="mb-0 mt-4">País</p>
                    <Select
                      placeholder="País"
                      options={countries}
                      value={selectedCountry}
                      onChange={(e) => {
                        setSelectedCountry({
                          value: e.value,
                          label: e.label,
                        });
                      }}
                    />
                    {['Provincia', 'Dirección', 'Ciudad', 'Código Postal', 'Teléfono'].map((item, index) => (
                      <React.Fragment key={`#DeliveryData${index}`}>
                        <p className="mb-0 mt-4">{item}</p>
                        <InputCustom type="text" />
                      </React.Fragment>
                    ))}
                  </Tab>
                  <Tab eventKey="second" title="Retirarlo en una tienda ¡Gratis!">
                    <h4 className="my-4 pl-3">Elige dónde retirar tu pedido</h4>
                    <Col lg={10} className="mt-2 d-flex libraryCheck">
                      <Form.Check type={'checkbox'} />
                      <Col lg={7} className="p-2">
                        <p className="m-0">8 1/2 - Ocho y Medio Libros de Cine</p>
                        <p>Calle de Martín de los Heros, 11</p>
                      </Col>
                      <img src={heartIcon} alt="icon.png" />
                      <p className="pt-3 pl-2">Tu librería beneficiada</p>
                    </Col>
                    <Col lg={10} className="mt-2 d-flex">
                      <Form.Check type={'checkbox'} />
                      <p className="m-0 pl-2">Otra librería</p>
                    </Col>
                    <SearchSelect />
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          )}
          <Row className="discount mt-5">
            <Col md={12} lg={12} xs={12} className="d-flex justify-content-between">
              <span>
                <Button
                  className="orange-btn ml-1 text-white"
                  as={Link}
                  to={{
                    pathname: '/carrito',
                    state: {
                      discount: state?.discount,
                      percentage: state?.percentage,
                      typeDiscount: state?.typeDiscount,
                    },
                  }}
                  variant="link"
                  size="sm"
                >
                  VOLVER
                </Button>
              </span>
              <span className="">
                <p className="pt-2 d-inline">Siguiente: Pago</p>
                <Link
                  to={{
                    pathname: '/pay',
                    state: {
                      discount: state?.discount,
                      percentage: state?.percentage,
                      typeDiscount: state?.typeDiscount,
                    },
                  }}
                >
                  <Button
                    className="orange-btn ml-3 p-2"
                    onClick={() => {
                      if (!isLogged) {
                        registerUser();
                      }
                      return dispatch(setGiftData(giftInfo));
                    }}
                    disabled={disableNext()}
                  >
                    CONTINUAR
                  </Button>
                </Link>
              </span>
            </Col>
          </Row>
          <p className="my-4">
            ¿Alguna duda? Revisa nuestras
            <Link to={'/faqs'}> preguntas frecuentes</Link> o<Link to={'/contact'}> ponte en contacto.</Link>
          </p>
          <p className="textInfo m-0">
            Cumplimentando este formulario me hago responsable de los datos facilitados en cuanto a su veracidad y
            titularidad.
          </p>
          <p className="textInfo">
            Leer la política de privacidad de Libelista
            <Link to={'/aviso-legal-y-politica-de-privacidad'}> aquí</Link>.
          </p>
        </Col>
        <Col md={3} className="mb-2 mt-3 text-muted priceCol">
          <Card>
            <Card.Body>
              <div className="aside-title mb-3 px-2">
                <span>Tu pedido</span>
              </div>
              <Card.Subtitle className="mb-3 pl-3 text-muted">{countItems} items</Card.Subtitle>
              <Col className="mt-4 d-flex justify-content-between">
                <p>Subtotal:</p>
                <p> {subtotal} €</p>
              </Col>
              <Col className="d-flex justify-content-between">
                <p>IVA:</p>
                <p> {iva} €</p>
              </Col>
              <Col className="d-flex justify-content-between">
                <p>Envío para España: </p>
                <p>{shippingCosts > 0 ? shippingCosts + ' €' : <b>Gratis</b>} </p>
              </Col>
              <Col className="borderDiscount  d-flex justify-content-between">
                <p>{showDiscountDetail ? 'Total sin descuento:' : 'Total:'} </p>
                <p>{totalWithoutDiscount} €</p>
              </Col>
              {showDiscountDetail && (
                <>
                  <Col className=" d-flex justify-content-between textDiscount">
                    <p>Descuento: </p>
                    <p>{`${appliedDiscount}${state?.typeDiscount === 0 ? '%' : '€'} `} </p>
                  </Col>
                  <Col className=" d-flex justify-content-between textDiscount">
                    <p>Monto a descontar: </p>
                    <p>{(totalWithoutDiscount - finalPrice).toFixed(2)} €</p>
                  </Col>
                  <Col className=" d-flex justify-content-between textDiscount borderDiscount">
                    <p>Total con descuento: </p>
                    <p>{finalPrice} €</p>
                  </Col>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Shipping;
