import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiFillPhone,
  AiOutlineTwitter,
  AiOutlineClockCircle,
} from "react-icons/ai";
import { MdPlace } from "react-icons/md";
import { HiMail } from "react-icons/hi";
import { FaGlobe } from "react-icons/fa";

import "./libraryCard.scss";

const LibraryCard = ({ library }) => {
  return (
    <Row className="library-card">
      <Col lg={12}>
        <Row className="info-row">
          <Col
            className="img-col"
            style={{ backgroundImage: `url('${library.photo}')` }}
            lg={12}
          ></Col>
          <h5> {library.name}</h5>
          <ul>
            <li>
              <MdPlace /> {library.address}, {library.locality}
            </li>
            <li>
              <AiOutlineClockCircle /> {library.working_schedule}
            </li>
            <li>
              <AiFillPhone /> {library.phone_number}
            </li>
          </ul>
          <Col className="icons-col d-flex" lg={12}>
            <div>
              <a target="_blank" title={library.email}>
                <HiMail />
              </a>
            </div>
            <div>
              <a target="_blank" href={`${library.twitter_url}`}>
                <AiOutlineTwitter />
              </a>
            </div>
            <div>
              <a target="_blank" href={`${library.instagram_url}`}>
                <AiOutlineInstagram />
              </a>
            </div>
            <div>
              <a target="_blank" href={`${library.facebook_url}`}>
                <AiOutlineFacebook />
              </a>
            </div>
            <div>
              <a target="_blank" href={library.web_url}>
                <FaGlobe />
              </a>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LibraryCard;
