import React, { useState, useEffect } from 'react';
import { Formik, Form, useField } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import './styleActionsForm.scss';

const FileInputControl = ({ label, ...props }) => {
  const [field, { setValue }] = useField(props);
  const { hidden, action, imageUrl } = props;
  const imagePreviewSource = field.value instanceof Blob ? URL.createObjectURL(field.value) : imageUrl;

  return hidden ? null : (
    <div className="d-flex flex-column my-2 ">
      {action !== 'detail' && (
        <div className="d-flex">
          <label className="font-weight-bold col-sm-2 col-form-label" htmlFor={props.id || props.name}>
            {label}
          </label>
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              onChange={(event) => {
                const files = event.currentTarget.files;
                const file = files[0];
                setValue(file);
              }}
            />
            <label className="custom-file-label" htmlFor="inputGroupFile01" data-browse="Elegir">
              {field.value?.name || 'Sin archivo'}
            </label>
          </div>
        </div>
      )}
      {imagePreviewSource && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={imagePreviewSource} className="img-thumbnail w-25 h-25" alt="Imagen actual" />
          <figcaption className="font-weight-bold">Imagen actual</figcaption>
        </div>
      )}
    </div>
  );
};
const InputControl = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { type } = props;
  if (meta.error) {
    props.style = { ...props.style, borderColor: 'red' };
  }
  let Control = 'input';
  if (type === 'textarea') {
    Control = 'textarea';
    props = { rows: 12, ...props };
  }
  const errorLeftPlacement = meta.error === '* ';

  const checkboxInput = type === 'checkbox';
  if (checkboxInput) {
    props.style = { width: '38px' };
  }

  return props.hidden ? null : (
    <div className={`d-flex my-2 heightLastInput`}>
      <label className={`font-weight-bold col-sm-2 col-form-label`} htmlFor={props.id || props.name}>
        {meta.error && errorLeftPlacement ? <span className="text-danger  font-weight-light">{meta.error}</span> : null}
        {label}
        {meta.error && !errorLeftPlacement ? (
          <>
            <br />
            <span className="text-danger font-weight-light">{meta.error}</span>
          </>
        ) : null}
      </label>
      <Control {...field} {...props} />
    </div>
  );
};
const SelectControl = ({ label, ...props }) => {
  const [ meta, { setValue }] = useField(props);
  const [searchRequestTimeoutId, setSearchRequestTimeoutId] = useState(undefined);
  let { isAsync, isMulti, loadOptions } = props;
  /**
   * @function debouncedLoadOptions
   * @summary wrapper around loadOptions that
   * prevents firing too many request in a short period of time when
   * searching for options on a select
   */
  let debouncedLoadOptions = undefined;
  if (loadOptions) {
    debouncedLoadOptions = (searchValue, callback) => {
      clearTimeout(searchRequestTimeoutId);
      const timeBeforeSearchTriggers = 500;
      const timeoutId = setTimeout(async () => {
        const options = await loadOptions(searchValue);
        callback(options);
      }, timeBeforeSearchTriggers);
      setSearchRequestTimeoutId(timeoutId);
    };
  }
  props.noOptionsMessage = () => 'No se hallaron opciones';
  props.loadingMessage = () => 'Cargando...';
  props.placeholder = 'Seleccionar o buscar una opción';
  if (meta.error) {
    props.styles = { control: (provided) => ({ ...provided, borderColor: 'red' }) };
  }
  return (
    <div className="d-flex" style={{ zIndex: 1 }}>
      <label className="font-weight-bold  col-sm-2 col-form-label" htmlFor={props.id || props.name}>
        {meta.error ? <span className="text-danger">{meta.error}</span> : null}
        {label}
      </label>
      {isAsync ? (
        <AsyncSelect
          onChange={(selectedOption) => {
            const newValue = isMulti
              ? selectedOption.map(({ value }) => parseInt(value) || value)
              : selectedOption.value;
            setValue(newValue);
          }}
          {...props}
          loadOptions={debouncedLoadOptions || loadOptions}
        />
      ) : (
        <Select
          onChange={(selectedOption) => {
            const newValue = isMulti
              ? selectedOption.map(({ value }) => parseInt(value) || value)
              : selectedOption.value;
            setValue(newValue);
          }}
          {...props}
        />
      )}
    </div>
  );
};
const ACTION_TEXTS = {
  list: {
    title: 'Listado',
    button: 'Ver lista',
    verb: 'Listar',
  },
  detail: { title: 'Detalle', button: 'Ver detalle', verb: 'Detallar' },
  edit: { title: 'Edición', button: 'Editar', verb: 'Editar' },
  create: {
    title: 'Creación',
    button: 'Crear',
    verb: 'Crear',
  },
};
/**
 * @param title string
 * @param subsectionPath string administration menu subsection path name to redirect to after successfull edition or creation
 * @param data Object with resource data  passed to formiks initialValues' prop
 * @param requests {get: function, edit: function} callback executed within formik's onSubmit function depending on action param
 * @param inputFields Array<{...}> array of objects; each with form controls properties
 * @param extraFormat Object whose fields are the same as data ones and the values this fields can take are 'disabled' or 'datetime-local-format'
 * @param action string 'edit' 'create' 'list'
 * @param formDataConfiguration object When non-falsy this property changes content-type to multipart/form-data through the use of FormData construct. if an object with fields matching the name of one or more controls are passed with a value of true, It will be considered as an array of values which each will be appended individually to the FormData instance
 * @param schema  object that matches formik's initialValues' fields with Yup validation values
 * @param extraSubmitLogic callback executed as first statement within onSubmit formik function. It is passed formik's values as only argument
 * @param postRequestSubmitLogic executed after succesfull request callback is made. It is passed request body as first argument and formik's values as second
 */
const ActionsForm = ({
  title,
  subsectionPath,
  data,
  requests,
  inputFields,
  extraFormat,
  action,
  formDataConfiguration,
  schema,
  addTable,
  extraSubmitLogic = () => {},
  postRequestSubmitLogic = () => {},
}) => {
  const [mode, setMode] = useState(action);
  const readMode = mode === 'detail';
  const history = useHistory();
  const changeActionHandler = (newAction) => {
    if (newAction !== 'create') {
      const replaceValues = [new RegExp(`/${action}/`), `/${newAction}/`];
      const path = history.location.pathname;
      const newPath = path.replace(...replaceValues);
      history.push(newPath);
    } else {
      history.push(`/administration/${subsectionPath}/${newAction}`);
    }
  };
  const inputClasses = mode !== 'detail' ? 'form-control' : 'form-control border-0 bg-transparent';
  const inputControlActionSettings = {
    disabled: readMode,
    className: inputClasses,
  };
  useEffect(() => {
    setMode(action);
  }, [action]);
  if (action !== 'create') {
    for (let field in extraFormat) {
      switch (extraFormat[field]) {
        case 'datetime-local-format':
          data[field] = data[field]?.replace(/T(\d{2}:\d{2}).*$/, 'T$1');
          break;
        default:
      }
    }
  }
  for (let key in data) {
    if (!data[key] && data[key] !== false) {
      data[key] = readMode && key !== 'parent_affiliate' ? '...' : '';
    }
  }
  return (
    <>
      <h2 className="text-secondary my-3">
        {`${ACTION_TEXTS[action].title} | ${title}${data?.id ? ' #' + (data.id || 'X') : ''}`}
      </h2>
      <Formik
        initialValues={data || {}}
        enableReinitialize
        validationSchema={schema && Yup.object(schema)}
        onSubmit={async (valuesSrc) => {
          /**
           * @var values object copy of valuesSrc which avoids data mutation
           * outside request scope
           */
          const values = { ...valuesSrc };
          await extraSubmitLogic(values);
          const request = mode === 'edit' ? requests.edit : requests.create;
          let data = values;
          if (formDataConfiguration) {
            const formData = new FormData();
            for (let [key, value] of Object.entries(values)) {
              const shouldAppendFieldSeveralTimes = formDataConfiguration[key];
              if (shouldAppendFieldSeveralTimes) {
                value.forEach((value) => formData.append(key, value));
              } else {
                formData.set(key, value);
              }
            }
            data = formData;
          }
          try {
            const response = await request(data);
            if ([200, 201].includes(response.status)) {
              postRequestSubmitLogic(response.data, values);
              history.push(`/administration/${subsectionPath}`);
            }
          } catch (exception) {
            console.log(exception);
          }
        }}
      >
        {({ values, isSubmitting }) => {
          return (
            <Form id="couponForm" className="bg-light p-1 rounded">
              {inputFields.map((inputProps, index) => {
                inputProps.hidden && (inputProps.hidden = mode !== 'detail');
                if (inputProps.hidden) {
                  return null;
                }
                readMode && inputProps.value && (inputProps.value = '...');
                const controlType = inputProps.type;
                switch (controlType) {
                  case 'formSubsectionHeader':
                    const { icon: Icon, label, doubleLine } = inputProps;
                    return (
                      <div key={`formSubsectionHeader${index}`} className="d-flex flex-column my-2">
                        <h3 className="px-1">
                          {doubleLine && <hr className="w-100" />}
                          <Icon className="mr-2" />
                          {label}
                        </h3>
                        <hr className="w-100" />
                      </div>
                    );
                  case 'select':
                    inputProps.isDisabled = mode === 'detail';
                    if (
                      mode === 'edit' &&
                      (inputProps?.defaultValue?.[0] ?? inputProps?.defaultValue)?.label === 'Sin asignar'
                    ) {
                      inputProps.defaultValue = undefined;
                    }
                    let { styles, ...restInputProps } = inputProps;
                    restInputProps.isDisabled = readMode;
                    return (
                      <SelectControl
                        key={`input${inputProps.name}#${index}${mode}`}
                        className={`w-100 mb-2`}
                        styles={{
                          ...styles,
                          singleValue: (provided, state) => ({
                            ...provided,
                            color: readMode ? `#495057` : provided.color,
                          }),
                          indicatorsContainer: (provided, state) => ({
                            display: readMode ? 'none' : provided.display,
                          }),
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: readMode ? 'transparent' : provided.backgroundColor,
                            borderColor: readMode ? 'transparent' : provided.borderColor,
                          }),
                          multiValueRemove: (provided, state) => ({
                            ...provided,
                            display: readMode ? 'none' : provided.display,
                          }),
                          multiValue: (provided, state) => ({
                            ...provided,
                            backgroundColor: readMode ? 'transparent' : provided.backgroundColor,
                          }),
                        }}
                        {...restInputProps}
                      />
                    );
                  case 'file':
                    return (
                      <FileInputControl
                        disabled={extraFormat?.[inputProps?.name] === 'disabled'}
                        key={`input${inputProps.name}#${index}`}
                        {...inputControlActionSettings}
                        {...{ ...inputProps, action }}
                      />
                    );
                  default:
                    return (
                      <InputControl
                        disabled={extraFormat?.[inputProps?.name] === 'disabled'}
                        key={`input${inputProps.name}#${index}`}
                        {...inputControlActionSettings}
                        {...inputProps}
                      />
                    );
                }
              })}
              {addTable}
              <div className={`d-flex my-2 justify-content-${readMode ? 'end' : 'between'} align-items-center`}>
                {mode !== 'detail' && (
                  <button
                    type="submit"
                    className={`w-${mode === 'create' ? '100' : '75'} text-capitalize font-weight-bold btn btn-${
                      isSubmitting ? 'warning' : 'info'
                    }`}
                  >
                    {isSubmitting ? 'Cargando...' : ACTION_TEXTS[mode].button}
                  </button>
                )}
                {mode !== 'create' &&
                  !extraFormat?.editButton?.disabled &&
                  ['edit', 'detail'].map((newAction, index) =>
                    newAction === mode ? null : (
                      <button
                        key={`action#${index}`}
                        onClick={(event) => {
                          event.preventDefault();
                          changeActionHandler(newAction);
                        }}
                        className="btn btn-info mx-1 w-25 text-capitalize"
                      >
                        {ACTION_TEXTS[newAction].button}
                      </button>
                    )
                  )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
export default ActionsForm;
