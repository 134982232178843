import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import ActionsForm from '../../../../components/backoffice/actionsForm/index';
import SectionLoader from '../../../../components/loader/SectionLoader';
import { getClientMessage as get, postMessage as create, editClientMessage as edit } from '../../../../api/libelista';
import { getSelectDataHandlers } from '../../../../utils/backoffice/getSelectDataHandlers';
import { getUsersBySearch, getUserById } from 'api/users';
const answeredOptions = [
  { label: 'Si', value: true },
  { label: 'No', value: false },
];
const ClientMessagesItem = ({ data }) => {
  const { action } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [{ defaultOptions: defaultUsersOptions, defaultOption: defaultUsersOption }, setUsersDefaultOptionValues] =
    useState({});
  const { getSelectData: getUsersOptions, setSelectDefaultData: setUsersOptions } = getSelectDataHandlers(
    getUserById,
    getUsersBySearch,
    setUsersDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'name',
      fieldNameWithRequestValue: 'id',
    }
  );
  const mustWaitForData = ['detail', 'edit'].includes(action);
  const renderConditions = [!mustWaitForData || data, defaultUsersOptions?.length];
  const disabled = ['edit', 'detail'].includes(action);

  useEffect(() => {
    if (mustWaitForData && data) {
      const userId = data.answered_by;
      setUsersOptions(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const inputFields = [
    { label: 'Nombre', name: 'full_name', type: 'text', disabled },
    { label: 'Correo electrónico', name: 'email', type: 'email', disabled },
    { label: 'Departamento', name: 'department', type: 'text', disabled },
    { label: 'Fecha de creación', name: 'created_at', type: 'datetime-local', disabled },
    {
      label: 'Respondido',
      name: 'answered',
      type: 'select',
      options: answeredOptions,
      defaultValue: answeredOptions[data?.answered ? 0 : 1],
    },
    {
      label: 'Respondido por',
      name: 'answered_by',
      isAsync: true,
      loadOptions: getUsersOptions,
      defaultOptions: true,
      type: 'select',
      cacheOptions: true,
      defaultValue: defaultUsersOptions?.find((option) => option.value === data.answered_by) || defaultUsersOptions?.[0],
    },
    { label: 'Mensaje', name: 'message', type: 'textarea', disabled },
  ];

  const actionsFormProps = {
    inputFields,
    title: 'Mensaje de cliente',
    subsectionPath: 'client-messages',
    data: data,
    requests: { edit, create },
    extraFormat: { created_at: 'datetime-local-format' },
    action,
  };
  const conditionsToRenderMet = renderConditions.every((condition) => condition);
  return conditionsToRenderMet ? <ActionsForm {...actionsFormProps} /> : <SectionLoader />;
};
ClientMessagesItem.request = {
  get,
};
const ClientMessagesItemPage = () => <BackofficePagesManager PageViewer={ClientMessagesItem} />;
export default ClientMessagesItemPage;
