import * as React from "react";
import BackofficeTable from "../../../components/backoffice/table/index";
import { getPurchaseCommissions } from "../../../api/orders";
import BackofficePagesManager from "../../../components/backoffice/pagesManager";

const PurchaseCommissionsTable = ({ data, ActionsMenu, loadData }) => {
  const headers = {
    code: "Nº de orden",
    total: "Total",
    libelista_amount: "Comision Libelista",
    book_provider: "Proveedor libro",
    book_provider_amount: "Comisión proveedor",
    benefited_company: "Librería beneficiada",
    benefited_store_amount: "Comisión lib. benef.",
    bookstore_to_pickup: "Librería de retiro",
    pickup_store_amount: "Comisión lib. retiro"
  };

  loadData.colSpan = Object.keys(headers).length;
  const PurchaseCommissionsTableProps = {
    title: "Comisiones por pedido",
    data,
    headers,
    ActionsMenu,
    loadData,
    extraFormat: {
      noActionsColumn: true,
      createButton: { disabled: true },
      bookstore_to_pickup: "isAssigned",
      book_provider: "isAssigned",
      benefited_company: "isAssigned",
    },
  };
  return <BackofficeTable {...PurchaseCommissionsTableProps} />;
};
PurchaseCommissionsTable.request = {
  get: getPurchaseCommissions,
};
PurchaseCommissionsTable.itemClass = "cupon";
PurchaseCommissionsTable.requestParameter = "id";
const PurchaseCommissionsTablePage = () => (
  <BackofficePagesManager PageViewer={PurchaseCommissionsTable} />
);
export default PurchaseCommissionsTablePage;
