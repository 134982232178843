import * as React from "react";
import BackofficeTable from "../../../components/backoffice/table/index";
import { getCommissionsByCompany } from "../../../api/orders";
import BackofficePagesManager from "../../../components/backoffice/pagesManager";

import { Button } from 'react-bootstrap';
import axios from "axios";
import { API_INFO } from '../../../constants';

const CommissionsByCompanyTable = ({ data, ActionsMenu, loadData }) => {
  const headers = {
    company: "Empresa",
    orders_quantity: "Nº ordenes",
    bookstore_pickup_total: "Comisiones por retiro",
    benefited_company_total: "Comisiones lib. beneficiada",
    books_provided_total: "Comisiones proveer libro",
    libe_fixed_comission: "Comisión fija a Libelista",
    total: "Suma total",
  };

  const recieveExport = async (req) => {
    const options = {
      headers: { 
        Authorization: "JWT " + localStorage.getItem("access"),
      }, responseType: "blob" ,
    };
    // const { status, data } = await getExportCommissions(req);
    const { status, data }  = await axios.post(
      API_INFO + "storehouse/commissions/export_commissions_by_company/",
      req,
      options
    );
    console.log("status", status);
    console.log("data", data);
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", "reservas.xls")
    document.body.appendChild(link)
    link.click()
    link.remove();
  };

  const exportButton = () => {
    return (
      <Button onClick={()=>recieveExport(data)}>
        Exportar
      </Button>
    )
  }

  loadData.colSpan = Object.keys(headers).length;
  const CommissionsByCompanyTableProps = {
    title: "Comisiones por empresa",
    data,
    headers,
    ActionsMenu,
    loadData,
    extraFormat: {
      noActionsColumn: true,
      createButton: { disabled: true },
      printExportButton: true
    },
    exportButton
  };
  return <BackofficeTable {...CommissionsByCompanyTableProps} />;
};
CommissionsByCompanyTable.request = {
  get: getCommissionsByCompany,
};
CommissionsByCompanyTable.itemClass = "cupon";
CommissionsByCompanyTable.requestParameter = "id";
const CommissionsByCompanyTablePage = () => (
  <BackofficePagesManager PageViewer={CommissionsByCompanyTable} />
);
export default CommissionsByCompanyTablePage;
