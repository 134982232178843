import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { getInfoRedsys } from "../../api/ecommerce";
import { cartSelector } from "../../slices/cart";
import { useSelector } from "react-redux";

const TPV = (props) => {
  const [redsys, setRedsys] = useState(null);
  const { appliedCoupon } = useSelector(cartSelector);

  const callGetInfoRedsys = async () => {
    let payload = {applied_coupon: appliedCoupon}
    const rs = await getInfoRedsys(payload);
    if (rs.status === 200) {
      const { data } = rs;
      setRedsys(data);
    }
  };

  useEffect(() => {
    callGetInfoRedsys();
  }, []);

  return (
    <>
      {redsys ? (
        <Row>
          <Col md={4}>
            <div
              dangerouslySetInnerHTML={{ __html: redsys }}
              className="footerCardBlog"
            ></div>
          </Col>
        </Row>
      ) : (
        <Row></Row>
      )}
    </>
  );
};

export default TPV;
