import React, { useState } from 'react';
import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import { FaCrosshairs } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import map from '../../assets/img/mapa.png';
import { InputCustom } from '../input';
import './findLibraries.scss';

const FindLibraries = () => {
  return (
    <Container fluid className="justify-content-center">
      <Row className="justify-content-center">
        <Col xs={12} sm={10} md={10} lg={10}>
          <Row className="findLibRow justify-content-center py-4">
            <Col xs={9} sm={4} md={4} lg={4} className="">
              <Row className="">
                <Col xs={12} sm={12} md={12} lg={12} className="">
                  <h4>
                    ¿Quieres saber cuál es <strong> tu librería más cercana?</strong>
                  </h4>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} className="">
                  <InputCustom type="text" placeholder="Dirección, ciudad o localidad" className="mt-2" />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} className="d-flex">
                  <Link to={'/bookshops/map'}>
                    <FaCrosshairs className="mr-1" /> <p>CERCA DE AQUÍ</p>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col xs={9} sm={3} md={3} lg={3}>
              <img src={map} alt="apps.png" />
            </Col>
            <Col
              xs={11}
              sm={11}
              md={11}
              lg={11}
              className="mt-4 mt-sm-2 mt-md-4 mt-lg-4  justify-content-center d-flex"
            >
              <Link to={'/bookshops/map'}>
                <Button className="py-2 w-100">IR AL MAPA DE LIBRERÍAS</Button>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col lg={9}>
          <Row className="justify-content-center"></Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FindLibraries;
