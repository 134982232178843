import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { userSelector } from '../slices/user';
import { useSelector } from 'react-redux';

// layouts
import DefaultLayout from '../layouts/defaultLayout';
import AdminPanelLayout from '../layouts/adminPanelLayout';

// pages
import HomePage from '../pages/home';
import ListPage from '../pages/list';
import BookDetail from '../pages/detail/index';
import OrderDetail from '../pages/orders/detail';
import ProfilePage from '../pages/profile';
import Cart from '../pages/cart';
import Shipping from '../pages/cart/shipping';
import Pay from '../pages/cart/pay';
import OkPayment from '../pages/cart/ok_payment';
import ErrorPayment from '../pages/cart/error_payment';
import DynamicFilter from '../pages/dynamicFilter/';
import FavoritesPage from '../pages/favorites';
import { PostList } from '../pages/blog';
import Categoryblog from '../pages/blog/categoryblog';
import Post from '../pages/blog/post';
import RecoverPage from '../pages/recover';
import SendMailRecover from '../pages/recover/sendMailRecover';
import Libraries from '../pages/libraries/libraries';
import AboutUs from '../pages/about/AboutUs';
import GiftCard from '../pages/gift-card/GiftCard';
import Contact from '../pages/contact/Contact';
import PricesAndTerms from '../pages/infoPages/pricesAndTerms';
import HowToBuy from '../pages/infoPages/howToBuy';
import Refunds from '../pages/infoPages/refunds';
import Ebook from '../pages/infoPages/ebook';
import Faqs from '../pages/infoPages/faqs';
import TermsOfUse from '../pages/infoPages/termsOfUse';
import LegalsAndPrivacy from '../pages/infoPages/legalsAndPrivacy';
import Cookies from '../pages/infoPages/cookies';
import Register from '../pages/register/register';
import LoginPage from '../pages/login/loginPage';
import ChildrenBooks from '../pages/childrenBooks';
import AwardedBooks from '../pages/awardedBooks';
import SmallReviews from '../pages/smallReviews';
import smallReviewsDetail from '../pages/smallReviewsDetail';
import Schedule from '../pages/schedule';
import EventDetail from '../pages/schedule/eventDetail';
import LibrariesMap from '../pages/mapsPage';
import NotFound from '../pages/notfound';
import MyBooksManager from '../pages/profile/myBooksManager/index';

//Admin Panel
import HomePanel from '../pages/adminPanel/home';
import ProductsEdition from '../pages/adminPanel/productsEdition';
import BooksOfTheMonth from '../components/blog/BooksOfTheMonth';
import BooksOfTheMonthSection from '../components/blog/BooksOfTheMonthSection';
import UsersTablePage from '../pages/backoffice/users/table/index';
import UserItemPage from '../pages/backoffice/users/item/index';
import EmailSubscriptionsTablePage from '../pages/backoffice/emailSuscriptions/table/index';
import EmailSubscriptionsItemPage from '../pages/backoffice/emailSuscriptions/item/index';
import BookstoresTable from '../pages/backoffice/bookstores/table/index';
import BookstoresItem from '../pages/backoffice/bookstores/item/index';
import CouponsTablePage from '../pages/backoffice/coupons/table/index';
import CouponItemPage from '../pages/backoffice/coupons/item/index';
import AuthorsItemPage from '../pages/backoffice/authors/item/index';
import AuthorsTablePage from '../pages/backoffice/authors/table/index';
import KeywordsTable from '../pages/backoffice/keywords/table/index';
import EditorialsTablePage from '../pages/backoffice/editorials/table/index';
import EditorialsItemPage from '../pages/backoffice/editorials/item/index';
import BooksOfTheMonthTablePage from '../pages/backoffice/booksOfTheMonth/table/index';
import BooksOfTheMonthItemPage from '../pages/backoffice/booksOfTheMonth/item/index';
import BooksOfTheMonthPostsTablePage from '../pages/backoffice/booksOfTheMonthPosts/table/index';
import BooksOfTheMonthPostsItemPage from '../pages/backoffice/booksOfTheMonthPosts/item/index';
import CommentsTablePage from '../pages/backoffice/comments/table/index';
import ListsTablePage from '../pages/backoffice/lists/table/index';
import ListsItemPage from '../pages/backoffice/lists/item/index';
import AwardWinningBooksTablePage from '../pages/backoffice/awardWinningBooks/table/index';
import AwardWinningBooksItemPage from '../pages/backoffice/awardWinningBooks/item/index';
import BlogPostsTablePage from '../pages/backoffice/blogPosts/table/index';
import BlogPostsItemPage from '../pages/backoffice/blogPosts/item/index';
import FeedbackPage from '../pages/backoffice/feedbackPage';
import OrdersTablePage from 'pages/backoffice/orders/table';
import OrdersItemPage from 'pages/backoffice/orders/item';
import ProductsTablePage from 'pages/backoffice/products/table/index';
import ProductsItemPage from 'pages/backoffice/products/item/index';
import CommentsItemPage from 'pages/backoffice/comments/item';
import ClientMessagesTablePage from 'pages/backoffice/client_messages/table';
import ClientMessagesItemPage from 'pages/backoffice/client_messages/items';
import SchedulesTablePage from 'pages/backoffice/schedules/table';
import ScheduleItemPage from 'pages/backoffice/schedules/item';
import PurchaseCommissionsTablePage from '../pages/backoffice/purchaseCommissions';
import CommissionsByCompanyTablePage from '../pages/backoffice/commissionsByCompany';

const DefaultRoute = ({ component: Component, ...rest }) => {
  const { isLogged } = useSelector(userSelector);
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.protected ? (
          !isLogged ? (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          ) : (
            <DefaultLayout>
              <Component {...props} />
            </DefaultLayout>
          )
        ) : (
          <DefaultLayout>
            {' '}
            <Component {...props} />{' '}
          </DefaultLayout>
        )
      }
    />
  );
};

const AdminPanelRoute = ({ component: Component, ...rest }) => {
  const { isWorker } = useSelector(userSelector);
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.protected ? (
          !isWorker ? (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          ) : (
            <AdminPanelLayout>
              <Component {...props} />
            </AdminPanelLayout>
          )
        ) : (
          <AdminPanelLayout>
            <Component {...props} />
          </AdminPanelLayout>
        )
      }
    />
  );
};

const MainRoute = () => {
  const adminSubroutes = [
    {
      path: '/administration/products',
      component: ProductsTablePage,
    },
    {
      path: '/administration/products/:action/:id?',
      component: ProductsItemPage,
    },
    {
      path: '/administration/comments',
      component: CommentsTablePage,
    },
    {
      path: '/administration/comments/:action/:id?',
      component: CommentsItemPage,
    },
    {
      path: '/administration/coupons',
      component: CouponsTablePage,
    },
    {
      path: '/administration/coupons/:action/:id?',
      component: CouponItemPage,
    },
    {
      path: '/administration/lists',
      component: ListsTablePage,
    },
    {
      path: '/administration/lists/:action/:id?',
      component: ListsItemPage,
    },
    {
      path: '/administration/users',
      component: UsersTablePage,
    },
    {
      path: '/administration/users/:action/:id?',
      component: UserItemPage,
    },
    {
      path: '/administration/email-subscriptions',
      component: EmailSubscriptionsTablePage,
    },
    {
      path: '/administration/email-subscriptions/:action/:id?',
      component: EmailSubscriptionsItemPage,
    },
    {
      path: '/administration/orders',
      component: OrdersTablePage,
    },
    {
      path: '/administration/orders/:action/:id?',
      component: OrdersItemPage,
    },
    { path: '/administration/authors', component: AuthorsTablePage },
    {
      path: '/administration/authors/:action/:id?',
      component: AuthorsItemPage,
    },
    { path: '/administration/bookstores', component: BookstoresTable },
    {
      path: '/administration/bookstores/:action/:id?',
      component: BookstoresItem,
    },
    { path: '/administration/keywords', component: KeywordsTable },
    {
      path: '/administration/keywords/:action/:id?',
      component: FeedbackPage,
    },
    {
      path: '/administration/editorials',
      component: EditorialsTablePage,
    },
    {
      path: '/administration/editorials/:action/:id?',
      component: EditorialsItemPage,
    },
    {
      path: '/administration/books-of-the-month',
      component: BooksOfTheMonthTablePage,
    },
    {
      path: '/administration/books-of-the-month/:action/:id?',
      component: BooksOfTheMonthItemPage,
    },
    {
      path: '/administration/books-of-the-month-posts',
      component: BooksOfTheMonthPostsTablePage,
    },
    {
      path: '/administration/books-of-the-month-posts/:action/:id?',
      component: BooksOfTheMonthPostsItemPage,
    },
    {
      path: '/administration/award-winning-books',
      component: AwardWinningBooksTablePage,
    },
    {
      path: '/administration/award-winning-books/:action/:id?',
      component: AwardWinningBooksItemPage,
    },
    {
      path: '/administration/blog-posts',
      component: BlogPostsTablePage,
    },
    {
      path: '/administration/blog-posts/:action/:id?',
      component: BlogPostsItemPage,
    },
    {
      path: '/administration/client-messages',
      component: ClientMessagesTablePage,
    },
    {
      path: '/administration/client-messages/:action/:id?',
      component: ClientMessagesItemPage,
    },
    {
      path: '/administration/schedules',
      component: SchedulesTablePage,
    },
    {
      path: '/administration/schedules/:action/:id?',
      component: ScheduleItemPage,
    },
    {
      path: '/administration/commissions',
      component: PurchaseCommissionsTablePage,
    },
    {
      path: '/administration/commissions-by-company',
      component: CommissionsByCompanyTablePage,
    },
  ];
  // should be the last routes to render
  const workInProgressRoutes = [
    {
      path: '/administration/:subsection',
      component: FeedbackPage,
    },
    {
      path: '/administration/:subsection/:action/:id?',
      component: FeedbackPage,
    },
  ];
  adminSubroutes.push(...workInProgressRoutes);
  return (
    <Router>
      <Switch>
        <DefaultRoute exact path="/" component={HomePage} />
        <DefaultRoute exact path={['/lista/:nombre/:id?', '/autor/:nombre', '/editorial/:slug']} component={ListPage} />
        <DefaultRoute exact path="/carrito" component={Cart} />
        <DefaultRoute exact path="/pago-ok" component={OkPayment} />
        <DefaultRoute exact path="/pago-error" component={ErrorPayment} />
        <DefaultRoute exact path="/perfil" protected={true} component={ProfilePage} />
        <DefaultRoute exact path="/filtro/:type/" component={DynamicFilter} />
        <DefaultRoute exact path="/productos/:type/:url/" component={BookDetail} />
        <DefaultRoute exact path="/favoritos" component={FavoritesPage} />
        <DefaultRoute exact path="/blog" component={PostList} />
        <DefaultRoute exact path="/blog/:category/" component={Categoryblog} />
        <DefaultRoute exact path="/blog/category/:booksOfTheMonthId" component={BooksOfTheMonth} />
        <DefaultRoute exact path="/blog/category/section/libros_del_mes" component={BooksOfTheMonthSection} />
        <DefaultRoute exact path="/libelista/posts/:id" component={Post} />
        <DefaultRoute exact path="/recuperar-contrasena/:refresh/:access" component={RecoverPage} />
        <DefaultRoute exact path="/recuperar-contrasena" component={SendMailRecover} />
        <DefaultRoute exact protected={true} path="/mis-libros" component={MyBooksManager} />
        <DefaultRoute exact protected={true} path="/mis-pedidos/:pk/" component={OrderDetail} />
        <DefaultRoute exact protected={false} path="/librerias" component={Libraries} />
        <DefaultRoute exact protected={false} path="/about-us" component={AboutUs} />
        <DefaultRoute exact protected={false} path="/contact" component={Contact} />
        <DefaultRoute exact protected={false} path="/gift-card" component={GiftCard} />
        <DefaultRoute exact protected={false} path="/tarifas-y-plazos-de-entrega" component={PricesAndTerms} />
        <DefaultRoute exact protected={false} path="/como-comprar" component={HowToBuy} />
        <DefaultRoute exact protected={false} path="/devoluciones-y-anulaciones" component={Refunds} />
        <DefaultRoute exact protected={false} path="/ebook" component={Ebook} />
        <DefaultRoute exact protected={false} path="/faqs" component={Faqs} />
        <DefaultRoute exact protected={false} path="/condiciones-de-uso" component={TermsOfUse} />
        <DefaultRoute
          exact
          protected={false}
          path="/aviso-legal-y-politica-de-privacidad"
          component={LegalsAndPrivacy}
        />
        <DefaultRoute exact protected={false} path="/cookies" component={Cookies} />
        <DefaultRoute exact protected={false} path="/register" component={Register} />
        <DefaultRoute exact protected={false} path={['/shipping', '/shipping?gift']} component={Shipping} />
        <DefaultRoute exact protected={false} path="/pay" component={Pay} />
        <DefaultRoute exact path="/login" component={LoginPage} />
        <DefaultRoute exact path="/for-children" component={ChildrenBooks} />
        <DefaultRoute exact path="/prizes" component={AwardedBooks} />
        <DefaultRoute exact path="/pequeno-libelista" component={SmallReviews} />
        <DefaultRoute exact path="/pequenas-resenas" component={smallReviewsDetail} />
        <DefaultRoute exact path="/agenda" component={Schedule} />
        <DefaultRoute exact path="/agenda/:id" component={EventDetail} />
        <DefaultRoute exact path="/bookshops/map" component={LibrariesMap} />

        <AdminPanelRoute exact path="/administration" protected={true} component={HomePanel} />
        <AdminPanelRoute
          exact
          path="/administration/products/edition/:id"
          protected={true}
          component={ProductsEdition}
        />
        {adminSubroutes.map(({ path, component }, index) => (
          <AdminPanelRoute key={`route${path}#${index}`} exact protected {...{ path, component }} />
        ))}
        <DefaultRoute component={NotFound} />
      </Switch>
    </Router>
  );
};
export default MainRoute;
