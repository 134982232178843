import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import Cookie from "js-cookie";

import "./cookies.scss";

const Cookies = () => {
  const [isAccepted, setIsAccepted] = useState(null);
  var cookieAccepted = Cookie.get("cookieAccepted");

  if (!cookieAccepted) {
    Cookie.set("cookieAccepted", "false");
    cookieAccepted = Cookie.get("cookieAccepted");
  }

  useEffect(() => {
    cookieAccepted = Cookie.get("cookieAccepted");
    setIsAccepted(cookieAccepted);
  }, [isAccepted]);

  const acceptCookie = () => {
    Cookie.set("cookieAccepted", "true");
    cookieAccepted = Cookie.get("cookieAccepted");
    setIsAccepted(cookieAccepted);
  };

  return (
    <div>
      {isAccepted == "false" ? (
        <Container fluid className="cookies">
          <Row className="cookies-row">
            <Col lg={8}>
              <p>
                Esta web utiliza cookies propias y de terceros para mejorar tu
                experiencia de navegación y realizar tareas de analítica.
              </p>
            </Col>
            <Col lg={2}>
              <p className="mr-3" onClick={acceptCookie}>
                Aceptar
              </p>
              <a href="/cookies">Leer mas</a>
            </Col>
          </Row>
        </Container>
      ) : null}
    </div>
  );
};

export default Cookies;
