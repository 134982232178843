import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Card, Container, Jumbotron, Table, Button, Form } from 'react-bootstrap';

import { cartSelector, setIsGiftOrder, setAppliedCoupon } from '../../slices/cart';
import { companySelector } from '../../slices/company';
import { useSelector, useDispatch } from 'react-redux';
import { COMPANY, responsive } from '../../constants';
import { Link } from 'react-router-dom';
import { getRelatedProduct, getValidateDiscount } from '../../api/products';
import Carousel from 'react-multi-carousel';
import ItemList from '../../components/itemList/item';
import { FiGift } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

import Items from './items';
import PaymentMethods from './payment_methods';
import useScript from 'react-script-hook';
import emptyBag from '../../assets/img/shopping_bag.png';

import './cart.scss';

const Cart = () => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const discountRef = useRef();
  const location = useLocation();
  const [isDiscoungBeingApplied, setIsDiscoungBeingApplied] = useState(false);
  const [detailOrder, setDetailOrder] = useState({
    numOrder: '',
    paymentMethod: '',
  });
  const { countItems, subtotal, iva, total, shippingCosts, items } = useSelector(cartSelector);
  const { data, paypal_sandbox, paypal_production } = useSelector(companySelector);
  const [isFinishedShop, setIsFinishedShop] = useState(false);
  const [finalPrice, setFinalPrice] = useState((parseFloat(total) + parseFloat(shippingCosts)).toFixed(2));
  const [hasDiscount, setHasDiscount] = useState(false);
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const [typeDiscount, setTypeDiscount] = useState(location.state?.typeDiscount);
  const [showDetailDiscount, setShowDetailDiscount] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    goUp();
    setFinalPrice((parseFloat(total) + parseFloat(shippingCosts)).toFixed(2));
    setHasDiscount(false);
    dispatch(setAppliedCoupon(null));
  }, [total]);

  useEffect(() => {
    window.document.title = 'Carrito de compras | ' + COMPANY;
    if (countItems !== 0) {
      handleRelatedProducts();
    }
  }, [countItems]);

  const CLIENT = {
    sandbox: paypal_sandbox,
    production: paypal_production,
  };

  const CLIENT_ID = process.env.NODE_ENV === 'production' ? CLIENT.production : CLIENT.sandbox;

  useScript({
    src: `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=EUR`,
  });

  const applyDiscount = (discount, typeDiscount) => {
    if (typeDiscount === 0) {
      setFinalPrice((finalPrice - finalPrice * (discount / 100)).toFixed(2));
      setHasDiscount(true);
      setAppliedDiscount(discount);
    } else {
      setFinalPrice((finalPrice - discount).toFixed(2));
      setHasDiscount(true);
      setAppliedDiscount(discount);
    }
  };

  const checkDiscount = async () => {
    setIsDiscoungBeingApplied(true);
    try {
      const rs = await getValidateDiscount(discountRef.current.value);
      if (rs.status === 200 && rs.data.is_valid === true) {
        applyDiscount(rs.data.discount, rs.data.type_discount);
        setTypeDiscount(rs.data.type_discount);
        dispatch(setAppliedCoupon(rs.data.coupon));
        alert(`Descuento exitoso: ${rs.data.discount}${rs.data.type_discount === 0 ? '%' : '€'} !!`);
        setShowDetailDiscount(true);
      } else {
        alert('No se ha podido validar el descuento.');
      }
    } catch (error) {}
    setIsDiscoungBeingApplied(false);
  };

  useEffect(() => {
    if (location.state?.discount) {
      applyDiscount(location.state?.percentage, location.state?.typeDiscount);
    } else {
      setFinalPrice((parseFloat(total) + parseFloat(shippingCosts)).toFixed(2));
      setHasDiscount(false);
      dispatch(setAppliedCoupon(null));
    }
  }, []);

  const onFinish = (num_order, payment_method) => {
    setIsFinishedShop(true);
    setDetailOrder({
      numOrder: num_order,
      paymentMethod: payment_method,
    });
  };

  const handleRelatedProducts = async () => {
    if (items[0] !== undefined) {
      let itemUrl = items[0].url;
      let type = items[0].type;
      const fields = ['id', 'titulo', 'url', 'get_url', 'autor', 'type', 'precio_venta_publico'].join(',');
      const rs = await getRelatedProduct(itemUrl, { type, fields: fields });
      if (rs.status === 200) {
        const { data } = rs;
        setRelatedProducts(data);
      }
    }
  };

  const goUp = () => {
    window.scrollTo(0, 0);
  };

  const showDiscount = showDetailDiscount || hasDiscount;

  const totalWithoutDiscount = (parseFloat(total) + parseFloat(shippingCosts)).toFixed(2);

  return (
    <Container fluid className="sm-fluid mainDiv">
      {countItems > 0 ? (
        <Row className="justify-content-center">
          <Col md={9}>
            <div className="row">
              <div className="col-md-12">
                <ul className="nav nav-tabs wizard">
                  <li className="active">1. Cesta</li>
                  <li className="grayArrow">2. Entrega</li>
                  <li>3. Pago</li>
                </ul>
              </div>
            </div>

            <Row className="">
              <Col md={12} lg={12} xs={12} className="ml-1">
                <Items isResume={false} />
              </Col>
            </Row>

            <Row className="discount mt-5">
              <Col md={6} lg={6} xs={6} className="d-flex justify-content-center">
                <Row>
                  <Col md={11} lg={11} xs={11} className="">
                    <p>¿Tienes un código de descuento?</p>
                  </Col>
                  <Col md={11} lg={11} xs={11} className="d-flex justify-content-start">
                    <Form.Control className="mr-1 pl-0" ref={discountRef} />
                    <Button className="blue-btn" onClick={checkDiscount} disabled={hasDiscount}>
                      APLICAR
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md={6} lg={6} xs={6} className="">
                <Row className="justify-content-end">
                  <Col md={11} lg={11} xs={11} className="">
                    <p className="text-center pr-5">Siguiente: Forma de entrega</p>
                  </Col>
                  <Col md={11} lg={11} xs={11} className="d-flex justify-content-end">
                    <Link
                      to={{
                        pathname: '/shipping?gift',
                        state: { discount: true, percentage: 25 },
                      }}
                    >
                      <Button onClick={() => dispatch(setIsGiftOrder(true))} className="white-btn p-2">
                        <FiGift className="mr-2" />
                        COMPRAR COMO REGALO
                      </Button>
                    </Link>
                    <Link
                      to={{
                        pathname: '/shipping',
                        state: {
                          discount: hasDiscount,
                          percentage: appliedDiscount,
                          typeDiscount: typeDiscount,
                        },
                      }}
                    >
                      <Button
                        onClick={() => dispatch(setIsGiftOrder(false))}
                        className="orange-btn ml-3 p-2"
                        disabled={isDiscoungBeingApplied}
                      >
                        COMPRAR PARA MÍ
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={3} className="mb-2 mt-3 text-muted priceCol">
            <Card>
              <Card.Body>
                <div className="aside-title mb-3 px-2">
                  <span>Tu pedido</span>
                </div>
                <Card.Subtitle className="mb-3 pl-3 text-muted">{countItems} items</Card.Subtitle>
                <Col className="mt-4 d-flex justify-content-between">
                  <p>Subtotal:</p>
                  <p> {subtotal} €</p>
                </Col>
                <Col className="d-flex justify-content-between">
                  <p>IVA:</p>
                  <p> {iva} €</p>
                </Col>
                <Col className="d-flex justify-content-between">
                  <p>Envío para España: </p>
                  <p>{shippingCosts > 0 ? shippingCosts + ' €' : <b>Gratis</b>} </p>
                </Col>
                <Col className="borderDiscount  d-flex justify-content-between">
                  <p>{showDiscount ? 'Total sin descuento:' : 'Total:'}</p>
                  <p>{totalWithoutDiscount} €</p>
                </Col>
                {showDiscount && (
                  <>
                    <Col className=" d-flex justify-content-between textDiscount">
                      <p>Descuento: </p>
                      <p>{`${appliedDiscount}${typeDiscount === 0 ? '%' : '€'} `} </p>
                    </Col>
                    <Col className=" d-flex justify-content-between textDiscount">
                      <p>Monto a descontar</p>
                      <p>{(totalWithoutDiscount - finalPrice).toFixed(2)} €</p>
                    </Col>
                    <Col className=" d-flex justify-content-between textDiscount borderDiscount">
                      <p>Total con descuento: </p>
                      <p>{finalPrice} €</p>
                    </Col>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            {isFinishedShop ? (
              <Jumbotron style={{ textAlign: 'center' }}>
                <p>Pedido procesado con éxito.</p>
                <p>Recibirás un email con los datos del pedido.</p>
                {parseInt(detailOrder.paymentMethod) === 1 && (
                  <Row style={{ justifyContent: 'center' }}>
                    <Col md={10}>
                      <Table bordered hover variant="dark">
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              <h3>Realizar transferencia a:</h3>{' '}
                            </td>
                          </tr>
                          <tr>
                            <td>Cuenta bancaria :</td>
                            <td>{data.bank_account}</td>
                          </tr>
                          <tr>
                            <td>Concepto:</td>
                            <td>{detailOrder.numOrder} </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col md={8} style={{ marginTop: 30 }}>
                      <p>
                        Por favor para completar su compra realize una transferencia al número de cuenta{' '}
                        <b>{data.bank_account}</b> con concepto el numero de pedido : <b>{detailOrder.numOrder}</b>.
                      </p>
                      <p>
                        Las trasferencias pueden tardar de 2 a 3 días en recibirse. Una vez hayamos recibido el pago
                        prepararemos el envío de su pedido.
                      </p>
                      <p>Gracias</p>
                    </Col>
                  </Row>
                )}
              </Jumbotron>
            ) : (
              <Row className="noFavorites text-center">
                <Col lg={10} className="margin-bottom-md mt-5">
                  <img src={emptyBag} alt="bag.png" />
                </Col>
                <Col lg={10} className="margin-bottom-md">
                  <h2>Tu cesta está vacía</h2>
                  <p>Navega por la tienda online y descubre nuestro amplio catálogo.</p>
                  <p>
                    Vuelve atrás. Si lo deseas puedes acceder a la página principal de
                    <Link to="/" className="">
                      {' '}
                      Libelista
                    </Link>
                  </p>
                  <Link to="/" className="">
                    <Button className="mt-4 p-3">VOLVER AL HOME</Button>
                  </Link>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      )}
      <Row className="justify-content-center mt-5">
        <Col lg={9} className="p-0">
          {relatedProducts.length > 0 && (
            <section className="margin-bottom-md list-section">
              <Row className="">
                <Col xs={12} sm={9}>
                  <h3>Otros productos que te pueden interesar</h3>
                </Col>
                <Col xs={12} sm={2} className="v-center"></Col>
              </Row>
              <Col xs={12} sm={12}>
                <Carousel
                  responsive={responsive}
                  slidesToSlide={1}
                  minimumTouchDrag={40}
                  draggable
                  infinite={true}
                  centerMode={false}
                  swipeable
                >
                  {relatedProducts.map((item, index) => (
                    <div key={`relatedProduct#${index}`} className="slide-div">
                      <ItemList data={item} />
                    </div>
                  ))}
                </Carousel>
              </Col>
            </section>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Cart;
