import React, { useEffect } from 'react';
import { Button, Row, Col, Navbar, NavDropdown, Dropdown, ButtonGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { logoutApi } from '../../api/login';
import { unSetLocal } from '../../helpers';
import {
  userSelector,
  setIsWorker,
  logoutSuccess,
  setAsociatedLibraryImage,
  setEmail,
  setFirstName,
  setLastName,
} from '../../slices/user';
import { clear, clearGiftCard } from '../../slices/cart';
import Logo from '../../assets/img/LOGO.png';
import { AiFillLock } from 'react-icons/ai';
import './adminPanelHeader.scss';

const AdminPanelHeader = () => {
  const { firstName, lastName, email } = useSelector(userSelector);

  const history = useHistory();
  const dispatch = useDispatch();

  const closeSession = async () => {
    const { status } = await logoutApi({
      refresh_token: localStorage.getItem('refresh'),
    });
    if (status === 200) {
      unSetLocal('access');
      unSetLocal('refresh');
      unSetLocal('permissions');
      unSetLocal('type_user');
      dispatch(setIsWorker(false));
      dispatch(setEmail(''));
      dispatch(setFirstName(''));
      dispatch(setLastName(''));
      dispatch(setAsociatedLibraryImage(''));
      dispatch(clear());
      dispatch(logoutSuccess());
      dispatch(clearGiftCard());
    }
    history.push('/');
  };

  return (
    <header className="">
      <Navbar className="d-flex justify-content-center panelHeader">
        <Row className="w-100 h-100 justify-content-between">
          <Col lg={3}>
            <Link to={'/'}>
              <img src={Logo} alt="logo.png" />
            </Link>
          </Col>
          <Col lg={3} className="d-flex justify-content-end pr-0 align-items-center dropCol">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle>
                {firstName !== '' ? firstName + ' ' + lastName : 'userName'}
                <AiFillLock className="ml-2" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="">
                <Dropdown.Item>{firstName !== '' ? firstName + ' ' + lastName : 'userName'}</Dropdown.Item>
                <Dropdown.Item>{email !== '' ? email : 'userEmail'}</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={closeSession}>
                  <p>Salir</p>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Navbar>
    </header>
  );
};

export default AdminPanelHeader;
