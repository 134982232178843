import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { InputCustom } from '../../components/input';
import {
  loginSuccess,
  setShippingAddress,
  setBillingAddress,
  setAsociatedLibraryImage,
  setFirstName,
  setLastName,
  setEmail,
  setBenefitedBookstore,
} from '../../slices/user';
import { cartSelector, addItem, setGiftCard } from '../../slices/cart';
import { loginApi } from '../../api/login';
import { getCart, addItemCart } from '../../api/ecommerce';
import { infoUser } from '../../api/users';

const LoginPage = () => {
  const [emailInput, setEmailInput] = useState('');
  const [password, setPassword] = useState('');
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const dispatch = useDispatch();
  const { items } = useSelector(cartSelector);
  const history = useHistory();

  const onLogin = async (event) => {
      event.preventDefault()
      setInvalidCredentials(false);
      const payload = { username: emailInput, password };
      const { status, data } = await loginApi(payload);
      if (status === 200) {
        localStorage.setItem('access', data.access);
        localStorage.setItem('refresh', data.refresh);
        localStorage.setItem('type_user', data.type_user);
        localStorage.setItem('permissions', data.permissions);
        handleInfoUser();
        dispatch(loginSuccess());
        dispatch(setBillingAddress(data.direccion_facturacion));
        dispatch(setShippingAddress(data.direccion_envio));
        dispatch(setAsociatedLibraryImage(data.logo_empresa));
        dispatch(setBenefitedBookstore(data.benefited_bookstore));
        const cart = await getCart();
        if (cart.status === 200) {
          if (cart.data.items) {
            for (const item of cart.data.items) {
              if (item.producto === null && item.created_gift_card !== null) {
                dispatch(setGiftCard(item.created_gift_card));
              } else if (item.producto !== null && item.created_gift_card !== null) {
                dispatch(addItem(item.producto));
                dispatch(setGiftCard(item.created_gift_card));
              } else {
                dispatch(addItem(item.producto));
              }
            }
          }
        } else {
          console.log('error');
        }

        for (const item of items) {
          await addItemCart({ item: item.id, quantity: item.quantity });
        }

        history.push('/');
      } else if (status === 400) {
        setInvalidCredentials(true);
      } else {
        console.log('Error');
      }
  };

  async function handleInfoUser() {
    const rs = await infoUser();
    if (rs.status === 200) {
      dispatch(setFirstName(rs.data.name));
      dispatch(setLastName(rs.data.last_name));
      dispatch(setEmail(rs.data.email));
    }
  }

  return (
    <div className="container-fluid mainDiv">
      <Row className="justify-content-center mainRow infoRow">
        <Col lg={8} className="mt-4">
          <div className="row">
            <div className="col-md-12">
              <h1 className="infoTitle">Bienvenido</h1>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p className="mb-2">¿Ya tienes cuenta?</p>
            </div>
            <Form onSubmit={onLogin} style={{width: '110%'}}>
              <div className="col-md-6 pt-2 pb-0" style={{ background: '#F8F8F8' }}>
                <p className="mb-0 inputTitle lato">Correo electrónico*</p>
                <InputCustom
                  type="text"
                  placeholder="Correo electrónico"
                  onChange={(e) => setEmailInput(e.target.value)}
                  value={emailInput}
                />
              </div>
              <div className="col-md-6 py-2" style={{ background: '#F8F8F8' }}>
                <p className="mb-0 inputTitle lato">Contraseña*</p>
                <InputCustom
                  type="password"
                  placeholder="Contraseña"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>
              {invalidCredentials && (
                <Col lg={12}>
                  <p style={{ color: '#F28C0F', fontSize: '19px' }}>El usuario o la contraseña son incorrectos</p>
                </Col>
              )}
              <Button type='submit' className="blue-btn px-4 py-1 lato" style={{ fontSize: '15px', fontWeight: '500' }}>
                ENVIAR
              </Button>
            </Form>
          </div>
          <Link to={'/recuperar-contrasena'}>
            <p className="mt-3 blue">¿Has olvidado tu contraseña?</p>
          </Link>
        </Col>
        <Col lg={3} className="mt-5 pt-4">
          <p className="mt-3 pt-4">¿Aún no estás registrado?</p>
          <Link to={'/register'}>
            <Button className="blue-btn px-4 py-1 lato" style={{ fontSize: '15px', fontWeight: '500' }}>
              REGÍSTRATE
            </Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
