import React from 'react';
import { Row, Col } from 'react-bootstrap';

import AdminFooter from '../components/adminFooter';
import PanelHeader from '../components/adminPanelHeader';
import AdminAsideMenu from '../components/adminAsideMenu';

const AdminPanelLayout = (props) => {
  return (
    <>
      <PanelHeader />
      <main className="border w-100 overflow-hidden pr-2">
        <Row className="m-0">
          <Col lg={2} className="border px-0 overflow-hidden">
            <AdminAsideMenu />
          </Col>
          <Col lg={10} className="border min-vh-100">
            {props.children}
          </Col>
        </Row>
      </main>
      <AdminFooter />
    </>
  );
};

export default AdminPanelLayout;
