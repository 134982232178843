import { getAllDiscountCoupons as get, deleteDiscountCoupon as del } from '../../../../api/products';
import React, { useEffect, useState } from 'react';
import BackofficeTable from '../../../../components/backoffice/table/index';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import { InputCustom } from '../../../../components/input';
import SectionLoader from '../../../../components/loader/SectionLoader';
import { useLocation, useHistory } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import Select from 'react-select';
import './styleTableCoupons.scss';

const headers = {
  coupon: "Nombre del Cupón",
  discount: "Descuento",
  type_discount: "Tipo",
  is_active: "Estado",
  valid_from: "Fecha de inicio",
  valid_until: "Fecha de caducidad",
};

const CouponsTable = (props) => {
  const { ActionsMenu, loadData } = props;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [coupon, setCoupon] = useState(() => query.get('coupon') || '');
  const [type_discount, setType_Discount] = useState(() => query.get('type_discount') || '');
  const [is_active, setIs_Active] = useState(() => query.get('is_active') || '');
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showInputCoupon, setShowInputCoupon] = useState(false);
  const [showInputTypeDiscount, setShowInputTypeDiscount] = useState(false);
  const [showInputIsActive, setShowInputIsActive] = useState(false);
  const history = useHistory();
  loadData.colSpan = Object.keys(headers).length;

  const optionsTypeDiscount = [
    { value: 0, label: 'Descuentos por porcentaje' },
    { value: 1, label: 'Descuentos por monto' },
  ];

  const optionsIsActive = [
    { value: 'true', label: 'Activo' },
    { value: 'false', label: 'Inactivo' },
  ];

  const utilitiesWidth = {
    width: '200px',
  };

  const marginBottomContainer = {
    marginBottom: '55px',
  };

  const deleteQueryParams = () => {
    history.push('/administration/coupons');
  };

  const myInputs = () => {
    const showSearchButton = showInputCoupon || showInputTypeDiscount || showInputIsActive;
    const url = window.location.href;
    const includesQueryParams = url.includes('?');

    return (
      <div className="d-flex " style={marginBottomContainer}>
        {includesQueryParams && (
          <button className="btn  buttonDeleteQuery" onClick={() => deleteQueryParams()}>
            Volver
          </button>
        )}
        <Accordion
          defaultActiveKey="1"
          className={`${includesQueryParams ? 'modifyPosition' : 'searchButton'}  `}
          style={{ marginTop: '15px' }}
        >
          <Accordion.Toggle className="btn btn-info w-100 ml-3">Búsqueda</Accordion.Toggle>
          <Accordion.Collapse className="ml-3 searchAccordion">
            <div className="d-flex flex-column ml-3">
              <button onClick={() => setShowInputCoupon(!showInputCoupon)}>Cupón</button>
              <button onClick={() => setShowInputTypeDiscount(!showInputTypeDiscount)}>Tipo de descuento</button>
              <button onClick={() => setShowInputIsActive(!showInputIsActive)}>Estado</button>
            </div>
          </Accordion.Collapse>
        </Accordion>
        <form className={`${includesQueryParams ? 'modifyMarginForm' : 'modifyForm'}  d-flex  `} onSubmit={searchData}>
          {showInputCoupon && (
            <div className="mr-3 ">
              <InputCustom
                key={`hello`}
                type="text"
                value={coupon}
                onChange={(event) => {
                  setCoupon(event.target.value);
                }}
                placeholder="Nombre del cupón"
                className="inputFontSize "
                style={{ zIndex: '1' }}
              />
            </div>
          )}
          {showInputTypeDiscount && (
            <div className="mr-3 " style={utilitiesWidth}>
              <Select
                options={optionsTypeDiscount}
                onChange={({ value }) => {
                  setType_Discount(value);
                }}
                placeholder="Tipo de descuento"
                className="inputFontSize "
              />
            </div>
          )}

          {showInputIsActive && (
            <div className="mr-3 " style={utilitiesWidth}>
              <Select
                options={optionsIsActive}
                onChange={({ value }) => {
                  setIs_Active(value);
                }}
                placeholder="Estado"
                className="inputFontSize"
                style={{ zIndex: '1' }}
              />
            </div>
          )}
          {showSearchButton && (
            <div className="my-3 ">
              <input className="btn btn-info btn-search" type="submit" value="Buscar" />
            </div>
          )}
        </form>
      </div>
    );
  };

  const getLoadData = async () => {
    setIsLoading(true);
    const { status: status1, data } = await get(1, 15, coupon, type_discount, is_active);
    if (status1 === 200) {
      setData(data.results);
    }
    setIsLoading(false);
    setCoupon('');
    setType_Discount('');
    setIs_Active('');
  };

  useEffect(() => {
    getLoadData();
  });

  const searchData = () => {
    history.push(`?&coupon=${coupon}&type_discount=${type_discount}&is_active=${is_active}&page=${1}&page_size=${15}/`);
    getLoadData();
  };

  const hasData = () => {
    if (data !== null && data !== undefined) {
      return Object.keys(data).length;
    } else {
      return false;
    }
  };

  const couponsTableProps = {
    title: 'Cupones',
    data,
    headers,
    ActionsMenu,
    loadData,
    extraFormat: {
      valid_from: "date",
      valid_until: "date",
      is_active: "status",
      discount: "int",
      type_discount: "typeDiscount"
    },
    customParams: myInputs(),
  };
  if (isLoading) {
    return <SectionLoader />;
  } else if (hasData()) {
    return <BackofficeTable {...couponsTableProps} />;
  } else {
    return (
      <>
        <div>No se encontraron datos</div>
        <button className="btn btn-info" onClick={() => deleteQueryParams()}>
          Volver
        </button>
      </>
    );
  }
};
CouponsTable.request = {
  get,
  del,
};
CouponsTable.itemClass = 'cupón';
CouponsTable.requestParameter = 'id';
const CouponsTablePage = () => <BackofficePagesManager PageViewer={CouponsTable} />;
export default CouponsTablePage;
