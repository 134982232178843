import React from "react";
import { Row, Col } from "react-bootstrap";

import Devoluciones from "../../assets/img/img_devoluciones.png";
import "./infoPages.scss";

//redux
import { useSelector } from "react-redux";
import { companySelector } from "../../slices/company";

//own components
import Head from "../../components/metatags_helmet/metatags";

const Refunds = () => {
  const title = "Devoluciones y anulaciones";
  const { descripcion_pub } = useSelector(companySelector);

  return (
    <div className="container-fluid mainDiv">
      <Head description={descripcion_pub} title={title} noIndex={true} />
      <Row className="justify-content-center mainRow infoRow">
        <Col lg={8} className="mt-4">
          <div className="row">
            <div className="col-md-12">
              <h1 className="infoTitle">Devoluciones y anulaciones</h1>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>
                Desde Libelista aceptamos devoluciones efectuadas dentro del
                plazo de 14 días naturales desde el envío del pedido. Los gastos
                de transporte, en este caso, serán asumidos por el cliente. Una
                vez recibida la devolución, Libelista comprobará su estado antes
                de reintegrar el importe.
              </p>

              <p>
                Si recibes un pedido en mal estado, puedes devolverlo y se te
                reembolsará el importe íntegro de tu compra (libro/s y gastos de
                envío)
              </p>

              <p>
                Puedes anular un pedido siempre y cuando no hayamos procedido al
                envío del mismo.
              </p>

              <p>
                Los libros digitales adquiridos a través de Libelista no podrán
                ser devueltos a no ser que la causa de la devolución sea debido
                a una incidencia técnica.
              </p>

              <p>
                Si tienes cualquier duda, puedes escribirnos a{" "}
                <strong>pedidos@libelista.com</strong>
              </p>
            </div>
          </div>
        </Col>
        <Col lg={3} className="mt-4">
          <img src={Devoluciones} alt="return.png" className="infoImg" />
        </Col>
      </Row>
    </div>
  );
};

export default Refunds;
