import React, { useState, useEffect } from "react";
import { Row, Col, Container, Jumbotron, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { cartSelector, clear } from "../../slices/cart";
import { userSelector } from "../../slices/user";
import { createOrder } from "../../api/ecommerce";
import { useLocation, Link } from "react-router-dom";
import shoppingbag from "../../assets/img/ico_shoppingbag.png";
import "./cart.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const OkPayment = () => {
  const query = useQuery();
  const ds_merchant_parameters = query.get("Ds_MerchantParameters");
  const ds_signature = query.get("Ds_Signature");
  const {
    isNewShippingAddress,
    storePickup,
    items,
    shippingCosts,
    isGiftOrder,
    giftData,
    appliedCoupon
  } = useSelector(cartSelector);
  const {
    billingAddress,
    shippingAddress,
    benefitedBookstore,
    pickUpLibrary
  } = useSelector(userSelector);
  const [header, setHeader] = useState("En proceso");
  const [msg, setMsg] = useState("Procesando pedido");
  const [successPayment, setSuccessPayment] = useState(true);

  const dispatch = useDispatch();

  const confirmOrder = async () => {
    if (items.length > 0) {
      const payload = {
        forma_pago: 4,
        billing_address: billingAddress,
        shipping_address: shippingAddress,
        is_new_shipping_address: isNewShippingAddress,
        store_pickup: storePickup,
        items,
        shipping_cost: shippingCosts,
        finish_process: true,
        is_gift: isGiftOrder,
        benefited_company: benefitedBookstore?.id,
        ds_merchant_parameters,
        ds_signature,
        applied_coupon: appliedCoupon
      };
      if (storePickup === true) {
        payload.bookstore_to_pickup = pickUpLibrary.id
      }
      if (isGiftOrder === true) {
        payload.gift_data = giftData
      }
      try {
        const { status, data } = await createOrder(payload);
        if (status === 201) {
          setSuccessPayment(true)
          dispatch(clear());
        } else {
          setHeader("Error al procesar el pedido");
          setMsg("Ha ocurrido un error al generar el pedido");
        }
      } catch (error) {
        setHeader("Error al procesar el pedido");
        setMsg("Ha ocurrido un error al generar el pedido");
      }
    } else {
      setHeader("Carrito vacío");
      setMsg("No hay elementos en el carrito");
    }
  };

  useEffect(() => {
    confirmOrder();
  }, []);

  return (
    <Container className="mainDiv">
      {successPayment ? (
        <Row className="noFavorites text-center">
          <Col lg={10} className="margin-bottom-md mt-5">
            <img src={shoppingbag} alt="bag.png" />
          </Col>
          <Col 
            lg={8} 
            className="margin-bottom-md position-relative"
            style={{left: '9%'}}
          >
            <h2>¡Muchas gracias por tu compra!</h2>
            <p>
            Muchas gracias por comprar en Libelista la red de librerías independientes.
            </p>
            <p>Te hemos enviado un mail con los datos de tu compra.</p>
            <p>
            Si has comprado libros digitales puedes acceder a ellos pulsando en la opción del menú
              <Link to="/my/books" className="">
                {" "}
                Mis libros
              </Link>.
            </p>
            <p>Si has comprado libros en papel, los recibirás en los próximos días.</p>
            <Link to="/" className="">
              <Button className="mt-4 p-3">VOLVER AL HOME</Button>
            </Link>
          </Col>
        </Row>
      ) : (
        <div>
          <Row>
            <Col md={12} className="title">
              <h2>Carrito de Compras</h2>
              <span className="divider-title"></span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Jumbotron style={{ textAlign: "center" }}>
                <h3>{header}</h3>
                <p>{msg}</p>
              </Jumbotron>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default OkPayment;
