import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Dropdown, Accordion } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { getLibelistaMainLists } from '../../api/libelista';
import { setMainLists, companySelector } from '../../slices/company';
import menuBooks from '../../assets/img/img_menu_books.png';

const BOOKS_RELATED_LINKS = [
  { title: 'NOVEDADES', path: '/lista/news?is_novelty=true' },
  { title: 'LAS LIBRERÍAS RECOMIENDAN', path: '/lista/recommendations?is_recommended=true' },
  { title: 'LIBROS PREMIADOS', path: '/prizes' },
  { title: 'EBOOKS', path: '/lista/ebooks' },
];
const CustomDropdown = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [subListOpenedKey, setSubListOpenedKey] = useState(undefined);
  const MenuToggler = useRef(undefined);
  const { mainLists } = useSelector(companySelector);
  const dispatch = useDispatch();

  const getAllLists = async () => {
    if (mainLists?.length === 0) {
      const { status, data } = await getLibelistaMainLists();
      if (status === 200) {
        dispatch(setMainLists(data));
      }
    }
  };

  useEffect(() => {
    getAllLists();
  }, []);

  const closeMenu = () => {
    MenuToggler.current.click();
  };

  return (
    <Dropdown className="customDrop">
      <Dropdown.Toggle ref={MenuToggler}>LIBROS</Dropdown.Toggle>
      <Dropdown.Menu
        style={{ height: '35vh' }}
        className="overflow-sm-auto overflow-lg-hidden"
        show={showMenu}
        rootCloseEvent="click"
      >
        <Row className="h-100">
          <Col lg={4} xl={3} className="d-flex flex-column justify-content-center align-items-start h-100 mt-n4">
            <Dropdown.Item disabled>
              <img className="mb-2 mt-n5" src={menuBooks} alt="books.png" />
            </Dropdown.Item>
            {BOOKS_RELATED_LINKS.map(({ title, path }, index) => (
              <Dropdown.Item
                className="text-uppercase"
                as={Link}
                to={path}
                key={`key${index + 1}`}
                eventKey={index}
                onClick={() => setShowMenu(false)}
              >
                {title}
              </Dropdown.Item>
            ))}
          </Col>
          <Col lg={8} xl={9} className="d-flex flex-column h-100" style={{ overflowX: 'auto' }}>
            <p className="mb-2 w-100 text-uppercase bg-light p-2">MATERIAS</p>
            <Accordion className="pl-2 d-flex flex-column flex-lg-wrap h-75">
              {mainLists &&
                mainLists.map(({ id, title, children: subLists = [], url }, index) => {
                  const SUBLIST_LINKS = subLists.map(({ id, url, title }, index) => (
                    <Link
                      className="d-block m-0 pl-1"
                      key={`collapsedItem${index}`}
                      to={title && id ? `/lista/materias/${id}` : `/#`}
                      onClick={closeMenu}
                    >
                      {title}
                    </Link>
                  ));
                  const listHasChildren = Boolean(subLists.length);
                  return (
                    <div
                      className="p-0 m-0"
                      key={`CustomDropdownList#${index}`}
                      style={{ width: window.innerWidth <= 576 ? '100%' : '25%', borderBottom: '1px solid #e5e5e5' }}
                    >
                      <Accordion.Toggle
                        className="d-flex w-100 text-uppercase justify-content-start p-0 m-0"
                        disabled={!listHasChildren}
                        eventKey={index}
                        onClick={() => {
                          setSubListOpenedKey(subListOpenedKey !== index ? index : undefined);
                        }}
                      >
                        <Link
                          onClick={() => {
                            setShowMenu(false);
                            closeMenu();
                          }}
                          to={title && id ? `/lista/materias/${id}` : `/#`}
                          className="text-justify m-0 p-0 "
                          // onClick={closeMenu}
                        >
                          {title}
                        </Link>
                        {listHasChildren && (
                          <span
                            className="font-weight-bold align-self-center px-2 py-0 m-0"
                            style={{
                              transform: `scale(1.25,1) ${subListOpenedKey === index ? 'rotate(180deg)' : ''}`,
                            }}
                          >
                            ^
                          </span>
                        )}
                      </Accordion.Toggle>
                      <Accordion.Collapse className="w-100 m-0 p-0" eventKey={index}>
                        <>{SUBLIST_LINKS}</>
                      </Accordion.Collapse>
                    </div>
                  );
                })}
            </Accordion>
          </Col>
        </Row>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;
