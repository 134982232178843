import { methodGet, methodPost, methodDelete, methodPatch } from './methods';
import { API_INFO } from '../constants';

export async function getRecentSales(page = 1, pageSize = 10) {
  const url = API_INFO + `storehouse/pedidos/recent_sales/?page=${page}&page_size=${pageSize}`;
  return methodGet(url);
}
export async function getRecentSalesNumber() {
  const url = API_INFO + `storehouse/pedidos/units_solds_last_week/`;
  return methodGet(url);
}
export async function getDetailOrder(id) {
  const url = API_INFO + `storehouse/orders/${id}/?dashboard=1`;
  return methodGet(url);
}
export async function getDetailOrderProfile(id) {
  const url = API_INFO + `storehouse/orders/${id}/`;
  return methodGet(url);
}
export async function deleteDetailOrder(id) {
  const url = API_INFO + `storehouse/orders/${id}/?dashboard=1`;
  return methodDelete(url);
}
export async function editDetailOrder(payload) {
  const url = API_INFO + `storehouse/pedidos/${payload.id || payload.get('id')}/?dashboard=1`;
  return methodPatch(url, payload);
}

export async function getAllOrders(page = 1, pageSize = 10, search = ' ') {
  const url =
    API_INFO + `storehouse/orders/?dashboard=1&page=${page}&page_size=${pageSize}${search ? '&search=' + search : ''}`;
  return methodGet(url);
}

export async function sendClaim(payload) {
  const url = API_INFO + 'storehouse/claims/';
  return methodPost(url, payload);
}
export async function getUserBoughtBooks() {
  const url = API_INFO + 'storehouse/orders/books_purchased_by_user/';
  return methodGet(url);
}
export async function getPurchaseCommissions(page = 1, pageSize = 10) {
  const url = API_INFO + `storehouse/commissions/?page=${page}&page_size=${pageSize}`;
  return methodGet(url);
}
export async function getCommissionsByCompany() {
  const url = API_INFO + `storehouse/commissions/commissions_by_company/`;
  return methodGet(url);
}
