import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import ItemList from "../../components/itemList/item";

import { favorites } from "../../api/lists";
import { userSelector } from "../../slices/user";
import { useSelector } from "react-redux";

import { removeItemFav } from "../../api/products";
import { AiFillDelete } from "react-icons/ai";
import favorite from "../../assets/img/ico_nofavorite.png";
import "./favorites.scss";

const Favorites = () => {
  const [saved, setSaved] = useState([]);
  const { isLogged } = useSelector(userSelector);

  useEffect(() => {
    async function handleFavorites() {
      const rs = await favorites();
      if (rs.status === 200) {
        setSaved(rs.data.productos);
      }
    }
    handleFavorites();
  }, []);

  const removeProductFav = async (item) => {
    if (isLogged) {
      const payload = { url: item.url };
      const id = item.id;
      for (var i = 0; i < saved.length; i++) {
        if (saved[i].id === id) {
          saved.splice(i, 1);
        }
      }
      const { status } = await removeItemFav(payload);
    }
  };

  const goUp = () => {
    window.scrollTo(0, 0);
  };
  goUp();

  return (
    <Container
      fluid
      className="margin-top-md justify-content-center d-flex mainDiv"
    >
      {saved.length > 0 ? (
        <Row className="favorites">
          <Col lg={12} className="">
            <h1 className="infoTitle">Favoritos</h1>
            <hr />
          </Col>
          {saved.map((item, i) => (
            <Col lg={2} className="margin-md">
              <ItemList key={i} data={item} />
              <div className="center margin-top-xs ">
                <Link
                  to="/favoritos"
                  className="button button--neutral button--small cart-item__delete "
                  aria-label="eliminar este item de favoritos"
                  onClick={() => removeProductFav(item)}
                >
                  <AiFillDelete className="icon-sm" />
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      ) : (
        <Row className="noFavorites text-center mt-4">
          <Col lg={10} className="margin-bottom-md mt-5">
            <img src={favorite} alt="fav.png" />
          </Col>
          <Col lg={10} className="margin-bottom-md">
            <h2>No tienes productos en tu lista de favoritos</h2>
            <p>
              Si lo deseas puedes acceder a la página principal de
              <Link to="/" className="">
                {" "}
                Libelista
              </Link>
            </p>
            <Link to="/" className="">
              <Button className="mt-4 p-3">VOLVER AL HOME</Button>
            </Link>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Favorites;
