import React from 'react';
import { Nav, Row, Col, Container, NavDropdown } from 'react-bootstrap';
import './footer.scss';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { FaMapMarkerAlt } from 'react-icons/fa';
import SubscriptionForm from '../subscription/subscriptionForm';
import FindLibraries from '../findLibraries/findLibraries';
import creditCards from '../../assets/img/creditcards.png';

const Footer = ({
  name,
  brandLogo,
  direccion_completa,
  telefono,
  email,
  redes_sociales,
  data,
  menus,
  has_subscription,
  refundText,
}) => {
  function createMarkup() {
    const text = `Copyright &copy; 2020 ${name}. Todos los derechos reservados.`;
    return {
      __html: text,
    };
  }

  const goUp = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer
      className="footer"
      style={{
        marginTop: '2rem',
      }}
    >
      <FindLibraries />
      <SubscriptionForm />
      {data ? (
        <>
          <Container fluid className="footer-links justify-content-center p-0" style={{ cursor: 'pointer' }}>
            <Row className="information">
              <Col className="secondSection" xs={8} sm={8} md={3} xl={3}>
                <h5 className="title-footer">Sobre nosotros</h5>
                <Nav.Link href="/about-us">
                  <p className="textFooter">¿Quiénes somos?</p>
                </Nav.Link>
                <Nav.Link href="/contact">
                  <p className="textFooter">Contacto</p>
                </Nav.Link>
                <Nav.Link href="/librerias">
                  <p className="textFooter">Librerías</p>
                </Nav.Link>
              </Col>

              <Col className="thirdSection" xs={8} sm={8} md={3} xl={3}>
                <h5 className="title-footer">Compra con confianza</h5>
                <Nav.Link href="/como-comprar">
                  <p className="textFooter">Cómo comprar</p>
                </Nav.Link>
                <Nav.Link href="/ebook">
                  <p className="textFooter">Ebook</p>
                </Nav.Link>
                <Nav.Link href="/tarifas-y-plazos-de-entrega">
                  <p className="textFooter">Tarifas de envío</p>
                </Nav.Link>
                <Nav.Link href="/tarifas-y-plazos-de-entrega">
                  <p className="textFooter">Plazos de entrega</p>
                </Nav.Link>
                <Nav.Link href="/devoluciones-y-anulaciones">
                  <p className="textFooter">Devoluciones y anulaciones</p>
                </Nav.Link>
              </Col>

              <Col className="secondSection" xs={8} sm={8} md={3} xl={3}>
                <h5 className="title-footer">Condiciones Generales</h5>
                <div className="textFooter contact pb-2">
                  <Link to="/aviso-legal-y-politica-de-privacidad" onClick={goUp}>
                    <p className="contactDetails">Aviso legal y privacidad</p>
                  </Link>
                </div>
                <div className="textFooter contact pb-2">
                  <Link to="/condiciones-de-uso" onClick={goUp}>
                    <p className="contactDetails">Condiciones de uso</p>
                  </Link>
                </div>
                <div className="textFooter contact pb-2">
                  <Link to="/cookies" onClick={goUp}>
                    <p className="contactDetails">Cookies</p>
                  </Link>
                </div>
              </Col>

              <Col className="lastSection" xs={12} sm={12} md={6} xl={3}>
                <p>Aceptamos múltiples medios de pago online</p>
                <div>
                  <img src={creditCards} alt="cards.png" />
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            <Col lg={12} className="bottom-footer">
              <Row className="copyright">
                <div className="markup" dangerouslySetInnerHTML={createMarkup()} />
              </Row>
            </Col>
          </Container>
        </>
      ) : null}
    </footer>
  );
};

export default Footer;
