import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
// api
import { searchFilterProduct } from '../../api/products';
import { getAuthor, getProductsByAuthor, getDetailEditorial, getProductsByEditorial } from '../../api/products';
//hooks
import { useIntersectionObserver } from 'react-intersection-observer-hook';
// own components
import ItemList from '../../components/itemList/item';
import ListItemsFilter from './listItemsFilter';
// own constants
import { companySelector } from '../../slices/company.js';
import { useSelector } from 'react-redux';
import { recoverMetatagName } from '../../utils';

import Head from '../../components/metatags_helmet/metatags';
import './list.scss';
import { getBookList, getLibelistaListDetail } from '../../api/libelista';
import FeedbackPage from '../backoffice/feedbackPage';
import SectionLoader from '../../components/loader/SectionLoader';

const List = (props) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [contentListBottom, { entry }] = useIntersectionObserver();
  const [isLoading, setIsLoading] = useState(false);
  const [parameters, setParameters] = useState('');
  // const [parameters, setParameters] = useState('');
  const isVisible = entry && entry.isIntersecting;
  const { name: nameCompany } = useSelector(companySelector);
  const partsPathname = props.location.pathname.split('/');
  const type = partsPathname[1];
  const slug = partsPathname[2];
  const id = partsPathname[3];
  const history = useHistory();

  const getList = async () => {
    let responseDetail;
    if (type === 'autor') {
      responseDetail = await getAuthor(slug);
      if (responseDetail.status === 200) {
        setName(responseDetail.data.nombre_autor);
      }
    } else if (type === 'editorial') {
      responseDetail = await getDetailEditorial(slug);
      if (responseDetail.status === 200) {
        setName(responseDetail.data.nombre_fiscal);
      }
    }
  };

  const changeParameters = () => {
    if (type === 'lista') {
      switch (slug) {
        case 'materias':
          setParameters({ ordering: '' });
          break;
        case 'news':
          setParameters({ is_novelty: true });
          break;
        case 'recommendations':
          setParameters({ is_recommended: true });
          break;
        case 'ebooks':
          setParameters({ is_ebook: true });
          break;
        default: // Do nothing
      }
    }
  };

  const getLoadData = async () => {
    let newData;
    if (type === 'lista' && slug) {
      let response = {};
      if (slug === 'search') {
        setName('Resultados de búsqueda');
        response = await searchFilterProduct(props.location.search + `&page=${page}`);
      }
      if (parameters !== '') {
        switch (slug) {
          case 'materias':
            response = await getBookList(id, parameters);
            break;
          case 'news':
            setName('Novedades');
            response = await searchFilterProduct(parameters, page);
            break;
          case 'recommendations':
            setName('Libros Recomendados');
            response = await searchFilterProduct(parameters, page);
            break;
          case 'ebooks':
            setName('Ebooks');
            response = await searchFilterProduct(parameters, page);
            break;
        }
      }
      const { status, data } = response;
      if (status === 200) {
        if (page === 1) {
          newData = data.results;
        } else {
          newData = [...items, ...data.results];
        }
        setItems([...newData]);
        setNextPage(() => (data.next !== null ? true : false));
      }
      setIsLoading(false);
    } else if (type == 'autor') {
      const { status, data } = await getProductsByAuthor(slug, page)
      if (status === 200) {
        if (page === 1) {
          newData = data.results
        } else {
          newData = [...items, ...data.results]
        }
        setItems(newData)
        setNextPage(() => (data.next !== null ? true : false))
      }
      setIsLoading(false);
    } else if (type == 'editorial') {
      const { status, data } = await getProductsByEditorial(slug, page)
      if (status === 200) {
        if (page === 1) {
          newData = data.results
        } else {
          newData = [...items, ...data.results]
        }
        setItems(newData)
        setNextPage(() => (data.next !== null ? true : false))
      }
      setIsLoading(false);
    }
  };

  const addFilters = (value) => {
    if (slug === 'search') {
      let searchParams = new URLSearchParams(props.location.search);
      let [key, value2] = Object.entries(value)[0];
      searchParams.set(key, value2);
      history.push(`/lista/search?${searchParams}`);
    } else {
      Object.keys(value).forEach((e) => {
        const appliedParameters = parameters;
        delete appliedParameters[e];
        setParameters({
          [e]: value[e],
          ...appliedParameters,
        });
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const goUp = () => {
      window.scrollTo(0, 0);
    };
    setItems([]);
    setPage(1);
    goUp();
    getList();
    setParameters('');
    changeParameters();
    if (slug === 'materias') {
      getListName(id);
    }
  }, [slug, props.location.search, id]);

  useEffect(() => {
    getLoadData();
  }, [page, parameters]);

  const getListName = async (id) => {
    const { status, data } = await getLibelistaListDetail(id);
    if (status === 200) {
      setName(data.title);
    }
  };

  const setTitle = () => {
    if (name.toLowerCase() !== 'merchandising') {
      setMetaTitle(`*${recoverMetatagName(name)}*`);
      setDescription(
        `Si buscas libros o Ebooks sobre ${recoverMetatagName(name)}, ¡estás de suerte en encontrarnos! Compra en ${nameCompany} a domicilio.`
      );
    } else {
      setMetaTitle(`Merchandising ${nameCompany} | Venta Online de Productos`);
      setDescription(
        `Si eres fan de la variedad de Libros, Películas o Canciones en ${nameCompany} tenemos un amplio catálogo de merchandising que te puede gustar.`
      );
    }
  };

  useEffect(() => {
    setTitle();
  }, [name]);

  useEffect(() => {
    // infinite scrolling
    if (items.length !== 0) {
      (async () => {
        if (isVisible && nextPage) {
          setPage((prevPage) => prevPage + 1);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  useEffect(() => {
    setIsLoading(!isLoading);
  }, [items]);

  return (
    <div className="container-fluid px-0 mainDiv">
      <Head description={description} title={metaTitle} />
      <Row className="px-2 mx-0">
        <Col md={12}>
          <h1 className="page-title static-title mt-5">{name}</h1>
        </Col>
      </Row>
      <Container fluid className="px-0 mx-0">
        <Row className="justify-content-center pl-3 mx-0">
          <Col lg={2} className="px-0 mx-0">
            {['editorial', 'autor'].includes(type) == false && (<ListItemsFilter listName={name} selectFilter={addFilters} />)}
          </Col>
          <Col lg={10}>
            {isLoading ? (
              <SectionLoader />
            ) : items?.length ? (
              <Row className="itemsRow">
                {items.map((item, index) => (
                  <Col md={4} lg={2} sm={6} key={`itmes#${index}`} style={{ marginTop: 5 }} className="">
                    <ItemList key={index} data={item} />
                  </Col>
                ))}
              </Row>
            ) : (
              <FeedbackPage
                title="¡Búsqueda sin resultados!"
                message={`No encontramos entradas para la búsqueda ${props.location.search}`}
                maxHeight
              />
            )}
          </Col>
        </Row>
        <div className="content-bottom" style={{ height: '20px' }} ref={contentListBottom}></div>
      </Container>
    </div>
  );
};

export default List;
