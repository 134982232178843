import React, { useEffect, useState } from 'react';
import { Row, Col, FormControl, InputGroup } from 'react-bootstrap';
import { RiCloseLine } from 'react-icons/ri';
import { TiArrowSortedDown } from 'react-icons/ti';
import { BiSearchAlt2 } from 'react-icons/bi';
import './customSelect.scss';

/* detalle de props: 
  -setQueryCallback: pasa la función para realizar una búsqueda a la api y obtener un nuevo queryset
    de elementos(solo se utiliza en caso de filter=false).
  - updateValue: función para actualizar el valor seleccionado en el componente padre.
  - selectedItem: sincroniza con el componente padre el ítem seleccionado.
  - width: establece el valor de la propiedad width que tendrá el elemento.
  - handledField: al poder pasarsele distintos items con distintos campos, handledField indica el campo
    de relevancia en los ítems pasados.
  - filter: indica si el componente debe filtrar entre los ítems pasados desde el componente padre (true)
    o si realizará una nueva consulta a la api para obtene un nuevo queryset de elementos (false).
  - countries, languages, authors, materies: items pasados desde el componente padre.
*/
const CustomSelect = (props) => {
  const {
    setQueryCallback,
    updateValue,
    selectedItem,
    width,
    handledField,
    filter,
    countries,
    languages,
    authors,
    materies,
    libelistaLists,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const [label, setLabel] = useState('');
  const [librariesShow, setLibrariesShow] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [handledItems, setHandledItems] = useState([]);
  const field = handledField;

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const filterItems = (selection, value) => {
    if (value.length >= 2) {
      let holder = [];
      selection.map((el) => {
        if (el[handledField].toLowerCase().includes(value.toLowerCase())) {
          holder.push(el);
        }
      });
      setFilteredItems(holder);
    } else {
      setFilteredItems(handledItems);
    }
  };

  const defineHandledItems = () => {
    if (countries) {
      setHandledItems(countries);
    } else if (authors) {
      setHandledItems(authors);
    } else if (languages) {
      setHandledItems(languages);
    } else if (materies) {
      setHandledItems(materies);
    } else if (libelistaLists) {
      setHandledItems(libelistaLists);
    }
  };

  useEffect(() => {
    if (filter) {
      setFilteredItems(handledItems);
    }
    defineHandledItems();
  }, [handledItems, props]);

  return (
    <>
      {!isOpen ? (
        <Row className="inputBar" onClick={toggleOpen} style={{ width: width ? `${width}px` : '' }}>
          <Col lg={10} className="p-0 m-0">
            <p style={{ fontSize: '12px' }} className="mb-0 mt-1 pl-2">
              {selectedItem}
            </p>
          </Col>
          <Col lg={2} className="p-0 m-0 d-flex justify-content-center align-items-center">
            <TiArrowSortedDown />
          </Col>
        </Row>
      ) : (
        <Row className="unfoldedRow">
          <Col lg={12} className="border d-flex justify-content-between inputCol">
            {filter ? (
              <InputGroup className="mr-2 mt-2 mb-2">
                <FormControl
                  placeholder="Introduce al menos 2 caracteres"
                  onChange={(event) => filterItems(handledItems, event.target.value)}
                />
                <InputGroup.Text>
                  <BiSearchAlt2 />
                </InputGroup.Text>
              </InputGroup>
            ) : (
              <InputGroup className="mr-2 mt-2 mb-2">
                <FormControl
                  placeholder="Introduce al menos 2 caracteres"
                  onChange={(event) => setQueryCallback(event.target.value)}
                />
                <InputGroup.Text>
                  <BiSearchAlt2 />
                </InputGroup.Text>
              </InputGroup>
            )}
            <span id="closeSearchContainer" className="mt-1 pl-3" onClick={toggleOpen}>
              <RiCloseLine className="" style={{ fontSize: '18px' }} />
            </span>
          </Col>
          <Col lg={12} className="px-0 itemsCol">
            {authors
              ? authors.map((el, value) => {
                  return (
                    <p
                      style={{ borderBottom: '1px solid #d8d8d8' }}
                      className="mb-1 pb-2 pl-2"
                      role="button"
                      key={value}
                      onClick={() => {
                        updateValue(handledField, Array(el));
                        toggleOpen();
                      }}
                    >
                      {el.nombre_autor !== '' ? el.nombre_autor : 'Sin datos'}
                    </p>
                  );
                })
              : ''}
            {countries || languages
              ? filteredItems.map((el, value) => {
                  return (
                    <p
                      style={{ borderBottom: '1px solid #d8d8d8' }}
                      className="mb-1 pb-2 pl-2"
                      role="button"
                      key={value}
                      onClick={() => {
                        updateValue(handledField, el);
                        toggleOpen();
                      }}
                    >
                      {el.nombre !== '' ? el.nombre : 'Sin datos'}
                    </p>
                  );
                })
              : ''}
            {materies
              ? filteredItems.map((el, value) => {
                  return (
                    <p
                      style={{ borderBottom: '1px solid #d8d8d8' }}
                      className="mb-1 pb-2 pl-2"
                      role="button"
                      key={value}
                      onClick={() => {
                        updateValue(el);
                        toggleOpen();
                      }}
                    >
                      {el.descripcion !== '' ? el.descripcion : 'Sin datos'}
                    </p>
                  );
                })
              : ''}
            {libelistaLists
              ? filteredItems.map((el, value) => {
                  return (
                    <p
                      style={{ borderBottom: '1px solid #d8d8d8' }}
                      className="mb-1 pb-2 pl-2"
                      role="button"
                      key={value}
                      onClick={() => {
                        updateValue(el);
                        toggleOpen();
                      }}
                    >
                      {el.title !== '' ? el.title : 'Sin datos'}
                    </p>
                  );
                })
              : ''}
          </Col>
        </Row>
      )}
    </>
  );
};

export default CustomSelect;
