import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// own components
import ListsHome from './listLoaderHome';
import Slider from '../../components/slider';
import EventHome from './events';
import Head from '../../components/metatags_helmet/metatags';

import { setMainLists, companySelector } from '../../slices/company';

//api
import { getCompany } from '../../api/company';
import { slidersClient } from '../../api/products';
import { getPosts } from '../../api/blog';
import { getListsHome } from '../../api/lists';
import { getActivities, getLibelistaMainLists } from '../../api/libelista';

const Home = () => {
  const [company, setCompany] = useState({});
  const [sliders, setSliders] = useState([]);
  const [events, setEvents] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [lists, setLists] = useState([]);
  const { mainLists } = useSelector(companySelector);
  const dispatch = useDispatch();
  const description = 'Una amplia selección de contenido te está esperando en Libelista. Comprar desde tu casa nunca fué tan fácil.'

  const { has_slider, has_events, has_blog } = useSelector(companySelector);

  async function loadEvents() {
    const { status, data } = await getActivities();
    if (status === 200) {
      let events = data.results.slice(0, 3);
      setEvents(events);
    }
  }

  async function loadBlogs() {
    const { status, data } = await getPosts({ page_size: 4 });
    if (status === 200) {
      setBlogs(data.results);
    }
  }

  async function getSlidersClient() {
    const rs = await slidersClient();
    if (rs.status === 200) {
      setSliders(rs.data);
    }
  }

  const getCompanyInfo = async () => {
    try {
      const rs = await getCompany();
      if (rs.status === 200) {
        const { data } = rs;
        setCompany(data);
      }
    } catch (error) {}
  };

  const handleLoadLists = async () => {
    try {
      const response = await getListsHome();
      if (response.status === 200) {
        setLists(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllLists = async () => {
    try {
      if (mainLists.length === 0) {
        const { status, data } = await getLibelistaMainLists();
        if (status === 200) {
          dispatch(setMainLists(data));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyInfo();
    handleLoadLists();
    getAllLists();
    goUp();
  }, []);

  useEffect(() => {
    if (has_slider) {
      getSlidersClient();
    }
  }, [has_slider]);

  useEffect(() => {
    if (has_events) {
      loadEvents();
    }
  }, [has_events]);

  useEffect(() => {
    if (has_blog) {
      loadBlogs();
    }
  }, [has_blog]);

  const goUp = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="container-fluid p-rel p-1 px-lg-0 mainDiv">
      <Row className="justify-content-center mainRow px-2 px-lg-0">
        <Col lg={11}>{has_slider && <Slider elements={sliders} />}</Col>
        <Col lg={8} className="px-2 px-lg-0">
          <ListsHome data={lists} />
          <Head description={company.descripcion_pub} title="" home={true} />
        </Col>
        <Col lg={3} className="pr-0">
          <EventHome blogs={blogs} events={events} lists={mainLists} />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
