import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Accordion, Card, Row } from "react-bootstrap";
import { AiOutlineFilter, AiOutlineArrowDown } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { InputCustom } from "../input/index";

//apis
import {
  getPublishedCountries,
  getLanguageProducts,
  getRangeDatePublished,
} from "../../api/products";
import {
  getMateriasByCompany,
  featuredMattersGeslibApi,
} from "../../api/libros";

//styles
import "rc-slider/assets/index.css";

import Slider, { createSliderWithTooltip } from "rc-slider";
//own components
import Head from "../../components/metatags_helmet/metatags";

const Range = createSliderWithTooltip(Slider.Range);

const FilterProductBooks = ({ filterProductsHandle }) => {
  const [isReady, setIsReady] = useState(false);
  const [pubCountries, setPubCountries] = useState([]);
  const [lanProducts, setLanProducts] = useState([]);
  const [materiasCompany, setMateriasCompany] = useState([]);
  const [featuredMateriasCompany, setfeaturedMateriasCompany] = useState([]);
  const [countriesChecked, setcountriesChecked] = useState([]);
  const [yearPublished, setyearPublished] = useState({
    fecha_public__min: 1900,
    fecha_public__max: 2022,
  });
  const [rangeDatePub, setRangeDatePub] = useState([]);
  const [lanChecked, setlanChecked] = useState([]);
  const [materiasChecked, setMateriasChecked] = useState([]);
  const [featuredMateriasChecked, setfeaturedMateriasChecked] = useState([]);
  const [querySearch, setquerySearch] = useState({});
  const { type } = useParams();
  const title = type;

  const onChangeFilter = (event, inputName) => {
    setquerySearch({ ...querySearch, [inputName]: event.target.value });
  };

  const onCheckedCountry = (target) => {
    const checked = target.checked;
    const country = target.value;

    if (checked) {
      setcountriesChecked((countries) => [country, ...countries]);
    } else {
      const removeCountry = countriesChecked.filter((e) => e !== country);
      setcountriesChecked(removeCountry);
    }
  };
  const onCheckedLan = (target) => {
    const checked = target.checked;
    const lan = target.value;
    if (checked) {
      setlanChecked([...lanChecked, lan]);
    } else {
      const removeLan = lanChecked.filter((e) => e !== lan);
      setlanChecked(removeLan);
    }
  };

  const onCheckedMaterias = (target) => {
    const checked = target.checked;
    const materia = target.value;
    if (checked) {
      setMateriasChecked([...materiasChecked, materia]);
    } else {
      const removeMateria = materiasChecked.filter((e) => e !== materia);
      setMateriasChecked(removeMateria);
    }
  };

  const onCheckedFeaturedMaterias = (target) => {
    const checked = target.checked;
    const featureMateria = target.value;
    if (checked) {
      setfeaturedMateriasChecked([...featuredMateriasChecked, featureMateria]);
    } else {
      const removeFeaturedMateria = featuredMateriasChecked.filter(
        (e) => e !== featureMateria
      );
      setfeaturedMateriasChecked(removeFeaturedMateria);
    }
  };

  const rangeYearSelected = (range) => {
    setRangeDatePub(range);
  };

  const PubCountryRadios = pubCountries?.map((val, i) => (
    <Form.Check
      key={i}
      id={val.codigo}
      type="checkbox"
      value={val.id}
      onChange={(e) => onCheckedCountry(e.target)}
      label={`${val.nombre} (${val.num_books})`}
    />
  ));

  const lanProductRadios = lanProducts?.map((val, i) => (
    <Form.Check
      key={val.id}
      id={val.id}
      value={val.id}
      type="checkbox"
      onChange={(e) => onCheckedLan(e.target)}
      label={`${val.nombre} (${val.num_books})`}
    />
  ));

  const materiasCompanyRadios = materiasCompany?.map((val, i) => (
    <Form.Check
      key={val.id}
      id={val.id}
      value={val.id}
      type="checkbox"
      onChange={(e) => onCheckedMaterias(e.target)}
      // label={`${val.nombre} (${val.num_books})`}
      label={`${val.descripcion}`}
    />
  ));

  const featuredMateriasCompanyRadios = featuredMateriasCompany?.map(
    (val, i) => (
      <Form.Check
        key={val.code}
        id={val.code}
        value={val.code}
        type="checkbox"
        onChange={(e) => onCheckedFeaturedMaterias(e.target)}
        label={`${val.name}`}
      />
    )
  );

  useEffect(() => {
    setquerySearch({ ...querySearch, type });
    const getCountries = async () => {
      const { data } = await getPublishedCountries({ type });
      setPubCountries(data);
    };

    const getLanguageProduct = async () => {
      const { data } = await getLanguageProducts({ type });
      setLanProducts(data);
    };

    const rangeDatePublished = async () => {
      const { data } = await getRangeDatePublished({ type });
      setyearPublished(data);
    };

    const getMateriasCompany = async () => {
      const { data } = await getMateriasByCompany({ type });
      setMateriasCompany(data);
    };

    const getFeaturedMateriasCompany = async () => {
      const { data } = await featuredMattersGeslibApi({ type });
      setfeaturedMateriasCompany(data);
    };

    (async () => {
      await getCountries();
      await getLanguageProduct();
      await rangeDatePublished();
      await getMateriasCompany();
      await getFeaturedMateriasCompany();
      setIsReady(true);
    })();
  }, [type]);
  useEffect(() => {
    // update query
    const searchQuery = {
      ...querySearch,
      ...(countriesChecked.length && {
        pais_public: countriesChecked.join(","),
      }),
      ...(lanChecked.length && { idioma: lanChecked.join(",") }),
      ...(rangeDatePub.length && {
        year_gt: rangeDatePub[0],
      }),
      ...(rangeDatePub.length && {
        year_lt: rangeDatePub[1],
      }),
      ...(featuredMateriasChecked.length && {
        materia_geslib: featuredMateriasChecked.join(","),
      }),
      ...(materiasChecked.length && {
        materia_cdu_selc: materiasChecked.join(","),
      }),
    };
    // update data displayed
    if (isReady) {
      filterProductsHandle(searchQuery);
      const getCountries = async () => {
        const { data } = await getPublishedCountries(searchQuery);
        setPubCountries(data);
      };
      const getLanguageProduct = async () => {
        const { data } = await getLanguageProducts(searchQuery);
        setLanProducts(data);
      };
      (async () => {
        await getCountries();
        await getLanguageProduct();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countriesChecked,
    filterProductsHandle,
    lanChecked,
    querySearch,
    rangeDatePub,
    materiasChecked,
    featuredMateriasChecked,
  ]);

  return (
    <div>
      <Head title={title} />
      <Form style={{ padding: "15px", backgroundColor: "#f1f1f1" }}>
        <Row className="d-flex justify-content-between px-3">
          <h2 className="page-title static-title">Filtro</h2>
          <AiOutlineFilter onClick={() => "d"} className="icon-md " />
        </Row>

        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <span>Producto</span>
              <AiOutlineArrowDown className="icon-sm" />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <InputCustom
                  placeholder={"EAN"}
                  onChange={(event) => onChangeFilter(event, "ean")}
                />
                <InputCustom
                  placeholder={"titulo"}
                  onChange={(event) => onChangeFilter(event, "titulo")}
                />
                <InputCustom
                  onChange={(event) => onChangeFilter(event, "autor")}
                  placeholder={"Autor"}
                />
                <InputCustom
                  onChange={(event) => onChangeFilter(event, "editorial")}
                  placeholder={"Editorial"}
                />
                {/*
                <InputCustom
                  onChange={(event) => onChangeFilter(event, 'encuadernacion')}
                  placeholder={'Encuadernación'}
                />
                */}
                <InputCustom
                  onChange={(event) => onChangeFilter(event, "materia_cdu")}
                  placeholder={"Materias/CDU"}
                />
                {/*
                <>
                  <label>Año de publicación</label>
                  <Range
                    style={{ marginBottom: 15 }}
                    min={yearPublished?.fecha_public__min}
                    max={yearPublished?.fecha_public__max}
                    marks={{
                      [yearPublished?.fecha_public__min]: (
                        <strong>{yearPublished?.fecha_public__min}</strong>
                      ),
                      [yearPublished?.fecha_public__max]: (
                        <strong>{yearPublished?.fecha_public__max}</strong>
                      ),
                    }}
                    step={1}
                    onChange={rangeYearSelected}
                    defaultValue={[
                      yearPublished?.fecha_public__min,
                      yearPublished?.fecha_public__max,
                    ]}
                    tipFormatter={(value) => `${value}`}
                  />
                </>
                */}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="1"
              style={{ minWidth: "200px" }}
            >
              <span>País de publicación</span>
              <AiOutlineArrowDown className="icon-sm" />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>{PubCountryRadios}</Card.Body>
            </Accordion.Collapse>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <span>Idioma</span>
              <AiOutlineArrowDown className="icon-sm" />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>{lanProductRadios}</Card.Body>
            </Accordion.Collapse>
            {/*
            {materiasCompany.length > 0 && (
              <>
                <Accordion.Toggle as={Card.Header} eventKey="3">
                  <span>Materias</span>
                  <AiOutlineArrowDown className="icon-sm" />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>{materiasCompanyRadios}</Card.Body>
                </Accordion.Collapse>
              </>
            )}
            {featuredMateriasCompany.length > 0 && (
              <>
                <Accordion.Toggle as={Card.Header} eventKey="4">
                  <span>Materias Destacadas</span>
                  <AiOutlineArrowDown className="icon-sm" />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>{featuredMateriasCompanyRadios}</Card.Body>
                </Accordion.Collapse>
              </>
            )}
            */}
          </Card>
        </Accordion>
      </Form>
    </div>
  );
};

export default FilterProductBooks;
