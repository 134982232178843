import React, { useState, useEffect } from "react";
import { getAllBooksOfTheMonth } from "./../../api/libelista";
import { Link } from "react-router-dom";
import calendarIcon from "../../assets/calendarIcon.png";
const booksOfTheMonthListRequestHandler = async (
  stateSetterCallback,
  ...requestParams
) => {
  const response = await getAllBooksOfTheMonth(...requestParams);
  try {
    stateSetterCallback(response.data);
  } catch (error) {
    console.log(error);
  }
};
const BookPostTeaser = ({ id, title, image }) => (
  <div className="p-1 col-12 col-sm-6 col-md-4 m-0 p-0">
    <Link to={`/blog/category/${id}/`} className="text-dark teaser-subtitle">
      <img src={image} alt={title} className="w-100" />
      <img
        width="32px"
        height="32px"
        src={calendarIcon}
        alt="calendarIcon"
        className="position-absolute fixed-top m-3"
      />
      <p className="w-100 my-2">{title}</p>
    </Link>
  </div>
);
const MainBookPostTeaser = ({ title, image, id }) => (
  <div className="row">
    <Link to={`/blog/category/${id}/`} className="teaser-text col-12">
      <img src={image} alt={title} className="w-100" />
      <div className="positon-absolute fixed-bottom title-subheading d-flex align-content-top p-0 m-2">
        <img width="40px" height="40px" src={calendarIcon} alt="calendarIcon" />
        <p className="px-2">{title}</p>
      </div>
    </Link>
  </div>
);
export const BooksOfTheMonthSection = () => {
  const [{ pageNumber, pageSize }, setPagination] = useState({
    pageNumber: 1,
    pageSize: 2,
  });
  const [booksOfTheMonthList, setBooksOfTheMonthList] = useState({});
  useEffect(() => {
    booksOfTheMonthListRequestHandler(
      setBooksOfTheMonthList,
      pageNumber,
      pageSize
    );
  }, [pageNumber, pageSize]);
  const booksOfTheMonthListData = booksOfTheMonthList?.results;
  const [mainBookPostTeaserData, ...otherBooksPostTeaserData] =
    booksOfTheMonthListData ?? [];
  const previousPageExists = booksOfTheMonthList?.previous;
  const nextPageExists = booksOfTheMonthList?.next;
  return (
    <div className="container-fluid books-of-the-month-section d-flex flex-column">
      <div className="col-12">
        <MainBookPostTeaser {...mainBookPostTeaserData} />
        <h2 className="section mt-5">Todas las publicaciones</h2>
        <div className="row">
          {otherBooksPostTeaserData.map((datum, index) => (
            <BookPostTeaser key={`bookPost#${index}`} {...datum} />
          ))}
        </div>
      </div>
      <div className="col-12">
        <nav className="row justify-content-end">
          <ul className="pagination">
            <li className={`page-item ${previousPageExists ? "" : "disabled"}`}>
              <a
                className="page-link"
                href={"/previous"}
                onClick={(event) => {
                  event.preventDefault();
                  setPagination({ pageNumber: pageNumber - 1, pageSize });
                }}
              >
                {previousPageExists ? "Anterior" : "Primer página"}
              </a>
            </li>
            <li className={`page-item ${nextPageExists ? "" : "disabled"}`}>
              <a
                className="page-link"
                onClick={(event) => {
                  event.preventDefault();
                  setPagination({ pageNumber: pageNumber + 1, pageSize });
                }}
                href={"/next"}
              >
                {nextPageExists ? "Siguiente" : "Última página"}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default BooksOfTheMonthSection;
