import { methodGet, methodPost } from './methods';
import { API_INFO } from '../constants';

export async function addItemCart(payload) {
  const url = API_INFO + 'ecommerce/carts/add_item/';
  return methodPost(url, payload);
}

export async function removeItemCart(payload) {
  const url = API_INFO + 'ecommerce/carts/remove_item/';
  return methodPost(url, payload);
}

export async function decreaseQuantityItemCart(payload) {
  const url = API_INFO + 'ecommerce/carts/decrease_quantity_item/';
  return methodPost(url, payload);
}

export async function getCart() {
  const url = API_INFO + 'ecommerce/carts/get_active/';
  return methodGet(url, {});
}

export async function settingStorePickup(payload) {
  const url = API_INFO + 'ecommerce/carts/setting_store_pickup/';
  return methodPost(url, payload);
}

export async function createOrder(payload) {
  const url = API_INFO + 'storehouse/orders/';
  return methodPost(url, payload);
}

export async function getInfoRedsys(payload) {
  const url = API_INFO + 'ecommerce/carts/get_info_redsys/';
  return methodGet(url, payload);
}

export async function errorPayment() {
  const url = API_INFO + 'ecommerce/carts/error_payment/';
  return methodPost(url, {});
}

export const createGiftCard = async (payload) => {
  const url = API_INFO + `ecommerce/carts/add_gift_card/`;
  const create = await methodPost(url, payload);
  return create;
};

export const deleteGiftCardCart = async (payload) => {
  const url = API_INFO + 'ecommerce/carts/remove_gift_card/';
  const deleteGiftCard = await methodPost(url, payload);
  return deleteGiftCard;
};
