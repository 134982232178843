import React, { useState, useEffect } from "react";
import "./blog.scss";
import { truncate } from "../../helpers";
import { Link } from "react-router-dom";

const CardBlog = (props) => {
  const [dt, setDt] = useState("");
  const [urlBlog, setUrlBlog] = useState("");
  const {
    featured_image,
    title,
    author,
    publish_date,
    publish_time,
    featured_paragraph,
    slug,
    body,
  } = props.event;

  useEffect(() => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    let x = new Date(publish_date).toLocaleDateString("es-ES", options);
    setDt(x);
    var url = `/blog/post/${publish_date}/${slug}`;
    setUrlBlog(url);
  }, []);

  const goUp = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div
        className="cardBlog"
        style={{
          boxShadow:
            props.isColumn || props.isContainer
              ? "0 0 15px 5px rgba(21, 22, 26, 0.05)"
              : "none",
          borderRadius: props.isColumn || props.isContainer ? "10px" : "none",
          width: props.isRow ? "45%" : "none",
          minWidth: props.isRow ? "45%" : "none",
        }}
      >
        {props.isOnlyPost ? null : (
          <div
            className="imageCardBlog"
            style={{
              backgroundImage: `url(${featured_image})`,
              maxWidth: props.isRow ? "100%" : "none",
              height: props.isContainer ? "300px" : "150px",
            }}
          ></div>
        )}
        {props.isOnlyPost ? null : (
          <div className="titleCardBlog">{truncate(title)}</div>
        )}

        <div className="authorBlog">
          {" "}
          Por <div className="author">{author}</div>{" "}
        </div>
        {props.isContainer ? (
          <div className="dateCardBlog">
            {dt} a las {publish_time}
          </div>
        ) : (
          <div className="dateCardBlog">{dt}</div>
        )}
        {props.isOnlyPost ? (
          <>
            <div className="footerCardBlog">{featured_paragraph}</div>
            <br />
            <div
              dangerouslySetInnerHTML={{ __html: body }}
              className="footerCardBlog"
            ></div>
          </>
        ) : (
          <div
            className="footerCardBlog"
            style={{ textAlign: featured_paragraph == "" ? "end" : "justify" }}
          >
            {truncate(
              featured_paragraph,
              props.isContainer
                ? 210
                : props.isColumn && props.isRow
                ? 30
                : props.isColumn
                ? 50
                : 45
            )}
            <Link
              onClick={goUp}
              to={urlBlog}
              style={{
                fontWeight: "bold",
                paddingLeft: "1px",
                textDecoration: "none",
              }}
            >
              Ver más
            </Link>
          </div>
        )}
      </div>
      {props.hasLine ? (
        <div
          style={{ width: "90%", width: "100%", padding: "0px 25px 0px 25px" }}
        >
          <hr />
        </div>
      ) : null}
    </>
  );
};

export default CardBlog;
