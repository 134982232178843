import React from "react";
import { Spinner } from "react-bootstrap";

export default function SectionLoader() {
  return (
    <div className="loader-section">
      <div className="loader-section__container">
        <Spinner
          className="loader-section__spinner"
          animation="border"
          variant="secondary"
        />
        <span
          className="loader-section__text"
          role="alert"
          aria-busy="true"
          aria-live="polite"
        >
          cargando...
        </span>
      </div>
    </div>
  );
}
