import { methodGet, methodPost, methodDelete, methodPut } from './methods';
import { API_INFO } from '../constants';

export const getBooksOfTheMonth = async (id) => {
  const url = API_INFO + `libelista/books-month/${id}/`;
  return methodGet(url);
};
export const editBooksOfTheMonth = async (payload) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const url = API_INFO + `libelista/books-month/${payload?.id || payload.get('id')}/`;
  return methodPut(url, payload, config);
};
export const createBooksOfTheMonth = async (payload) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data;',
    },
  };
  const url = API_INFO + `libelista/books-month/`;
  return methodPost(url, payload, config);
};
export const deleteBooksOfTheMonth = async (id) => {
  const url = API_INFO + `libelista/books-month/${id}/`;
  return methodDelete(url);
};
export const getAllBooksOfTheMonth = async (page = 1, page_size = 12, search = '') => {
  const url =
    API_INFO + `libelista/books-month/?page=${page}&page_size=${page_size}${search ? '&search=' + search : ''}`;
  return methodGet(url);
};
export const getBookOfTheMonthItem = async (id) => {
  const url = API_INFO + `libelista/book-month-items/${id}/`;
  return methodGet(url);
};
export const deleteBooksOfTheMonthItem = async (id) => {
  const url = API_INFO + `libelista/book-month-items/${id}/`;
  return methodDelete(url);
};
export const getAllBookOfTheMonthItems = async (page = 1, page_size = 10, search = ' ') => {
  const url =
    API_INFO + `libelista/book-month-items/?page=${page}&page_size=${page_size}${search ? '&search=' + search : ''}`;
  return methodGet(url);
};
export const createBooksOfTheMonthItem = async (payload) => {
  const url = API_INFO + `libelista/book-month-items/`;
  return methodPost(url, payload);
};
export const editBooksOfTheMonthItem = async (payload) => {
  const url = API_INFO + `libelista/book-month-items/${payload.id}`;
  return methodPut(url, payload);
};

export const getLibraries = async (address, locality) => {
  let url = API_INFO + 'libelista/bookstores/';
  if (address !== '' && locality === '') {
    url = API_INFO + `libelista/bookstores/?address=${address}`;
  } else if (address === '' && locality !== '') {
    url = API_INFO + `libelista/bookstores/?locality=${locality}`;
  } else if (address !== '' && locality !== '') {
    url = API_INFO + `libelista/bookstores/?address=${address}&locality=${locality}`;
  }
  return methodGet(url, {});
};

export const getPostsCategories = async () => {
  const testing = await methodGet(API_INFO + 'libelista/posts/category/', {});
  return testing;
};

export const getBookComment = async (id) => {
  return methodGet(API_INFO + `products/comentarios/${id}/`);
};
export const editBookComment = async (payload) => {
  return methodPut(API_INFO + `products/comentarios/${payload.id || payload.get('id')}`, payload);
};
export const createBookComment = async (payload) => {
  return methodPost(API_INFO + `products/comentarios/`, payload);
};
export const getProductComments = async (pageNumber = 1, pageSize = 10, search = '') => {
  return methodGet(
    API_INFO + `products/comentarios/?page=${pageNumber}&page_size=${pageSize}${search ? '&search=' + search : ''}`,
    {}
  );
};
export const deleteProductComment = async (commentId) => {
  return methodDelete(API_INFO + `products/comentarios/${commentId}`, {});
};

export const getPosts = async (query) => {
  const url = await methodGet(API_INFO + 'libelista/posts/', query);
  return url;
};

export const getBlogPosts = async (page, page_size, type_post_slug, author_username, title) => {
  const firstVal =
    API_INFO +
    `libelista/posts/all-posts/?&type_post_slug=${type_post_slug}&author_username=${author_username}&title=${title}&page=${page}&page_size=${page_size}/`;
  const secondVal = API_INFO + `libelista/posts/all-posts/?page=${page}&page_size=${page_size}/`;
  const url = type_post_slug || author_username || title ? firstVal : secondVal;
  const testing = await methodGet(url);
  return testing;
};

export const getAssociatedList = async () => {
  const testing = await methodGet(API_INFO + `libelista/booklists/`, {});
  return testing;
};

export const createPost = async (payload) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return methodPost(API_INFO + 'libelista/posts/', payload, config);
};
export const editPost = async (payload) => {
  return methodPut(API_INFO + `libelista/posts/${payload.id || payload.get('id')}/`, payload);
};

export const getPost = async (id) => {
  const url = await methodGet(API_INFO + `libelista/posts/${id}/`);
  return url;
};

export const deletePost = async (id) => {
  return methodDelete(API_INFO + `libelista/posts/${id}/`);
};

export const getAllClientMessages = async (page, page_size, answered, full_name, email) => {
  const firstVal =
    API_INFO +
    `libelista/customer_message/?&answered=${answered}&full_name=${full_name}&email=${email}&page=${page}&page_size=${page_size}/`;
  const secondVal = API_INFO + `libelista/customer_message/?&page=${page}&page_size=${page_size}/`;
  const url = answered || full_name || email || `${answered === ''}` ? firstVal : secondVal;
  const testing = await methodGet(url);
  return testing;
};
export const getClientMessage = async (id) => {
  const url = API_INFO + `libelista/customer_message/${id}`;
  return methodGet(url);
};
export const deleteClientMessage = async (id) => {
  const url = API_INFO + `libelista/customer_message/${id}`;
  return methodDelete(url);
};
export const editClientMessage = async (payload) => {
  const url = API_INFO + `libelista/customer_message/${payload.id || payload.get('id')}/`;
  return methodPut(url, payload);
};
export const postMessage = async (payload) => {
  const url = API_INFO + 'libelista/customer_message/';
  return methodPost(url, payload);
};

export const postRegisterUser = async (payload) => {
  const url = API_INFO + 'libelista/libelista_user/';
  return methodPost(url, payload);
};

export const getActivities = async (slug) => {
  let url = '';
  if (slug) {
    url = API_INFO + `libelista/agenda/?type_schedule__slug=${slug}/`;
  } else {
    url = API_INFO + 'libelista/agenda/';
  }
  return methodGet(url);
};

export const getActivityDetail = async (id) => {
  const url = API_INFO + `libelista/agenda/${id}/`;
  return methodGet(url);
};

export const getAvailableLibraries = async (id) => {
  const url = API_INFO + `libelista/bookstores/with-book/${id}/`;
  return methodGet(url);
};

export const getLibelistaLists = async (pageNum = 1, pageSize = 10, title = '') => {
  const url = API_INFO + `libelista/booklists?page=${pageNum}&page_size=${pageSize}${title ? '&title=' + title : ''}`;
  return methodGet(url);
};

export const getLibelistaListDetail = async (id) => {
  const url = API_INFO + `libelista/booklists/${id}/`;
  return methodGet(url);
};

export const getLibelistaProducts = async (page, page_size, titulo, ean, autor) => {
  const firstVal =
    API_INFO +
    `libelista/dashboard-books/?&titulo=${titulo}&ean=${ean}&autor=${autor}&page=${page}&page_size=${page_size}`;
  const secondVal = API_INFO + `libelista/dashboard-books/?page=${page}&page_size=${page_size}`;
  const url = titulo || ean || autor ? firstVal : secondVal;
  const testing = await methodGet(url);
  return testing;
};

/*export const getSchedulesList = async (page, page_size, type_schedule__slug, title, status, place) => {
  const firstVal =
    API_INFO +
    `libelista/agenda/all-schedules/?&type_schedule__slug=${type_schedule__slug}&title=${title}&status=${status}&place=${place}&page=${page}&page_size=${page_size}/`;
  const secondVal = API_INFO + `libelista/agenda/all-schedules/?&page=${page}&page_size=${page_size}/`;
  const url = type_schedule__slug || title || status || place ? firstVal : secondVal;
  const testing = await methodGet(url);
  return testing;
};*/

export const getLibelistaMainLists = async () => {
  const url = API_INFO + `libelista/booklists/main_lists/`;
  return methodGet(url);
};

export const getAllBooklists = async (page = 1, pageSize = 1000) => {
  const url = API_INFO + `libelista/booklists/?page=${1}&page_size=${pageSize}`;
  return methodGet(url);
};

export const getBookList = async (id, query) => {
  const isURLqueryString = typeof query === 'string';
  const url = API_INFO + `libelista/booklists/${id}/get-books/${isURLqueryString ? query : ''}`;
  return isURLqueryString ? methodGet(url) : methodGet(url, query);
};

export const deleteLibelistaList = async (id) => {
  const url = API_INFO + `libelista/booklists/${id}/`;
  return methodDelete(url, {});
};

export const postLibelistaList = async (payload) => {
  const url = API_INFO + 'libelista/booklists/';
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return methodPost(url, payload, config);
};

export const editLibelistaList = async (payload, id) => {
  const url = API_INFO + `libelista/booklists/${id || payload.id || payload.get('id')}/`;
  return methodPut(url, payload);
};

export const getSchedulesList = async (page, page_size, type_schedule__slug, title, status, place) => {
  const firstVal =
    API_INFO +
    `libelista/agenda/all-schedules/?&type_schedule__slug=${type_schedule__slug}&title=${title}&status=${status}&place=${place}&page=${page}&page_size=${page_size}/`;
  const secondVal = API_INFO + `libelista/agenda/all-schedules/?&page=${page}&page_size=${page_size}/`;
  const url = type_schedule__slug || title || status || place ? firstVal : secondVal;
  const testing = await methodGet(url);
  return testing;
};

export const deleteSchedule = async (id) => {
  const url = API_INFO + `libelista/agenda/${id}`;
  return methodDelete(url);
};

export const postSchedule = async (payload) => {
  const url = API_INFO + 'libelista/agenda/';
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return methodPost(url, payload, config);
};

export const editSchedule = async (payload, id) => {
  const url = API_INFO + `libelista/agenda/${id || payload.id || payload.get('id')}/`;
  return methodPut(url, payload);
};

export const getScheduleDetail = async (id) => {
  const url = API_INFO + `libelista/agenda/${id}/`;
  return methodGet(url);
};

export const getTypeSchedule = async () => {
  const url = API_INFO + `libelista/agenda/tipo-agenda/`;
  return methodGet(url);
};
