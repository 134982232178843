import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import ActionsForm from '../../../../components/backoffice/actionsForm/index';
import SectionLoader from '../../../../components/loader/SectionLoader';
import { getDetailOrder as get, editDetailOrder as edit } from '../../../../api/orders';
import { getBookstoresBackoffice } from '../../../../api/company';
import { getSelectDataHandlers } from '../../../../utils/backoffice/getSelectDataHandlers';

const statusOptions = [
  { label: 'Pendiente', value: 1 },
  { label: 'Pagado', value: 2 },
  { label: 'Procesado', value: 3 },
  { label: 'Finalizado', value: 4 },
  { label: 'Anulado', value: 5 },
  { label: 'Incompleto', value: 6 },
  { label: 'Completo', value: 7 },
  { label: 'En Caja', value: 8 },
  { label: 'Reembolsado', value: 9 },
  { label: 'Enviado', value: 10 },
  { label: 'Solicitado', value: 11 },
];

const OrdersItem = ({ data, loadData }) => {
  const { action, id } = useParams();
  const mustWaitForData = ['detail', 'edit'].includes(action);

  const [
    { defaultOptions: defaultLibrariesOptions, defaultOption: defaultLibrariesOption },
    setLibrariesDefaultOptionValues,
  ] = useState({});
  const { getSelectData: getLibrariesOptions, setSelectDefaultData: setLibrariesOptions } = getSelectDataHandlers(
    undefined,
    getBookstoresBackoffice,
    setLibrariesDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'name',
      fieldNameWithRequestValue: 'id',
    }
  );

  useEffect(() => {
    setLibrariesOptions();
  }, [data]);

  /**
   * Below is an quick workaround to provide item data for detail view
   */
  if (action === 'detail' && data) {
    data.businessName = data.empresa?.name || '...';
    data.pickUpBookstore = data.bookstore_to_pickup_selected?.name || '...';
  }
  const inputFields = [
    ...(action === 'detail'
      ? [
          { disabled: true, label: 'Número de pedido', name: 'code' },
          { disabled: true, label: 'Cliente', name: 'get_usuario_biblioteca' },
          { disabled: true, label: 'Librería', name: 'businessName' },
          {
            disabled: true,
            label: 'Librería de retiro',
            name: 'pickUpBookstore',
          },
          { disabled: true, label: 'Fecha', name: 'fecha', type: 'datetime-local' },
          { disabled: true, label: 'Precio sin impuestos', name: 'subtotal_no_iva' },
          { disabled: true, label: 'Impuestos', name: 'iva' },
          { disabled: true, label: 'Costos de envío', name: 'shipping_cost' },
          { disabled: true, label: 'Costo final', name: 'total' },
        ]
      : []),
    {
      label: 'Estado',
      name: 'estado',
      type: 'select',
      defaultValue: statusOptions.find(({ label }) => label === data?.status) || statusOptions[0],
      options: statusOptions,
      isDisabled: action === 'detail',
    },
    { label: 'Comentario', name: 'comentario', type: 'textarea', disabled: action === 'detail' },
    {
      label: 'Proveedor de libro',
      name: 'book_provider',
      type: 'select',
      defaultValue: defaultLibrariesOptions?.find((option) => option.value.toString() === data?.book_provider?.id.toString()),
      options: defaultLibrariesOptions,
      isDisabled: action === 'detail',
      required: true,
    },
  ];
  //  const schema = {
  //    type_post: yup.string().required('* '),
  //    title: yup.string().required('* '),
  //    slug: yup.string().required('* '),
  //  };
  data && (data.fecha = new Date(data.fecha).toISOString().split('.')[0]);
  data && (data.id = id);

  const renderConditions = [
    !mustWaitForData || data,
    defaultLibrariesOptions?.length || defaultLibrariesOption,
  ];

  const showTable = () => {
    const headersTable = ['Nombre producto', 'Cantidad', 'Ean', 'Iva', 'Precio', 'Total'];
    return (
      <div className="table-responsive">
        <table className="border table table-striped w-100">
          <thead className="w-100">
            <tr className="w-100">
              {headersTable.map((item, index) => {
                return <th key={index}>{item}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {data.items.length &&
              data.items.map((element, index) => {
                return (
                  <tr key={index}>
                    <td className="text-truncate align-middle">{element.nombre}</td>
                    <td className="text-truncate align-middle">{element.cantidad}</td>
                    <td className="text-truncate align-middle">{element.ean}</td>
                    <td className="text-truncate align-middle">{element.iva_value}</td>
                    <td className="text-truncate align-middle">{element.precio_venta}</td>
                    <td className="text-truncate align-middle">{element.total}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };

  const actionsFormProps = {
    inputFields,
    title: 'pedido',
    subsectionPath: 'orders',
    data: action === 'detail' ? data : { status: data?.status, comentario: data?.comentario, id },
    requests: { edit, create: () => null },
    action,
    addTable: showTable(),
    defaultLibrariesOptions
  };
  return renderConditions.every((condition) => condition) ? <ActionsForm {...actionsFormProps} /> : <SectionLoader />;
};

OrdersItem.request = {
  get,
};
const OrdersItemPage = () => <BackofficePagesManager PageViewer={OrdersItem} />;
export default OrdersItemPage;
