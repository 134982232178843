import React, { useState, useEffect } from 'react';
import './blog.scss';
import { truncate } from '../../helpers';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { responsive } from '../../constants';
import { Container, Col, Row, Spinner } from 'react-bootstrap';

import ItemList from '../../components/itemList/item';

//api
import { getPosts } from '../../api/libelista';

const goUp = () => {
  window.scrollTo(0, 0);
};

const MightBeInterestingPosts = (props) => {
  const { printException, categ } = props;
  const [mainPosts, setMainPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [printPosts, setPrintPosts] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const maxAsidePosts = 3;

  useEffect(() => {
    async function setPostsCategory() {
      const rs = await getPosts({ type_post__slug: categ });
      if (rs?.status === 200) {
        setMainPosts(rs.data?.results);
      }
    }
    setPostsCategory();
  }, [props]);

  useEffect(() => {
    let filterAllPosts = mainPosts?.filter((post) => post.slug !== printException);
    setFilteredPosts(filterAllPosts);
  }, [mainPosts]);

  useEffect(() => {
    setPrintPosts(filteredPosts.slice(0, maxAsidePosts));
    setLoaded(true);
  }, [filteredPosts]);

  if (loaded) {
    return (
      <div>
        {printPosts?.map((post) => {
          return (
            <div key={post.id} id={`${post.title}`} className="my-3 media">
              <div className="media-left pr-3" style={{ maxWidth: '150px' }} onClick={goUp()}>
                <Link to={`/libelista/posts/${post.publish_date}/${post.slug}/`}>
                  <img
                    src={post.featured_image}
                    alt={`${post.title} imagen`}
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </Link>
              </div>
              <Link to={`/libelista/posts/${post.publish_date}/${post.slug}/`} onClick={goUp()}>
                <p>{post.title}</p>
              </Link>
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <Container fluid={true} style={{ height: '50vh' }}>
        <Spinner animation={'grow'} style={{ marginTop: '20vh', marginLeft: '40vw' }} />
      </Container>
    );
  }
};

const CardPost = (props) => {
  const {
    featured_image,
    title,
    author,
    publish_date,
    publish_time,
    display_related_book_list,
    featured_paragraph,
    slug,
    body,
    type_post,
    selected_type_post,
  } = props.event;
  const showRelatedBookList = display_related_book_list !== null || display_related_book_list !== undefined;

  useEffect(() => {
    goUp();
  }, []);

  return (
    <>
      <div className="containerPostList">
        <Row>
          <Col lg={4} className="d-none d-lg-block">
            <h2>{title}</h2>
            <p>{featured_paragraph}</p>
            <p>
              {publish_date} en <Link to={`/blog/${selected_type_post?.slug}/`}>{selected_type_post?.name}</Link>
            </p>
            <p>por {author}</p>
          </Col>

          <Col lg={8}>
            <div>
              <img
                className="imageCardBlog"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
                src={featured_image}
                alt={`${title} imagen`}
              />
            </div>
          </Col>
          <Col sm={12} xs={12} className="d-block d-lg-none">
            <h2>{title}</h2>
            <p>{featured_paragraph}</p>
            <p>
              {publish_date} en <Link to={`/blog/${selected_type_post?.slug}/`}>{selected_type_post?.name}</Link>
            </p>
            <p>por {author}</p>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col lg={1} className="d-none d-lg-block text-center">
            {type_post[0]?.featured_image !== '' ? (
              <img src={selected_type_post?.featured_image} alt={`${selected_type_post?.name} imagen`} />
            ) : null}
          </Col>
          <Col sm={12} lg={7}>
            <p>{body}</p>
            {/*
            ##habilitar cuando se encuentre disponible funcionalidad en backend.
            <Row>
              <Col lg={12}>
                <div className="titleColumnPostTitle author margin-top-md">
                  <Link>
                    <h2 className="section" style={{ textAlign: "left" }}>
                      Más listas relacionadas con la categoria
                    </h2>
                  </Link>
                </div>
              </Col>
            </Row> */}
          </Col>

          <Col className="d-none d-lg-block" lg={4}>
            <h2 className="section">También te puede interesar</h2>
            <MightBeInterestingPosts printException={slug} categ={type_post[0]?.slug} />
          </Col>
        </Row>
        <section className="margin-bottom-md list-section w-75 mx-5 ">
          <Col className="mx-5 " lg={10} md={12} xs={12} sm={10}>
            <h3>{title}</h3>
          </Col>
          <Col lg={9} md={12} xs={12} sm={10} className="mx-5 ">
            <Carousel
              responsive={responsive}
              slidesToSlide={1}
              minimumTouchDrag={40}
              draggable
              infinite={true}
              centerMode={true}
              swipeable
              className="mx-5"
            >
              {showRelatedBookList &&
                display_related_book_list.books.map((book, index) => (
                  <div key={`productItem#${index}`} className="slide-div">
                    <ItemList data={book} />
                  </div>
                ))}
            </Carousel>
          </Col>
        </section>
      </div>
    </>
  );
};

export default CardPost;
