import React from 'react';
import { getProductComments as get, deleteProductComment as del } from '../../../../api/libelista';
import BackofficeTable from '../../../../components/backoffice/table/index';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
const CommentsTable = ({ data, ActionsMenu, loadData }) => {
  const headers = {
    title: 'Titulo',
    comentary: 'Comentario',
    user: 'Usuario',
    nickname: 'Nickname',
    product: 'Producto',
    created: 'Fecha de creación',
  };

  loadData.colSpan = Object.keys(headers).length;
  const commentsTableProps = {
    title: 'Comentarios',
    data,
    headers,
    ActionsMenu,
    extraFormat: { created: 'date', createButton: { disabled: true }, comentary: 'elipsis' },
    loadData,
    filterParameterName: 'search',
    filterRelatedFields: ['title', 'nickname', 'user'],
  };
  return <BackofficeTable {...commentsTableProps} />;
};
CommentsTable.request = {
  get,
  del,
};
CommentsTable.itemClass = 'Comentarios';
CommentsTable.requestParameter = 'id';
const CommentsTablePage = () => {
  const backOfficesPageManagerProps = { PageViewer: CommentsTable, filterParameterName: 'search' };
  return <BackofficePagesManager {...backOfficesPageManagerProps} />;
};
export default CommentsTablePage;
