import { getAllBookOfTheMonthItems as get, deleteBooksOfTheMonthItem as del } from '../../../../api/libelista';
import * as React from 'react';
import BackofficeTable from '../../../../components/backoffice/table/index';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
const BookOfTheMonthItemsTable = ({ data, ActionsMenu, loadData }) => {
  const headers = {
    title: 'Titulo',
    featured_paragraph: 'Párrafo distinguido',
    description: 'Descripción',
  };

  loadData.colSpan = Object.keys(headers).length;
  const bookOfTheMonthItemsTableProps = {
    title: 'Entradas de Libros del Mes',
    data,
    headers,
    ActionsMenu,
    extraFormat: { description: 'elipsis' },
    loadData,
    filterParameterName: 'search',
    filterRelatedFields: ['title'],
  };
  return <BackofficeTable {...bookOfTheMonthItemsTableProps} />;
};
BookOfTheMonthItemsTable.request = {
  get,
  del,
};
BookOfTheMonthItemsTable.itemClass = 'Entradas de Libros del Mes';
BookOfTheMonthItemsTable.requestParameter = 'id';
const BookOfTheMonthItemsTablePage = () => {
  const backOfficesPageManagerProps = { PageViewer: BookOfTheMonthItemsTable, filterParameterName: 'search' };
  return <BackofficePagesManager {...backOfficesPageManagerProps} />;
};

export default BookOfTheMonthItemsTablePage;
