import React, { useState, useEffect } from "react";
import { Row, Col, Container, Spinner } from "react-bootstrap";
// api
import { getElementsList, getDetailList } from "../../api/lists";
//hooks
import { useIntersectionObserver } from "react-intersection-observer-hook";
// own components
import ItemList from "../../components/itemList/item";
// own constants
import { convertMetatagName, recoverMetatagName } from "../../utils";

import Head from "../../components/metatags_helmet/metatags";
import Star from "../../assets/img/ico_star.png";
import "./childrenBooks.scss"

const ChildrenBooks = (props) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [listUrls, setListUrls] = useState("");
  const [contentListBottom, { entry }] = useIntersectionObserver();
  const isVisible = entry && entry.isIntersecting;
  const type = "lista"
  const [slug, setSlug] = useState("");

  const STAGING_LIST_URLS = [
    "mas-pequenos-64",
    "primeros-lectores-65",
    "infantil-hasta-7-61",
    "infantil-hasta-9-62",
    "infantil-hasta-13-63"
  ]
  const PRODUCTION_LIST_URLS = [
    "mas-pequenos",
    "primeros-lectores",
    "infantil-mas-7",
    "infantil-mas-9",
    "infantil-mas-13"
  ]

  useEffect(() => {
    const getLoadData = async () => {
      if(slug == "") {
        setSlug((prevState) => {
          prevState = listUrls[0]
          return prevState
        })
      }
      let newData;
      if (type == "lista") {
        const { status, data } = await getElementsList(slug, page);
        if (status === 200) {
          if (page === 1) {
            newData = data.results;
          } else {
            newData = [...items, ...data.results];
          }
          setItems(newData);
          setNextPage(() => (data.next !== null ? true : false));
        }
      } 
    };
    getLoadData();
  }, [slug, page]);

  useEffect(() => {
    const goUp = () => {
      window.scrollTo(0, 0);
    };
    goUp();
    setPage(1);
    if (window.location.origin === "https://nuevo.libelista.com") {
      setSlug(PRODUCTION_LIST_URLS[0])
      setListUrls(PRODUCTION_LIST_URLS)
    } else {
      setSlug(STAGING_LIST_URLS[0])
      setListUrls(STAGING_LIST_URLS)
    }
  }, []);

  const setTitle = () => {
    if (name.toLowerCase() !== "merchandising") {
      setMetaTitle(`El mejor contenido sobre *${recoverMetatagName(name)}*`);
      setDescription(
        `Si buscas libros, películas o música sobre ${convertMetatagName(
          name
        )}, ¡estás de suerte en encontrarnos! Compra en Name a domicilio.`
      );
    }
  };

  useEffect(() => {
    // infinite scrolling
    (async () => {
      if (isVisible && nextPage) {
        setPage((prevPage) => prevPage + 1);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <div className="container-fluid px-0 mainDiv">
      <Head description={description} title={metaTitle} />
      <Row className="px-2 mx-0">
        <Col md={12} className="pl-1">
          <h1 className="page-title static-title mt-4 spectral">
            Pequeño Libelista
          </h1>
        </Col>
        <Col md={12} className="d-flex subTitleCol p-0">
          <img src={Star} alt="star.png" />
          <h2 className="">Novedades</h2>
        </Col>
      </Row>
      <Container fluid className="px-0 mx-0">
        <Row className="justify-content-center pl-3 mx-0">
          <Col lg={2} className="px-0 mx-0">
            <Row className="px-0 mt-4">
              <Col lg={12} className="px-0 ageSelectors">
                <p 
                  className={(slug?.includes("mas-peque") ? "active" : "")}
                  onClick={()=>setSlug(listUrls[0])}
                >
                  LOS MAS PEQUEÑOS
                </p>
                <p 
                  className={(slug == listUrls[1] ? "active" : "")}
                  onClick={()=>setSlug(listUrls[1])}
                >
                  PRIMEROS LECTORES
                </p>
                <p
                  className={(slug == listUrls[2] ? "active" : "")}
                  onClick={()=>setSlug(listUrls[2])}
                >
                  A PARTIR DE 7 AÑOS
                </p>
                <p 
                  className={(slug == listUrls[3] ? "active" : "")}
                  onClick={()=>setSlug(listUrls[3])}
                >
                  A PARTIR DE 9 AÑOS
                </p>
                <p 
                  className={(slug == listUrls[4] ? "active" : "")}
                  onClick={()=>setSlug(listUrls[4])}
                >
                  A PARTIR DE 13 AÑOS
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={10}>
            <Row className="itemsRow">
              {items.map((item, i) => (
                <Col 
                  md={3} lg={2} sm={3} key={i}
                  style={{ marginTop: 5 }}
                  className="mx-3"
                >
                  <ItemList key={i} data={item} childList={true} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <div
          className="content-bottom"
          style={{ height: "20px" }}
          ref={contentListBottom}
        ></div>
      </Container>
    </div>
  );
};

export default ChildrenBooks;
