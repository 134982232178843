import React, { useEffect, useState } from 'react';
import { Button, Col, Jumbotron, Row, Spinner } from 'react-bootstrap';
import { AiFillEye } from 'react-icons/ai';
import { FaCrosshairs } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { getCountries } from '../../api/company';
// own api
import { changePassword, getMyOrders, infoUser, saveChanges } from '../../api/users';
// own components
import { InputCustom } from '../../components/input';
import SelectLibrary from '../../components/searchSelect';
import { COMPANY } from '../../constants';
import {
  setBenefitedBookstore,
  setBillingAddress,
  setEmail,
  setFirstName,
  setLastName,
  setShippingAddress,
  userSelector,
} from '../../slices/user';
import './profile.scss';

const Profile = () => {
  const [countries, setCountries] = useState([]);
  const [disableBtnChangePass, setDisableBtnChangePass] = useState(false);
  const [disableBtnSave, setDisableBtnSave] = useState(false);
  const [errorChangePass, setErrorChangePass] = useState('');
  const [successChangePass, setSuccessChangePass] = useState(false);
  const [errorSave, setErrorSave] = useState('');
  const [successSave, setSuccessSave] = useState(false);
  const [successAddressSave, setSuccessAddressSave] = useState(false);
  const [showEditForm, setShowEditForm] = useState(true);
  const [showPassForm, setShowPassForm] = useState(false);
  const [showAddressBillingForm, setShowAddressBillingForm] = useState(false);
  const [showAddressShippingForm, setShowAddressShippingForm] = useState(false);
  const [orders, setOrders] = useState([]);
  const [callFunc, setCallFunc] = useState(false);
  const { benefitedBookstore } = useSelector(userSelector);
  const dispatch = useDispatch();

  const [initialUserInfo, setInitialUserInfo] = useState({
    username: '',
    cif: '',
    name: '',
    last_name: '',
    email: '',
    birth_date: '',
    direccion_facturacion: {},
    direccion_envio: {},
    benefited_bookstore: benefitedBookstore?.id,
  });

  const [formInfo, setFormInfo] = useState({
    username: '',
    cif: '',
    name: '',
    last_name: '',
    email: '',
    birth_date: '',
    direccion_facturacion: {},
    direccion_envio: {},
    benefited_bookstore: benefitedBookstore?.id,
  });
  const [formChangePass, setFormChangePass] = useState({
    oldPass: '',
    newPass: '',
    repeatPass: '',
  });

  useEffect(() => {
    async function handleCountries() {
      const rs = await getCountries();
      if (rs.status === 200) {
        const c = rs.data.map((item) => ({
          value: item.codigo,
          label: item.nombre,
        }));
        setCountries(c);
      }
    }
    async function handleInfoUser() {
      const rs = await infoUser();
      if (rs.status === 200) {
        setInitialUserInfo(rs.data);
        setFormInfo({
          ...formInfo,
          ...rs.data,
          benefited_bookstore: rs.data.benefited_bookstore?.id,
        });
      }
    }
    handleCountries();
    handleInfoUser();
    getOrders();
    window.document.title = 'Mi cuenta | ' + COMPANY;
    // eslint-disable-next-line
    goUp();
  }, []);

  const getOrders = async () => {
    try {
      const { data, status } = await getMyOrders();
      if (status == 200) {
        setOrders(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  async function recoverUserInfo() {
    const rs = await infoUser();
    if (rs.status === 200) {
      setInitialUserInfo(rs.data);
    }
  }

  const handleChangePass = async () => {
    setDisableBtnChangePass(true);
    setErrorChangePass('');
    setSuccessChangePass(false);
    const { oldPass, newPass, repeatPass } = formChangePass;
    if (oldPass !== '' && newPass !== '' && repeatPass !== '') {
      const rs = await changePassword(formChangePass);
      if (rs.status === 200) {
        setFormChangePass({
          oldPass: '',
          newPass: '',
          repeatPass: '',
        });
        setSuccessChangePass(true);
      } else if (rs.status === 400) {
        setErrorChangePass(rs.data.msg);
      }
      setDisableBtnChangePass(false);
    } else {
      setErrorChangePass('Todos los campos son obligatorios');
      setDisableBtnChangePass(false);
    }
  };

  let time = null;
  let time2 = null;
  useEffect(() => {
    if (successSave) {
      time = setTimeout(() => setSuccessSave(false), 3000);
    }
    if (successAddressSave) {
      time2 = setTimeout(() => setSuccessAddressSave(false), 3000);
    }
    return () => {
      clearTimeout(time);
      clearTimeout(time2);
    };
  }, [successSave, successAddressSave]);

  const handleChangeSave = async () => {
    setDisableBtnSave(true);
    setErrorSave('');
    setSuccessAddressSave(false);
    const rs = await saveChanges(formInfo);
    try {
      if (rs.status === 200) {
        dispatch(setBillingAddress(formInfo.direccion_facturacion));
        dispatch(setShippingAddress(formInfo.direccion_envio));
        dispatch(setFirstName(formInfo.name));
        dispatch(setLastName(formInfo.last_name));
        dispatch(setEmail(formInfo.email));
        recoverUserInfo();
        setSuccessAddressSave(true);
        setDisableBtnSave(false);
      } else {
        setErrorSave('Algo ha ido mal, por favor, vuelve a intentarlo');
        setDisableBtnSave(false);
      }
    } catch (e) {
      console.log(e);
      setErrorSave('Algo ha ido mal, por favor, vuelve a intentarlo');
      setDisableBtnSave(false);
    }
  };

  const selectedLibrary = (library) => {
    dispatch(setBenefitedBookstore(library));
    setFormInfo({ ...formInfo, benefited_bookstore: library.id });
  };

  const goUp = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="container-fluid mainDiv">
      <Row>
        <Col md={12}>
          <h1 className="page-title static-title" onClick={() => console.log(formInfo)}>
            Mi cuenta
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h2 className="section">Datos de cuenta</h2>
          <Jumbotron fluid className="px-4 py-3">
            <div style={{ display: `${showEditForm ? 'block' : 'none'}` }}>
              <p>Usuario: {initialUserInfo.username}</p>
              {initialUserInfo.name !== '' ? (
                <>
                  <p>
                    Nombre y apellidos: {initialUserInfo.name} {initialUserInfo.last_name}
                  </p>
                  <p>Email: {initialUserInfo.email}</p>
                  {initialUserInfo.birth_date !== '' && <p>Fecha de nacimiento: {initialUserInfo.birth_date}</p>}
                  {initialUserInfo.direccion_facturacion?.phone !== '' && (
                    <p>Telf: {initialUserInfo.direccion_facturacion?.phone}</p>
                  )}
                </>
              ) : (
                <>
                  <p>Email: {initialUserInfo.email}</p>
                  {initialUserInfo.birth_date !== '' && <p>Fecha de nacimiento: {initialUserInfo.birth_date}</p>}
                  {initialUserInfo.direccion_facturacion?.phone !== '' && (
                    <p>Telf: {initialUserInfo.direccion_facturacion?.phone}</p>
                  )}
                </>
              )}
              {errorChangePass !== '' ? (
                <Row className="alert-danger">
                  <span>{errorChangePass}</span>
                </Row>
              ) : null}
              {successChangePass ? (
                <Row className="alert-success">
                  <span className="mb-0">Su contraseña se ha actualizado correctamente.</span>
                </Row>
              ) : null}
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setShowEditForm(false);
                  setShowPassForm(false);
                }}
              >
                EDITAR
              </Button>{' '}
              <Button variant="outline-secondary" onClick={() => setShowPassForm(!showPassForm)}>
                CAMBIAR CONTRASEÑA
              </Button>
            </div>

            <div id="password_change" style={{ display: `${showPassForm ? 'block' : 'none'}` }}>
              <Col md={6}>
                {/* {console.log(formChangePass)} */}
                <div style={{ display: 'none' }}>
                  <InputCustom
                    type="password"
                    label="Contraseña actual"
                    value={formChangePass.oldPass}
                    placeholder="Contraseña actual"
                    onChange={(e) =>
                      setFormChangePass({
                        ...formChangePass,
                        oldPass: e.target.value,
                      })
                    }
                  />
                </div>
                <InputCustom
                  type="password"
                  label="Contraseña nueva"
                  value={formChangePass.newPass}
                  placeholder="Nueva contraseña"
                  onChange={(e) =>
                    setFormChangePass({
                      ...formChangePass,
                      newPass: e.target.value,
                    })
                  }
                />
                <InputCustom
                  type="password"
                  label="Repetir contraseña"
                  value={formChangePass.repeatPass}
                  placeholder="Repetir contraseña nueva"
                  onChange={(e) =>
                    setFormChangePass({
                      ...formChangePass,
                      repeatPass: e.target.value,
                    })
                  }
                />
                <Button variant="outline-secondary" onClick={() => setShowPassForm(false)}>
                  CANCELAR
                </Button>{' '}
                <Button className="blue-btn" disabled={disableBtnChangePass} onClick={handleChangePass}>
                  {disableBtnChangePass ? <Spinner animation="grow" variant="dark" /> : <span>GUARDAR</span>}
                </Button>
              </Col>
            </div>

            <div id="account_data_edit" style={{ display: `${showEditForm ? 'none' : 'block'}` }}>
              <Col md={6}>
                <InputCustom
                  type="text"
                  label="Nombre"
                  placeholder="Nombre"
                  title="Nombre"
                  value={formInfo.name}
                  onChange={(e) => setFormInfo({ ...formInfo, name: e.target.value })}
                />
                <InputCustom
                  type="text"
                  label="Apellidos"
                  placeholder="Apellidos"
                  title="Apellidos"
                  value={formInfo.last_name}
                  onChange={(e) => setFormInfo({ ...formInfo, last_name: e.target.value })}
                />
                <InputCustom
                  type="date"
                  label="Fecha de nacimiento"
                  title="Fecha de nacimiento"
                  className="pb-2 pt-2"
                  value={formInfo.birth_date}
                  onChange={(e) => setFormInfo({ ...formInfo, birth_date: e.target.value })}
                />
                <InputCustom
                  type="email"
                  label="Correo electrónico"
                  placeholder="Correo electrónico"
                  title="Correo electrónico"
                  value={formInfo.email}
                  onChange={(e) => setFormInfo({ ...formInfo, email: e.target.value })}
                />
                <InputCustom
                  type="text"
                  label="CIF"
                  placeholder="CIF"
                  title="CIF"
                  value={formInfo.cif}
                  onChange={(e) => setFormInfo({ ...formInfo, cif: e.target.value })}
                />
                <InputCustom
                  type="tel"
                  label="Teléfono"
                  placeholder="Teléfono"
                  title="Teléfono"
                  value={formInfo.direccion_facturacion?.phone}
                  onChange={(e) => {
                    const df = {
                      ...formInfo.direccion_facturacion,
                      phone: e.target.value,
                    };
                    setFormInfo({ ...formInfo, direccion_facturacion: df });
                  }}
                />
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    setShowEditForm(true);
                  }}
                >
                  CANCELAR
                </Button>{' '}
                <Button
                  disabled={disableBtnSave}
                  className="blue-btn"
                  onClick={() => {
                    handleChangeSave();
                    setShowEditForm(true);
                  }}
                >
                  {disableBtnSave ? <Spinner animation="grow" variant="dark" /> : 'GUARDAR'}
                </Button>
              </Col>
            </div>
          </Jumbotron>
        </Col>
      </Row>
      {errorSave !== '' ? (
        <Row className="alert-danger">
          <span>{errorSave}</span>
        </Row>
      ) : null}
      {successSave ? (
        <Row className="alert-success">
          <span className="mb-0">Los cambios en sus datos personales se guardaron correctamente.</span>
        </Row>
      ) : null}
      <h2 className="section">Mi librería beneficiada</h2>
      <div className="section">
        <SelectLibrary selectedLibrary={benefitedBookstore} callback={selectedLibrary} />
      </div>
      <p className="locate-me">
        <Link to={'/bookshops/map'}>
          <FaCrosshairs />
          <a id="loc">Cerca de tu ubicación actual</a>{' '}
        </Link>
      </p>
      <div id="bookshop-save" className={``}>
        <Button id="bookshop-save--cancel" className="btn btn-default" variant="outline-secondary">
          Cancelar
        </Button>
        <Button onClick={handleChangeSave} className="blue-btn">
          GUARDAR
        </Button>
      </div>
      <Row id="addresses">
        <Col md={12}>
          <h2 className="section mt-4">Información de contacto (direcciones)</h2>
          <div className="addresses-container">
            {errorSave !== '' ? (
              <Row className="alert-danger">
                <span>{errorSave}</span>
              </Row>
            ) : null}
            {successAddressSave ? (
              <Row className="alert-success">
                <span className="mb-0">Los cambios en su dirección se guardaron correctamente</span>
              </Row>
            ) : null}

            {formInfo.direccion_facturacion?.address !== null ? (
              <>
                <h5>Dirección de facturación</h5>
                <p>{formInfo.direccion_facturacion?.address}</p>
                <p>
                  {formInfo.direccion_facturacion?.postal_code} - {formInfo.direccion_facturacion?.locality} -{' '}
                  {formInfo.direccion_facturacion?.province}
                </p>
                {formInfo.direccion_facturacion?.country !== undefined ? (
                  <p>{formInfo.direccion_facturacion?.country.label}</p>
                ) : null}
                <Button
                  className="blue-btn"
                  size="sm"
                  onClick={() => setShowAddressBillingForm(!showAddressBillingForm)}
                >
                  EDITAR
                </Button>{' '}
                <Button className="orange-btn" size="sm">
                  ELIMINAR
                </Button>
              </>
            ) : (
              <>
                <p>No tienes dirección de FACTURACIÓN creada</p>
                <p>
                  <a onClick={() => setShowAddressBillingForm(!showAddressBillingForm)}>Añade una dirección</a>
                </p>
              </>
            )}

            <div
              style={{
                display: `${showAddressBillingForm ? 'block' : 'none'}`,
              }}
            >
              <Col md={6}>
                <InputCustom
                  type="text"
                  label="Dirección"
                  placeholder="Dirección"
                  title="Dirección"
                  value={formInfo.direccion_facturacion?.address}
                  onChange={(e) => {
                    const df = {
                      ...formInfo.direccion_facturacion,
                      address: e.target.value,
                    };
                    setFormInfo({ ...formInfo, direccion_facturacion: df });
                  }}
                />
                <InputCustom
                  type="text"
                  name="postalcode"
                  label="Código postal"
                  placeholder="Código postal"
                  title="Código postal"
                  value={formInfo.direccion_facturacion?.postal_code}
                  onChange={(e) => {
                    const df = {
                      ...formInfo.direccion_facturacion,
                      postal_code: e.target.value,
                    };
                    setFormInfo({ ...formInfo, direccion_facturacion: df });
                  }}
                />
                <InputCustom
                  type="text"
                  label="Localidad"
                  placeholder="Localidad"
                  title="Localidad"
                  value={formInfo.direccion_facturacion?.locality}
                  onChange={(e) => {
                    const df = {
                      ...formInfo.direccion_facturacion,
                      locality: e.target.value,
                    };
                    setFormInfo({ ...formInfo, direccion_facturacion: df });
                  }}
                />
                <InputCustom
                  type="text"
                  label="Provincia"
                  placeholder="Provincia"
                  title="Provincia"
                  value={formInfo.direccion_facturacion?.province}
                  onChange={(e) => {
                    const df = {
                      ...formInfo.direccion_facturacion,
                      province: e.target.value,
                    };
                    setFormInfo({ ...formInfo, direccion_facturacion: df });
                  }}
                />
                <label>País</label>
                <Select
                  placeholder="País"
                  title="País"
                  className="mb-3"
                  options={countries}
                  defaultValue={formInfo.direccion_facturacion?.country}
                  value={formInfo.direccion_facturacion?.country}
                  onChange={(e) => {
                    const df = {
                      ...formInfo.direccion_facturacion,
                      country: { value: e.value, label: e.label },
                    };
                    setFormInfo({ ...formInfo, direccion_facturacion: df });
                  }}
                />
                <InputCustom
                  type="text"
                  label="Email"
                  placeholder="Email"
                  title="Email"
                  value={formInfo.direccion_facturacion?.email}
                  onChange={(e) => {
                    const df = {
                      ...formInfo.direccion_facturacion,
                      email: e.target.value,
                    };
                    setFormInfo({ ...formInfo, direccion_facturacion: df });
                  }}
                />
                <InputCustom
                  type="text"
                  label="Teléfono"
                  placeholder="Teléfono"
                  title="Teléfono"
                  value={formInfo.direccion_facturacion?.phone}
                  onChange={(e) => {
                    const df = {
                      ...formInfo.direccion_facturacion,
                      phone: e.target.value,
                    };
                    setFormInfo({ ...formInfo, direccion_facturacion: df });
                  }}
                />
                <Button variant="outline-secondary" onClick={() => setShowAddressBillingForm(false)}>
                  CANCELAR
                </Button>{' '}
                <Button
                  className="blue-btn"
                  disabled={disableBtnSave}
                  onClick={() => {
                    handleChangeSave();
                    setShowAddressBillingForm(false);
                  }}
                >
                  {disableBtnSave ? <Spinner animation="grow" variant="dark" /> : <span>GUARDAR</span>}
                </Button>
              </Col>
            </div>

            <hr className="w-100 mt-4" />

            {formInfo.direccion_envio?.address !== null ? (
              <>
                <h5>Dirección de envío</h5>
                <p>{formInfo.direccion_envio?.address}</p>
                <p>
                  {formInfo.direccion_envio?.postal_code} - {formInfo.direccion_envio?.locality} -{' '}
                  {formInfo.direccion_envio?.province}
                </p>
                {formInfo.direccion_envio?.country !== undefined ? (
                  <p>{formInfo.direccion_envio?.country.label}</p>
                ) : null}
                <Button
                  className="blue-btn"
                  size="sm"
                  onClick={() => setShowAddressShippingForm(!showAddressShippingForm)}
                >
                  EDITAR
                </Button>{' '}
                <Button className="orange-btn" size="sm">
                  ELIMINAR
                </Button>
              </>
            ) : (
              <>
                <p>No tienes dirección de ENVIO creada</p>
                <p>
                  <a onClick={() => setShowAddressShippingForm(!showAddressShippingForm)}>Añade una dirección</a>
                </p>
              </>
            )}

            <div
              style={{
                display: `${showAddressShippingForm ? 'block' : 'none'}`,
              }}
            >
              <Col md={6}>
                <InputCustom
                  type="text"
                  label="Dirección"
                  placeholder="Dirección"
                  title="Dirección"
                  value={formInfo.direccion_envio?.address}
                  onChange={(e) => {
                    const df = {
                      ...formInfo.direccion_envio,
                      address: e.target.value,
                    };
                    setFormInfo({ ...formInfo, direccion_envio: df });
                  }}
                />
                <InputCustom
                  type="text"
                  name="postalcode"
                  label="Código postal"
                  placeholder="Código postal"
                  title="Código postal"
                  value={formInfo.direccion_envio?.postal_code}
                  onChange={(e) => {
                    const df = {
                      ...formInfo.direccion_envio,
                      postal_code: e.target.value,
                    };
                    setFormInfo({ ...formInfo, direccion_envio: df });
                  }}
                />
                <InputCustom
                  type="text"
                  label="Teléfono"
                  placeholder="Teléfono"
                  title="Teléfono"
                  value={formInfo.direccion_envio?.phone}
                  onChange={(e) => {
                    const df = {
                      ...formInfo.direccion_envio,
                      phone: e.target.value,
                    };
                    setFormInfo({ ...formInfo, direccion_envio: df });
                  }}
                />
                <InputCustom
                  type="text"
                  label="Localidad"
                  placeholder="Localidad"
                  title="Localidad"
                  value={formInfo.direccion_envio?.locality}
                  onChange={(e) => {
                    const df = {
                      ...formInfo.direccion_envio,
                      locality: e.target.value,
                    };
                    setFormInfo({ ...formInfo, direccion_envio: df });
                  }}
                />
                <InputCustom
                  type="text"
                  label="Provincia"
                  placeholder="Provincia"
                  title="Provincia"
                  value={formInfo.direccion_envio?.province}
                  onChange={(e) => {
                    const df = {
                      ...formInfo.direccion_envio,
                      province: e.target.value,
                    };
                    setFormInfo({ ...formInfo, direccion_envio: df });
                  }}
                />
                <label>País</label>
                <Select
                  placeholder="País"
                  title="País"
                  className="mb-3"
                  options={countries}
                  defaultValue={formInfo.direccion_envio?.country}
                  value={formInfo.direccion_envio?.country}
                  onChange={(e) => {
                    const df = {
                      ...formInfo.direccion_envio,
                      country: { value: e.value, label: e.label },
                    };
                    setFormInfo({ ...formInfo, direccion_envio: df });
                  }}
                />
                <Button variant="outline-secondary" onClick={() => setShowAddressShippingForm(false)}>
                  CANCELAR
                </Button>{' '}
                <Button
                  className="blue-btn"
                  disabled={disableBtnSave}
                  onClick={() => {
                    handleChangeSave();
                    setShowAddressShippingForm(false);
                  }}
                >
                  {disableBtnSave ? <Spinner animation="grow" variant="dark" /> : <span>GUARDAR</span>}
                </Button>
              </Col>
            </div>
          </div>
        </Col>
      </Row>
      <Row id="Orders" className="mt-3 ordersRow">
        <Col md={12}>
          <h2 className="align-self-start section mt-4">Pedidos</h2>
          {orders.length ? (
            <>
              <Row style={{ padding: '0 12px' }}>
                <Col className="border tableTitle px-sm-4 px-0" style={{ whiteSpace: 'nowrap' }}>
                  <span>Nº de pedido</span>
                </Col>
                <Col className="border tableTitle px-sm-4 px-0">
                  <span>Fecha</span>
                </Col>
                <Col className="border tableTitle px-sm-4 px-0">
                  <span>Importe</span>
                </Col>
                <Col className="border tableTitle px-sm-4 px-0">
                  <span>Estado</span>
                </Col>
                <Col className="border tableTitle px-sm-4 px-0">
                  <span>Acciones</span>
                </Col>
              </Row>
              {orders.map((el, value) => (
                <Row
                  style={{
                    background: value % 2 === 0 ? '#f9f9f9' : '',
                    padding: '0 12px',
                  }}
                >
                  <Col className="border px-sm-4 px-0">
                    <span>{el.id}</span>
                  </Col>
                  <Col className="border px-sm-4 px-0" style={{ whiteSpace: 'nowrap' }}>
                    <span>{el.fecha}</span>
                  </Col>
                  <Col className="border px-sm-4 px-0">
                    <span style={{ color: '#f2a20d' }}>{el.total} €</span>
                  </Col>
                  <Col className="border px-sm-4 px-0">
                    <span>{el.status}</span>
                  </Col>
                  <Col className="border px-sm-4 px-0">
                    <Link className="col-md-1" to={`/mis-pedidos/${el.id}/`} title="Ver pedido">
                      <span>
                        <AiFillEye />
                      </span>
                    </Link>
                  </Col>
                </Row>
              ))}
            </>
          ) : (
            <h2 className=" font-weight-bold w-100 text-center">No se encontraron pedidos</h2>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
