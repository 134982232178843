import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Button } from "react-bootstrap";
import { InputCustom } from "../../components/input";
import { changeRecoverPassword } from "../../api/users";
// own constants
import { COMPANY } from "../../constants";
import { unSetLocal } from "../../helpers";

import "./recover.scss";

const Recover = ({ props }) => {
  useEffect(() => {
    window.document.title = "Recuperar contraseña | " + COMPANY;
  }, []);

  const [disableBtnRecoverPass, setDisableBtnRecoverPass] = useState(false);
  const [errorRecoverPass, setErrorRecoverPass] = useState("");
  const [successRecoverPass, setSuccessRecoverPass] = useState(false);
  let { refresh, access } = useParams();
  const [formRecoverPass, setFormRecoverPass] = useState({
    newPass: "",
    repeatPass: "",
    refresh_token: refresh,
    access: access,
  });
  const handleRecoverPass = async () => {
    setDisableBtnRecoverPass(true);
    setErrorRecoverPass("");
    setSuccessRecoverPass(false);
    const { newPass, repeatPass } = formRecoverPass;
    if (newPass !== "" && repeatPass !== "") {
      if (newPass === repeatPass) {
        localStorage.setItem("access", access);
        localStorage.setItem("refresh", refresh);
        const rs = await changeRecoverPassword(formRecoverPass);
        if (rs.status === 200) {
          setFormRecoverPass({
            newPass: "",
            repeatPass: "",
            refresh_token: "",
            access: "",
          });
          setSuccessRecoverPass(true);
        } else if (rs.status === 400) {
          setErrorRecoverPass(rs.data.msg);
        } else {
          setErrorRecoverPass("Su enlace ha caducado o no es válido");
        }
      } else {
        setErrorRecoverPass("Las contraseñas no coinciden");
        setDisableBtnRecoverPass(false);
      }

      unSetLocal("access");
      unSetLocal("refresh");
      setDisableBtnRecoverPass(false);
    } else {
      setErrorRecoverPass("Todos los campos son obligatorios");
      setDisableBtnRecoverPass(false);
    }
  };

  return (
    <div className="container p-rel">
      <div className="containerRecover margin-top-md">
        <h3>Cambiar Contraseña</h3>

        <div style={{ height: "20px", width: "40%" }}>
          <hr />
        </div>
        {!successRecoverPass ? (
          <div className="containerChangePass margin-top-md">
            <InputCustom
              type="password"
              value={formRecoverPass.newPass}
              label="Introduzca su nueva contraseña"
              placeholder="Nueva contraseña"
              onChange={(e) =>
                setFormRecoverPass({
                  ...formRecoverPass,
                  newPass: e.target.value,
                })
              }
            />
            <InputCustom
              type="password"
              value={formRecoverPass.repeatPass}
              label="Repetir contraseña"
              placeholder="Repetir contraseña"
              onChange={(e) =>
                setFormRecoverPass({
                  ...formRecoverPass,
                  repeatPass: e.target.value,
                })
              }
            />
          </div>
        ) : null}
        {errorRecoverPass !== "" ? (
          <Row className="alert-danger">
            <span>{errorRecoverPass}</span>
          </Row>
        ) : null}
        {successRecoverPass ? (
          <Row className="alert-success">
            <span>Su contraseña se ha actualizado correctamente</span>
          </Row>
        ) : null}

        {!successRecoverPass ? (
          <div className="containerButton">
            <Button
              variant="primary"
              disabled={disableBtnRecoverPass}
              onClick={disableBtnRecoverPass ? null : handleRecoverPass}
            >
              {disableBtnRecoverPass ? (
                <span>Cargando ...</span>
              ) : (
                <span>Cambiar contraseña</span>
              )}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Recover;
