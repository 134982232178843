/*eslint eqeqeq: "off"*/
import React from "react";
import { 
  Row, 
  Col, 
  Container, 
  InputGroup, 
  FormControl,
  Accordion,
  Card
} from "react-bootstrap";
import { RiCloseLine } from "react-icons/ri";
import { BiSearchAlt2 } from "react-icons/bi";
import { MdPlace } from "react-icons/md";
import { AiFillPhone, AiOutlineClockCircle } from "react-icons/ai";
import { HiMail } from "react-icons/hi";
import { Link } from "react-router-dom";

import "./availableLibraries.scss"

const AvailableLibraries = ({
  showLibraries, 
  closeOverlay,
  libraries
}) => {

  return (
    <>
    {showLibraries ? (
      <Container 
        fluid 
        className="modalOverlay libraries py-3 p m-0 d-flex justify-content-center"
      >
        <Row className="overflow-auto">
          <Col lg={12} className="">
            <Row className="pt-2">
              <Col lg={12} className="d-flex justify-content-between">
                <h2>Librerías</h2>
                <span
                  id="closeSearchContainer"
                  onClick={closeOverlay}
                >
                  <RiCloseLine className="" />
                </span>
              </Col>
            </Row>
            <Row className="pt-3 justify-content-center">
              <Col lg={12} className="d-flex justify-content-center">
                {/* <InputCustom /> */}
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Buscar por nombre, dirección CP..."
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <InputGroup.Text 
                    id="basic-addon2"
                    style={{background: '#4B95A6'}}
                  >
                    <BiSearchAlt2 />
                  </InputGroup.Text>
                </InputGroup>

              </Col>
              <Col lg={12} className="">
                <p>Se han encontrado {libraries.length} librerías:</p>
              </Col>
            </Row>
            <Row className="librariesRow">
              {libraries.map((el,value)=> {
                return (
                  <Col lg={12} className="p-0" key={value}>
                    <Accordion>
                      <Card>
                        <Card.Header className="pb-0">
                          <Row>
                            <Col>
                            
                            <p 
                              className="font-weight-bolder"
                              style={{fontSize: '14px'}}
                            > <MdPlace />{el.name}
                            </p>
                              {el.address !== '' && (
                                <p>{el.address}{' '}-{' '} {el.locality}</p>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="d-flex justify-content-center">
                            <Accordion.Toggle eventKey="0">
                              <p 
                                className="cursor-pointer"
                                style={{color: '#007bff'}}
                              >
                                Ver detalles 
                              </p>
                            </Accordion.Toggle>
                              &nbsp;|&nbsp;
                            <Link to={"/bookshops/map"}>
                              <p> Ver mapa</p>
                            </Link>
                            </Col>
                          </Row>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <p>
                              <AiOutlineClockCircle className="mr-2"/> 
                              {el.working_schedule == null ? 'sin datos' : el.working_schedule}
                            </p>
                            <p>
                              <AiFillPhone className="mr-2"/> 
                              {el.phone_number == '' ? 'sin datos' : el.phone_number}
                            </p>
                            <p>
                              <HiMail className="mr-2"/> 
                              {el.email == '' ? 'sin datos' : el.email}
                            </p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    ) : ('')}
    </>
  )
}

export default AvailableLibraries;