import React, { useState, useEffect } from 'react';
import { getAllBookstores as get, deleteBookstore as del } from '../../../../api/company';
import BackofficeTable from '../../../../components/backoffice/table/index';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import { InputCustom } from '../../../../components/input';
import SectionLoader from '../../../../components/loader/SectionLoader';
import { useLocation, useHistory } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import './bookStoreTableStyle.scss';
const headers = {
  name: 'Nombre',
  web_url: 'Enlace (U.R.L.)',
  email: 'Correo Electrónico',
  libelista_affiliate: 'Red Libelista',
  code: 'Código',
};

const BookstoresTable = (props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [name, setName] = useState(() => query.get('name') || '');
  const [code, setCode] = useState(() => query.get('code') || '');
  const [email, setEmail] = useState(() => query.get('email') || '');
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showInputName, setShowInputName] = useState(false);
  const [showInputCode, setShowInputCode] = useState(false);
  const [showInputEmail, setShowInputEmail] = useState(false);
  const history = useHistory();
  const { loadData, ActionsMenu } = props;
  loadData.colSpan = Object.keys(headers).length;

  const deleteQueryParams = () => {
    history.push('/administration/bookstores');
  };

  const buttonCollapse = {
    width: '100%',
    position: 'relative',
  };

  const utilitiesWidth = {
    width: '225px',
  };

  const myInputs = () => {
    const url = window.location.href;
    const includesQueryParams = url.includes('?');
    const showButtonSearch = showInputName || showInputCode || showInputEmail;
    return (
      <div className="d-flex my-3 ">
        {includesQueryParams && (
          <button className="btn  buttonDeleteQueryParams" onClick={() => deleteQueryParams()}>
            Volver
          </button>
        )}
        <Accordion defaultActiveKey="1" className={`${includesQueryParams ? 'modifyPosition' : 'buttonSearchPost'}`}>
          <Accordion.Toggle className="btn btn-info w-100">Búsqueda</Accordion.Toggle>
          <Accordion.Collapse style={buttonCollapse}>
            <div className="d-flex flex-column">
              <button onClick={() => setShowInputName(!showInputName)}>Nombre</button>
              <button onClick={() => setShowInputCode(!showInputCode)}>Código</button>
              <button onClick={() => setShowInputEmail(!showInputEmail)}>Correo electrónico</button>
            </div>
          </Accordion.Collapse>
        </Accordion>
        <form className={`${includesQueryParams ? 'modifyForm' : 'form-style'} d-flex`} onSubmit={searchData}>
          {showInputName && (
            <div className="mr-3" style={utilitiesWidth}>
              <InputCustom
                key={`hello`}
                type="text"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                placeholder="Buscar por nombre"
              />
            </div>
          )}
          {showInputCode && (
            <div className="mr-3" style={utilitiesWidth}>
              <InputCustom
                key={`hello`}
                type="text"
                value={code}
                onChange={(event) => {
                  setCode(event.target.value);
                }}
                placeholder="Buscar por código"
              />
            </div>
          )}
          {showInputEmail && (
            <div style={utilitiesWidth}>
              <InputCustom
                key={`hello`}
                type="text"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                placeholder="Buscar por correo electrónico"
              />
            </div>
          )}
          {showButtonSearch && (
            <div className="my-3 ">
              <input className="btn btn-info button" type="submit" value="Buscar" />
            </div>
          )}
        </form>
      </div>
    );
  };

  const getLoadData = async () => {
    setIsLoading(true);
    const { status, data } = await get(1, 15, name, code, email);
    if (status === 200) {
      setData(data.results);
    }
    setIsLoading(false);
    setName('');
    setCode('');
    setEmail('');
  };

  useEffect(() => {
    getLoadData();
  });

  const searchData = () => {
    history.push(`?&name=${name}&code=${code}&email=${email}&page=${1}&page_size=${15}/`);
    getLoadData();
  };

  const hasData = () => {
    if (data !== null && data !== undefined) {
      return Object.keys(data).length;
    } else {
      return false;
    }
  };

  const bookstoresTableProps = {
    title: 'Librerías',
    data,
    headers,
    ActionsMenu,
    loadData,
    customParams: myInputs(),
  };
  if (isLoading) {
    return <SectionLoader />;
  } else if (hasData()) {
    return <BackofficeTable {...bookstoresTableProps} />;
  } else {
    return (
      <>
        <div>No se encontraron resultados</div>
        <button className="btn  mt-1" onClick={() => deleteQueryParams()}>
          Volver
        </button>
      </>
    );
  }
};
BookstoresTable.request = {
  get,
  del,
};
BookstoresTable.itemClass = 'libreria';
BookstoresTable.requestParameter = 'id';
const BookstoresTablePage = () => <BackofficePagesManager PageViewer={BookstoresTable} />;
export default BookstoresTablePage;
