import React from "react";
import { Row, Col } from "react-bootstrap";

//redux
import { useSelector } from "react-redux";
import { companySelector } from "../../slices/company";

//styles
import "../../assets/css/staticPages.scss";

//own components
import Head from "../../components/metatags_helmet/metatags";

// about us pictures
import librariesNet from "../../assets/img/aboutus/quees_01.jpg";
import library from "../../assets/img/aboutus/quees_02.jpg";
import mind from "../../assets/img/aboutus/quees_03.png";
import howWorks1 from "../../assets/img/aboutus/comofunciona_01.jpg";
import howWorks2 from "../../assets/img/aboutus/comofunciona_02.jpg";
import howWorks3 from "../../assets/img/aboutus/comofunciona_03.jpg";
import howWorks4 from "../../assets/img/aboutus/comofunciona_04.jpg";
import ourHistory1 from "../../assets/img/aboutus/historia_01.jpg";
import ourHistory2 from "../../assets/img/aboutus/historia_02.jpg";
import ourHistory3 from "../../assets/img/aboutus/historia_03.jpg";
import ourHistory4 from "../../assets/img/aboutus/historia_04.jpg";
import team1 from "../../assets/img/aboutus/41.jpg";
import team2 from "../../assets/img/aboutus/42.jpg";
import team3 from "../../assets/img/aboutus/43.jpg";
import team4 from "../../assets/img/aboutus/44.jpg";
import team5 from "../../assets/img/aboutus/45.jpg";
import team6 from "../../assets/img/aboutus/46.jpg";
import ministerio from "../../assets/img/aboutus/ministerio.png";

const AboutUs = () => {
  const title = "¿Quiénes somos?";

  const { name, descripcion_pub } = useSelector(companySelector);

  return (
    <div className="content mainDiv">
      <div className="container-fluid">
        <Head description={descripcion_pub} title={title} noIndex={true} />
        <Row>
          <Col md={12}>
            <h1 className="page-title static-title">¿Quiénes somos?</h1>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <h2 className="page-subtitle mt-5 mb-4">¿Qué es Libelista?</h2>
            <Row>
              <Col sm={6} lg={4} className="px-4 mb-5">
                <div className="text-center">
                  <img src={librariesNet} alt="libraries net"></img>
                </div>
                <p>
                  Ocho y Medio forma parte de una red de librerías
                  independientes que, unidas, ofrecen un servicio de venta
                  digital de calidad y de proximidad.
                </p>
              </Col>
              <Col sm={6} lg={4} className="px-4 mb-5">
                <div className="text-center">
                  <img src={library} alt="library"></img>
                </div>
                <p>
                  Usando Ocho y Medio, das tu apoyo a las librerías locales y
                  ayudas a mantener el tejido cultural de tu ciudad.
                </p>
              </Col>
              <Col sm={6} lg={4} className="px-4 mb-5">
                <div className="text-center">
                  <img src={mind} alt="mind"></img>
                </div>
                <p>
                  El equipo de Ocho y Medio está formado por libreras y libreros
                  profesionales que te atenderán personalmente, ¡sin usar
                  algoritmos!
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <h2 className="text-center mt-5 mb-4">¿Cómo funciona?</h2>
            <Row>
              <Col sm={6} lg={3} className="px-4 mb-5">
                <div className="text-center">
                  <img src={howWorks1} alt="register pic"></img>
                </div>
                <p>
                  ¡Regístrate en Ocho y Medio! Podrás elegir a qué librería de
                  nuestra red quieres dar tu apoyo con tus futuros pedidos.
                </p>
              </Col>
              <Col sm={6} lg={3} className="px-4 mb-5">
                <div className="text-center">
                  <img src={howWorks2} alt="loupe pic"></img>
                </div>
                <p>
                  Navega por el catálogo de Ocho y Medio y selecciona los libros
                  que quieras añadir a tu cesta de la compra.
                </p>
              </Col>
              <Col sm={6} lg={3} className="px-4 mb-5">
                <div className="text-center">
                  <img src={howWorks3} alt="library net"></img>
                </div>
                <p>
                  Elige si quieres recibir el pedido en tu casa o pasar a
                  recogerlo en alguna de las librerías de la red.
                </p>
              </Col>
              <Col sm={6} lg={3} className="px-4 mb-5">
                <div className="text-center">
                  <img src={howWorks4} alt="bag"></img>
                </div>
                <p>
                  Si no lo has hecho al registrarte, ahora podrás elegir a qué
                  librería quieres destinar los beneficios de tu pedido.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <h2 className="text-center mt-5 mb-4">Nuestra historia</h2>
            <Row>
              <Col sm={6} lg={3} className="px-4 mb-5">
                <div className="text-center">
                  <img src={ourHistory1} alt="brain"></img>
                </div>
                <h4>
                  <strong>2012</strong>
                </h4>
                <h4>
                  <strong>Un pequeño gran proyecto.</strong>
                </h4>
                <p>
                  Aparece Liberdrac, un pequeño proyecto de venta de libros
                  digitales para librerías de Cataluña.
                </p>
              </Col>
              <Col sm={6} lg={3} className="px-4 mb-5">
                <div className="text-center">
                  <img src={ourHistory2} alt="books"></img>
                </div>
                <h4>
                  <strong>2015</strong>
                </h4>
                <h4>
                  <strong>Nace Libelista. ¡Hurra!</strong>
                </h4>
                <p>
                  Liberdrac crece en el resto del estado y suma el libro físico
                  a su catálogo. ¡Liberdrac se convierte en Libelista!
                </p>
              </Col>
              <Col sm={6} lg={3} className="px-4 mb-5">
                <div className="text-center">
                  <img src={ourHistory3} alt="worl map"></img>
                </div>
                <h4>
                  <strong>2018</strong>
                </h4>
                <h4>
                  <strong>¡Ya somos 100!</strong>
                </h4>
                <h4>
                  <strong>La família crece.</strong>
                </h4>
                <p>
                  Libelista amplia su equipo de trabajo y cuenta con más de 100
                  librerías asociadas. El proyecto se define y toma empuje.
                </p>
              </Col>
              <Col sm={6} lg={3} className="px-4 mb-5">
                <div className="text-center">
                  <img src={ourHistory4} alt="rocket"></img>
                </div>
                <h4>
                  <strong>2019</strong>
                </h4>
                <h4>
                  <strong>Nueva página web.</strong>
                </h4>
                <h4>
                  <strong>Nueva etapa.</strong>
                </h4>
                <p>
                  Aumentamos equipo y estrenamos página web, ¡todo con el fin de
                  ofrecer el mejor servicio a los usuarios!
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <h2 className="text-center mt-5 mb-4">El equipo Libelista</h2>
            <Row>
              <Col sm={6} lg={4} className="px-4 mb-5">
                <div className="text-center">
                  <img src={team1} alt="project coordinator"></img>
                </div>
                <h4 className="text-center">
                  <strong>Txetxu Barandiarán</strong>
                </h4>
                <p className="text-center">Coordinador del proyecto</p>
              </Col>
              <Col sm={6} lg={4} className="px-4 mb-5">
                <div className="text-center">
                  <img src={team2} alt="project coordinator"></img>
                </div>
                <h4 className="text-center">
                  <strong>Antonio de Marco</strong>
                </h4>
                <p className="text-center">Coordinador del proyecto</p>
              </Col>
              <Col sm={6} lg={4} className="px-4 mb-5">
                <div className="text-center">
                  <img src={team3} alt="communication responsible"></img>
                </div>
                <h4 className="text-center">
                  <strong>Raquel Durán</strong>
                </h4>
                <p className="text-center">Responsable de comunicación</p>
              </Col>
              <Col sm={6} lg={4} className="px-4 mb-5">
                <div className="text-center">
                  <img src={team4} alt="orders responsible"></img>
                </div>
                <h4 className="text-center">
                  <strong>Laia Benet</strong>
                </h4>
                <p className="text-center">Responsable de pedidos</p>
              </Col>
              <Col sm={6} lg={4} className="px-4 mb-5">
                <div className="text-center">
                  <img src={team5} alt="orders responsible"></img>
                </div>
                <h4 className="text-center">
                  <strong>Gerard Almirall</strong>
                </h4>
                <p className="text-center">Responsable de contenidos</p>
              </Col>
              <Col sm={6} lg={4} className="px-4 mb-5">
                <div className="text-center">
                  <img src={team6} alt="comercial responsible"></img>
                </div>
                <h4 className="text-center">
                  <strong>Iván Elvira</strong>
                </h4>
                <p className="text-center">Responsable comercial</p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 10, offset: 1 }} className="mt-5 mb-5">
            <div className="text-center">
              <img src={ministerio} alt="culture & sport ministery logo"></img>
            </div>
            <p className="text-center mt-2">
              Actividad subvencionada por el Ministerio de Cultura y Deporte
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AboutUs;
