import React, { useState, useEffect } from "react";
import { getUserBoughtBooks } from "../../../api/orders";
import defaultImg from "../../../assets/img/preview.jpg";

const MyBooks = ({ data }) => {
  return (
    <div className="container-fluid mainDiv">
      <div className="row">
        <div>
          <h1 className="page-title static-title">Mis libros</h1>
        </div>
      </div>
      <div className="d-flex flex-wrap">
        {data?.map(({ is_ebook, cover, titulo, autor, date }, index) => (
          <div className="position-relative" key={`MyBooksItem#${index}`}>
            <div className="img-container shadow-none d-flex justify-content-center">
              <img src={cover.includes('vacia') ? defaultImg : cover} alt={`${titulo} libro`} />
            </div>
            <div className="markers">
              {is_ebook && <p className="markers-ebook">EBOOK</p>}
            </div>
            <div className="row img-caption">
              <div className="col">
                <h5 className="title limit-long">
                  {titulo.charAt(0).toUpperCase() +
                    titulo.slice(1).toLowerCase()}
                </h5>
                <p className="text-center text-black-50">
                  {autor?.pop()?.nombre_autor || "..."}
                </p>
                <p className="mt-3 mb-0 text-center text-black-50">
                  {is_ebook ? "EBOOK EPUB" : "RUSTICO"}
                </p>
                <p className="text-black-50 text-center">
                  Comprado el {date || "..."}
                </p>
                {is_ebook && (
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      className=" my-0 mr-1 ml-0 libelistaButtonYellow py-2 px-3  rounded text-uppercase  text-white"
                    >
                      Leer
                    </button>
                    <button
                      type="button"
                      className="m-0 libelistaButtonBlue py-2 px-3 rounded  text-white text-uppercase"
                    >
                      descargar
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )) || "Aún no has comprado libros"}
      </div>
    </div>
  );
};

const MyBooksManager = () => {
  const [boughtBooksData, setBoughtBooksData] = useState(undefined);
  const getUserBoughtBooksHandler = async () => {
    try {
      const response = await getUserBoughtBooks();
      const { status, data } = response;
      if ([200, 201].includes(status) && data?.length) {
        setBoughtBooksData(data);
      }
    } catch (exception) {
      console.log(exception);
    }
  };
  useEffect(() => {
    getUserBoughtBooksHandler();
  }, []);
  return <MyBooks data={boughtBooksData?.flat()} />;
};
export default MyBooksManager;
