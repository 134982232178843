import { getBlogPosts as get, deletePost as del } from '../../../../api/libelista';
import React, { useState, useEffect } from 'react';
import BackofficeTable from '../../../../components/backoffice/table/index';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import SectionLoader from '../../../../components/loader/SectionLoader';
import { useLocation, useHistory } from 'react-router-dom';
import { InputCustom } from '../../../../components/input';
import { Accordion } from 'react-bootstrap';
import Select from 'react-select';
import './styleBlogPostTable.scss';

const headers = {
  title: 'Título',
  author: 'Autor',
  publish_date: 'Fecha de publicación',
  featured_paragraph: 'Párrafo destacado',
  'selected_type_post.name': 'Tipo de publicación',
};
const BlogPostsTable = (props) => {
  const { ActionsMenu, loadData } = props;
  loadData.colSpan = Object.keys(headers).length;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [type_post__slug, setType_Post__Slug] = useState(() => query.get('type_post__slug') || '');
  const [author__username, setAuthor__Username] = useState(() => query.get('author__username') || '');
  const [title, setTitle] = useState(() => query.get('title') || '');
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showInputTypePostSlug, setShowInputTypePostSlug] = useState(false);
  const [showInputAuthorUsername, setShowInputAuthorUsername] = useState(false);
  const [showInputTitle, setShowInputTitle] = useState(false);
  const history = useHistory();

  const buttonCollapse = {
    width: '100%',
    position: 'relative',
  };

  const utilitiesWidth = {
    width: '225px',
  };

  const options = [
    { value: 'noticias', label: 'Noticias' },
    { value: 'librerias-protagonistas', label: 'Librerias Protagonistas' },
    { value: 'entrevistas-libelista', label: 'Entrevistas libelista' },
    { value: 'libelista-propone', label: 'Libelista propone' },
  ];

  const showButtonSearch = showInputTypePostSlug || showInputAuthorUsername || showInputTitle;

  const deleteQueryParams = () => {
    history.push('/administration/blog-posts');
  };

  const myInputs = () => {
    const url = window.location.href;
    const includesQueryParams = url.includes('?');
    return (
      <div className="d-flex my-3 ">
        {includesQueryParams && (
          <button className="btn  buttonDeleteQuery positionDeleteQuery" onClick={() => deleteQueryParams()}>
            Volver
          </button>
        )}
        <Accordion defaultActiveKey="1" className={`${includesQueryParams ? 'modifyButtonPost' : 'buttonSearchPost'} `}>
          <Accordion.Toggle className="btn btn-info w-100">Búsqueda</Accordion.Toggle>
          <Accordion.Collapse style={buttonCollapse}>
            <div className="d-flex flex-column">
              <button onClick={() => setShowInputTypePostSlug(!showInputTypePostSlug)}>Tipo de publicación</button>
              <button onClick={() => setShowInputAuthorUsername(!showInputAuthorUsername)}>Autor</button>
              <button onClick={() => setShowInputTitle(!showInputTitle)}>Título</button>
            </div>
          </Accordion.Collapse>
        </Accordion>
        <form className={`${includesQueryParams ? 'positionFormPost' : 'form-post'} d-flex `} onSubmit={searchData}>
          {showInputTypePostSlug && (
            <div className="mr-3" style={utilitiesWidth}>
              <Select
                options={options}
                onChange={({ value }) => {
                  setType_Post__Slug(value);
                }}
                isSearchable={true}
                placeholder="Tipo de publicación"
              />
            </div>
          )}
          {showInputAuthorUsername && (
            <div className="mr-3" style={utilitiesWidth}>
              <InputCustom
                key={`hello`}
                type="text"
                value={author__username}
                onChange={(event) => {
                  setAuthor__Username(event.target.value);
                }}
                placeholder="Buscar por autor"
                className="modifyPlaceholder"
              />
            </div>
          )}
          {showInputTitle && (
            <div style={utilitiesWidth}>
              <InputCustom
                key={`hello`}
                type="text"
                value={title}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
                placeholder="Buscar por título"
                className="modifyPlaceholder"
              />
            </div>
          )}
          {showButtonSearch && (
            <div className="my-3">
              <input className="btn btn-info styleButtonPost" type="submit" value="Buscar" />
            </div>
          )}
        </form>
      </div>
    );
  };

  const getLoadData = async () => {
    setIsLoading(true);
    const { status, data } = await get(1, 15, type_post__slug, author__username, title);
    if (status === 200) {
      setData(data.results);
    }
    setIsLoading(false);
    setType_Post__Slug('');
    setAuthor__Username('');
    setTitle('');
  };

  const searchData = () => {
    history.push(
      `?&type_post__slug=${type_post__slug}&author__username=${author__username}&title=${title}&page=${1}&page_size=${15}/`
    );
    getLoadData();
  };

  useEffect(() => {
    getLoadData();
  });

  const hasData = () => {
    if (data !== null && data !== undefined) {
      return Object.keys(data).length;
    } else {
      return false;
    }
  };

  const blogPostsTableProps = {
    title: 'Publicaciónes de blog',
    data,
    headers,
    ActionsMenu,
    extraFormat: { featured_paragraph: 'elipsis' },
    loadData,
    customParams: myInputs(),
  };
  if (isLoading) {
    return <SectionLoader />;
  } else if (hasData()) {
    return <BackofficeTable {...blogPostsTableProps} />;
  } else {
    return (
      <>
        <div>No se encontraron datos</div>
        <button className="btn  buttonDeleteQuery" onClick={() => deleteQueryParams()}>
          Volver
        </button>
      </>
    );
  }
};
BlogPostsTable.request = {
  get,
  del,
};
BlogPostsTable.itemClass = 'publicación de blog';
BlogPostsTable.requestParameter = 'id';
const BlogPostsTablePage = () => <BackofficePagesManager PageViewer={BlogPostsTable} />;
export default BlogPostsTablePage;
