import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  code: '',
  name: '',
  descripcion_pub: '',
  brandLogo: '',
  has_slider: false,
  has_events: false,
  has_blog: false,
  has_subscription: false,
  latitude: '',
  longitude: '',
  business_hours: null,
  cafeteria_hours: null,
  pais: '',
  localidad: '',
  menus: [],
  redes_sociales: {},
  favicon: '',
  data: null,
  paypal_sandbox: null,
  paypal_production: null,
  claimsText: '',
  refundText: '',
  mainLists: [],
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    loadMetaCompany: (state, action) => {
      const {
        code,
        name,
        descripcion_pub,
        logo,
        direccion_completa,
        telefono,
        email,
        menus,
        business_hours,
        cafeteria_hours,
        redes_sociales,
        favicon,
        data,
        paypal_sandbox,
        paypal_production,
        latitude,
        longitude,
        pais,
        localidad,
        has_slider,
        has_events,
        has_blog,
        has_subscription,
        claims_text,
        refund_text,
      } = action.payload;
      state.code = code;
      state.name = name;
      state.descripcion_pub = descripcion_pub;
      state.brandLogo = logo;
      state.direccion_completa = direccion_completa;
      state.telefono = telefono;
      state.email = email;
      state.menus = menus;
      state.redes_sociales = redes_sociales;
      state.business_hours = business_hours;
      state.cafeteria_hours = cafeteria_hours;
      state.favicon = favicon;
      state.data = data;
      state.paypal_sandbox = paypal_sandbox;
      state.paypal_production = paypal_production;
      state.latitude = latitude;
      state.longitude = longitude;
      state.pais = pais;
      state.localidad = localidad;
      state.has_slider = has_slider;
      state.has_events = has_events;
      state.has_blog = has_blog;
      state.has_subscription = has_subscription;
      state.claimsText = claims_text;
      state.refundText = refund_text;
    },
    setMainLists: (state, action) => {
      state.mainLists = action.payload;
    },
  },
});

export const { loadMetaCompany, setMainLists } = companySlice.actions;
export const companySelector = (state) => state.company;
export default companySlice.reducer;
