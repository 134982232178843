import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import {
  getBooksOfTheMonth as get,
  editBooksOfTheMonth as edit,
  createBooksOfTheMonth as create,
  getAllBookOfTheMonthItems as getAllFeaturedBooks,
  getBookOfTheMonthItem as getFeaturedBook,
} from '../../../../api/libelista';
import ActionsForm from '../../../../components/backoffice/actionsForm/index';
import { getSelectDataHandlers } from '../../../../utils/backoffice/getSelectDataHandlers';
import SectionLoader from 'components/loader/SectionLoader';
const BooksOfTheMonthPostItem = ({ data, loadData }) => {
  const { action } = useParams();
  const [
    { defaultOptions: defaultFeaturedBooksOptions, defaultOption: defaultFeaturedBooksOption },
    setFeaturedBooksDefaultOptionValues,
  ] = useState({});
  const { getSelectData: getFeaturedBooksOptions, setSelectDefaultData: setFeaturedBooksOptions } =
    getSelectDataHandlers(getFeaturedBook, getAllFeaturedBooks, setFeaturedBooksDefaultOptionValues, {
      fieldNameWithDisplayValue: 'title',
      fieldNameWithRequestValue: 'id',
    });
  const mustWaitForData = ['detail', 'edit'].includes(action);
  const renderConditions = [
    !mustWaitForData || data,
    defaultFeaturedBooksOptions?.length || defaultFeaturedBooksOption,
  ];
  useEffect(() => {
    if (!mustWaitForData || data) {
      setFeaturedBooksOptions(data?.featured_books);
    }
  }, [data]);
  /**
   * defaultValue for multi select is better constructed this way so the select component
   * can handle the hiding or displaying of the selected option by itself
   * Note: In case there's no list of options the state's defaul option will be used
   */
  const multiSelectDefaultValues =
    data?.featured_books?.map((id) => defaultFeaturedBooksOptions?.find(({ value }) => parseInt(value) === id)) ||
    defaultFeaturedBooksOption;
  const inputFields = [
    {
      label: 'Título',
      name: 'title',
      type: 'text',
      required: true,
    },
    {
      label: 'Imágen',
      name: 'image',
      type: 'file',
      imageUrl: data?.image,
      required: true,
    },
    {
      label: 'Libros destacados',
      name: 'featured_books',
      type: 'select',
      isAsync: true,
      loadOptions: getFeaturedBooksOptions,
      defaultOptions: defaultFeaturedBooksOptions,
      defaultValue: multiSelectDefaultValues,
      isMulti: true,
      isDisabled: action === 'detail',
    },
  ];
  /**
   * Avoids sending the url image that comes when image
   * is previously set
   */
  const { image, ...dataWithouImageField } = data || {};
  const actionsFormProps = {
    inputFields,
    title: 'Libros del Mes',
    subsectionPath: 'books-of-the-month-posts',
    data: dataWithouImageField || {},
    requests: { edit, create },
    formDataConfiguration: { featured_books: true },
    action,
    loadData,
  };
  return renderConditions.every((condition) => condition) ? <ActionsForm {...actionsFormProps} /> : <SectionLoader />;
};
BooksOfTheMonthPostItem.request = {
  get,
};
const BooksOfTheMonthPostItemPage = () => <BackofficePagesManager PageViewer={BooksOfTheMonthPostItem} />;
export default BooksOfTheMonthPostItemPage;
