import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Form } from 'react-bootstrap';
import Select from 'react-select';

// own components
import ItemList from '../../components/itemList/item';
import { convertMetatagName, recoverMetatagName } from '../../utils';
import { getAwardedBooks } from '../../api/libros';

import Head from '../../components/metatags_helmet/metatags';
import './awardedBooks.scss';

const AwardedBooks = (props) => {
  const [items, setItems] = useState([]);
  const [itemsByYear, setItemsByYear] = useState([]);
  const options = [
    { value: 0, label: 'all' },
    { value: 1, label: '2017' },
    { value: 2, label: '2018' },
    { value: 3, label: '2019' },
    { value: 4, label: '2020' },
    { value: 5, label: '2021' },
    { value: 6, label: '2022' },
  ];

  const getLoadData = async () => {
    const { status, data } = await getAwardedBooks();
    if (status === 200) {
      setItems(data.results);
    }
  };

  const filterItems = (year) => {
    if (year !== 'all') {
      let newItems = items.filter((el) => {
        return el.year == year;
      });
      setItemsByYear(newItems);
    } else {
      setItemsByYear([]);
    }
  };

  useEffect(() => {
    getLoadData();
  }, []);

  return (
    <div className="container-fluid px-0 mainDiv">
      {/* <Head description={description} title={metaTitle} /> */}
      <Row className="px-2 mx-0">
        <Col md={12} className="pl-1">
          <h1 className="page-title static-title mt-4 spectral">Libros premiados</h1>
        </Col>
        <Col md={12} className="d-flex p-0 yearCol">
          <p>Seleccionar el año</p>
          <Select options={options} className="mb-3 select" onChange={(e) => filterItems(e.label)} />
        </Col>
      </Row>
      <Container fluid className="px-0 mx-0">
        <Row className="justify-content-center pl-3 mx-0">
          <Col lg={12}>
            {itemsByYear.length > 0 ? (
              <Row className="itemsRow">
                {itemsByYear.map((item, i) => (
                  <Col md={4} lg={2} sm={6} key={i} style={{ marginTop: 5 }} className="">
                    <ItemList key={i} data={item.libro[0]} prize={item} isAward={true} />
                  </Col>
                ))}
              </Row>
            ) : (
              <Row className="itemsRow">
                {items.map((item, i) => (
                  <Col md={4} lg={2} sm={6} key={i} style={{ marginTop: 5 }} className="">
                    <ItemList key={i} data={item.display_libro[0]} prize={item} isAward={true} />
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AwardedBooks;
