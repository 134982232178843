import React from 'react';
import { Row, Col, Container, Card, Accordion } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { ImBook } from 'react-icons/im';
import { BiWorld } from 'react-icons/bi';
import { TiShoppingCart } from 'react-icons/ti';
import { RiNewspaperLine } from 'react-icons/ri';
import { BsPersonFill } from 'react-icons/bs';
import './adminAsideMenu.scss';
const HomeSection = () => {
  const history = useHistory();
  return (
    <Card className="p-0">
      <Card.Header className="p-0">
        <Accordion.Toggle
          className="text-white font-weight-bold text-center"
          onClick={() => {
            history.push('/administration');
          }}
        >
          INICIO
        </Accordion.Toggle>
      </Card.Header>
    </Card>
  );
};
const CATALOGUE_TITLE = (
  <>
    <ImBook className="mr-2" style={{ fontSize: '16px' }} />
    CATÁLOGO
  </>
);
const CATALOGUE_SUBSECTIONS = [
  ['Productos', { endpoint: 'products' }],
  ['Listas', { endpoint: 'lists' }],
  ['Importar', { endpoint: 'imports' }],
  ['Editoriales', { endpoint: 'editorials' }],
  ['Autores', { endpoint: 'authors' }],
  ['Cupones', { endpoint: 'coupons' }],
  // ['Palabras claves', { endpoint: 'keywords' }],
];
const CLIENT_TITLE = (
  <>
    <BiWorld className="mr-2" />
    CLIENTES
  </>
);
const CLIENT_SUBSECTIONS = [
  ['Usuarios', { endpoint: 'users' }],
  ['Suscripciones a restock', { endpoint: 'email-subscriptions' }],
  ['Comentarios de libros', { endpoint: 'comments' }],
  ['Mensajes', { endpoint: 'client-messages' }],
];
const SALES_TITLE = (
  <>
    <TiShoppingCart className="mr-2" />
    VENTAS
  </>
);
const SALES_SUBSECTIONS = [
  ['Pedidos', { endpoint: 'orders' }],
  ['Facturas', { endpoint: 'bills' }],
  ['Comisiones por pedido', { endpoint: 'commissions' }],
  ['Comisiones por empresa', { endpoint: 'commissions-by-company' }],
];
const MARKETING_TITLE = (
  <>
    <RiNewspaperLine className="mr-2" />
    MARKETING
  </>
);
const MARKETING_SUBSECTIONS = [
  ['Publicaciones de Blog', { endpoint: 'blog-posts' }],
  ['Publicaciones de Libros del mes', { endpoint: 'books-of-the-month-posts' }],
  ['Libros del mes', { endpoint: 'books-of-the-month' }],
  ['Agenda', { endpoint: 'schedules' }],
  ['Libros premiados', { endpoint: 'award-winning-books' }],
  // ['Carritos', { endpoint: 'carts' }],
];
const ADMINISTRATION_TITLE = (
  <>
    <BsPersonFill className="mr-2" />
    ADMINISTRACIÓN
  </>
);
const ADMINISTRATION_SUBSECTIONS = [['Librerías', { endpoint: 'bookstores' }]];
const AdminAsideMenu = () => {
  return (
    <Container fluid className="px-0">
      <Row className="adminAsideRow px-0 m-0">
        <Col lg={12} className="px-0 m-0">
          <Accordion>
            <HomeSection />
            {[
              [CATALOGUE_TITLE, CATALOGUE_SUBSECTIONS],
              [CLIENT_TITLE, CLIENT_SUBSECTIONS],
              [SALES_TITLE, SALES_SUBSECTIONS],
              [MARKETING_TITLE, MARKETING_SUBSECTIONS],
              [ADMINISTRATION_TITLE, ADMINISTRATION_SUBSECTIONS],
            ].map(([SectionTitle, subsectionsData], index) => (
              <Card key={`Section#${index}`} className="p-0">
                <Card.Header className="p-0 m-0">
                  <Accordion.Toggle eventKey={`${index}`}>{SectionTitle}</Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={`${index}`}>
                  <Card.Body className="d-flex flex-column p-2">
                    {subsectionsData.map(([subsectionName, { endpoint, callback }], index) => (
                      <Link
                        key={`subSection${subsectionName + '#' + index}`}
                        to={endpoint ? `/administration/${endpoint}` : '#'}
                        className="text-left w-100 pt-1 mb-1"
                      >
                        {subsectionName}
                      </Link>
                    ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminAsideMenu;
