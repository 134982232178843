import React, { useEffect, useState } from "react";

// api
import { getListsHome } from "../../api/lists";

// own components
import ListHome from "./listHome";
import SectionLoader from "../../components/loader/SectionLoader";

const ListsHome = (props) => {
  const { data } = props;

  return (
    <>
      {data && data.length > 0 ? (
        data.map((item, i) => <ListHome key={i} data={item} />)
      ) : (
        <SectionLoader />
      )}
    </>
  );
};

export default ListsHome;
