export const formatDate = (date = null) => {
  return new Date(date).toLocaleDateString("es-ES", {
    weekday: undefined,
    year: "numeric",
    month: "short",
    day: "numeric",
    // hour: "numeric",
    // minute: "numeric",
  });
};
