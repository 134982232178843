import { methodPut, methodGet, methodPost, methodDelete } from './methods';

import { API_INFO } from '../constants';

export const changePassword = async (payload) => {
  return methodPut(API_INFO + 'user/actions/change-pass/', payload);
};

export const saveChanges = async (payload) => {
  return methodPut(API_INFO + 'user/actions/save-changes/', payload);
};

export const infoUser = async () => {
  return methodGet(API_INFO + 'user/actions/info/', {});
};

export const registerUser = async (payload) => {
  return methodPost(API_INFO + 'user/users/', payload);
};
export const deleteUser = async (id) => {
  return methodDelete(API_INFO + `user/users/${id}/`);
};
export const getAllUsers = async (page = 1, pageSize = 10, search = '') => {
  return methodGet(API_INFO + `user/users/?page=${page}&page_size=${pageSize}${search ? '&search=' + search : ''}`);
};
export const getUsersBySearch = async (search) => {
  return methodGet(API_INFO + `user/users/${search ? '?search=' + search : ''}`);
};
export const getUserById = async (id) => {
  return methodGet(API_INFO + `user/users/${id}`);
};

export const editUser = async (payload, id) => {
  const url = API_INFO + `user/users/${id || payload.id || payload.get('id')}/`;
  return methodPut(url, payload);
};

export const recoverPassword = async (payload) => {
  return methodPost(API_INFO + 'user/users/recover-password/', payload);
};

export const getMyOrders = async () => {
  return methodGet(API_INFO + 'storehouse/orders/get_orders_by_user/');
};

export const changeRecoverPassword = async (payload) => {
  return methodPut(API_INFO + 'user/actions/change-recover-pass/', payload);
};
