import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { getActivityDetail } from "../../api/libelista";
import Head from "../../components/metatags_helmet/metatags";
import { BiCalendarCheck } from "react-icons/bi";
import { MdPlace } from "react-icons/md";
import "./schedule.scss"

const EventDetail = (props) => {
  const [item, setItem] = useState([]);
  const [description, setDescription] = useState("Libelista");
  const [metaTitle, setMetaTitle] = useState("Libelista");
  const params = useParams()

  const getActivity = async () => {
    const rs = await getActivityDetail(params.id);
    if (rs.status === 200) {
      rs.data.start_date = rs.data.start_date.split('T')[0];
      rs.data.end_date = rs.data.end_date.split('T')[0];
      setItem(rs.data)
    }
  };

  useEffect(() => {
    const goUp = () => {
      window.scrollTo(0, 0);
    };
    goUp();
    getActivity();
  }, []);

  return (
    <div className="container-fluid px-0 mainDiv">
      <Head description={description} title={metaTitle} />
      <Row className="px-2 mx-0">
        <Col md={12} className="pl-1">
          <h1 className="page-title static-title mt-4 spectral">
            Agenda
          </h1>
        </Col>
      </Row>
      <Container fluid className="px-0 mx-0 eventDetail">
        <Row className="pl-3 mx-0">
          <Col lg={10} className="px-0 mx-0 overflow-hidden">
            <img 
              src={`${item.image}`} 
              alt="background.jpg"
              style={{width: '95%'}}
            />
          </Col>
        </Row>
        <Row className="pl-3 mx-0">
          {item.title && (
            <>
              <Col lg={3} className="">
                <h6 className="orange text-uppercase mt-4">
                  {item.display_type_schedule[0]?.name}
                </h6>
                <h3 className="mt-1 mb-4">
                  {item.title}
                </h3>
                <div className="d-flex mt-4">
                  <BiCalendarCheck style={{color: '#f2a20d'}} />
                  <span className="ml-1 font-weight-bold">{item.start_date} AL</span> 
                  <span className="font-weight-bold">&nbsp;{item.end_date}</span>
                </div>
                <div className="d-flex mt-1">
                  <MdPlace style={{color: '#f2a20d'}} />
                  <span className="ml-1 text-uppercase font-weight-bold">{item.place}</span>
                </div>
              </Col>
              <Col lg={7} className="pr-5">
                <p className="mt-4"> {item.body}</p>
                {item.display_libro !== null && 
                  <Row className="relatedBookRow px-3 pb-3">
                    <Col className="pt-4" lg={12}>
                      <p>Relacionado</p>
                    </Col>
                    <Col className="px-4" lg={12}>
                      <Row>
                        <Col className="px-1" lg={3}>
                          {item.display_libro !== null && 
                            <img src={item.display_libro?.caratula} alt="img.jpg" className="px-0" />
                          }
                        </Col>
                        <Col className="" lg={9}>
                          <Row className="h-100">
                            <Col lg={12}>
                              {item.display_libro !== null && 
                                <>
                                  <h3>{item.display_libro.titulo}</h3>
                                  <p>{item.display_libro.autor[0].nombre_autor}</p>
                                </>
                              }
                            </Col>
                            <Col className="align-self-end" lg={12}>
                              {item.display_libro !== null && 
                                <Link to={`/productos/libro/${item.display_libro.url}`}>
                                  <Button className="blue-btn">
                                    Ver libro
                                  </Button>
                                </Link>
                              } 
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default EventDetail;
