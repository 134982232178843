import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import { RiCloseLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//redux
import { cartSelector, removeItem, removeGiftCard } from '../../slices/cart';
import { useSelector } from 'react-redux';

// own components
import Image from '../../components/image';
import notavaib from '../../assets/img/preview.jpg';
//api
import { removeItemCart, deleteGiftCardCart } from '../../api/ecommerce';
import giftImageCard from '../../assets/img/giftCard.jpg';

const MiniCart = ({ isLogged }) => {
  const { items, hasGiftCard, giftCard } = useSelector(cartSelector);

  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);

  const removeProductCart = async (item) => {
    if (isLogged) {
      const payload = { item: item.id };
      const { status, dataResponse } = await removeItemCart(payload);
      if (status === 200) {
        dispatch(removeItem(item));
      } else {
        console.log('error');
      }
    } else {
      dispatch(removeItem(item));
    }
  };

  const removeGiftCardCart = async () => {
    if (isLogged) {
      const payload = { item: giftCard };
      const { status, data } = await deleteGiftCardCart(payload);
      if (status === 200) {
        dispatch(removeGiftCard());
      } else {
        console.log('error');
      }
    } else {
      dispatch(removeGiftCard());
    }
  };

  const urlItem = (item) => `/productos/${item.type}/${item.url}/`;

  const [productsShow, setProductsShow] = useState(() => [items]);

  useEffect(() => {
    if (items !== '' && items !== undefined) {
      setProductsShow(items);
    }
  }, [items]);

  const styleGiftCard = {
    width: '100%',
    height: 'auto',
    maxHeight: '242px',
  };

  const paintCartProducts = (item, index) => {
    return (
      <div key={index} className="cart-product" id={item.id}>
        <Row className="cart-product__item">
          <Col lg={3} className="px-2">
            <Link to={urlItem(item)} onClick={() => setModalShow(false)}>
              <Image key={item.id} src={item.get_url} fallbackSrc={notavaib} height="100" />
            </Link>
          </Col>
          <Col lg={8} className="details px-2">
            <div className="details-title">
              <Link to={urlItem(item)} onClick={() => setModalShow(false)}>
                {item.titulo}
              </Link>
            </div>
            {item.autor ? (
              <div className="details-author">
                <span>{item.autor.length > 0 ? item.autor[0].nombre_autor : ''}</span>
              </div>
            ) : (
              ''
            )}
            <div className="details-price">
              <span className="quantity">
                {item.quantity} {item.quantity > 1 ? 'unidades X ' : 'unidad X '}
              </span>
              <span className="unitPrice">{(item.quantity * item.precio_venta_publico).toFixed(2)} €</span>
            </div>
          </Col>
          <Col lg={1} className="px-2">
            <RiCloseLine className="icon-sm" style={{ cursor: 'pointer' }} onClick={() => removeProductCart(item)} />
          </Col>
        </Row>
      </div>
    );
  };

  const paintGiftCard = () => {
    return (
      <div className=" cart-product">
        <Row className="cart-product__item">
          <Col lg={3} className="px-2">
            <Image src={giftImageCard} style={styleGiftCard} />
          </Col>
          <Col lg={8} className="details px-2">
            <div className="details-title">Tarjeta de regalo</div>
            <div className="details-price">
              <span className="quantity">1 unidad X </span>
              <span className="unitPrice">{giftCard?.discount} €</span>
            </div>
          </Col>
          <Col lg={1} className="px-2">
            <RiCloseLine className="icon-sm" style={{ cursor: 'pointer' }} onClick={() => removeGiftCardCart()} />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="cart">
      {productsShow !== undefined && productsShow.map((item, index) => paintCartProducts(item, index))}
      {hasGiftCard && paintGiftCard()}
    </div>
  );
};

export default MiniCart;
