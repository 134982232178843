import React from 'react';
const ActionConfirmationModal = ({
  hidden = true,
  payload = {},
  actionHandler = () => {},
  action = 'Confirmar',
  actionState,
  itemClass = 'objeto',
  resetModalDataHandler = () => {},
}) => {
  let confirmButtonText = action;
  let confirmButtonClasses = 'btn btn-danger';
  if (['success', 'error'].includes(actionState)) {
    confirmButtonText = actionState === 'success' ? 'Exito' : 'Error';
    confirmButtonClasses = `btn disabled ${actionState === 'success' ? 'btn-success' : 'btn-warning'}`;
    setTimeout(resetModalDataHandler, 1000);
  } else if (actionState === 'pending') {
    confirmButtonText = 'Cargando...';
    confirmButtonClasses = 'btn btn-info disabled';
  }
  return (
    !hidden && (
      <div
        className="position-absolute h-100 w-100"
        style={{ backgroundColor: '#3335', zIndex: 1 }}
        id="actionConfirmationModal"
        aria-labelledby="actionConfirmationModal"
        aria-hidden={hidden}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Diálogo de confirmación - <strong>{action}</strong>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => resetModalDataHandler()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Estas por <span className="text-lowercase">{action}</span> el{' '}
              <strong>
                {itemClass} #{payload.id}
              </strong>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => resetModalDataHandler()}
              >
                Salir
              </button>
              <button
                type="button"
                disabled={actionState}
                className={confirmButtonClasses}
                onClick={() => actionHandler(payload)}
              >
                {confirmButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
export default ActionConfirmationModal;
