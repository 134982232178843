import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Navbar, Nav, Row, Col, NavDropdown, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import { AiOutlineSearch } from 'react-icons/ai';
import { RiShoppingBagFill, RiCloseLine } from 'react-icons/ri';
import { FaUserAlt, FaSignInAlt, FaHeart, FaUserEdit, FaSignOutAlt, FaBookReader } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import delay from 'delay';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import logoLibe from '../../assets/img/logo_libelista.png';
import loggedLibe from '../../assets/img/logged_libe.png';

//redux
import { cartSelector, clear } from '../../slices/cart';
import { useSelector } from 'react-redux';

// context
import { UserContext } from '../../context/userContext';

// own components
import SearchBar from './SearchBar';
import SearchBarDesktop from './SearchBarDesktop';
import ProductBarDesktop from './ProductBarDesktop';
import LoginModal from '../../pages/login';
import DropMenuLogged from './DropMenuLogged';
import CustomDropdown from './CustomDropdown';

//helpers
import { unSetLocal } from '../../helpers';

//api
import { logoutApi } from '../../api/login';
import {
  logoutSuccess,
  userSelector,
  setAsociatedLibraryImage,
  setIsWorker,
  setEmail,
  setFirstName,
  setLastName,
  setBenefitedBookstore,
  setShippingAddress,
  setBillingAddress,
} from '../../slices/user';

const MenuMain = ({ brandLogo, menus, isLogged }) => {
  const { isSignUpModalOpen, showSignUpModal } = useContext(UserContext);
  const [showToast, setShowToast] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showMiniSearchBar, setShowMiniSearchBar] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [regLogModalControl, setRegLogModalControl] = useState(false);
  const history = useHistory();
  const handleCloseModal = useCallback(() => showSignUpModal(false), [showSignUpModal]);
  const handleShowModal = useCallback(() => showSignUpModal(true), [showSignUpModal]);
  const { countItems, lastAddedItem, lastRemovedItem, isIncrease } = useSelector(cartSelector);
  const { asociatedLibraryImage, isWorker } = useSelector(userSelector);
  const [secondaryImage, setSecondaryImage] = useState(false);
  const previousCounterValue = useRef(countItems);
  const dispatch = useDispatch();
  const headerRef = useRef();

  const [counterAnimProps, set] = useSpring(() => ({
    backgroundColor: 'white',
    transform: 'translateY(0px)',
    config: {
      tension: 160,
      friction: 20,
    },
  }));

  useScrollPosition(
    ({ currPos }) => {
      setIsSticky(Math.abs(currPos.y) > headerRef.current.offsetHeight);
    },
    [],
    null,
    null,
    200
  );

  useEffect(() => {
    (async () => {
      if (countItems !== previousCounterValue.current) {
        set({
          backgroundColor: 'rgb(145, 214, 255)',
          transform: 'translateY(-2px)',
        });
        await delay(180);
        setShowToast(true);
        set({ backgroundColor: 'white', transform: 'translateY(0px)' });
      }
    })();
  }, [countItems]);

  useEffect(() => {
    (async () => {
      if (showToast) {
        await delay(4000);
        setShowToast(false);
      }
    })();
  }, [showToast]);

  useEffect(() => {
    if ((asociatedLibraryImage !== '') & (asociatedLibraryImage !== undefined)) {
      setSecondaryImage(true);
    }
  }, [asociatedLibraryImage]);

  const closeSession = async () => {
    const { status } = await logoutApi({
      refresh_token: localStorage.getItem('refresh'),
    });
    if (status === 200) {
      unSetLocal('access');
      unSetLocal('refresh');
      unSetLocal('permissions');
      unSetLocal('type_user');
      setSecondaryImage(false);
      dispatch(setAsociatedLibraryImage(''));
      dispatch(setIsWorker(false));
      dispatch(setEmail(''));
      dispatch(setFirstName(''));
      dispatch(setLastName(''));
      dispatch(setBenefitedBookstore([]));
      dispatch(setBillingAddress({}));
      dispatch(setShippingAddress({}));
      dispatch(clear());
      dispatch(logoutSuccess());
    }
    history.push('/');
  };

  function handleShowSearchBar() {
    if (showSearchBar === false) {
      setShowSearchBar(true);
      setExpanded(false);
    } else {
      setShowSearchBar(false);
    }
  }

  const checkPermission = () => {
    if (localStorage.type_user == 'trabajador') {
      dispatch(setIsWorker(true));
    } else {
      dispatch(setIsWorker(false));
    }
  };

  useEffect(() => {
    checkPermission();
  }, [localStorage.type_user]);

  return (
    <header className={`header  container-full ${isSticky ? 'header--sticky' : ''}`}>
      {/* Menu NAVEGACION */}
      <div className="container-full ">
        <div className=" header__nav ">
          <Navbar className="bg-transparency header__main-nav p-0" expand="lg" expanded={expanded}>
            {/* BARRA SUPERIOR INFO DESKTOP*/}

            <div id="info-bar" className={`container-fluid ${isSticky ? 'd-none' : 'd-block'}`}>
              <div className="desktop-info container-fluid ">
                <Col md={6} className="d-none d-lg-block px-0">
                  <ul className="desktop-info__items">
                    <DropdownButton id="dropdown-list-button" as="li" title="Ayuda">
                      <Dropdown.Item as={Link} className="help-item" to={'/faqs'}>
                        Preguntas frecuentes
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} className="help-item" to={'/como-comprar'}>
                        Cómo comprar
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} className="help-item" to={'/ebook'}>
                        Cómo descargar un ebook
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} className="help-item" to={'/devoluciones-y-anulaciones'}>
                        Devoluciones
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} className="help-item" to={'/tarifas-y-plazos-de-entrega'}>
                        Tarifas y plazos de entrega
                      </Dropdown.Item>
                    </DropdownButton>
                    <li>
                      <Link to="/contact">Contacto</Link>
                    </li>
                    <li>
                      <Link to="/about-us">¿Quiénes somos?</Link>
                    </li>
                    <li>
                      <Link to="/gift-card">Comprar tarjeta de regalo</Link>
                    </li>
                  </ul>
                </Col>
                <Col md={6} className="lang-bar d-none d-lg-block px-0">
                  {isWorker && (
                    <div className="mr-4">
                      <Link to={'/administration'}>
                        <Button className="">Panel de Administración</Button>
                      </Link>
                    </div>
                  )}
                  <div className="select-language d-none d-lg-block">
                    <select className="lang-select-desktop">
                      <option value="es">Español</option>
                      <option value="ca">Català</option>
                      <option value="en">English</option>
                    </select>
                  </div>
                </Col>
              </div>
            </div>

            {/* BARRA MENU HAMBURGUESA MOVIL */}
            <div id="mobile-bar" className="container-fluid">
              {/* BOTON MOVIL HAMBURGUESA */}
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="mobile-menu"
                onMouseEnter={() => setExpanded('expanded')}
              />

              {/* BOTON LUPA HAMBURGUESA */}
              <span
                onClick={handleShowSearchBar}
                id="mobile-simple-search-btn"
                title="Mostrar/Ocultar barra búsqueda"
                className={`d-lg-none ${isSticky ? 'd-block' : 'd-none'}`}
              >
                <AiOutlineSearch className={`icon-input fas search `} />
              </span>

              {/* IMAGEN 8 Y MEDIO hasta 991*/}
              {!secondaryImage ? (
                <Link className="show-mobile d-lg-none" to="/">
                  <img className="logo--mobile" alt="Logotipo Empresa" src={logoLibe} />
                </Link>
              ) : (
                <div>
                  <Link className="show-mobile d-lg-none" to="/">
                    <img className="logo--mobile" alt="Logotipo Empresa" src={loggedLibe} />
                  </Link>
                  <Link className="show-mobile d-lg-none" to="/">
                    <img
                      className="logo--mobile position-relative"
                      style={{ right: '20px' }}
                      alt="Logotipo Empresa"
                      src={asociatedLibraryImage}
                    />
                  </Link>
                </div>
              )}

              <Link className="header__button header__button--cart mr-2 d-lg-none" to="/carrito">
                {/* ICONO ACTUAL BOLSA */}
                <RiShoppingBagFill className="icon-md" />
                {countItems > 0 && (
                  <animated.span style={counterAnimProps} className="cart-counter">
                    {countItems}
                  </animated.span>
                )}

                {/* pop up en hamburguesa! */}
                {showToast && (
                  <div
                    className="cart-toast"
                    style={{
                      backgroundColor: isIncrease ? 'green' : 'red',
                      top: isSticky ? '55px' : '130px',
                    }}
                  >
                    <span className="cart-toast__detail">
                      Has {isIncrease ? 'añadido ' : 'quitado '}
                      {isIncrease ? <strong>"{lastAddedItem}"</strong> : <strong>"{lastRemovedItem}"</strong>}{' '}
                      {isIncrease ? 'al' : 'del'} carrito
                    </span>
                  </div>
                )}
              </Link>
            </div>

            {/* MENU BARRA BUSQUEDA  */}

            <div
              ref={headerRef}
              id="search-bar"
              className={`container-full `}
              style={{ display: !isSticky || showSearchBar ? 'block' : 'none' }}
            >
              {/* MENU BUSQUEDA < 992 */}
              <div className="mobile-look container-full pb-1 mb-1 ">
                <Row className="header__container">
                  <Col className="header__search mobile">
                    <SearchBar />
                  </Col>
                </Row>
              </div>

              <div className={`desktop-look container-full pb-1 mb-1 ${isSticky ? 'd-none' : 'd-block'}`}>
                <Row className="header__container pt-3">
                  {/* MENU BUSQUEDA >= 992 */}

                  {/* LOGO IMAGEN */}
                  <Col md={3} className={'d-none  ' + (!secondaryImage ? 'd-lg-block' : 'd-lg-flex')}>
                    {!secondaryImage ? (
                      <Link to="/" className="show-desktop">
                        <img className="logo--desktop" alt="Logotipo Empresa" src={logoLibe} />
                      </Link>
                    ) : (
                      <>
                        <Link to="/" className="show-desktop">
                          <img
                            className="logo--desktop position-relative"
                            style={{ left: '10px' }}
                            alt="Logotipo Empresa"
                            src={loggedLibe}
                          />
                        </Link>
                        <Link className="show-desktop" to="/">
                          <img
                            className="logo--desktop position-relative"
                            style={{ left: '10px' }}
                            alt="Logotipo Empresa"
                            src={asociatedLibraryImage}
                          />
                        </Link>
                      </>
                    )}
                  </Col>

                  {/* BUSQUEDA >992 */}
                  <SearchBarDesktop />
                  {/* BOTON PRODUCTOS >992 */}
                  <ProductBarDesktop />
                </Row>
              </div>
            </div>

            {/* MENU NAVEGACION CATEGORIAS */}
            <Navbar.Collapse id="basic-navbar-nav" className="header-mobile-desktop-nav pb-0">
              <Nav className="mx-auto navbar-left" onMouseLeave={() => setExpanded(false)}>
                {/* IMAGEN 8 Y MEDIO >1200 + scroll*/}
                {!secondaryImage ? (
                  <Link to="/" className={`show-desktop-scroll ${isSticky ? 'd-block' : 'd-none'}`}>
                    <img className="d-none d-lg-block logo--scroll" alt="Logotipo Empresa" src={logoLibe} />
                  </Link>
                ) : (
                  <>
                    <Link to="/" className={`show-desktop-scroll ${isSticky ? 'd-block' : 'd-none'}`}>
                      <img className="d-none d-lg-block logo--scroll" alt="Logotipo Empresa" src={loggedLibe} />
                    </Link>
                    <Link to="/" className={`show-desktop-scroll ${isSticky ? 'd-block' : 'd-none'}`}>
                      <img
                        className="d-none d-lg-block logo--scroll position-relative"
                        style={{ right: '20px' }}
                        alt="Logotipo Empresa"
                        src={asociatedLibraryImage}
                      />
                    </Link>
                  </>
                )}

                {/* ICONO USUARIOS <992*/}
                <div className="hamb-menu d-lg-none">
                  <select className="lang-select-mobile">
                    <option value="es">Español</option>
                    <option value="ca">Català</option>
                    <option value="en">English</option>
                  </select>

                  {/* CONEXION DESDE MENU HAMBURGUESA*/}
                  {isLogged ? (
                    <>
                      <Link className="hamb-menu-user" to={'/perfil'}>
                        <FaUserEdit className="icon-sm fas ham-h i-user" title="Mi Perfil" />
                        <span className="simple-hide-mobile">Mi Perfil</span>
                      </Link>
                      <Link className="hamb-menu-user" to={'/mis-libros'}>
                        <FaBookReader className="icon-sm fas ham-h i-user" title="Mis Libros" />
                        <span className="simple-hide-mobile">Mis libros</span>
                      </Link>
                      <Link className="hamb-menu-user" to={'/favoritos'}>
                        <FaHeart className="icon-sm fas ham-h i-user" title="Mis Favoritos" />
                        <span className="simple-hide-mobile">Mis Favoritos</span>
                      </Link>
                      <Link className="hamb-menu-user" onClick={() => closeSession()} to="/#">
                        <FaSignOutAlt className="icon-sm fas ham-h i-user" title="Cerrar sesión" />
                        <span className="simple-hide-mobile">Cerrar sesión</span>
                      </Link>
                    </>
                  ) : (
                    <>
                      <a
                        className="hamb-menu-user"
                        onClick={() => {
                          handleShowModal();
                          setRegLogModalControl(true);
                        }}
                      >
                        <FaUserAlt className="icon-sm fas ham-h sign-in-alt" title="Registro" />
                        <span className="simple-hide-mobile">Registro</span>
                      </a>
                      <a
                        className="hamb-menu-user"
                        onClick={() => {
                          handleShowModal();
                          setRegLogModalControl(false);
                        }}
                      >
                        <FaSignInAlt className="icon-sm fas ham-h sign-in-alt" title="Iniciar sesión" />
                        <span className="simple-hide-mobile">Iniciar sesión</span>
                      </a>
                    </>
                  )}
                </div>
                <CustomDropdown />
                <Nav.Link as={Link} to="/librerias">
                  LIBRERÍAS
                </Nav.Link>
                <Nav.Link as={Link} to="/for-children">
                  PEQUEÑO LIBELISTA
                </Nav.Link>
                <NavDropdown title="MUNDO LIBELISTA">
                  <NavDropdown.Item as={Link} to={{ pathname: `/blog/librerias-protagonistas/` }}>
                    LIBRERÍAS PROTAGONISTAS
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={{ pathname: `/blog/libelista-propone/` }}>
                    LIBELISTA PROPONE
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={{ pathname: `/blog/entrevistas-libelista/` }}>
                    ENTREVISTAS
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={{ pathname: '/pequeno-libelista' }}>
                    PEQUEÑAS RESEÑAS
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={{ pathname: `/blog/libros-del-mes/` }}>
                    LIBROS DEL MES
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/agenda">
                  ACTIVIDADES
                </Nav.Link>
                <Button className="library-search d-lg-none" variant="info">
                  <MdLocationOn className="icon-md" />
                  BUSCADOR DE LIBRERÍAS
                </Button>
              </Nav>

              <div id="menu-right">
                <Nav className={`navbar-right ${isSticky ? 'scroll' : ''}`}>
                  {/* BOTON LUPA en scroll */}
                  <span id="desktop-simple-search-btn" className={` ${isSticky ? 'd-block' : 'd-none'}`}>
                    <AiOutlineSearch
                      className={`icon-input fas search-scroll `}
                      title="Búsqueda"
                      onClick={() => setShowMiniSearchBar(true)}
                    />
                    <RiCloseLine
                      className="miniSearch-closing"
                      onClick={() => setShowMiniSearchBar(false)}
                      style={{ display: showMiniSearchBar ? 'block' : 'none' }}
                    />
                    <div
                      type="text"
                      placeholder=""
                      title="Barra Búsqueda"
                      className={`showMiniBarSearch ${showMiniSearchBar ? 'animate' : ''}`}
                      style={{ display: showMiniSearchBar ? 'block' : 'none' }}
                    >
                      {/* BARRA BUSQUEDA TAMAÑO DESKTOP + SCROLL */}
                      <SearchBar stickyBar={isSticky} />
                    </div>
                  </span>

                  {/* CONEXION DESDE MENU DESKTOP*/}
                  {isLogged ? (
                    <div className="header__button--logged">
                      <DropMenuLogged
                        className="header__button "
                        dropDirection="down"
                        closeSession={closeSession}
                        sticky={isSticky}
                      />
                    </div>
                  ) : (
                    <>
                      <Link className="navbar-right__link position-relative" style={{ top: '-5px' }} to={'/register'}>
                        <FaUserAlt className="fas i-user" title="Registro" />
                        <span className={`simple-hide-desktop ${isSticky ? 'd-none' : 'd-block'}`}>Registro</span>
                      </Link>
                      <Link
                        className="navbar-right__link position-relative"
                        style={{ top: '-5px' }}
                        to={'/login'}
                        // onClick={() => {
                        //   handleShowModal();
                        //   setRegLogModalControl(false);
                        // }}
                      >
                        <FaSignInAlt className="fas sign-in-alt" title="Iniciar sesión" />
                        <span className={`simple-hide-desktop ${isSticky ? 'd-none' : 'd-block'}`}>Iniciar sesión</span>
                      </Link>
                    </>
                  )}
                </Nav>

                {/* ICONO CARRO CUANDO SCROLL DESKTOP */}
                <Link
                  to="/carrito"
                  aria-label={`Carrito de compras (${countItems} items)`}
                  className={`header__button header__button--cart ml-4
                  ${countItems > 0 ? ' filled' : ''}
                  `}
                  style={{ display: isSticky ? 'flex' : 'none' }}
                >
                  {/* ICONO ANTERIOR CARRO */}
                  {/* <AiOutlineShoppingCart className="icon-md" /> */}

                  {/* ICONO ACTUAL BOLSA */}
                  <RiShoppingBagFill className="icon-md" />
                  {countItems > 0 && (
                    <animated.span style={counterAnimProps} className="cart-counter">
                      {countItems}
                    </animated.span>
                  )}
                </Link>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
      {isSignUpModalOpen ? (
        <LoginModal show={isSignUpModalOpen} handleClose={handleCloseModal} controlModalType={regLogModalControl} />
      ) : null}
    </header>
  );
};

export default MenuMain;
