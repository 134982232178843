import { Formik, Form, Field } from 'formik';
import React, { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { InputCustom } from '../input';

// own components

const SearchBar = ({ children }) => {
  const history = useHistory();
  const initialValues = { value: '', searchType: 'titulo' };
  const onSubmit = async (values) => {
    const { searchType, value, ...otherUrlParams } = values;
    const URLqueryParametersManager = new URLSearchParams({ [searchType]: value, ...otherUrlParams });
    const advancedSearchQueryParameters = URLqueryParametersManager.toString();
    history.push(`/lista/search?${advancedSearchQueryParameters}`);
  };
  const formikProps = { initialValues, onSubmit };
  return (
    <Formik {...formikProps}>
      <Form role="search" className="d-flex w-100 ">
        <Field
          as={InputCustom}
          name="value"
          type="text"
          icon={1}
          placeholder="Buscar libros, cine, música y mucho más..."
          required
        />
        <span className="d-lg-none">
          <AiOutlineSearch className="icon-input" />
        </span>
        {children}
      </Form>
    </Formik>
  );
};

export default SearchBar;
