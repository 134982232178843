import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { formatDate } from '../../../utils/formatDate';
import { IoIosImage } from 'react-icons/io';
import './styleBackofficeTable.scss';

const BackofficeTable = ({
  title,
  headers,
  data = [{}],
  ActionsMenu,
  extraFormat,
  filterParameterName,
  filterRelatedFields,
  customParams,
  exportButton
}) => {
  const HEADERS = Object.values(headers);
  const createButtonDisabled = extraFormat?.createButton?.disabled;
  const printExportButton = extraFormat?.printExportButton
  const history = useHistory();
  const location = useLocation();

  const deleteQueryParams = () => {
    history.push(location.pathname);
  };
  const url = window.location.href;
  const includesQueryParams = url.includes('?');

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="text-secondary my-3">{title}</h3>
        <div className="my-3">
          {printExportButton ? 
            (<div>
              {exportButton()}
            </div>)
           : (
            <Link
              style={createButtonDisabled ? { textDecoration: 'line-through', display: 'none' } : {}}
              role="button"
              className={`btn btn-info ${createButtonDisabled ? 'disabled' : ''}`}
              to={(location) => `${location.pathname}/create`}
              target="_blank"
            >
              Crear
            </Link>
          )}
        </div>
      </div>
      {filterParameterName && filterRelatedFields && (
        <form
          className="mb-3"
          onSubmit={(event) => {
            event.preventDefault();
            const form = event.target;
            const searchInput = form.search;
            const searchValue = searchInput.value;
            var searchParams = new URLSearchParams(location.search);
            searchParams.set(filterParameterName, searchValue);
            searchParams.set('page', 1);
            history.push(searchValue ? `${location.pathname}?${searchParams.toString()}` : location.pathname);
          }}
        >
          <div className="form-row">
            <div className="col">
              <input
                type="text text-capitalize"
                id="search"
                className="form-control w-50"
                placeholder={`Buscar por ${filterRelatedFields?.reduce((fieldNames, field, iteration) => {
                  if (filterRelatedFields.length - 1 === iteration) {
                    return `${fieldNames}${headers[field] || field}.`;
                  } else if (filterRelatedFields.length === 1) {
                    return `${headers[field] || field}.`;
                  } else {
                    return `${fieldNames}${headers[field] || field}, `;
                  }
                }, '')}`}
              />
            </div>
            <input type="submit" className="btn btn-info positionSearch" value="Buscar" />
          </div>
          {includesQueryParams && (
            <button className="btn  buttonDeleteQuery" onClick={() => deleteQueryParams()}>
              Volver
            </button>
          )}
        </form>
      )}
      {customParams}
      <div className="table-responsive">
        <table className="border table table-sm table-striped w-100">
          <thead className="w-100">
            <tr className="w-100">
              {HEADERS.map((header, index) => (
                <th className="text-truncate text-center" scope="col" key={`header#${index})`}>
                  {header}
                </th>
              ))}
              {extraFormat?.noActionsColumn ? null :
                (<th className="text-truncate text-center" scope="col">Acciones</th>)
              }
            </tr>
          </thead>
          <tbody>
            {data.length ? (
              data.map((datum, index) => (
                <tr className="w-100 text-center" key={`couponRow#${index}`}>
                  {Object.keys(headers).map((key) => {
                    let tdInformation = datum[key];
                    const deeplyNestedKey = /\./.test(key);
                    if (deeplyNestedKey) {
                      const nestedKeys = key.split('.');
                      tdInformation = nestedKeys.reduce((reducedDatum, currentKey) => {
                        return reducedDatum?.[currentKey];
                      }, datum);
                    }
                    if (extraFormat?.[key]) {
                      switch (extraFormat[key]) {
                        case 'date':
                          tdInformation = formatDate(tdInformation);
                          break;
                        case 'image':
                          tdInformation = /portada_vacia/.test(datum[key]) ? (
                            <IoIosImage size="75px" />
                          ) : (
                            <img
                              height="100px"
                              width="75px"
                              className="img-thumbnail"
                              src={datum[key]}
                              alt="A Product"
                            />
                          );
                          break;
                        case 'status':
                          tdInformation = datum[key] ? 'Activo' : 'Inactivo';
                          break;
                        case 'bookFormat':
                          tdInformation = datum[key] ? 'Ebook' : 'Libro físico';
                          break;
                        case 'published':
                          tdInformation = datum[key] ? 'Publicado' : 'Pendiente';
                          break;
                        case 'yesOrNo':
                          tdInformation = datum[key] ? 'Si' : 'No';
                          break;
                        case 'staff':
                          tdInformation = datum[key] ? 'Staff' : 'Cliente';
                          break;
                        case 'int':
                          tdInformation = parseInt(datum[key]);
                          break;
                        case 'string':
                          tdInformation = String(datum[key]);
                          break;
                        case 'elipsis':
                          tdInformation =
                            datum[key].charAt(0).toUpperCase() + datum[key].slice(1, datum[key].length).toLowerCase();
                          break;
                        case 'notification':
                          tdInformation = datum[key] ? 'Notificado' : 'Pendiente';
                          break;
                        case 'typeDiscount':
                          tdInformation = !datum[key] ? 'Porcentaje' : 'Monto fijo';
                          break;
                        case 'isAssigned':
                            tdInformation = !datum[key] ? 'Sin asignar' : datum[key];
                            break;
                        default:
                      }
                    }
                    if (!tdInformation && tdInformation !== false) {
                      tdInformation = '...';
                    } else if (tdInformation === true) {
                      tdInformation = 'Si';
                    } else if (tdInformation === false) {
                      tdInformation = 'No';
                    }
                    return (
                      <td
                        className={`text-truncate align-middle ${tdInformation === "Sin asignar" ? "colorGray" : null}`}
                        key={key}
                        {...(extraFormat?.[key] === 'elipsis'
                          ? {
                              'data-toggle': 'tooltip',
                              'data-placement': 'top',
                              title: datum?.[key] || 'Just a tooltip',
                            }
                          : {})}
                      >
                        {tdInformation}
                      </td>
                    );
                  })}
                  {extraFormat?.noActionsColumn ? null :
                    (
                      <td className="text-center">
                        <ActionsMenu payload={datum} />
                      </td>
                    )
                  }
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center">No hay {title}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BackofficeTable;
