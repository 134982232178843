import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiOutlineInfoCircle, AiFillStar } from 'react-icons/ai';
import { FaTrophy } from 'react-icons/fa';

import { checkEmpty, truncate } from '../../helpers';
import CoverImg from '../coverImage/CoverImage';
import { userSelector } from '../../slices/user';
import { addItemCart } from '../../api/ecommerce';
import { addItem } from '../../slices/cart';
//preview para libros sin portada
import preview from '../../assets/img/preview.jpg';

const Item = (props) => {
  const { data } = props;
  const urlItem = checkEmpty(data) ? `/productos/${data.type}/${data.url}/` : '';
  const dispatch = useDispatch();
  const { isLogged } = useSelector(userSelector);

  const properties = {
    processAuthors() {
      const authors = data.autor
        .map((val, i) => {
          return `${val.nombre_autor}`;
        })
        .join('. ');
      return authors;
    },
  };

  const goUp = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="store-item">
      {props.isAward && (
        <p className="prizeName" title={props.prize.prize}>
          {props.prize.prize}
        </p>
      )}
      <div className="store-cover">
        {props.isAward && (
          <div className="trophyDiv">
            <FaTrophy />
          </div>
        )}
        {data.recommended && (
          <div className="recommendedDiv">
            <AiFillStar />
          </div>
        )}
        <div
          className={'img-container' + (props.isReview ? ' img-review' : '')}
          style={{ cursor: props.isReview ? 'default' : 'pointer' }}
        >
          <div className="overlay"></div>
          <CoverImg src={data.get_url || data.cover || preview} style={{ cursor: 'default' }} />

          <span>
            {!props.isReview && (
              <Link to={urlItem} onClick={goUp}>
                <button
                  aria-label="ver producto"
                  title="ver producto"
                  className="button button--icon button--outline-hover"
                >
                  <AiOutlineInfoCircle />
                </button>
              </Link>
            )}
          </span>
        </div>
        {!props.isAward && !props.isReview && (
          <div className="markers">
            {data.is_novelty && <p className="markers-novelty">NOVEDAD</p>}
            {data.is_ebook && <p className="markers-ebook">EBOOK</p>}
          </div>
        )}
      </div>
      <Row className="img-caption" style={{ marginBottom: props.isAward ? '0px' : '' }}>
        <Col xs={12}>
          {!props.isReview ? (
            <Link className="store-item__link" to={urlItem}>
              <h5 className="title limit-long" title={data.titulo}>
                {data.titulo.charAt(0).toUpperCase() + data.titulo.slice(1).toLowerCase()}
              </h5>
            </Link>
          ) : (
            <h5 className="title limit-long" title={data.titulo}>
              {data.titulo.charAt(0).toUpperCase() + data.titulo.slice(1).toLowerCase()}
            </h5>
          )}
        </Col>
        <Col xs={12} className="d-flex justify-content-center">
          {data.autor && (
            <h6
              className="subtitle limit-long pb-2 mb-1"
              title={properties.processAuthors()}
              style={{ maxWidth: '175px' }}
            >
              {truncate(properties.processAuthors(), 24)}
            </h6>
          )}
        </Col>
      </Row>
      {props.isAward ? (
        <p className="mb-3" style={{ color: '#000' }}>
          {data.editorial}
        </p>
      ) : props.isReview ? (
        <Link
          className="blue text-center"
          to={`/pequenas-resenas/?small_reviews_id=${props.reviewId}`}
          style={{ fontFamily: 'Lato', fontSize: '15px', fontWeight: '700' }}
        >
          Ver más
        </Link>
      ) : (
        <p>{data.precio_venta_publico} €</p>
      )}

      {!props.isReview && (
        <Link to={urlItem} onClick={goUp}>
          <Button
            variant="outline-dark"
            size="sm"
            title="ver producto"
            block
            className={props.childList ? 'goldenBtn' : ''}
          >
            Ver {props.isAward ? 'ficha' : 'detalle'}
          </Button>
        </Link>
      )}
    </div>
  );
};

export default Item;
