import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import {
  getAllBooklists,
  getLibelistaListDetail as get,
  editLibelistaList as edit,
  postLibelistaList as create,
  getBookList,
} from '../../../../api/libelista';
import ActionsForm from '../../../../components/backoffice/actionsForm/index';
import { getSelectDataHandlers } from '../../../../utils/backoffice/getSelectDataHandlers';
import { getBooksByTitle, editBookDetail, getBookDetail } from '../../../../api/libros';
import SectionLoader from '../../../../components/loader/SectionLoader';
import * as Yup from 'yup';
const REMOVE_BOOK = true;
let statusOptions = [
  { label: 'Activa', value: true },
  { label: 'Inactiva', value: false },
];
let strightAnswerOptions = [
  { label: 'Si', value: true },
  { label: 'No', value: false },
];

const updateBookData = async (bookId, removeBook, listId) => {
  const getBookResponse = await getBookDetail(bookId);
  if (getBookResponse.status == 200 && bookId) {
    const { libelista_list: previousBookList } = getBookResponse.data;
    const newLibelista_list = removeBook
      ? previousBookList.filter((id) => id != listId)
      : [...previousBookList, listId];
    const bookData = { id: bookId, libelista_list: newLibelista_list };
    const editBookResponse = await editBookDetail(bookData);
    editBookResponse.status != 200 && console.log(editBookResponse);
  }
};
const generateOptions = async (request, requestValue) => {
  try {
    const response = await request(requestValue);
    if (response?.status == '200') {
      const data = response?.data;
      const books = data.results;
      const bookOptions = data.results.length
        ? books.map(({ titulo, id }) => ({ label: titulo + ` (${id})`, value: id }))
        : [{ label: 'Sin asignar', value: '' }];
      return bookOptions;
    }
  } catch (exception) {
    console.log(exception);
  }
};
const getBooksInListDefaultOptions = async (booklistId, stateSetterCallback) => {
  const defaultOptions = await generateOptions(getBookList, booklistId);
  stateSetterCallback(defaultOptions);
};
const getBookOptions = async (title) => {
  const options = await generateOptions(getBooksByTitle, title);
  return options;
};
const ListItem = ({ data }) => {
  delete data?.materia_ibic;
  delete data?.materia_ibic_display;
  delete data?.parent_display;
  const { action } = useParams();
  const [
    { defaultOptions: defaultBooklistOptions, defaultOption: defaultBooklistOption },
    setBooklistDefaultOptionValues,
  ] = useState({});
  const { getSelectData: getBooklistOptions, setSelectDefaultData: setBooklistOptions } = getSelectDataHandlers(
    get,
    getAllBooklists,
    setBooklistDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'title',
      fieldNameWithRequestValue: 'id',
    }
  );
  const mustWaitForData = ['detail', 'edit'].includes(action);
  const renderConditions = [!mustWaitForData || data, defaultBooklistOptions?.length || defaultBooklistOption];
  useEffect(() => {
    if (!mustWaitForData || data) {
      setBooklistOptions();
    }
  }, [data]);
  let isDisabled = action === 'detail';
  const noOption = defaultBooklistOptions?.[0];
  const [booksInListOptions, setBooksInListOptions] = useState(undefined);
  mustWaitForData && renderConditions.push(booksInListOptions);
  useEffect(() => {
    if (data?.id) {
      getBooksInListDefaultOptions(data?.id, setBooksInListOptions);
    }
  }, [data]);
  if (data && booksInListOptions) {
    const bookIds = booksInListOptions.map(({ value }) => parseInt(value) || 0);
    data.bookIds = bookIds;
  }
  /**
   * defaultValue for multi select is better constructed this way so the select component
   * can handle the hiding or displaying of the selected option by itself
   * Note: In case there's no list of options the state's default option will be used
   * Note2: == is intented for pre-comparison convertion
   */
  const sublistDefaultValues = data?.children?.length
    ? data?.children?.map(({ id }) => defaultBooklistOptions?.find(({ value }) => value == id))
    : noOption;
  let suplistDefaultValue = data?.parent
    ? defaultBooklistOptions?.filter(({ value }) => value == data?.parent)?.[0]
    : noOption;
  const inputFields = [
    { label: 'Título', name: 'title', type: 'text' },
    { label: 'U.R.L.', name: 'url', type: 'text' },
    {
      label: 'Estado',
      name: 'active',
      type: 'select',
      defaultValue: statusOptions[data?.active ? 0 : 1],
      options: statusOptions,
      isDisabled,
    },
    {
      label: '¿Es sublista?',
      name: 'active',
      type: 'select',
      defaultValue: strightAnswerOptions[data?.is_child ? 0 : 1],
      options: strightAnswerOptions,
      isDisabled: true,
    },
    {
      label: '¿En pagina principal?',
      name: 'home_list',
      type: 'select',
      defaultValue: strightAnswerOptions[data?.home_list ? 0 : 1],
      options: strightAnswerOptions,
      isDisabled,
    },
    {
      label: 'Pertenece a la lista',
      name: 'parent',
      type: 'select',
      options: defaultBooklistOptions,
      defaultValue: suplistDefaultValue,
      isDisabled: isDisabled || data?.children?.length,
    },
    {
      label: 'Sublistas',
      name: 'children',
      type: 'select',
      defaultValue: sublistDefaultValues,
      options: defaultBooklistOptions,
      isMulti: true,
      isDisabled: true,
    },
    {
      label: 'Libros',
      name: 'bookIds',
      type: 'select',
      defaultValue: booksInListOptions,
      defaultOptions: true,
      closeMenuOnSelect: false,
      loadOptions: getBookOptions,
      isAsync: true,
      isMulti: true,
      isDisabled,
    },
  ];
  const postRequestSubmitLogic = async (requestData, formData) => {
    const { bookIds = [] } = formData;
    const { id: listId } = requestData;
    try {
      const previousBookList = data?.bookIds;
      const booksToAdd = action === 'create' ? bookIds : bookIds.filter((id) => !previousBookList.includes(id));
      booksToAdd.forEach((bookId) => updateBookData(bookId, !REMOVE_BOOK, listId));
      if (action === 'edit') {
        const booksToRemove = previousBookList.filter((id) => !bookIds.includes(id));
        booksToRemove.forEach((bookId) => updateBookData(bookId, REMOVE_BOOK, listId));
      }
    } catch (exception) {
      console.log(exception);
    }
  };
  const schema = { title: Yup.string().required('* '), bookIds: Yup.array().required('* ') };
  const actionsFormProps = {
    inputFields,
    title: 'Lista',
    subsectionPath: 'lists',
    data,
    requests: { edit, create },
    postRequestSubmitLogic,
    formDataConfiguration: { children: true },
    schema,
    action,
  };
  return renderConditions.every((condition) => condition) ? <ActionsForm {...actionsFormProps} /> : <SectionLoader />;
};
ListItem.request = {
  get,
};
const ListItemPage = () => <BackofficePagesManager PageViewer={ListItem} />;
export default ListItemPage;
