import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { CgArrowUpR, CgArrowDownR } from 'react-icons/cg';
import { GoPencil } from 'react-icons/go';
import { ImBook } from 'react-icons/im';
import { FcCancel } from 'react-icons/fc';
import { FaCalendarAlt } from 'react-icons/fa';
import { BsTagFill, BsBraces } from 'react-icons/bs';
import { FiPlusCircle } from 'react-icons/fi';

import { InputCustom, SelectInputCustom } from '../../../components/input/index';
import CustomSelect from '../../../components/customSelect/index';
import { getBookDetail } from '../../../api/libros';
import { getAuthors } from '../../../api/products';
import { getCountries, getLanguages } from '../../../api/company';

import './productsEdition.scss';

const ProductsEdition = () => {
  const [bookDetail, setBookDetail] = useState([]);
  const [fillData, setFillData] = useState('principal');
  const [authors, setAuthors] = useState([]);
  const [query, setQuery] = useState('');
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);

  const { id } = useParams();

  const childChoice = [
    { id: '', value: '--------' },
    { id: 1, value: 'Si' },
    { id: 2, value: 'No' },
  ];

  const getDetail = async () => {
    const { status, data } = await getBookDetail(id);
    if (status === 200) {
      setBookDetail(data);
    }
  };

  const getBookAuthors = async (req) => {
    const { status, data } = await getAuthors(req);
    if (status === 200) {
      setAuthors(data.results);
    }
  };

  const getCountryItems = async () => {
    const { status, data } = await getCountries();
    if (status === 200) {
      setCountries(data);
    }
  };

  const getAvailableLanguages = async () => {
    const { status, data } = await getLanguages();
    if (status === 200) {
      setLanguages(data);
    }
  };

  const handleQuery = (param) => {
    setQuery(param);
  };

  useEffect(() => {
    if (query.length > 2) {
      getBookAuthors(query);
    }
  }, [query]);

  useEffect(() => {
    getDetail();
    getBookAuthors(query);
    getCountryItems();
    getAvailableLanguages();
  }, []);

  const updateValue = (field, value) => {
    switch (field) {
      case 'pais_public':
        setBookDetail({ ...bookDetail, pais_public: value });
        break;
      case 'idioma':
        setBookDetail({ ...bookDetail, idioma: Array(value) });
        break;
      case 'idioma_orig':
        setBookDetail({ ...bookDetail, idioma_orig: value });
        break;
      case 'autor':
        setBookDetail({ ...bookDetail, autor: value });
        break;
    }
  };

  return (
    <>
      <Row className="listDetailRow mb-3">
        <Col lg={12} className="titlePanel">
          <h3 className="my-2">Editar Producto (Libro físico) - {bookDetail.titulo}</h3>
        </Col>
        <Col className="mt-3 justify-content-end" lg={12}>
          <Row className="pr-2">
            <Col lg={12} className="d-flex justify-content-start px-2">
              Los campos marcados con un * son obligatorios
            </Col>
          </Row>
        </Col>
        <Col lg={12} className="">
          <Row className="mt-3 pl-1 pr-2 titleRow">
            <Col
              className={'pt-2 border d-flex ' + (fillData == 'principal' ? 'active' : '')}
              onClick={() => setFillData('principal')}
            >
              <GoPencil className="mr-2" /> Datos principales
            </Col>
            <Col
              className={'pt-2 border d-flex ' + (fillData == 'publication' ? 'active' : '')}
              onClick={() => setFillData('publication')}
            >
              <FaCalendarAlt className="mr-2" /> Publicación
            </Col>
            <Col
              className={'pt-2 border d-flex ' + (fillData == 'genres' ? 'active' : '')}
              onClick={() => setFillData('genres')}
            >
              <BsTagFill className="mr-2" /> Géneros
            </Col>
            <Col
              className={'pt-2 border d-flex ' + (fillData == 'extra' ? 'active' : '')}
              onClick={() => setFillData('extra')}
            >
              <BsBraces className="mr-2" /> Extras
            </Col>
            <Col className="pl-2 d-flex align-items-end flex-grow-1"></Col>
          </Row>
          {fillData == 'principal' ? (
            <>
              <Row className="pl-1 pr-2 mt-3">
                <Col lg={6} className="pl-2">
                  <p>Título*</p>
                  <InputCustom value={bookDetail.titulo} />
                </Col>
              </Row>
              <Row className="pl-1 pr-2">
                <Col lg={6} className="pl-2">
                  <p>Nombre único (slug)*</p>
                  <InputCustom value={bookDetail.url} />
                </Col>
              </Row>
              <Row className="pl-1 pr-2">
                <Col lg={6} className="pl-2">
                  <p>Texto resumen</p>
                  <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Introducir texto"
                        value={bookDetail.texto_resumen}
                      />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row className="pl-1 pr-2">
                <Col lg={6} className="pl-2">
                  <p>Ean*</p>
                  <InputCustom value={bookDetail.ean} />
                </Col>
              </Row>
              <Row className="pl-1 pr-2">
                <Col lg={6} className="pl-2">
                  <p>Autor*</p>
                  {bookDetail.autor && <InputCustom value={bookDetail.autor[0].nombre_autor} />}
                </Col>
                <Col lg={6} className="pl-2">
                  <p>Seleccionar Autor</p>
                  {bookDetail.autor && (
                    <div className="pl-2">
                      <CustomSelect
                        authors={authors}
                        setQueryCallback={handleQuery}
                        updateValue={updateValue}
                        selectedItem={bookDetail.autor[0].nombre_autor}
                        handledField={'autor'}
                        width={265}
                        filter={false}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="pl-1 pr-2">
                <Col lg={6} className="pl-2">
                  <p>Imagen (url)</p>
                  <Form.Group className="mb-3">
                    <Form.Control className="px-2" type="text" placeholder="url imagen" value={bookDetail.get_url} />
                    <Form.Text className="text-muted px-1">Incluir "http://" o "https://" en la dirección.</Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="pl-1 pr-2 mb-2">
                <Col lg={6} className="pl-2">
                  <p className="mb-1">Activo {bookDetail.active + ' '}</p>
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      // onChange={()=>setTrueVal(!trueVal)}
                      checked={bookDetail.active}
                    />
                  </Form>
                </Col>
              </Row>
            </>
          ) : fillData == 'publication' ? (
            <>
              <Row className="pl-1 pr-2 mt-3">
                <Col lg={6} className="pl-2">
                  <p>Pais de publicación</p>
                  {bookDetail.pais_public && <InputCustom value={bookDetail.pais_public.nombre} />}
                </Col>
                <Col lg={6} className="pl-2">
                  <p>Seleccionar país de publicación</p>
                  {bookDetail.pais_public && (
                    <div className="pl-2">
                      <CustomSelect
                        countries={countries}
                        setQueryCallback={handleQuery}
                        updateValue={updateValue}
                        selectedItem={bookDetail.pais_public.nombre}
                        handledField={'pais_public'}
                        width={265}
                        filter={true}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="pl-1 pr-2 mt-3">
                <Col lg={6} className="pl-2">
                  <p>Idioma original</p>
                  {bookDetail.pais_public && <InputCustom value={bookDetail.idioma_orig.nombre} />}
                </Col>
                <Col lg={6} className="pl-2">
                  <p>Seleccionar idioma original</p>
                  {bookDetail.idioma_orig && (
                    <div className="pl-2">
                      <CustomSelect
                        languages={languages}
                        setQueryCallback={handleQuery}
                        updateValue={updateValue}
                        selectedItem={bookDetail.idioma_orig.nombre}
                        handledField={'idioma_orig'}
                        width={265}
                        filter={true}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="pl-1 pr-2 mt-3">
                <Col lg={6} className="pl-2">
                  <p>Idioma</p>
                  {bookDetail.idioma && <InputCustom value={bookDetail.idioma[0].nombre} />}
                </Col>
                <Col lg={6} className="pl-2">
                  <p>Seleccionar idioma</p>
                  {bookDetail.idioma && (
                    <div className="pl-2">
                      <CustomSelect
                        languages={languages}
                        setQueryCallback={handleQuery}
                        updateValue={updateValue}
                        selectedItem={bookDetail.idioma[0].nombre}
                        handledField={'idioma'}
                        width={265}
                        filter={true}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="pl-1 pr-2">
                <Col lg={6} className="pl-2">
                  <p>Fecha de publicación</p>
                  <InputCustom
                    type="date"
                    value={bookDetail.fecha_public}
                    onChange={(event) => {
                      setBookDetail({ ...bookDetail, fecha_public: event.target.value });
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : fillData == 'genres' ? (
            <>
              <Row className="justify-content-center">
                <Col lg={7} className="mt-5 ml-4 text-center">
                  <h2>GENRES ROW</h2>
                  <FcCancel className="mt-2" style={{ fontSize: '53px' }} />
                </Col>
              </Row>
              <Row className="pl-1 pr-2 mt-3">
                <Col lg={6} className="pl-2">
                  <p>Categoría padre</p>
                  <SelectInputCustom choices={childChoice} />
                </Col>
              </Row>
              <Row className="pl-1 pr-2">
                <Col lg={6} className="pl-2">
                  <p>Género IBIC</p>
                  <InputCustom />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="justify-content-center">
                <Col lg={7} className="mt-5 ml-4 text-center">
                  <h2>EXTRAS ROW</h2>
                  <FcCancel className="mt-2" style={{ fontSize: '53px' }} />
                </Col>
              </Row>
              <Row className="pl-1 pr-2 mt-3">
                <Col lg={6} className="pl-2">
                  <p>Categoría padre</p>
                  <SelectInputCustom choices={childChoice} />
                </Col>
              </Row>
              <Row className="pl-1 pr-2">
                <Col lg={6} className="pl-2">
                  <p>Género IBIC</p>
                  <InputCustom />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProductsEdition;
