import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDetailEditorial as get, createEditorial as create, editEditorial as edit } from '../../../../api/products';
import { getLocalities, getLocality, getCountries, getCountry } from '../../../../api/company';
import ActionsForm from '../../../../components/backoffice/actionsForm/index';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import { getSelectDataHandlers } from '../../../../utils/backoffice/getSelectDataHandlers';
import SectionLoader from 'components/loader/SectionLoader';
const EditorialsItem = ({ data = { url: 'Placeholder' } }) => {
  const { action } = useParams();
  const [
    { defaultOptions: defaultCountryOptions, defaultOption: defaultCountryOption },
    setCountryDefaultOptionValues,
  ] = useState({});
  const [
    { defaultOptions: defaultLocalityOptions, defaultOption: defaultLocalityOption },
    setLocalityDefaultOptionValues,
  ] = useState({});
  const { setSelectDefaultData: setCountryOptions } = getSelectDataHandlers(
    getCountry,
    getCountries,
    setCountryDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'nombre',
      fieldNameWithRequestValue: 'id',
    }
  );
  const { getSelectData: getLocalityOptions, setSelectDefaultData: setLocalityOptions } = getSelectDataHandlers(
    getLocality,
    getLocalities,
    setLocalityDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'nombre',
      fieldNameWithRequestValue: 'id',
    }
  );
  const mustWaitForData = ['edit', 'detail'].includes(action);
  const dataRetrieved = data.nombre_fiscal;
  const renderConditions = [
    !mustWaitForData || dataRetrieved,
    defaultCountryOptions?.length,
    defaultLocalityOptions?.length || defaultLocalityOption,
  ];
  useEffect(() => {
    if (!mustWaitForData || dataRetrieved) {
      !defaultCountryOptions && setCountryOptions(data?.pais);
      !defaultLocalityOptions && setLocalityOptions(data?.localidad);
    }
  }, [data]);
  const inputFields = [
    {
      label: 'Nombre Fiscal',
      name: 'nombre_fiscal',
      type: 'text',
      required: true,
    },
    {
      label: 'Nombre Comercial',
      name: 'nombre_comercial',
      type: 'text',
    },
    {
      label: 'C.I.F.',
      name: 'cif',
      type: 'text',
    },
    {
      label: 'Dirección',
      name: 'direccion',
      type: 'text',
    },
    {
      label: 'Teléfono',
      name: 'telefono',
      type: 'tel',
    },
    {
      label: 'Correo Electrónico',
      name: 'email',
      type: 'email',
      required: false,
    },
    {
      label: 'U.R.L',
      name: 'url',
      type: 'text',
      disabled: true,
      hidden: action !== create,
    },
    {
      label: 'Localidad',
      name: 'localidad',
      type: 'select',
      isAsync: true,
      loadOptions: getLocalityOptions,
      defaultOptions: defaultLocalityOptions,
      defaultValue: defaultLocalityOption,
      isDisabled: action === 'detail',
    },
    {
      label: 'Pais',
      name: 'pais',
      type: 'select',
      options: defaultCountryOptions,
      defaultValue: defaultCountryOption,
      isDisabled: action === 'detail',
    },
  ];
  const actionsFormProps = {
    inputFields,
    title: 'Editorial',
    subsectionPath: 'editorials',
    data,
    requests: { edit, create },
    action,
  };
  return renderConditions.every((condition) => condition) ? <ActionsForm {...actionsFormProps} /> : <SectionLoader />;
};
EditorialsItem.request = {
  get,
};
const EditorialsItemPage = () => <BackofficePagesManager PageViewer={EditorialsItem} />;
export default EditorialsItemPage;
