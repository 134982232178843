import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";

//ui
import { Row, Col, Card, Container, Jumbotron, Table } from "react-bootstrap";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import { MdShoppingBasket, MdFavoriteBorder, MdFavorite } from "react-icons/md";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Button } from "react-bootstrap";
import CoverImage from "../../components/coverImage/CoverImage";

//router
import { useParams } from "react-router-dom";
//api
import { getDetailOrderProfile } from "../../api/orders";
import { addSavedUser } from "../../api/lists";

//styles
//import './detailItem.scss'

import { COMPANY } from "../../constants";
import "./orders.scss"

function OrderDetail() {
  const [orderDetail, setOrderDetail] = useState(null);
  let { pk } = useParams();
  let history = useHistory();

  useEffect(() => {
    window.document.title = "Detalle del Pedido | " + COMPANY;
  }, [orderDetail]);

  const handleDetailOrder = async () => {
    const rs = await getDetailOrderProfile(pk);
    if (rs.status === 200) {
      const { data } = rs;
      setOrderDetail(data);
    }
  };

  useEffect(() => {
    handleDetailOrder();
  }, [pk]);

  const calcTotal = () => {
    let itemsTotal = 0
    if (orderDetail !== null) {
      orderDetail.items.map(el => {
        itemsTotal += el.total
      })
    }
    return itemsTotal + parseInt(orderDetail.shipping_cost) + orderDetail.iva
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [orderDetail]);

  return (
    <Container fluid className="px-0 mainDiv">
      {orderDetail ? (
        <>
          <Row className="px-2 mx-0">
            <Col md={12} className="pl-1">
              <h1 className="page-title static-title mt-4 spectral">
                Pedido
              </h1>
            </Col>
          </Row>
          <Container fluid className="px-0 mx-0">
            <Row className="justify-content-center px-2 mx-0 orderTable">
              <Col lg={12}>
                <h5 className="mb-3">Resumen del pedido</h5>
                <Row className="tableTitle">
                  <Col lg={5} md={5} className="tableBorder">
                    <h6>PRODUCTO</h6>
                  </Col>
                  <Col lg={5} md={5} className="tableBorder">
                    <h6>UNIDADES</h6>
                  </Col>
                  <Col lg={2} md={2} className="tableBorder">
                    <h6>SUBTOTAL</h6>
                  </Col>
                </Row>
                {orderDetail.items.length !== 0 && (orderDetail.items.map((el,value) => (
                  <Row key={value} className="borderBottom">
                    <Col lg={5} md={5} className="">
                      <span>{orderDetail.items[value].nombre}</span>
                    </Col>
                    <Col lg={5} md={5} className="">
                      <span>{orderDetail.items[value].cantidad}</span>
                    </Col>
                    <Col lg={2} md={2} className="">
                      <span>{orderDetail.items[value].total} €</span>
                    </Col>
                  </Row>
                )))}
                {orderDetail !== null && (
                  <Row className="borderBottom">
                    <Col lg={5} md={5} className="">
                      
                    </Col>
                    <Col lg={5} md={5} className="">
                      <h6>Subtotal:</h6>
                    </Col>
                    <Col lg={2} md={2} className="">
                      <span className="orange">{orderDetail.total} €</span>
                    </Col>
                  </Row>
                )}
                {orderDetail !== null && (
                  <Row className="borderBottom">
                    <Col lg={5} md={5} className="">
                      
                    </Col>
                    <Col lg={5} md={5} className="">
                      <h6>Impuestos:</h6>
                    </Col>
                    <Col lg={2} md={2} className="">
                      <span>{orderDetail.iva} €</span>
                    </Col>
                  </Row>
                )}
                {orderDetail !== null && (
                  <Row className="borderBottom">
                    <Col lg={5} md={5} className="">
                      
                    </Col>
                    <Col lg={5} md={5} className="">
                      <h6>Gastos de envío:</h6>
                    </Col>
                    <Col lg={2} md={2} className="">
                      <span>{orderDetail.shipping_cost} €</span>
                    </Col>
                  </Row>
                )}
                {orderDetail !== null && (
                  <Row className="">
                    <Col lg={5} md={5} className="">
                      
                    </Col>
                    <Col lg={5} md={5} className="">
                      <h3>Total:</h3>
                    </Col>
                    <Col lg={2} md={2} className="">
                      <h3 className="orange">{calcTotal().toFixed(2)} €</h3>
                    </Col>
                  </Row>
                )}
                
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <Row>
          <Col>
            <Jumbotron>
              <p>Cargando información</p>
            </Jumbotron>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default OrderDetail;
