import React, { useState, useEffect, useRef, } from 'react'
import { Row, Col, Button, Container, Accordion } from "react-bootstrap";
import { BiCrosshair } from "react-icons/bi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Link } from "react-router-dom";

import Map from "../../components/map-google/map";
import { InputCustom } from "../../components/input";

const LibrariesMap = () => {
const [address, setAddress] = useState("");
const [lat, setLat] = useState(39.28);
const [long, setLong] = useState(-3.70797);
const [callByState, setCallByState] = useState(false);
const [floatMenu, setFloatMenu] = useState(()=> {
  if (window.innerWidth > 992) {
    return true
  }
  return false
});
const [width, setWidth] = useState(window.innerWidth);
const [toggleUp, setToggleUp] = useState(false);
const mapRef = useRef()
const key = "AIzaSyBuOghB9VSjn2WiMABRq_L-cFp6flo0Pr8"

useEffect(() => {  
  const handleResizeWindow = () => {
    setWidth(prevState => {
      prevState = window.innerWidth
      return prevState
    });
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }  
  window.addEventListener("resize", handleResizeWindow);
 }, []);

const checkOpen = () => {
  if (width <= 990 ) {
    setFloatMenu(prevState => {
      prevState = false
      return prevState
    })
  } 
  if (width > 992) {
    setFloatMenu(prevState => {
      prevState = true
      return prevState
    })
  }
}  

useEffect(() => {
  checkOpen()
}, [width]);

const goUp = () => {
  window.scrollTo(0, 0);
};

useEffect(() => {
  goUp();
}, []);

const handleLocation = () => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(function(position) {
      setLat(prevState => {
        prevState = position.coords.latitude
        return prevState
      })
      setLong(prevState => {
        prevState = position.coords.longitude
        return prevState
      })
    });
  } else {
    console.log("Not Available");
  }
}

  return (
    <Container fluid className="mapContainer px-0 mainDiv">
      <Row>
        <Col lg={12}>
          {floatMenu && (
            <div className="border floatMenu px-2">
              <div className="aside-title mb-3">
                <a>Mapa de Librerías</a>
              </div>
              <InputCustom
                type="text"
                placeholder="Dirección,ciudad, localidad..."
                value={address}
                onChange={(e)=>{setAddress(e.target.value)}}
                style={{background: '#f8f8f8'}}
              />
              <Button 
                className="blue-btn"
                onClick={()=> setCallByState(!callByState)}
              >
                Buscar
              </Button>
              <div 
                onClick={handleLocation} 
                className="d-flex mt-4 mb-2 text-center pointer"
              >
                <BiCrosshair  className="orange mr-1"/>
                <p className="orange">CERCA DE AQUÍ</p>
              </div>
              <Link className="blue mt-5" to={"/librerias"}>LISTA DE LIBRERÍAS</Link>
            </div>
          )}
          
          <Map
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${key}&language=es`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `500px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            ref={mapRef}
            lat={lat}
            long={long}
            callByState={callByState}
            address={address}
          >
            
          </Map>
        </Col>
        {!floatMenu && (
          <Col lg={12} className="">
            <Accordion className="d-flex flex-column-reverse">
              <Accordion.Toggle 
                as={Button}
                variant="link" 
                eventKey="0"
                onClick={()=> setToggleUp(!toggleUp)}
              >
                Buscar / Filtrar {' '}
                {toggleUp ? (
                  <IoIosArrowUp />
                ) : (
                  <IoIosArrowDown />
                )}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0" className="flex-column-reverse mb-3">
                <div className="px-3 pt-3 text-center">
                  <div className="aside-title mb-3">
                    <a>Mapa de Librerías</a>
                  </div>
                  <InputCustom
                    type="text"
                    placeholder="Dirección,ciudad, localidad..."
                    value={address}
                    onChange={(e)=>{setAddress(e.target.value)}}
                    style={{background: '#f8f8f8'}}
                  />
                  <Button 
                    className="blue-btn w-100"
                    onClick={()=> setCallByState(!callByState)}
                  >
                    Buscar
                  </Button>
                  <div 
                    onClick={handleLocation} 
                    className="d-flex mt-4 mb-2 text-center pointer"
                  >
                    <BiCrosshair  className="orange mr-1"/>
                    <p className="orange">CERCA DE AQUÍ</p>
                  </div>
                  <Link className="blue my-5" to={"/librerias"}>LISTA DE LIBRERÍAS</Link>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </Col>
        )}
      </Row>
    </Container>      
  )
}

export default LibrariesMap;