import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { loginSuccess, setShippingAddress, setBillingAddress } from '../../slices/user';
import { companySelector } from '../../slices/company';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Button } from 'react-bootstrap';
import { emailRegex } from '../../constants';
import { useHistory, Link } from 'react-router-dom';

// own components
import Modal from '../../components/modal';
import { InputCustom } from '../../components/input/index';
import { cartSelector, addItem, setGiftCard } from '../../slices/cart';
//api
import { loginApi } from '../../api/login';
import { registerUser, recoverPassword } from '../../api/users';
import './login.scss';
import { getCart, addItemCart } from '../../api/ecommerce';

const LoginModal = (props) => {
  const [isRegister, setIsRegister] = useState(props.controlModalType);
  const [acceptConditions, setAcceptConditions] = useState(true);
  const [isRecover, setIsRecover] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successSendEmail, setSuccessSendEmail] = useState(false);
  const history = useHistory();
  const { items, giftCard, hasGiftCard } = useSelector(cartSelector);
  const { name } = useSelector(companySelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isRegister) {
      setAcceptConditions(false);
    } else {
      setAcceptConditions(true);
    }
  }, [isRegister]);

  const urlLegals = '/aviso-legal/';
  const urlConditions = '/condiciones/';
  const strTerms = (
    <p>
      He leído y acepto las <Link to={urlLegals}>políticas legales </Link>y las{' '}
      <Link to={urlConditions}>condiciones de servicio</Link> de {name}.
    </p>
  );

  const headerLogin = () => (
    <div className="headerText">{isRecover ? 'Recuperar contraseña' : isRegister ? 'Registro' : 'Iniciar Sesión'}</div>
  );

  const cleanForm = () => {
    setFeedback('');
    setSuccessSendEmail(false);
    setUsername('');
    setEmail('');
    setPassword('');
    setRepeatPassword('');
  };

  const onLogin = async (event) => {
    if (event.keyCode === 13 || event.type === 'click') {
      setSuccessSendEmail(false);
      setIsLoading(true);
      setFeedback('');
      const form = event.target;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      const payload = { username, password };
      const { status, data } = await loginApi(payload);
      if (status === 200) {
        localStorage.setItem('access', data.access);
        localStorage.setItem('refresh', data.refresh);
        localStorage.setItem('type_user', data.type_user);
        localStorage.setItem('permissions', data.permissions);
        dispatch(loginSuccess());
        dispatch(setBillingAddress(data.direccion_facturacion));
        dispatch(setShippingAddress(data.direcciones_envio));
        const cart = await getCart();
        if (cart.status === 200) {
          if (cart.data.items) {
            for (const item of cart.data.items) {
              if (item.producto === null && item.created_gift_card !== null) {
                dispatch(setGiftCard(item.created_gift_card));
              } else if (item.producto !== null && item.created_gift_card !== null) {
                dispatch(addItem(item.producto));
                dispatch(setGiftCard(item.created_gift_card));
              } else {
                dispatch(addItem(item.producto));
              }
            }
          }
        } else {
          console.log('error');
        }
        for (const item of items) {
          await addItemCart({ item: item.id, quantity: item.quantity });
        }

        setIsLoading(false);
        props.handleClose();
        history.push('/');
      } else {
        const errfeedback = Object.keys(data)
          .map((val, i) => {
            if (val.includes('non_field_errors')) return 'Credenciales invalidas';
            return `${val} - ${data[val]}`;
          })
          .join('-');
        setFeedback(errfeedback);
        setIsLoading(false);
      }
    }
  };

  const onRegister = async (event) => {
    if (event.keyCode === 13 || event.type === 'click') {
      setSuccessSendEmail(false);
      setIsLoading(true);
      setFeedback('');
      const form = event.target;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      const emailValid = emailRegex.test(email);

      if (password.length > 0 && password === repeatPassword && emailValid) {
        const payload = { username, password, email };
        const { status, data } = await registerUser(payload);
        if (status === 201) {
          localStorage.setItem('access', data.access);
          localStorage.setItem('refresh', data.refresh);
          dispatch(loginSuccess());
          dispatch(setBillingAddress(data.direccion_facturacion));
          dispatch(setShippingAddress(data.direcciones_envio));
          for (const item of items) {
            await addItemCart({ item: item.id, quantity: item.quantity });
          }
          setIsLoading(false);
          props.handleClose();
        } else {
          if (status === 500) {
            const errfeedback = 'Error interno intentar mas tarde';
            setFeedback(errfeedback);
            setIsLoading(false);
          } else {
            const errfeedback = Object.keys(data)
              .map((val, i) => {
                if (val.includes('non_field_errors')) return 'Credenciales invalidas';
                return `${val} - ${data[val]}`;
              })
              .join('-');
            setFeedback(errfeedback);
            setIsLoading(false);
          }
        }
      } else if (password.length === 0) {
        setFeedback('Debes de ingresar una contraseña');
        setIsLoading(false);
      } else if (emailValid === false) {
        setFeedback('Email inválido.');
        setIsLoading(false);
      } else {
        setFeedback('La contraseña de confirmación no coincide');
        setIsLoading(false);
      }
    }
  };

  const onRecover = async (event) => {
    if (event.keyCode === 13 || event.type === 'click') {
      setSuccessSendEmail(false);
      setIsLoading(true);
      setFeedback('');

      const form = event.target;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (email.length > 0) {
        const payload = { email };
        const { status, data } = await recoverPassword(payload);
        if (status === 200) {
          setSuccessSendEmail(true);
          setIsLoading(false);
        } else if (status === 404) {
          setFeedback('El correo no existe o es incorrecto');
          setIsLoading(false);
        } else {
          setFeedback('Problemas de conexión, inténtelo más tarde');
          setIsLoading(false);
        }
      } else {
        setFeedback('Debes de ingresar un correo');
        setIsLoading(false);
      }
    }
  };

  const loginForm = () => (
    <div onKeyDown={onLogin}>
      <h2 className="mt-3">Iniciar sesión</h2>
      <Form onSubmit={onLogin} className="mt-4">
        <InputCustom
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder={'Correo electrónico'}
        />
        <InputCustom
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          placeholder={'Contraseña'}
        />
      </Form>
    </div>
  );

  const registerForm = () => (
    <div onKeyDown={onRegister}>
      <h2 className="mt-3">Registro</h2>
      <Form onSubmit={onRegister}>
        <InputCustom
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          label="Usuario"
          placeholder={'Ingrese nombre de usuario o email'}
        />
        <InputCustom
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Correo electrónico"
          type="email"
          placeholder={'Ingrese su correo'}
        />
        <InputCustom
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="Contraseña"
          type="password"
          placeholder={'Ingrese su password'}
        />
        <InputCustom
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          label="Repetir contraseña"
          type="password"
          placeholder={'Repetir contraseña'}
        />
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check onChange={(e) => setAcceptConditions(!acceptConditions)} type="checkbox" label={strTerms} />
        </Form.Group>
      </Form>
    </div>
  );

  const recoverForm = () => (
    <div onKeyDown={onRecover}>
      <Form onSubmit={onRecover}>
        <InputCustom
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Ingrese su correo electrónico"
          type="email"
          placeholder={'Ingrese su correo'}
        />
      </Form>
    </div>
  );

  const BodyLogin = () => (isRecover ? recoverForm() : !isRegister ? loginForm() : registerForm());

  const FooterLogin = () => (
    <div className="footerLogin">
      <span className="feedback">{feedback}</span>
      {successSendEmail ? (
        <Row className="alert-success">
          <span>Se ha enviado un enlace a su correo</span>
        </Row>
      ) : null}
      {acceptConditions && (
        <Button
          disabled={isLoading}
          onClick={isLoading ? null : isRecover ? onRecover : isRegister ? onRegister : onLogin}
        >
          {isLoading
            ? 'Cargando...'
            : isRecover
            ? 'Recuperar contraseña'
            : isRegister
            ? 'Registrarme'
            : 'INICIAR SESIÓN'}
        </Button>
      )}
      <br />

      <div className="footerOptions">
        {!isRecover ? (
          <div className="boxText">
            <Link to={'/recuperar-contrasena'}>
              <div className="linkText" onClick={() => props.handleClose()}>
                ¿Has olvidado tu contraseña?
              </div>
            </Link>
          </div>
        ) : null}

        {isRecover || isRegister ? (
          <div className="boxText">
            <div className="questionText">¿Ya tienes cuenta?</div>{' '}
            <div
              className="linkText"
              onClick={() => {
                setIsRecover(setIsRegister(false));
                cleanForm();
              }}
            >
              Entrar
            </div>
          </div>
        ) : null}

        {/* {!isRegister ? (
          <div className="boxText">
            <div className="questionText">¿No tienes cuenta?</div>{' '}
            <div
              className="linkText"
              onClick={() => {
                setIsRecover(setIsRegister(true))
                cleanForm()
              }}
            >
              Regístrate
            </div>
          </div>
        ) : null} */}
      </div>
    </div>
  );

  return (
    <Modal
      className={'loginModal'}
      show={props.show}
      onHide={() => props.handleClose()}
      // title={headerLogin()}
      children={BodyLogin()}
      footer={FooterLogin()}
    ></Modal>
  );
};

export default LoginModal;
