import React, { useState, useEffect } from "react";
import { Container, Image, Row, Col, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

//apis
import { getPosts } from "../../api/blog";

//redux
import { useSelector } from "react-redux";
import { companySelector } from "../../slices/company";

//own components
import Head from "../../components/metatags_helmet/metatags";
import CardBlog from "../../components/blog/cardBlog";
import BlogCategories from "../../components/blog/BlogCategories";

//styles
// import './blog.scss'
import "../../assets/css/staticPages.scss";

const DatePublishPost = ({ post }) => {
  const date = new Date(post.publish_date);
  return (
    <Row key={"info-" + post.id}>
      <span>{`Publicado el ${date.toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })} a las ${post.publish_time} por ${post.author}`}</span>
    </Row>
  );
};

const PostList = () => {
  const title = "Mundo libelista";

  const { descripcion_pub } = useSelector(companySelector);

  const [posts, setPosts] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getPostList = async () => {
      const rs = await getPosts();
      if (rs.status === 200) {
        setPosts(rs.data.results);
        setLoaded(true);
      }
    };
    getPostList();
  }, []);

  const goUp = () => {
    window.scrollTo(0, 0);
  };
  goUp();

  if (loaded) {
    return (
      <div className="content mainDiv">
        <div className="container-fluid blog">
          <Head description={descripcion_pub} title={title} noIndex={true} />
          <Row>
            <Col md={12}>
              <h1 className="page-title static-title">Mundo libelista</h1>
            </Col>
          </Row>
          <div className="container-fluid p-rel margin-bottom-md">
            <div className="blogContainer">
              <Col md={8} xl={9}>
                <div className="containerPostList">
                  <div className="titleColumnPostTitle">Lo más nuevo</div>
                  {posts.map((post, i) => {
                    return (
                      <>
                        <CardBlog
                          event={post}
                          key={i}
                          isContainer={true}
                          hasLine={posts.length - 1 != i}
                        />
                      </>
                    );
                  })}
                </div>
              </Col>
              <Col md={4} xl={3} className="blog-aside">
                <BlogCategories />

                <div className="blog-aside-mostvisited">
                  <h2 className="section-col mt-3">Más visitados</h2>
                  {posts.map((post, i) => {
                    return (
                      <CardBlog
                        event={post}
                        key={i}
                        isColumn={true}
                        hasLine={posts.length - 1 != i}
                      />
                    );
                  })}
                </div>
              </Col>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Container style={{ height: "50vh" }}>
        <Spinner
          animation={"grow"}
          style={{ marginTop: "20vh", marginLeft: "40vw" }}
        />
      </Container>
    );
  }
};

export { DatePublishPost, PostList };
