import { getAwardedBooks as get, deleteAwardedBook as del } from '../../../../api/libros';
import React from 'react';
import BackofficeTable from '../../../../components/backoffice/table/index';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
const AwardWinningBooksTable = ({ data, ActionsMenu, loadData }) => {
  const headers = {
    'display_libro.0.titulo': 'Titulo',
    'display_libro.0.autor.0.nombre_autor': 'Autor',
    'display_libro.0.editorial': 'Editorial',
    prize: 'Premio',
    year: 'Año de premiación',
  };

  loadData.colSpan = Object.keys(headers).length;
  const awardWinningBooksTableProps = {
    title: 'Libros Premiados',
    data,
    headers,
    ActionsMenu,
    loadData,
    filterParameterName: 'search',
    filterRelatedFields: ['title', 'year', 'prize'],
  };
  return <BackofficeTable {...awardWinningBooksTableProps} />;
};
AwardWinningBooksTable.request = {
  get,
  del,
};
AwardWinningBooksTable.itemClass = 'Libro premiado';
AwardWinningBooksTable.requestParameter = 'id';
const AwardWinningBooksTablePage = () => {
  const backOfficesPageManagerProps = { PageViewer: AwardWinningBooksTable, filterParameterName: 'search' };
  return <BackofficePagesManager {...backOfficesPageManagerProps} />;
};
export default AwardWinningBooksTablePage;
