import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Ebook1 from '../../assets/img/ebook_1.jpg';
import Ebook2 from '../../assets/img/ebook_2.jpg';
import Ebook3 from '../../assets/img/ebook_3.jpg';
import Ebook4 from '../../assets/img/ebook_4.jpg';
import Ebook from '../../assets/img/img_ebook.png';
import './infoPages.scss';

//redux
import { useSelector } from 'react-redux';
import { companySelector } from '../../slices/company';

//own components
import Head from '../../components/metatags_helmet/metatags';

const PricesAndTerms = () => {
  const title = 'Ebook';
  const { descripcion_pub } = useSelector(companySelector);

  return (
    <div className="container-fluid mainDiv">
      <Head description={descripcion_pub} title={title} noIndex={true} />
      <Row className="justify-content-center mainRow infoRow">
        <Col lg={8} className="mt-4">
          <div className="row">
            <div className="col-md-12">
              <h1 className="infoTitle">Ebook</h1>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" id="static-content">
              <h2>
                <em>¿Cómo leer tus libros digitales?</em>
              </h2>
              <p>&nbsp;</p>
              <p>
                Queremos que puedas leer allí donde estés y con tus propios dispositivos. Los libros de Libelista pueden
                leerse en tu ordenador, tu portátil, tu tableta (Ipad y Android), en tu móvil (Iphone y Android) o en tu
                dispositivo eReader.
              </p>
              <p>
                <strong>
                  Nota importante: Los libros adquiridos en la plataforma Libelista no son compatibles con los lectores
                  Kindle. Estos lectores solamente admiten libros adquiridos en su propio market place.
                </strong>
              </p>
              <p>Tienes tres opciones a la hora de leer tus libros.</p>
              <ol>
                <li>Podrás leerlos directamente en la nube, mediante un navegador.</li>
                <li>
                  Podrás descargarlos a tu ordenador y pasarlos luego a tu ereader o con una aplicación estándar de
                  lectura.
                </li>
                <li>
                  Podrás leerlos de forma muy sencilla mediante nuestras aplicaciones de lectura para iOS y Android (app
                  Libelista).
                </li>
              </ol>
              <p>&nbsp;</p>
              <h3>
                <strong>1.&nbsp;Lectura en la nube</strong>
              </h3>
              <p>
                Accediendo a&nbsp;
                <a href="https://www.libelista.com/my/books">Mis Libros</a> desde el navegador de tu ordenador o
                portátil podrás disfrutar de la lectura en la nube, clicando en el botón Leer.
              </p>
              <p>
                <img src={Ebook1} alt="ebook1.jpg" />
              </p>
              <p>&nbsp;</p>
              <h3>
                <strong>2.&nbsp;Descarga de libros y lectura offline en tus dispositivos</strong>
              </h3>
              <h4>
                <br />
                <u>2.1 En tu ordenador o portátil (y transferir a tu eReader):</u>
              </h4>
              <p>
                Podrás descargar los libros directamente a tu ordenador o portátil desde el Rincón de lectura pulsando
                el botón Descargar.
              </p>
              <p>
                Es MUY IMPORTANTE que tengas en cuenta antes de abrir el fichero que descargues que debes tener un ID de
                Adobe y tener instalado y autorizado el programa Adobe Digital Editions.
              </p>
              <h4>
                <br />
                <u>2.2 ¿Cómo funciona la descarga con Adobe Digital Editions?</u>
              </h4>
              <p>
                <br />
                <strong>2.2.1 Descarga el software para leer tu ebook</strong>
              </p>
              <p>
                Para leer el ebook, tienes que descargate el programa
                <a
                  href="http://www.adobe.com/es/products/digital-editions.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Adobe Digital Editions
                </a>
                .
              </p>
              <p>
                Para completar la descarga, necesitarás crear una identificación de Adobe para proteger y asegurar los
                derechos de autor.
              </p>
              <p>
                Podrás descargarte el mismo ebook en seis equipos y seis dispositivos usando la misma cuenta Adobe
                Digital Editions.
              </p>
              <p>
                Nota: En la instalación deberás autorizar tu equipo a una cuenta de Adobe Digital Editions. Si no lo has
                hecho, puedes hacerlo más tarde desde el menú de Adobe Digital Editions 2.0 yendo a Ayuda &gt; Autorizar
                equipo (en versiones anteriores a la 2.0 en Biblioteca &gt; Autorizar equipo) antes de empezar la
                descarga. Una vez hecho esto ¡ya podrás empezar a leer!
              </p>
              <p>
                <img src={Ebook2} alt="ebook2.jpg" />
              </p>
              <p>
                <br />
                <strong>2.2.2&nbsp;Añade tu ebook a la Biblioteca de Adobe Digital Editions</strong>
              </p>
              <p>
                ¿Has descargado el Adobe Digital Editions? ¡Perfecto! el siguiente paso es añadir tu libro a la
                biblioteca del programa.
              </p>
              <p>
                Para descargar los libros de tus pedidos debes dirigirte al Rincón de Lectura. Allí encontrarás el
                listado de todos los libros que has adquirido. Es posible que los enlaces tarden unos minutos en
                generarse. Si después de hacer la compra no aparece listado tu pedido vuelve a intentar entrar en unos
                minutos.
              </p>
              <p>
                Empezar a leer los libros de tu Rincón de Lecturaes sencillo: haz clic en Descargar y abre la descarga
                (fichero urllink.acsm) en Adobe Digital Editions. Tu ebook se incorporará a tu Biblioteca de Adobe
                automáticamente.
              </p>
              <p>
                <img src={Ebook3} alt="ebook3.jpg" />
              </p>
              <p>
                Para comprobarlo puedes ir a Adobe Digital Editions y verás que tu libro se ha incorporado a la
                biblioteca.
              </p>
              <p>
                <img src={Ebook4} alt="ebook4.jpg" />
              </p>
              <p>
                Si tienes más dudas sobre el programa, puedes resolverlas en la web de ayuda de Adobe o bien ponerte en
                contacto con nosotros. También puedes consultar la página oficial de Adobe.
              </p>
              <p>
                <br />
                <strong>2.2.3. Transfiere el libro a tu eReader</strong>
              </p>
              <p>
                ¡Ya tienes tu libro! Si ahora lo que quieres es transferirlo a tu eReader, es tan sencillo como contar
                hasta tres:
              </p>
              <ul>
                <li>
                  <b>UNO: Conecta el dispositivo</b>&nbsp;reproductor de ebooks al ordenador.
                </li>
                <li>
                  <b>DOS: Autoriza el dispositivo</b>&nbsp;para poder sincronizar tus ebooks.
                </li>
                <li>
                  <b>¡… Y TRES!: Arrastra el libro</b>&nbsp;al menú que encontrarás a la izquierda, encima del nombre de
                  tu eReader, y ¡listo para disfrutar!
                </li>
              </ul>
              <p>
                <strong>Nota importante:</strong>&nbsp;El formato epub no es compatible con los dispositivos Kindle.
              </p>
              <p>&nbsp;</p>
              <h3>
                <strong>3. En tus dispositivos móviles (tabletas y/o teléfonos)</strong>
              </h3>
              <p>
                Libelista te ofrece una aplicación gratuita para iPad, iPhone y Android (tanto tableta como móviles)
                llamada Libelista. Al descargarte esta aplicación, y accediendo con los mismos datos de acceso que la
                tienda, se sincroniza con tu Rincón de lectura, pudiéndolos leer también sin conexión a Internet.
              </p>
            </div>
          </div>
        </Col>
        <Col lg={3} className="mt-4">
          <img src={Ebook} alt="cards.png" className="infoImg" />
        </Col>
      </Row>
    </div>
  );
};

export default PricesAndTerms;
