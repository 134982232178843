import { methodPost } from "./methods";
import { API_INFO } from "../constants";

export async function loginApi(payload) {
  const url = API_INFO + "token/obtain/";
  return methodPost(url, payload);
}

export async function logoutApi(payload) {
  const url = API_INFO + "logout/";
  return methodPost(url, payload);
}
