import React from 'react';
import { useParams } from 'react-router-dom';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import ActionsForm from '../../../../components/backoffice/actionsForm/index';
import SectionLoader from '../../../../components/loader/SectionLoader';
import { getBookComment as get, editBookComment as edit, createBookComment as create } from '../../../../api/libelista';
import * as Yup from 'yup';
const headers = {
  title: 'Titulo',
  comentary: 'Comentario',
  product: 'Producto',
  created: 'Fecha de creación',
  user: 'Usuario',
};
const statusOptions = [{ label: 'Pendiente', value: 1 }];
const CommentsItem = ({ data }) => {
  const { action, id } = useParams();
  const mustWaitForData = ['detail', 'edit'].includes(action);
  const renderConditions = [!mustWaitForData || data];
  const inputFields = [
    { label: 'Titulo', name: 'title', type: 'text' },
    { label: 'Producto', name: 'product', type: 'text' },
    {
      label: 'Fecha de creación',
      name: 'created',
      type: 'datetime-local',
      hidden: ['edit', 'create'].includes(action),
    },
    { label: 'Usuario', name: 'user', type: 'text' },
    { label: 'Comentario', name: 'comentary', type: 'textarea' },
  ];
  const schema = {
    title: Yup.string().required('* '),
    user: Yup.string().required('* '),
    created: Yup.mixed().required('* '),
    comentary: Yup.string().required('* '),
    product: Yup.string().required('* '),
  };
  const actionsFormProps = {
    inputFields,
    title: 'Comentario de libro',
    subsectionPath: 'comments',
    data,
    requests: { edit, create },
    action,
    extraFormat: { created: 'datetime-local-format', editButton: { disabled: true } },
  };
  return renderConditions.every((condition) => condition) ? <ActionsForm {...actionsFormProps} /> : <SectionLoader />;
};
CommentsItem.request = {
  get,
};
const CommentsItemPage = () => <BackofficePagesManager PageViewer={CommentsItem} />;
export default CommentsItemPage;
