import React, { useState } from "react";
import { Container, Carousel } from "react-bootstrap";

import ItemSlider from "./ItemSlider";

import "../../assets/css/slider.scss";

const Slider = ({ elements }) => {
  const [index, setIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleSelect = (selectedIndex, e) => {
    let beforeSlide = elements[index];
    let afterSlide = elements[selectedIndex];
    let video;

    if (beforeSlide.type_rsc === 1) {
      if (!beforeSlide.resource.indexOf("youtube")) {
        video = document.getElementById(`${index}${beforeSlide.resource}`);
        if (video.paused) {
        } else {
          video.pause();
        }
      } else {
        setIsPlaying(false);
      }
    }

    if (afterSlide.type_rsc === 1) {
      if (!beforeSlide.resource.indexOf("youtube")) {
        video = document.getElementById(
          `${selectedIndex}${afterSlide.resource}`
        );
        if (video.paused) {
          video.play();
        }
      } else {
        setIsPlaying(true);
      }
    }
    setIndex(selectedIndex);
  };

  return (
    <>
      {elements.length > 0 ? (
        <Container fluid className="container-slider p-0">
          <Carousel
            data-testid="carousel"
            controls={elements.length > 1 ? true : false}
            interval={null}
            activeIndex={index}
            onSelect={handleSelect}
            indicators={false}
          >
            {elements.map((item, i) => (
              <Carousel.Item key={i}>
                <ItemSlider item={item} i={i} isPlaying={isPlaying} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
      ) : null}
    </>
  );
};

export default Slider;
