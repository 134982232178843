/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cartSelector, addItem, decreaseQuantity } from "../../slices/cart";
import { userSelector } from "../../slices/user";
import { isEmpty } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import delay from "delay";
// context
import { UserContext } from "../../context/userContext";
//ui
import { Row, Col, Form, Button, Container, Collapse } from "react-bootstrap";
import { AiOutlineTwitter } from "react-icons/ai";
import { GrFacebookOption } from "react-icons/gr";
import { BiCheckCircle } from "react-icons/bi";
import { FaRegTimesCircle, FaShippingFast } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { BsPersonFill } from "react-icons/bs";
import CoverImage from "../../components/coverImage/CoverImage";
import { InputCustom } from "../../components/input";
import letterIcon from "../../assets/img/ico_newsletter.png";
import shopIcon from "../../assets/img/ico_shoplocation.png";
//helpers
import { truncate, checkEmpty } from "../../helpers";
// components
import ItemList from "../../components/itemList/item";
import AvailableLibraries from "../../components/availableLibraries";
import Head from "../../components/metatags_helmet/metatags";
import Carousel from "react-multi-carousel";
//api
import {
  getDetailProduct,
  getRelatedProduct,
  postNotifyAvailability,
  removeItemFav,
  postUserReview,
} from "../../api/products";
import { addSavedUser } from "../../api/lists";
import { addItemCart, decreaseQuantityItemCart } from "../../api/ecommerce";
import { getAvailableLibraries } from "../../api/libelista";

//styles
import "./detailItem.scss";

import { cleanHTML } from "../../utils";
import { companySelector } from "../../slices/company.js";
import Authors from "../../components/authors";
import { responsive } from "../../constants";
import "react-multi-carousel/lib/styles.css";

function BookDetail() {
  const dispatch = useDispatch();
  const { isLogged } = useSelector(userSelector);
  const { items } = useSelector(cartSelector);
  const [productDetail, setProductDetail] = useState("");
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [isBookFavorited, setIsBookFavorited] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [fullDescription, setFullDescription] = useState(false);
  const [available, setAvailable] = useState("");
  const [validatedCaptcha, setValidatedCaptcha] = useState(false);
  const [comment, setComment] = useState("");
  const [nickname, setNickname] = useState("");
  const [msgTitle, setMsgTitle] = useState("");
  const [sharedFacebook, setSharedFacebook] = useState(false);
  const [sharedTwitter, setSharedTwitter] = useState(false);
  const [successCreated, setSuccessCreated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showRestockSuscrptionForm, setShowRestockSuscriptionForm] =
    useState(false);
  const [restockSuscriptionStatus, setRestockSuscriptionStatus] =
    useState(undefined);
  const [libelistaStockMain, setLibelistaStockMain] = useState(false);
  const [libelistaStockSimilar, setLibelistaStockSimilar] = useState(false);
  const [noStock, setNoStock] = useState(false);
  const [availableLibraries, setAvailableLibraries] = useState([]);
  const [selectedItem, setSelectedItem] = useState(productDetail);
  const [selectedBookMain, setSelectedBookMain] = useState(true);
  const [selectedBookStock, setSelectedBookStock] = useState(false);
  const [stockAdvice, setStockAdvice] = useState("Stock");
  const [showAvailableBookstores, setShowAvailableBookstores] = useState(false);
  const [similars, setSimilars] = useState({
    titulo: "",
    precio_venta_publico: "",
    id: "",
  });
  const reCaptcha = useRef();
  const shareUrl = window.location.href;
  let { type, url } = useParams();
  const { name } = useSelector(companySelector);

  const properties = {
    processLan() {
      if (!productDetail.display_idioma) return null;
      const lans = productDetail.display_idioma
        .map((val, i) => {
          return val.nombre;
        })
        .join(",");
      return lans.length >= 1 ? lans : "N/A";
    },
    processEncuadernacion(product) {
      if (!product.display_encuadernacion) return null;
      const bookBinding = product.display_encuadernacion
        .map((val, i) => {
          return `${val.descripcion}`;
        })
        .join(",");
      return bookBinding;
    },
  };

  async function onChange(value) {
    if (value !== null) {
      setValidatedCaptcha(true);
    }
  }

  const onExpired = async (value) => {
    setValidatedCaptcha(false);
  };

  const createReview = async () => {
    let urlShare;
    const payload = {
      product: productDetail.id,
      comment: comment,
      nickname: nickname,
      title: msgTitle,
      shared_facebook: sharedFacebook,
      shared_twitter: sharedTwitter,
    };
    try {
      const rs = await postUserReview(payload);
      if (rs.status === 200) {
        setComment("");
        setNickname("");
        setMsgTitle("");
        setSharedTwitter(false);
        setSharedFacebook(false);
        reCaptcha.current.reset();
        setSuccessCreated(true);
        if (sharedTwitter) {
          let twitterComment = comment.replace(/\s/g, "%20");
          twitterComment += "%0A";
          urlShare = `https://twitter.com/intent/tweet?text=${twitterComment}${shareUrl}`;
          window.open(urlShare, "_blank");
        }
        await delay(1800);
        if (sharedFacebook) {
          urlShare = `https://facebook.com/sharer/sharer.php?u=${shareUrl}`;
          window.open(urlShare, "_blank");
        }
        await delay(1800);
        setSuccessCreated(false);
        handleDetailProduct();
      }
    } catch (error) {
      setErrorMessage(true);
      await delay(4000);
      setErrorMessage(false);
    }
  };

  const sendNotifyAvailability = async (event) => {
    event.preventDefault();
    const emailInput = event.target.elements["email"];
    const payload = {
      producto: productDetail.id,
      email: emailInput.value,
    };
    try {
      const response = await postNotifyAvailability(payload);
      const { status } = response;
      setRestockSuscriptionStatus(status);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDetailProduct = async () => {
    const rs = await getDetailProduct(url, { type });
    if (rs.status === 200) {
      const { data } = rs;
      setIsBookFavorited(data.favorite);
      setProductDetail(data);
      if (data.type === "libro") {
        setAvailable(data.available.status);
      } else {
        setAvailable(true);
      }
      if (typeof data.similars[0] === "object") {
        setSimilars(data.similars[0]);
      }
      setTitle(
        data.type === "merchandising"
          ? `merchandising ${data.titulo}`
          : data.type === "cine"
          ? `película ${data.titulo}`
          : `${data.titulo}| Comprar Libro`
      );
      const editorial = data.display_editorial ? data.display_editorial.nombre_fiscal : "";
      const autor = data.display_autor[0] ? data.display_autor[0].nombre_autor : "";
      setDescription(
        data.type === "cine"
          ? `La película ${data.titulo} del estudio ${editorial}, está a la venta de forma online en ${name}.`
          : data.type === "merchandising"
          ? `Si eres fan de ${data.titulo}, en ${name} tenemos un producto de merchandising que te puede gustar. ¡No te lo pierdas!`
          : `El libro ${data.titulo} del autor ${autor} con EAN ${data.ean}, está a la venta de forma online en ${name}.`
      );
    }
  };

  const goUp = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    handleDetailProduct();
    goUp()
  }, [url, type]);

  useEffect(() => {
    const handleRelatedProducts = async () => {
      const fields = [
        "id",
        "titulo",
        "url",
        "get_url",
        "autor",
        "type",
        "precio_venta_publico",
      ].join(",");
      const rs = await getRelatedProduct(url, { type, fields: fields });
      if (rs.status === 200) {
        const { data } = rs;
        setRelatedProducts(data);
      }
    };
    handleRelatedProducts();
  }, [url, type]);

  const addBookToFavouritesHandler = async () => {
    const rs = await addSavedUser({ product_id: productDetail.id });
    if (rs.status === 200) {
      setIsBookFavorited(true);
    }
  };
  const removeBookFromFavouritesHandler = async () => {
    const payload = { url: productDetail.url };
    const rs = await removeItemFav(payload);
    if (rs.status === 204) {
      setIsBookFavorited(false);
    }
  };

  const { isSignUpModalOpen, showSignUpModal } = useContext(UserContext);

  const handleShowModal = useCallback(
    () => showSignUpModal(true),
    [showSignUpModal]
  );

  const getCountItems = (() => {
    let ctnItems = 0;
    if (checkEmpty(productDetail)) {
      const item = items.find((item) => item.id === productDetail.id);
      ctnItems = item ? item.quantity : 0;
    }
    return ctnItems;
  })();

  const addProductToCart = async (data) => {
    if (isLogged) {
      const payload = { item: data.id, quantity: 1 };
      const { status, dataResponse } = await addItemCart(payload);
      if (status === 201) {
        dispatch(addItem(data));
      } else {
        console.log("error");
      }
    } else {
      dispatch(addItem(data));
    }
  };

  const handleRemoveItem = async () => {
    const ctnItems = getCountItems;
    if (ctnItems === 0) return;
    else if (ctnItems >= 1) {
      if (isLogged) {
        const payload = { item: productDetail.id };
        const { status, dataResponse } = await decreaseQuantityItemCart(
          payload
        );
        if (status === 200) {
          dispatch(decreaseQuantity(productDetail.id));
        } else {
          console.log("error");
        }
      } else {
        dispatch(decreaseQuantity(productDetail.id));
      }
    }
  };

  const getBookAvalability = async () => {
    try {
      const rs = await getAvailableLibraries(productDetail.id);
      if (rs.status === 200) {
        if (typeof rs.data[0] === "object") {
          //Si rs.data[0] es objeto entonces hay stock disponible en alguna librería de la red
          // por lo que noStock = false
          setNoStock(false);
          setAvailableLibraries(rs.data);
        } else {
          //Si rs.data[0] no es objeto entonces no hay stock disponible ninguna librería de la red
          // por lo que noStock = true
          setNoStock(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (productDetail.availabled_status) {
      //const DISABLED_AVAILABLED_STATUS = 1
      const ENABLED_AVAILABLED_STATUS = 2;
      if (productDetail.availabled_status.LIBE !== ENABLED_AVAILABLED_STATUS) {
        setLibelistaStockMain(false);
        if (similars.availabled_status) {
          if (similars.availabled_status.LIBE !== ENABLED_AVAILABLED_STATUS) {
            setLibelistaStockSimilar(false);
            getBookAvalability();
          }
        } else {
          setLibelistaStockSimilar(false);
          getBookAvalability();
        }
      } else {
        setLibelistaStockMain(true);
      }
      if (similars.availabled_status) {
        if (similars.availabled_status.LIBE === ENABLED_AVAILABLED_STATUS) {
          setLibelistaStockSimilar(true);
        } else {
          setLibelistaStockSimilar(false);
        }
      }
    }
    setSelectedItem(productDetail);
  }, [productDetail, similars]);

  const selectedItemStock = () => {
    if (selectedItem.availabled_status) {
      if (selectedItem.availabled_status.LIBE === 2) {
        setSelectedBookStock(true);
      } else {
        setSelectedBookStock(false);
      }
    }
  };

  useEffect(() => {
    selectedItemStock();
    setRestockSuscriptionStatus(undefined);
  }, [productDetail, selectedItem]);

  const closeOverlay = () => {
    setShowAvailableBookstores(!showAvailableBookstores);
  };

  const handleStockAdvice = () => {
    if (!selectedBookMain) {
      if (!libelistaStockSimilar) {
        setStockAdvice("Sin stock local");
      } else {
        setStockAdvice("En Stock");
      }
    } else {
      if (!libelistaStockMain) {
        setStockAdvice("Sin stock local");
      } else {
        setStockAdvice("En Stock");
      }
    }
  };

  useEffect(() => {
    handleStockAdvice();
  }, [selectedItem]);

  const renders = {
    renderRelatedProcuts() {
      if (relatedProducts.length === 0) return null;
      const compRelatedProducts = relatedProducts.map((item, i) => (
        <Col xs={6} sm={6} md={4} lg={12} key={i}>
          <ItemList key={i} data={item} />
        </Col>
      ));
      return compRelatedProducts;
    },
  };

  return (
    <div className="container-fluid justify-content-center pt-5 overflow-hidden mainDiv">
      <Head description={description} title={title} />
      <Container fluid className="justify-content-center px-0">
        <Row className="justify-content-center px-0">
          <Col lg={9} className="pl-3">
            <Row className="px-0">
              <Col md={4} lg={4} className="mb-2">
                {!isEmpty(productDetail) && (
                  <CoverImage
                    title={productDetail.titulo}
                    src={productDetail.get_url}
                  />
                )}
                <div className="markers markers-detail">
                  {productDetail.is_novelty && (
                    <p className="markers-novelty">NOVEDAD</p>
                  )}
                </div>
                <div className="socialDiv">
                  <p>Compartir: </p>
                  <a
                    href={`https://facebook.com/sharer/sharer.php?u=${shareUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="icon">
                      <GrFacebookOption />
                    </div>
                  </a>
                  <a
                    href={`https://twitter.com/intent/tweet?text=Libro%20recomendado%3A%0A${shareUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="icon">
                      <AiOutlineTwitter />
                    </div>
                  </a>
                  <div className="icon">
                    <HiMail />
                  </div>
                </div>
              </Col>
              <Col md={6} lg={8} className="pl-3">
                <Row md={12} className="p-0">
                  <Col md={12}>
                    <h1 className="titleItem">{productDetail.titulo}</h1>
                  </Col>
                </Row>
                <Row md={12}>
                  <Col md={12}>
                    <h3 className="autor-item">
                      {productDetail.display_autor && (
                        <Authors elements={productDetail.display_autor} />
                      )}
                    </h3>
                  </Col>
                  {showAvailableBookstores && (
                    <Col md={12}>
                      <h3 className="autor-item">LIBRERÍAS DISPONIBLES</h3>
                    </Col>
                  )}
                </Row>

                <Row md={12} className="description-item pt-3">
                  <Col md={12}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: checkEmpty(productDetail)
                          ? fullDescription
                            ? cleanHTML(productDetail.texto_resumen)
                            : truncate(
                                cleanHTML(productDetail.texto_resumen),
                                400
                              )
                          : "",
                      }}
                    />
                  </Col>
                  {!productDetail.texto_resumen ? (
                    ""
                  ) : productDetail.texto_resumen.length > 400 ? (
                    <Button
                      className="mb-3"
                      size="sm"
                      variant="link"
                      onClick={() => {
                        fullDescription
                          ? setFullDescription(false)
                          : setFullDescription(true);
                      }}
                    >
                      <h6
                        dangerouslySetInnerHTML={{
                          __html: fullDescription
                            ? "VER MENOS..."
                            : "VER MAS...",
                        }}
                      />
                    </Button>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            lg={3}
            style={{ minHeight: "500px" }}
            className="pr-2 buttonsCol"
          >
            <Row>
              <Col
                lg={12}
                className={
                  "d-flex justify-content-end " +
                  (selectedBookMain
                    ? libelistaStockMain
                      ? "orange"
                      : "gray"
                    : libelistaStockSimilar
                    ? "orange"
                    : "gray")
                }
              >
                {selectedBookMain ? (
                  libelistaStockMain ? (
                    <BiCheckCircle className="mr-2" />
                  ) : (
                    <FaRegTimesCircle className="mr-2" />
                  )
                ) : libelistaStockSimilar ? (
                  <BiCheckCircle className="mr-2" />
                ) : (
                  <FaRegTimesCircle className="mr-2" />
                )}{" "}
                <p className="mr-3">{stockAdvice}</p>
                <span>{selectedItem.precio_venta_publico} €</span>
              </Col>
            </Row>
            <Row className="buttonRow">
              <Col
                lg={12}
                onClick={() => {
                  setSelectedItem(productDetail);
                  setSelectedBookMain(true);
                }}
              >
                <Button className={noStock ? "noStock" : "inStock"}>
                  {properties.processEncuadernacion(productDetail) !== null
                    ? properties.processEncuadernacion(productDetail) + " "
                    : "Libro físico "}
                  {productDetail.precio_venta_publico} €
                  {noStock ? <FaRegTimesCircle /> : <BiCheckCircle />}
                </Button>
              </Col>
            </Row>
            {similars.titulo !== "" ? (
              <Row className="buttonRow">
                <Col
                  lg={12}
                  onClick={() => {
                    setSelectedItem(similars);
                    setSelectedBookMain(false);
                  }}
                >
                  <Button
                    className={
                      "mt-2 " + (!libelistaStockSimilar ? "noStock" : "inStock")
                    }
                  >
                    {properties.processEncuadernacion(similars) !== null
                      ? properties.processEncuadernacion(similars) + " "
                      : "Formato alternativo "}
                    {similars.precio_venta_publico} €
                    {!libelistaStockSimilar ? (
                      <FaRegTimesCircle />
                    ) : (
                      <BiCheckCircle />
                    )}
                  </Button>
                </Col>
              </Row>
            ) : (
              ""
            )}
            {selectedBookStock && (
              <Row className="buttonRow">
                <Col lg={12} className="">
                  <Button
                    onClick={() => addProductToCart(selectedItem)}
                    className="mt-3 addCart"
                  >
                    AÑADIR A LA CESTA
                  </Button>
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={12} className="d-flex mt-1 shippingTime">
                <p>
                  <FaShippingFast /> Recíbelo en 4 - 7 días laborables
                </p>
              </Col>
            </Row>
            {!noStock && (
              <Row>
                <Col
                  lg={12}
                  className="d-flex mt-3 placesAvailable"
                  onClick={() => {
                    if (!libelistaStockMain) {
                      setShowAvailableBookstores(!showAvailableBookstores);
                    }
                  }}
                >
                  <img src={shopIcon} alt="letter.png" />
                  {libelistaStockMain || libelistaStockSimilar ? (
                    <p>Disponible en almacén Libelista</p>
                  ) : (
                    <p>En que librerías puedes encontrarlo</p>
                  )}
                </Col>
              </Row>
            )}
            <Row>
              {restockSuscriptionStatus ? (
                <div className="col-12 d-flex justify-content-center align-items-center mt-3">
                  {[200, 201].includes(restockSuscriptionStatus) ? (
                    <p className=" font-weight-bolder text-success">
                      Recibirás un email cuando el producto vuelva a estar
                      disponible.
                    </p>
                  ) : (
                    <p className=" font-weight-bolder text-danger">
                      Hubo un error. Quizá el correo ya fue ingresado
                    </p>
                  )}
                </div>
              ) : (
                !libelistaStockMain &&
                noStock && (
                  <div>
                    <Col lg={12} className="d-flex mt-3 noticeAvailability">
                      <img src={letterIcon} alt="letter.png" />
                      <p
                        onClick={() =>
                          setShowRestockSuscriptionForm(
                            !showRestockSuscrptionForm
                          )
                        }
                        role="button"
                      >
                        ¿Quieres que te avisemos cuando vuelva a estar
                        disponible?
                      </p>
                    </Col>
                    <Collapse in={showRestockSuscrptionForm}>
                      <form
                        className="w-100 pr-3"
                        onSubmit={sendNotifyAvailability}
                      >
                        <InputCustom
                          type="text"
                          name="email"
                          placeholder="Correo electrónico"
                          className="w-100 bg-light"
                        />
                        <input
                          className="blue-btn w-100 text-white p-1"
                          type="submit"
                          value="AVISAR"
                        />
                      </form>
                    </Collapse>
                  </div>
                )
              )}
              <Col lg={12} className="my-2 d-flex justify-content-center">
                <Button
                  className="text-uppercase"
                  variant="light"
                  onClick={
                    isBookFavorited
                      ? removeBookFromFavouritesHandler
                      : addBookToFavouritesHandler
                  }
                >
                  {isBookFavorited
                    ? "eliminar de favoritos"
                    : "añadir a favoritos "}
                </Button>
              </Col>
            </Row>
            <Row className="mt-2 detailRow">
              <p>Datos del producto</p>
              <ul>
                {/* <li>Editorial: {productDetail.editorial.nombre_fiscal}</li> */}

                {productDetail !== "" && productDetail.display_editorial ? (
                  <li>
                    <strong>Editorial: </strong>
                    <Link
                      className="store-item__link"
                      to={`/editorial/${productDetail.display_editorial.url}/`}
                    >
                      {productDetail.display_editorial.nombre_fiscal}
                    </Link>
                  </li>
                ) : (
                  <p>
                    <strong>Editorial: </strong>
                    <span>No especificado</span>
                  </p>
                )}
                <li>
                  <strong>ISBN:</strong> {productDetail.ean}
                </li>
                <li>
                  <strong>Publicación:</strong> {productDetail.fecha_public}
                </li>
                <li>
                  <strong>Formato:</strong>{" "}
                  {properties.processEncuadernacion(productDetail)}
                </li>
                {productDetail !== "" && productDetail.idioma ? (
                  <li>
                    <strong>Idioma:</strong> {properties.processLan()}
                  </li>
                ) : (
                  ""
                )}
                {productDetail.num_paginas ? (
                  <li>
                    <strong>Número de páginas:</strong>{" "}
                    {productDetail.num_paginas}
                  </li>
                ) : null}
              </ul>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col lg={11} className="p-0">
            {relatedProducts.length > 0 && (
              <section className="margin-bottom-md list-section">
                <Row className="">
                  <Col xs={12} sm={9}>
                    <h3>Otros productos que te pueden interesar</h3>
                  </Col>
                  <Col xs={12} sm={2} className="v-center"></Col>
                </Row>
                <Col xs={12} sm={12}>
                  <Carousel
                    responsive={responsive}
                    slidesToSlide={1}
                    minimumTouchDrag={40}
                    draggable
                    infinite={true}
                    centerMode={false}
                    swipeable
                  >
                    {relatedProducts.map((item, i) => (
                      <div
                        key={`relatedProductsItem#${i}`}
                        className="slide-div"
                      >
                        <ItemList data={item} />
                      </div>
                    ))}
                  </Carousel>
                </Col>
              </section>
            )}
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col lg={9} className="p-0 commentsCol">
            <Row className="justify-content-center mt-2">
              <Col lg={7} className="p-0">
                <p className="mb-4 position-relative comTitle">Comentarios</p>
                <InputCustom
                  type="text"
                  placeholder="Apodo"
                  onChange={(e) => setNickname(e.target.value)}
                  value={nickname}
                />
                <InputCustom
                  type="text"
                  placeholder="Título"
                  onChange={(e) => setMsgTitle(e.target.value)}
                  value={msgTitle}
                />
                <InputCustom
                  as="textarea"
                  placeholder={"Mensaje"}
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                />
                <Col className="d-flex socialDiv">
                  <p className="p-0">Compartir en:</p>
                  <Form.Check
                    type={"checkbox"}
                    className="ml-3"
                    onChange={(e) => setSharedFacebook(!sharedFacebook)}
                    checked={sharedFacebook}
                  />
                  <div className="icon m-0">
                    <GrFacebookOption />
                  </div>
                  <Form.Check
                    type={"checkbox"}
                    className="ml-3"
                    onChange={(e) => setSharedTwitter(!sharedTwitter)}
                    checked={sharedTwitter}
                  />
                  <div className="icon m-0">
                    <AiOutlineTwitter />
                  </div>
                </Col>
                <Col lg={12} className="p-0">
                  <ReCAPTCHA
                    sitekey="6LfaNDcbAAAAAMoJUCmqXn5RNIi3kSW2p6InZW55"
                    onChange={onChange}
                    onExpired={onExpired}
                    ref={reCaptcha}
                  />
                  <Button
                    className="blue-btn mt-4"
                    disabled={!validatedCaptcha}
                    onClick={createReview}
                  >
                    Enviar Comentario
                  </Button>
                </Col>
                {successCreated && (
                  <Col lg={12}>
                    <p
                      style={{ color: "#24C700", fontSize: "19px" }}
                      className="mt-4"
                    >
                      ¡Comentario añadido exitosamente!
                    </p>
                  </Col>
                )}
                {errorMessage && (
                  <Col lg={12}>
                    <p
                      style={{ color: "#EA0000", fontSize: "19px" }}
                      className="mt-4"
                    >
                      Error al crear mensaje, por favor intente nuevamente.
                    </p>
                  </Col>
                )}
              </Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <Col lg={7} className="p-0">
                <hr className="position-relative" />
                {productDetail !== "" &&
                  productDetail.comments &&
                  productDetail.comments.map((item, i) => (
                    <Row className="commentRow" key={i}>
                      <Col>
                        <p className="text-uppercase my-2">{item.title}</p>
                        <p className="m-0">
                          <BsPersonFill /> {item.nickname} Fecha:
                          {" " + item.created.split("T")[0]}{" "}
                        </p>
                        <p className="m-0">{item.comentary}</p>
                      </Col>
                    </Row>
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <AvailableLibraries
          showLibraries={showAvailableBookstores}
          closeOverlay={closeOverlay}
          libraries={availableLibraries}
        />
      </Container>
    </div>
  );
}

export default BookDetail;
