import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from 'utils/customHooks/useQuery';

const BackofficePaginator = ({ page, pages, pageState, filterParameterName = '' }) => {
  page = parseInt(page);
  const pageNumbers = [];
  const history = useHistory();
  const query = useQuery();
  const filterParameterValue = query.get(filterParameterName) || '';
  const filterQueryString = `${
    filterParameterName && filterParameterValue ? filterParameterName + '=' + filterParameterValue + '&' : ''
  }`;
  const pageLimit = 5;
  const lowerPageLimit = page - pageLimit > 0 ? page - pageLimit : 1;
  const areMorePagesBelowLowerLimit = lowerPageLimit > 1;
  const upperPageLimit = page + pageLimit > pages ? pages : page + pageLimit;
  const areMorePagesAboveUpperLimit = pages - upperPageLimit > 1;
  let iteration = lowerPageLimit;
  while (iteration <= upperPageLimit) {
    pageNumbers.push(iteration++);
  }
  const goToPage = (event) => {
    event.preventDefault();
    const pageNumber = event.target.elements['pageNumber'].value;
    /^\d+$/.test(pageNumber) && history.push(`?${filterQueryString}page=${pageNumber}`);
  };

  return (
    <div className="container-fluid mt-1 p-0 ">
      <nav aria-label="Page navigation">
        <ul className="pagination pagination-sm justify-content-between mb-1">
          <li className={`page-item ${page <= 1 ? 'disabled' : ''}`}>
            <Link className="page-link" to={`?${filterQueryString}page=${page - 1}`}>
              Anterior
            </Link>
          </li>
          <li className="d-flex overflow-auto ">
            <ul className="pagination">
              {areMorePagesBelowLowerLimit && (
                <li className={`page-item`}>
                  <Link className="page-link" to={`?${filterQueryString}page=1`}>
                    Inicio
                  </Link>
                </li>
              )}
              {pageNumbers.map((pageNumber, index) => {
                const firstLink = index === 0;
                const lastLink = index === pageNumbers.length - 1;
                const areMorePagesBelow = areMorePagesBelowLowerLimit && firstLink;
                const areMorePagesAbove = areMorePagesAboveUpperLimit && lastLink;
                const path = `?${filterQueryString}page=${pageNumber}`;
                return (
                  <li key={`page#${pageNumber}`} className={`page-item ${page === pageNumber ? 'disabled' : ''}`}>
                    <Link className="page-link" to={path}>
                      {areMorePagesAbove || areMorePagesBelow ? '...' : pageNumber}
                    </Link>
                  </li>
                );
              })}
              {page < pages - pageLimit && (
                <li className={`page-item`}>
                  <Link className="page-link" to={`?${filterQueryString}page=${pages}`}>
                    Fin
                  </Link>
                </li>
              )}
            </ul>
          </li>
          <li className={`page-item ${page < pages ? '' : 'disabled'}`}>
            <Link className="page-link" to={`?${filterQueryString}page=${page + 1}`}>
              Siguiente
            </Link>
          </li>
        </ul>
      </nav>
      <form onSubmit={goToPage}>
        <div className="form-row justify-content-center col-12 p-0 m-0">
          <div className="pagination-sm col-12 d-flex p-0">
            <input
              className="page-link w-50"
              name="pageNumber"
              placeholder="Número de página"
              type="number"
              min="1"
              max={pages}
              required
            />
            <button className="w-50 m-0 page-link" type="submit">
              Ir
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default BackofficePaginator;
