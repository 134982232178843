/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { companySelector } from '../../slices/company.js';
import { UserContext } from '../../context/userContext';

//ui
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import CoverImage from '../../components/coverImage/CoverImage';
import Head from '../../components/metatags_helmet/metatags';
import Rocket from '../../assets/img/rocket.png';

//api
import { getSmallReviewsDetail } from '../../api/libros';

import './smallReviewDetail.scss';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SmallReviewDetail() {
  const [productDetail, setProductDetail] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  let query = useQuery();
  const { showSignUpModal } = useContext(UserContext);
  // const handleShowModal = useCallback(() => showSignUpModal(true), [showSignUpModal]);

  const properties = {
    processLan() {
      if (!productDetail[0]) return null;
      const lans = productDetail[0].libro[0].idioma
        .map((val, i) => {
          return val.nombre;
        })
        .join(',');
      return lans.length >= 1 ? lans : 'N/A';
    },
    processEncuadernacion() {
      if (!productDetail[0]) return null;
      const bookBinding = productDetail[0].libro[0].encuadernacion
        .map((val, i) => {
          return `${val.descripcion}`;
        })
        .join(',');
      return bookBinding;
    },
    processAuthors() {
      if (!productDetail[0]) return null;
      const authors = productDetail[0].libro[0].autor
        .map((val, i) => {
          return `${val.nombre_autor}`;
        })
        .join('. ');
      return authors;
    },
  };

  const handleDetailProduct = async () => {
    const rs = await getSmallReviewsDetail(String(query).split('=')[1]);
    if (rs.status === 200) {
      const { data } = rs;
      setProductDetail(data);
    }
  };

  useEffect(() => {
    handleDetailProduct();
  }, []);

  const goUp = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="container-fluid justify-content-center pt-4 mainDiv">
      <Head description={description} title={title} />
      <Container fluid className="justify-content-center px-0">
        <Row className="px-1 mx-0 mb-4">
          <Col md={12} className="pl-0">
            <h1 className="page-title static-title mt-0 spectral">Mundo Libelista</h1>
          </Col>
        </Row>
        <Row className="justify-content-center px-0">
          <Col lg={10} className="pl-3">
            <Row className="px-0 smReviewRow">
              <Col md={4} lg={4} className="mb-2">
                {!isEmpty(productDetail) && (
                  <CoverImage title={productDetail[0].libro[0].titulo} src={productDetail[0].libro[0].get_url} />
                )}
                <Row className="mt-2 detailRow">
                  <ul>
                    {productDetail[0] && productDetail[0].libro[0].editorial ? (
                      <li>
                        <strong>Editorial: </strong>
                        {productDetail[0].libro[0].editorial.nombre_fiscal}
                      </li>
                    ) : (
                      <li>
                        <strong>Editorial: </strong>
                        <span>No especificado</span>
                      </li>
                    )}
                    <li>
                      {productDetail[0] && (
                        <>
                          <strong>ISBN:</strong> {productDetail[0].libro[0].ean}
                        </>
                      )}
                    </li>
                    <li>
                      <strong>Pubicación:</strong>{' '}
                    </li>
                    <li>
                      <strong>Formato:</strong> {properties.processEncuadernacion()}
                    </li>
                    {productDetail[0] && (
                      <li>
                        <strong>Idioma:</strong> {properties.processLan()}
                      </li>
                    )}
                    {productDetail[0] ? (
                      <li>
                        <strong>Número de páginas:</strong> {productDetail[0].libro[0].num_paginas}
                      </li>
                    ) : null}
                    {productDetail[0] && (
                      <li>
                        <strong>Tamaño:</strong> {productDetail[0].libro[0].alto_cm}cm X{' '}
                        {productDetail[0].libro[0].ancho_cm}cm
                      </li>
                    )}
                  </ul>
                </Row>
              </Col>
              <Col md={6} lg={8} className="pl-3">
                <Row md={12} className="p-0">
                  <Col md={12}>
                    <p className="w-100 smReviewSubtitle">Pequeña Reseña</p>
                    {productDetail[0] && <h2 className="titleItem">{productDetail[0].libro[0].titulo}</h2>}
                  </Col>
                </Row>
                <Row md={12}>
                  <Col md={12} className="smReviewDescription">
                    {productDetail[0] && <p className="mt-3">{productDetail[0].description}</p>}
                  </Col>
                </Row>

                <Row md={12} className="relatedItem pt-3">
                  <Col md={12}>
                    <p className="subTitle mt-3 mb-4">Relacionado</p>
                    <Row className="mt-2 mb-4">
                      <Col lg={5} className="">
                        {!isEmpty(productDetail) && (
                          <CoverImage
                            title={productDetail[0].libro[0].titulo}
                            src={productDetail[0].libro[0].get_url}
                          />
                        )}
                      </Col>
                      <Col lg={7} className="">
                        {productDetail[0] && <h3 className="titleItem">{productDetail[0].libro[0].titulo}</h3>}
                        <p className="authors">{properties.processAuthors()}</p>
                        {productDetail[0] && <p className="description">{productDetail[0].libro[0].texto_resumen}</p>}

                        {productDetail[0] && (
                          <Link
                            to={`/productos/${productDetail[0].libro[0].type}/` + `${productDetail[0].libro[0].url}/`}
                            onClick={goUp}
                          >
                            <Button
                              // variant="outline-dark"
                              size="sm"
                              title="ver producto"
                              block
                              className="blue-btn"
                            >
                              VER DETALLE
                            </Button>
                          </Link>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col lg={2} style={{ minHeight: '500px' }} className="pr-2 buttonsCol">
            <Row>
              <Col lg={10} className="d-flex justify-content-end">
                <img src={Rocket} alt="paper.png" style={{ marginRight: '15px' }} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SmallReviewDetail;
