import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackofficePagesManager from '../../../../components/backoffice/pagesManager';
import {
  getBookOfTheMonthItem as get,
  editBooksOfTheMonthItem as edit,
  createBooksOfTheMonthItem as create,
} from '../../../../api/libelista';
import { getAllBooks, getBookDetail } from '../../../../api/libros';
import ActionsForm from '../../../../components/backoffice/actionsForm/index';
import { getSelectDataHandlers } from '../../../../utils/backoffice/getSelectDataHandlers';
import SectionLoader from 'components/loader/SectionLoader';
const BooksOfTheMonthItem = ({ data, loadData }) => {
  const { action } = useParams();
  const [{ defaultOptions: defaultBookOptions, defaultOption: defaultBookOption }, setBooksDefaultOptionValues] =
    useState({});
  const { getSelectData: getBookOptions, setSelectDefaultData: setBookOptions } = getSelectDataHandlers(
    getBookDetail,
    getAllBooks,
    setBooksDefaultOptionValues,
    {
      fieldNameWithDisplayValue: 'titulo',
      fieldNameWithRequestValue: 'id',
    }
  );
  const mustWaitForData = ['detail', 'edit'].includes(action);
  const renderConditions = [!mustWaitForData || data, defaultBookOptions?.length || defaultBookOption];
  useEffect(() => {
    if (!mustWaitForData || data) {
      setBookOptions(data?.related_book);
    }
  }, [data]);
  const inputFields = [
    {
      label: 'Título',
      name: 'title',
      type: 'text',
      required: true,
    },
    {
      label: 'Párrafo destacado',
      name: 'featured_paragraph',
      type: 'text',
      required: true,
    },
    {
      label: 'Descripción',
      name: 'description',
      type: 'text',
    },
    {
      label: 'Libro relacionado',
      name: 'related_book',
      type: 'select',
      isAsync: true,
      loadOptions: getBookOptions,
      defaultOptions: defaultBookOptions,
      defaultValue: defaultBookOption,
      isDisabled: action === 'detail',
    },
  ];
  const actionsFormProps = {
    inputFields,
    title: 'Entrada de Libro del Mes',
    subsectionPath: 'books-of-the-month',
    data: data || {},
    requests: { edit, create },
    action,
    loadData,
  };
  return renderConditions.every((condition) => {
    return condition;
  }) ? (
    <ActionsForm {...actionsFormProps} />
  ) : (
    <SectionLoader />
  );
};
BooksOfTheMonthItem.request = {
  get,
};
const BooksOfTheMonthItemPage = () => <BackofficePagesManager PageViewer={BooksOfTheMonthItem} />;
export default BooksOfTheMonthItemPage;
