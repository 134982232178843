import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import { defaultTheme } from 'react-select';
import { IoIosArrowDown } from 'react-icons/io';

import { getLibraries } from '../../api/libelista';
import './search.scss';

const { colors } = defaultTheme;

const Menu = (props) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      css={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = (props) => (
  <div
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p) => <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />;
const DropdownIndicator = () => (
  <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);

const SearchSelect = ({ callback, selectedLibrary }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const [label, setLabel] = useState('');
  const [libraries, setLibraries] = useState([]);
  const [librariesShow, setLibrariesShow] = useState([{ id: null, label: 'Quitar selección' }]);
  let string = '';

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onSelectChange = (event) => {
    toggleOpen();
    setValue(event.id);
    setLabel(event.label);
    if (callback !== undefined) {
      callback(event);
    }
  };

  const randomChoice = () => {
    let optionsLenth = Object.keys(librariesShow).length;
    const randInt = Math.floor(Math.random() * optionsLenth + 1);
    let selectedOpt = librariesShow[randInt];
    setValue(selectedOpt?.id);
    setLabel(selectedOpt?.label);
    if (callback !== undefined) {
      callback(selectedOpt);
    }
  };

  async function getLibrariesAsoc() {
    const rs = await getLibraries(string, string);
    if (rs.status === 200) {
      setLibraries(rs.data);
    }
  }
  const setLibraryItems = () => {
    if (libraries.length > 0) {
      libraries.map((el, value) => {
        el.id = el.id;
        el.label = el.name;
        setLibrariesShow((prevState) => {
          return [...prevState, el];
        });
      });
    }
  };

  useEffect(() => {
    getLibrariesAsoc();
  }, []);

  useEffect(() => {
    setLibraryItems();
  }, [libraries]);

  useEffect(() => {
    if (selectedLibrary !== undefined) {
      setValue(selectedLibrary.id);
      let myLib;
      setLabel(() => {
        myLib = librariesShow.filter((el) => {
          return parseInt(el.id) == parseInt(selectedLibrary.id);
        });
        if (typeof myLib[0] !== 'object') {
          return 'none';
        } else {
          return myLib[0].label;
        }
      });
    }
  }, [librariesShow, selectedLibrary]);

  return (
    <>
      {libraries.length == 0 ? (
        <>
          <h1>No hay librerías disponibles</h1>
          {libraries}
        </>
      ) : (
        <>
          <Dropdown
            isOpen={isOpen}
            onClose={toggleOpen}
            target={
              <Button onClick={toggleOpen} className="carouselBtn">
                <span>{value ? `${label}` : 'Seleccionar librería'}</span> <IoIosArrowDown />
              </Button>
            }
          >
            <Select
              autoFocus
              backspaceRemovesValue={false}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={onSelectChange}
              options={librariesShow}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={value}
            />
          </Dropdown>
          <Button className="blue-btn" onClick={randomChoice}>
            Elige una librería por mi
          </Button>
        </>
      )}
    </>
  );
};

export default SearchSelect;
