import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isLogged: false,
  shippingAddress: [],
  billingAddress: [],
  pickUpLibrary: [],
  asociatedLibraryImage: '',
  firstName: '',
  lastName: '',
  email: '',
  isWorker: false,
  benefitedBookstore: []
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isLogged = true;
    },
    logoutSuccess: (state, action) => {
      state.isLogged = false;
    },
    setShippingAddress: (state, action) => {
      state.shippingAddress = action.payload;
    },
    setBillingAddress: (state, action) => {
      state.billingAddress = action.payload;
    },
    setPickUpLibrary: (state, action) => {
      state.pickUpLibrary = action.payload;
    },
    setAsociatedLibraryImage: (state, action) => {
      state.asociatedLibraryImage = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setIsWorker: (state, action) => {
      state.isWorker = action.payload;
    },
    setBenefitedBookstore: (state, action) => {
      state.benefitedBookstore = action.payload;
    },
  },
});

export const {
  loginSuccess,
  logoutSuccess,
  setShippingAddress,
  setAsociatedLibraryImage,
  setFirstName,
  setLastName,
  setEmail,
  setIsWorker,
  setBenefitedBookstore,
  setBillingAddress,
  setPickUpLibrary
} = userSlice.actions;
export const userSelector = (state) => state.user;
export default userSlice.reducer;
