import React, { useState, useEffect, useMemo, useReducer } from "react";
import { useParams } from "react-router-dom";
import { Spinner, Container, Row, Col } from "react-bootstrap";
import { AiOutlineFilter } from "react-icons/ai";
import Alert from "react-bootstrap/Alert";
import { debounce, isEmpty } from "lodash";

//own components
import ItemList from "../../components/itemList/item";
import FilterProductBooks from "../../components/filters/filterProductBooks";

//hooks
import { useIntersectionObserver } from "react-intersection-observer-hook";

//api
import { searchFilterProduct } from "../../api/products";

const reducer = (state, action) => {
  const { data } = action;

  switch (action.type) {
    case "initialData":
      return { ...state, queryResults: data };
    case "updateOnScroll":
      return {
        ...state,
        queryResults: {
          results: [...state.queryResults.results, ...data.results],
          next: data.next,
          count: data.count,
        },
        page: data.page,
      };
    case "updateSearch":
      return { ...state, ...data };
    default:
      return state;
  }
};

const DynamicFilter = () => {
  const initialState = {
    querySearch: {},
    queryResults: {},
    page: 1,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [contentListBottom, { entry }] = useIntersectionObserver();
  const isVisible = entry && entry.isIntersecting;
  const [state, dispatch] = useReducer(reducer, initialState);

  const { type } = useParams();

  let path = window.location.pathname.split("/")[2];

  useEffect(() => {
    // initial load
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await searchFilterProduct({ page: state.page, type });
        dispatch({ type: "initialData", data });
      } catch (e) {}
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    goUp();
  }, []);

  useEffect(() => {
    // infinite scrolling
    (async () => {
      if (isVisible && !isLoading && state.queryResults?.next) {
        const page = state.page + 1;
        try {
          const { data } = await searchFilterProduct({
            page,
            ...state.querySearch,
            type,
          });
          dispatch({ type: "updateOnScroll", data: { ...data, page } });
        } catch (e) {}
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  //render
  const renderFilterProducts = useMemo(() => {
    const updateQuery = debounce(async (query) => {
      try {
        setIsLoading(true);
        const { data } = await searchFilterProduct(query);
        dispatch({
          type: "updateSearch",
          data: { querySearch: query, queryResults: data, page: 1 },
        });
      } catch (e) {}
      setIsLoading(false);
    }, 500);

    return <FilterProductBooks filterProductsHandle={updateQuery} />;
  }, []);

  const goUp = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="content">
      <div className="container-fluid">
        <Row>
          <Col md={12}>
            <h1
              className="page-title static-title"
              style={{ textTransform: "capitalize" }}
            >
              {path}
            </h1>
          </Col>
        </Row>
        <Container fluid>
          {/* <Row className="mt-4 align-items-center">
            <Col md={11} xs={10}>
              <h4 className="mb-0">Filtro</h4>
            </Col>
            <Col md={1} xs={2}>
              <AiOutlineFilter onClick={() => 'd'} className="icon-md" />
            </Col>
          </Row>
          <Row className="mt-1 align-items-center">
            <Col>
              <hr />
            </Col>
          </Row> */}
          <Row className="mb-4">
            <Col lg={3} md={4} sm={12} xs={12}>
              {renderFilterProducts}
            </Col>
            <Col lg={9} md={8}>
              <Alert
                variant={"secondary"}
                className="mt-4 mt-md-0 mx-0 mx-md-3"
              >
                Se encontraron{" "}
                {state.queryResults ? state.queryResults.count : 0} resultados
              </Alert>
              <Row>
                {isLoading ? (
                  <Col md={12} lg={12} sm={12} style={{ marginTop: 55 }}>
                    <h5 className="text-center">
                      {" "}
                      <Spinner animation="border" role="status"></Spinner>
                    </h5>
                  </Col>
                ) : (
                  state.queryResults &&
                  !isEmpty(state.queryResults.results) &&
                  state.queryResults?.results?.map((item, i) => (
                    <Col
                      xl={3}
                      lg={4}
                      md={6}
                      sm={6}
                      key={i}
                      style={{ marginTop: 5 }}
                    >
                      <ItemList key={i} data={item} />
                    </Col>
                  ))
                )}
                {state.queryResults?.next !== null && !isLoading && (
                  <Col md={12} lg={12} sm={12} style={{ marginTop: 55 }}>
                    <h5 className="text-center">
                      {" "}
                      <Spinner animation="border" role="status"></Spinner>
                    </h5>
                  </Col>
                )}
              </Row>
              <div
                className="content-bottom"
                style={{ height: "20px" }}
                ref={contentListBottom}
              ></div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default DynamicFilter;
