import { methodGet, methodDelete, methodPost, methodPut } from './methods';
import { API_INFO } from '../constants';

export async function getDetailProduct(id, query) {
  const url = API_INFO + 'products/products/' + id + '/';
  return methodGet(url, query);
}

export async function getRelatedProduct(id, query) {
  const url = API_INFO + `products/products/${id}/get_releated_products/`;
  return methodGet(url, query);
}

export async function getPublishedCountries(query) {
  const url = API_INFO + `products/products/get_published_countries/`;
  return methodGet(url, query);
}

export async function getLanguageProducts(query) {
  const url = API_INFO + `products/products/get_lan_products/`;
  return methodGet(url, query);
}

export async function searchProduct(query) {
  // new version (Elasticsearch), to replace searchFilterProduct
  const url = API_INFO + `products/products/search_products/`;
  return methodGet(url, query);
}

export async function searchFilterProduct(query, page) {
  const isURLqueryString = typeof query === 'string';
  const actualPage = `&page=${page}`;
  const url = API_INFO + `products/products/filter_products/${isURLqueryString ? query : ''}`;
  return isURLqueryString ? methodGet(url) : methodGet(url, query, actualPage);
}

export async function listAudiencia() {
  const url = API_INFO + 'products/products/get_clasification_products/';
  return methodGet(url, {});
}

export const slidersClient = () => {
  const url = API_INFO + 'products/sliders-client/';
  return methodGet(url, {});
};

export const getRangeDatePublished = () => {
  const url = API_INFO + 'products/products/get_range_fecha_public/';
  return methodGet(url, {});
};

export async function removeItemFav(payload) {
  const url = API_INFO + `products/products/${payload.url}/remove_saved_user/`;
  return methodDelete(url, payload);
}

export async function getAllAuthors(page = 1, pageSize = 10, nombre_autor = '') {
  const url =
    API_INFO +
    `products/authors/?page=${page}&page_size=${pageSize}${nombre_autor ? '&nombre_autor=' + nombre_autor : ''}`;
  return methodGet(url);
}
export async function getAuthor(urlParam) {
  const url = API_INFO + `products/authors/${urlParam}/`;
  return methodGet(url);
}
export async function deleteAuthor(urlParam) {
  const url = API_INFO + `products/authors/${urlParam}/`;
  return methodDelete(url);
}
export async function editAuthor(payload) {
  const url = API_INFO + `products/authors/${payload.url}/`;
  return methodPut(url, payload);
}
export async function createAuthor(payload) {
  const url = API_INFO + `products/authors/`;
  return methodPost(url, payload);
}

export async function getProductsByAuthor(slug, page) {
  const url = API_INFO + `products/authors/${slug}/get-products?page=${page}`;
  return methodGet(url, {});
}

export async function getAllEditorials(page = 1, pageSize = 10, search = '') {
  const url = API_INFO + `products/editorials?page=${page}&page_size=${pageSize}${search ? '&search=' + search : ''}`;
  return methodGet(url);
}
export async function deleteEditorial(slug) {
  const url = API_INFO + `products/editorials/${slug}/`;
  return methodDelete(url);
}
export async function editEditorial(payload) {
  const url = API_INFO + `products/editorials/${payload.url}/`;
  return methodPut(url, payload);
}
export async function createEditorial(payload) {
  const url = API_INFO + `products/editorials/`;
  return methodPost(url, payload);
}
export async function getDetailEditorial(slug) {
  const url = API_INFO + `products/editorials/${slug}/`;
  return methodGet(url, {});
}

export async function getProductsByEditorial(slug, page) {
  const url = API_INFO + `products/editorials/${slug}/get-products?page=${page}`;
  return methodGet(url, {});
}

export async function getStockProviders(id) {
  const url = API_INFO + `products/products/${id}/get_stock_cegal`;
  return methodGet(url, {});
}

export const postUserReview = async (payload) => {
  const url = API_INFO + 'products/products/comentary-product/';
  return methodPost(url, payload);
};

export async function getAllDiscountCoupons(page, page_size, coupon, type_discount, is_active) {
  const firstVal =
    API_INFO +
    `products/discount-coupons/?&coupon=${coupon}&type_discount=${type_discount}&is_active=${is_active}&page=${page}&page_size=${page_size}`;
  const secondVal = API_INFO + `products/discount-coupons/?page=${page}&page_size=${page_size}`;
  const url = coupon || type_discount || is_active ? firstVal : secondVal;
  const testing = await methodGet(url);
  return testing;
}
export async function getDiscountCoupon(id) {
  const url = API_INFO + `products/discount-coupons/${id}/`;
  return methodGet(url);
}
export async function createDiscountCoupon(payload) {
  const url = API_INFO + `products/discount-coupons/`;
  return methodPost(url, payload);
}
export async function editDiscountCoupon(payload) {
  const url = API_INFO + `products/discount-coupons/${payload.id}/`;
  return methodPut(url, payload);
}
export async function deleteDiscountCoupon(id) {
  const url = API_INFO + `products/discount-coupons/${id}/`;
  return methodDelete(url);
}
export async function getValidateDiscount(value) {
  const url = API_INFO + `products/discount-coupons/validate-discount/?coupon=${value}`;
  return methodGet(url);
}
export async function getAvailabilityNotificatonSubscriptions(pageNumber = 1, pageSize = 10, search = '') {
  const url =
    API_INFO +
    `products/notify-availability/?page=${pageNumber}&page_size=${pageSize}${search ? '&search=' + search : ''}`;
  return methodGet(url);
}
export async function getRestockNotificationSubscription(id) {
  const url = API_INFO + `products/notify-availability/${id}/`;
  return methodGet(url);
}
export async function deleteEmailAvailabilityNotificationSubscription(id) {
  const url = API_INFO + `products/notify-availability/${id}/`;
  return methodDelete(url);
}
export async function updateEmailAvailabilityNotificationSubscription(payload) {
  const url = API_INFO + `products/notify-availability/${payload.id}/`;
  return methodPut(url, payload);
}
export async function postNotifyAvailability(payload) {
  const url = API_INFO + 'products/notify-availability/';
  return methodPost(url, payload);
}

export async function getAuthors(query) {
  let url = API_INFO + `products/authors/?page_size=1000${query ? '&nombre_autor=' + query : ''}`;
  return methodGet(url);
}

export async function getKeywords() {
  const url = API_INFO + `products/palabras-claves/`;
  return methodGet(url, {});
}

export async function postKeyword(payload) {
  const url = API_INFO + 'products/palabras-claves/';
  return methodPost(url, payload);
}

export async function deleteKeyword(id) {
  const url = API_INFO + `products/palabras-claves/${id}/`;
  return methodDelete(url, {});
}

export const editKeyword = async (id, payload) => {
  const url = API_INFO + `products/palabras-claves/${id}/`;
  return methodPut(url, payload);
};
